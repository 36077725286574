import React,{history,useEffect, useState, useMemo } from "react";
import services from "../../services/services";
import $, { parseJSON } from 'jquery';

const Ourlibrary = () => {

    const companyIdChkLogin=sessionStorage.getItem("logCompanyId");
    const studentIdChkLogin=sessionStorage.getItem("logStudentId");
    const jobSeekerIdChkLogin=sessionStorage.getItem("logProfileId");
    const userIdChkLogin=sessionStorage.getItem("logUserId");
    const chKRoleAr=sessionStorage.getItem("roleAr");
    const [ourlibrariesContent, setOurlibrariesContent] = useState([]);
    const getPageInfo = (reqopt) => {

        
        services.getCMSPageContent('10').then((res) => {
            
            console.log(JSON.stringify(res));
            if(res.data){
                setOurlibrariesContent(res.data.content.rendered);
            }
       
        });
        /*
        $.ajax({
           // url: 'http://strapi-development-alb-930632520.us-east-2.elb.amazonaws.com/pages/2',
           url: process.env.CMS_PAGES_API+'wp-json/wp/v2/pages/10',
            
            type: 'get',
            dataType: 'json',
            contentType: 'application/json',
            success: function (data) {
                //$('#target').html(data.msg);
                console.log(JSON.stringify(data));
                //var temp = data.data;
              //  console.log(temp);
                //var desc = temp.attributes.Description;
                var desc = data.Description;
                desc = desc.replace(/\\/g, '');
                setOurlibrariesContent(desc);
                //setDownloadAppContent(data.attributes.Description);
            }
          
        });
        */

        /*
        services.getPageInfo_downloadourapp().then((res) => {
            
            console.log(JSON.stringify(res));
       
        });
        */
     }

    useEffect(() => {        
        getPageInfo("downloadourapp");
  
    }, []);
  return(
  <>
<main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                    <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb">
                        <ol class="breadcrumb">
                                
                            {(() => {

                                if( jobSeekerIdChkLogin != null && jobSeekerIdChkLogin != "null"){
                                    return (<><li class="breadcrumb-item headtitle"> <a href="/profileDashboard">Job Seeker Dashboard</a> </li> </>);
                                }

                                if( chKRoleAr == "schooladmin"  || chKRoleAr == "schoolsuper" ){
                                return (<><li class="breadcrumb-item headtitle"> <a href="/schoolAdminDashboard">School Admin</a> </li> </>);
                                }



                                if( studentIdChkLogin != null && studentIdChkLogin != "null"){
                                    return (<><li class="breadcrumb-item headtitle"> <a href="/studentDashboard">Student Dashboard</a> </li> </>);
                                }

                                })()}

                                <li class="breadcrumb-item headtitle "><a href="#">Our Libraries</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
 
        <section class="company-detail-wrap py-5 ">
            <div class="container">
            <div class="row">
            <div class="col-lg-12">
                        <div class="white-bg px-5 pt-5 pb-4">
                            <div class="row mb-3">
                                <div class="col-lg-12 mb-4">
                                <div dangerouslySetInnerHTML={{ __html: ourlibrariesContent }} />
                </div> </div> </div> </div> 
                </div> 
            
            </div>
            
        </section>

    </main>
  </>
)};

Ourlibrary.propTypes = {};

Ourlibrary.defaultProps = {};

export default Ourlibrary;
