import React from "react";
import {
  Routes,
  Route ,
  Navigate
} from "react-router-dom";

import Home from "../../components/home/home";
import StudentDashboard from "../../components/student/student-dasboard";
import ProfileDashboard from "../../components/profile/profile-dasboard";
import StudentRegistration from "../../components/student/student-registration";
import EmployeeManufacturing from "../../components/employee/emp-manufacturing";
import EmployerDirectory from "../../components/employee/employer-directory";
import AvailableJobs from "../../components/Jobs/available-jobs";
import AvailableJobs2 from "../../components/Jobs/available-jobs2";
import LocallifeCommunities from "../../components/locallife/locallife-communities";
import Leisure from "../../components/locallife/leisure";
import LocallifeSchool from "../../components/locallife/locallife-school";
import PostJob from "../../components/Jobs/post-job";
import CompanyDashboard from "../../components/company/company-dasboard";
import CompanyLogo from "../../components/company/company-logo";
import CompanyRegister from "../../components/company/company-register";
import PostResume from "../../components/Jobs/post-resume";
import ProfileInfo from "../../components/profile/profile-info";
import VideoUpload from "../../components/video/video-upload";
import VideoLibrary from "../../components/video/video-library";
import router from "../../router/router";
import Aboutus from "../../components/aboutus/aboutus";
import LocalCompanyMap from "../../components/locallife/local-company-map";
import Contactus from "../../components/home/contactus";
import Blog from "../../components/resources/blog";
import BlogDetails from "../../components/resources/blog-detail";
import CompanyDetails from "../../components/company/company-details";
import JobsByCompany from "../../components/company/jobs-by-company";
import CompanySummary from "../../components/company/company-summary";
import Login from "../../components/profile/login";
import Logout from "../../components/profile/logout";
import faq from "../../components/faq/faq";
import Faq from "../../components/faq/faq";
import Industries from "../../components/industries";
import PrivacyPolicy from "../../components/privacypolicy";
import Events from "../../components/events";
import Forgotpassword from "../../components/profile/forgot_pwd";
import SubscribeToCareer from "../../components/home/subscribe-to-career";
import Ourlibrary from "../../components/home/ourlibraries";
import HelpLink from "../../components/home/hlplink";
import RelevantArticles from "../../components/home/relevantarticles";
import CompanyJobs from "../../components/company/company-jobs";
import PersonalProfiles from "../../components/profile/personal-profiles";
import StudentList from "../../components/profile/student-list";
import StudentListBySchool from "../../components/profile/student-list-by-school";
import StudentReportBySchool from "../../components/profile/student-report-by-school";
import PersonalProfileView from "../../components/profile/personal-profile-view";
import StudentProfileView from "../../components/profile/student-profile-view";
import CareerView from "../../components/career/CareerView";
import CareerExploreJobs from "../../components/career/CareerExploreJobs";
import EmailNotification from "../../components/email-notification";
import EditAdminstrationLogin from "../../components/edit-adminstration-login";
import WageData from "../../components/wage-data";
import WageSurvey from "../../components/wage-survey";
import WageSurveyMulti from "../../components/wage-survey-multi";
import JobApplicantProfileView from "../../components/profile/job-applicant-profile-view";
import EditStudent from "../../components/profile/edit-student";

import CompanyUsers from "../../components/company/company-users";
import PerksList from "../../components/company/perks-list";
import CompanyJobApplications from "../../components/company/company-job-applications";
import DownloadApp from "../../components/download-app";
import HelpfullLinks from "../../components/helpfull-links";
import ProfileBanner from "../../components/profile/profile-banner";
import PublicProfileView from "../../components/profile/public-profile-view";
import ManageProfile from "../../components/profile/manage-profile";  
import MovingToTheArea from "../../components/moving-to-the-area"; 
import SampleCareerPath from "../../components/sample-career-path"; 
import MarketSupport from "../../components/market-support";
import ResumeDasboard from "../../components/Jobs/resume-dasboard";
import WatchVideos from "../../components/video/watch-videos";
import UpdateProfle from "../../components/profile/update-profle";
import ApplyJobProfile from "../../components/profile/apply-job-profile";
import ViewResume from "../../components/profile/view-resume";
import UpdateStudent from "../../components/profile/update-student";
import Thankyou from "../../utility/thankyou";

import EducationTraining from "../../components/education-training";
import SchoolDetails from "../../components/student/school-details";
import CompanyListByIndustry from "../../components/company-list-by-industry";

import SchoolList from "../../components/student/school-list";
import StatsFacts from "../../components/stats";
import CompanyEditJob from "../../components/company/company-edit-job";
import CompanyCopyJob from "../../components/company/company-copy-job";
import ResumeDashboard from "../../components/Jobs/resume-dasboard";
import EventDetails from "../../components/events-view-detail";
import NotificationDetails from "../../components/notification-view-detail";
import ConvertToProfile from "../../components/student/convert-to-profile";
import EducationTrainingDetails from "../../components/student/education-training-details";
import AnnouncementWidget from "../../components/Jobs/announcement-wid";
import SchoolAdminDashboard from "../../components/student/school-admin-dasboard";
import WageSurveyData from "../../components/wageSurveyData";
import HTOADV from "../../components/htoadv";




function MainContent(props) 
{
  const routes = router.getInstance().getRoutesInfo([
    "htoadv"  
  ]);
  //const classes = useStyles();

  return (
      // <Switch>
      //   <Route path={routes.home.path} component={Home} />
      //   <Redirect from="/home" to={routes.home.path} />

      // </Switch>

      <Routes>
          <Route path='/home' element={<HTOADV/>} />
          <Route path='/htoadv' element={<HTOADV/>} />
          <Route path="*" element={<Navigate to ="/Home" />}/>

          <Route path='/aboutus' element={<Aboutus/>} />
          <Route path="/aboutus" element={<Navigate to ="/aboutus" />}/>

          <Route path='/faq' element={<Faq/>} />
          <Route path="/faq" element={<Navigate to ="/faq" />}/>

          <Route path='/industries' element={<Industries/>} />
          <Route path="/industries" element={<Navigate to ="/Industries" />}/>

          <Route path='/events' element={<Events/>} />
          <Route path="/events" element={<Navigate to ="/Events" />}/>

          <Route path='/eventDetails' element={<EventDetails/>} />
          <Route path="/eventDetails" element={<Navigate to ="/EventDetails" />}/>

          <Route path='/notificationDetails' element={<NotificationDetails/>} />
          <Route path="/notificationDetails" element={<Navigate to ="/NotificationDetails" />}/>
          
        
          <Route path='/privacyPolicy' element={<PrivacyPolicy/>} />
          <Route path="/privacyPolicy" element={<Navigate to ="/PrivacyPolicy" />}/>

          <Route path='/subscribeToCareer' element={<SubscribeToCareer/>} />
          <Route path="/subscribeToCareer" element={<Navigate to ="/SubscribeToCareer" />}/>
          
          <Route path='/contactus' element={<Contactus/>} />
          <Route path="/contactus" element={<Navigate to ="/contactus" />}/>

          <Route path='/companyDetails' element={<CompanyDetails/>} />
          <Route path="/companyDetails" element={<Navigate to ="/CompanyDetails" />}/>


          <Route path='/companyListByIndustry' element={<CompanyListByIndustry/>} />
          <Route path="/companyListByIndustry" element={<Navigate to ="/CompanyListByIndustry" />}/>

          

          <Route path='/jobsByCompany' element={<JobsByCompany/>} />
          <Route path="/jobsByCompany" element={<Navigate to ="/JobsByCompany" />}/>

          <Route path='/jobSummary' element={<CompanySummary/>} />
          <Route path="/jobSummary" element={<Navigate to ="/companySummary" />}/>

          <Route path='/blog' element={<Blog/>} />
          <Route path="/blog" element={<Navigate to ="/blog" />}/>

          <Route path='/localCompanyMap' element={<LocalCompanyMap/>} />
          <Route path="/localCompanyMap" element={<Navigate to ="/localCompanyMap" />}/>

          

          <Route path='/studentDashboard' element={<StudentDashboard/>} />
          <Route path="/studentDashboard" element={<Navigate to ="/StudentDashboard" />}/>

          <Route path='/schoolAdminDashboard' element={<SchoolAdminDashboard/>} />
          <Route path="/schoolAdminDashboard" element={<Navigate to ="/SchoolAdminDashboard" />}/>
          

          <Route path='/profileDashboard' element={<ProfileDashboard/>} />
          <Route path="/profileDashboard" element={<Navigate to ="/ProfileDashboard" />}/>

          <Route path='/studentRegistration' element={<StudentRegistration/>} />
          <Route path="/studentRegistration" element={<Navigate to ="/StudentRegistration" />}/>
          
          <Route path='/employeeManufacturing' element={<EmployeeManufacturing/>} />
          <Route path="/employeeManufacturing" element={<Navigate to ="/EmployeeManufacturing" />}/>

          <Route path='/employerDirectory' element={<EmployerDirectory/>} />
          <Route path="/employerDirectory" element={<Navigate to ="/EmployerDirectory" />}/>

          <Route path='/availableJobs' element={<AvailableJobs/>} />
          <Route path="/availableJobs" element={<Navigate to ="/AvailableJobs" />}/>

          <Route path='/availableJobs2' element={<AvailableJobs2/>} />
          <Route path="/availableJobs2" element={<Navigate to ="/AvailableJobs2" />}/>

          <Route path='/locallifeCommunities' element={<LocallifeCommunities/>} />
          <Route path="/locallifeCommunities" element={<Navigate to ="/LocallifeCommunities" />}/>

          <Route path='/leisure' element={<Leisure/>} />
          <Route path="/leisure" element={<Navigate to ="/leisure" />}/>
          

          <Route path='/locallifeSchool' element={<LocallifeSchool/>} />
          <Route path="/locallifeSchool" element={<Navigate to ="/LocallifeSchool" />}/>

          <Route path='/postJob' element={<PostJob/>} />
          <Route path="/postJob" element={<Navigate to ="/PostJob" />}/>

          <Route path='/companyDashboard' element={<CompanyDashboard/>} />
          <Route path="/companyDashboard" element={<Navigate to ="/CompanyDashboard" />}/>

          <Route path='/companyLogo' element={<CompanyLogo/>} />
          <Route path="/companyLogo" element={<Navigate to ="/CompanyLogo" />}/>

          <Route path='/companyRegister' element={<CompanyRegister/>} />
          <Route path="/companyRegister" element={<Navigate to ="/CompanyRegister" />}/>


          <Route path='/convertToProfile' element={<ConvertToProfile/>} />
          <Route path="/convertToProfile" element={<Navigate to ="/convertToProfile" />}/>

          <Route path='/postResume' element={<PostResume/>} />
          <Route path="/postResume" element={<Navigate to ="/PostResume" />}/>

          <Route path='/jobApplicantProfileView' element={<JobApplicantProfileView/>} />
          <Route path="/jobApplicantProfileView" element={<Navigate to ="/JobApplicantProfileView" />}/>

          <Route path='/profileInfo' element={<ProfileInfo/>} />
          <Route path="/profileInfo" element={<Navigate to ="/ProfileInfo" />}/>

          <Route path='/videoUpload' element={<VideoUpload/>} />
          <Route path="/videoUpload" element={<Navigate to ="/VideoUpload" />}/>

          <Route path='/videoLibrary' element={<VideoLibrary/>} />
          <Route path="/videoLibrary" element={<Navigate to ="/VideoLibrary" />}/>

          <Route path='/ourlibrary' element={<Ourlibrary/>} />
          <Route path="/ourlibrary" element={<Navigate to ="/ourlibrary" />}/>

         
          

          
          <Route path='/RelevantArticles' element={<RelevantArticles/>} />
          <Route path="/RelevantArticles" element={<Navigate to ="/RelevantArticles" />}/>

          <Route path='/login' element={<Login/>} />
          <Route path="/login" element={<Navigate to ="/Login" />}/>

          <Route path='/logout' element={<Logout/>} />
          <Route path="/logout" element={<Navigate to ="/Logout" />}/>
    
          <Route path='/forgotPwd' element={<Forgotpassword/>} />
          <Route path="/forgotPwd" element={<Navigate to ="/forgotPwd" />}/>

          <Route path='/companyJobs' element={<CompanyJobs/>} />
          <Route path="/companyJobs" element={<Navigate to ="/CompanyJobs" />}/>

          <Route path='/personalProfiles' element={<PersonalProfiles/>} />
          <Route path="/personalProfiles" element={<Navigate to ="/PersonalProfiles" />}/>

          
          <Route path='/studentList' element={<StudentList/>} />
          <Route path="/studentList" element={<Navigate to ="/StudentList" />}/>

          <Route path='/studentListBySchool' element={<StudentListBySchool/>} />
          <Route path="/studentListBySchool" element={<Navigate to ="/StudentListBySchool" />}/>

          <Route path='/studentReportBySchool' element={<StudentReportBySchool/>} />
          <Route path="/studentReportBySchool" element={<Navigate to ="/StudentReportBySchool" />}/>

          
          <Route path='/personalProfileView' element={<PersonalProfileView/>} />
          <Route path="/personalProfileView" element={<Navigate to ="/PersonalProfileView" />}/>

          <Route path='/studentProfileView' element={<StudentProfileView/>} />
          <Route path="/studentProfileView" element={<Navigate to ="/StudentProfileView" />}/>
          

          <Route path='/careerView' element={<CareerView/>} />
          <Route path="/careerView" element={<Navigate to ="/CareerView" />}/>

          <Route path='/careerExploreJobs' element={<CareerExploreJobs/>} />
          <Route path="/careerExploreJobs" element={<Navigate to ="/CareerExploreJobs" />}/>

          <Route path='/emailNotification' element={<EmailNotification/>} />
          <Route path="/emailNotification" element={<Navigate to ="/EmailNotification" />}/>

          <Route path='/editAdminstrationLogin' element={<EditAdminstrationLogin/>} />
          <Route path="/editAdminstrationLogin" element={<Navigate to ="/EditAdminstrationLogin" />}/>

          <Route path='/wageData' element={<WageData/>} />
          <Route path="/wageData" element={<Navigate to ="/WageData" />}/> 

          <Route path='/wageSurveyData' element={<WageSurveyData/>} />
          <Route path="/wageSurveyData" element={<Navigate to ="/wageSurveyData" />}/>         

          {/* <Route path='/wageSurvey' element={<WageSurvey/>} />
          <Route path="/wageSurvey" element={<Navigate to ="/WageSurvey" />}/>      */}


          <Route path='/wageSurvey' element={<WageSurveyMulti/>} />
          <Route path="/wageSurvey" element={<Navigate to ="/WageSurvey" />}/>     

               

          <Route path='/companyUsers' element={<CompanyUsers/>} />
          <Route path="/companyUsers" element={<Navigate to ="/CompanyUsers" />}/>

          <Route path='/perksList' element={<PerksList/>} />
          <Route path="/perksList" element={<Navigate to ="/PerksList" />}/>

          <Route path='/companyJobApplications' element={<CompanyJobApplications/>} />
          <Route path="/companyJobApplications" element={<Navigate to ="/CompanyJobApplications" />}/>

          <Route path='/helpfullLinks' element={<HelpfullLinks/>} />
          <Route path="/helpfullLinks" element={<Navigate to ="/HelpfullLinks" />}/>

          <Route path='/helplink' element={<HelpfullLinks/>} />
          <Route path="/helplink" element={<Navigate to ="/HelpfullLinks" />}/>


          <Route path='/downloadApp' element={<DownloadApp/>} />
          <Route path="/downloadApp" element={<Navigate to ="/DownloadApp" />}/>

          <Route path='/educationTrainingDetails/:slug' element={<EducationTrainingDetails/>} />
          <Route path="/educationTrainingDetails/:slug" element={<Navigate to ="/educationTrainingDetails" />}/>
          
          <Route path='/schoolDetails' element={<SchoolDetails/>} />
          <Route path="/schoolDetails" element={<Navigate to ="/schoolDetails" />}/>
          
          {/*<Route path='/schoolDetails/:schoolId' element={<SchoolDetails/>} />
          <Route path="/schoolDetails/:schoolId" element={<Navigate to ="/schoolDetails" />}/> */}

          <Route path='/blogDetails' element={<BlogDetails/>} />
          <Route path="/blogDetails" element={<Navigate to ="/blogDetails" />}/>

         {/* <Route path='/blogDetails/:blogId' element={<BlogDetails/>} />
          <Route path="/blogDetails/:blogId" element={<Navigate to ="/blogDetails" />}/> */}
          
          <Route path='/publicProfileView' element={<PublicProfileView/>} />
          <Route path="/publicProfileView" element={<Navigate to ="/PublicProfileView" />}/>


          <Route path='/profileBanner' element={<ProfileBanner/>} />
          <Route path="/profileBanner" element={<Navigate to ="/ProfileBanner" />}/>

          <Route path='/manageProfile' element={<ManageProfile/>} />
          <Route path="/manageProfile" element={<Navigate to ="/ManageProfile" />}/>

          <Route path='/movingToTheArea' element={<MovingToTheArea/>} />
          <Route path="/movingToTheArea" element={<Navigate to ="/MovingToTheArea" />}/>

          <Route path='/sampleCareerPath' element={<SampleCareerPath/>} />
          <Route path="/sampleCareerPath" element={<Navigate to ="/SampleCareerPath" />}/>
          

          <Route path='/marketSupport' element={<MarketSupport/>} />
          <Route path="/marketSupport" element={<Navigate to ="/MarketSupport" />}/>

          <Route path='/resumeDashboard' element={<UpdateProfle/>} />
          <Route path="/resumeDashboard" element={<Navigate to ="/resumeDashboard" />}/>

          <Route path='/watchVideos' element={<WatchVideos/>} />
          <Route path="/watchVideos" element={<Navigate to ="/WatchVideos" />}/>

          <Route path='/updateProfile' element={<UpdateProfle/>} />
          <Route path="/updateProfile" element={<Navigate to ="/UpdateProfle" />}/>

          <Route path='/applyJobProfile' element={<ApplyJobProfile/>} />
          <Route path="/applyJobProfile" element={<Navigate to ="/ApplyJobProfile" />}/>

          <Route path='/viewResume' element={<ViewResume/>} />
          <Route path="/viewResume" element={<Navigate to ="/ViewResume" />}/>


          <Route path='/updateStudent' element={<UpdateStudent/>} />
          <Route path="/updateStudent" element={<Navigate to ="/UpdateStudent" />}/>

          <Route path='/editStudent' element={<EditStudent/>} />
          <Route path="/editStudent" element={<Navigate to ="/EditStudent" />}/>

          

          <Route path='/thankyou' element={<Thankyou/>} />
          <Route path="/thankyou" element={<Navigate to ="/Thankyou" />}/>
          
          <Route path='/educationTraining' element={<EducationTraining/>} />
          <Route path="/educationTraining" element={<Navigate to ="/EducationTraining" />}/>


          <Route path='/schoolList' element={<SchoolList/>} />
          <Route path="/schoolList" element={<Navigate to ="/SchoolList" />}/>
          

          <Route path='/statsFacts' element={<StatsFacts/>} />
          <Route path="/statsFacts" element={<Navigate to ="/StatsFacts" />}/>

          <Route path='/companyEditJob' element={<CompanyEditJob/>} />
          <Route path="/companyEditJob" element={<Navigate to ="/CompanyEditJob" />}/>

          <Route path='/companyCopyJob' element={<CompanyCopyJob/>} />
          <Route path="/companyCopyJob" element={<Navigate to ="/companyCopyJob" />}/>

          
      </Routes>

  );
}

export default MainContent;
