import React, { history, useEffect, useState, useMemo } from "react";
import Quick_links from "../../utility/Quick_links";
import Hiring from "../Jobs/Hiring";
import services from "../../services/services";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import { useNavigate,useParams } from "react-router-dom";
const PerksList = () => {

    // const { companyId } = useParams(); 
    //const companyId=24;
    const companyId=sessionStorage.getItem("logCompanyId");
    
   
    const navigate = useNavigate();
      
    const [companyUsers, setCompanyUsers] = useState([]);
    const [perkCategory, sePerkCategory] = useState([]);
    const [perkList, sePerkList] = useState([]);
    const [existingPerks, setExistingPerks] = useState([]);
    //const [perkIdExisting, setPerkIdExisting] = useState([]);
    
    var perkIdExisting = [];
    const getPerkCategoryList = () => {
        services.getPerkCategoryList().then((res) => {    
          console.log("Industry data=" + JSON.stringify(res.data));    
          sePerkCategory(res.data);    
          
        });    
      }
     
      const getPerksByCatId = (perkCatId) => {
        services.getPerksByCategoryId(perkCatId).then((res) => {    
          if(res.data){
            sePerkList(res.data);    
           
            //alert(JSON.stringify(existingPerks))
            //alert(JSON.stringify(perkIdExisting))
            
            
            $('input[name="perkIdInfo"]').prop("checked", false);
            $('input[name="perkIdInfo"]').attr("checked", false);
            setTimeout(() => {
                for (var i = 0; i < perkIdExisting.length; i++) {
                  //$("input[value='" + val + "']").prop('checked', true);                 
                  console.log("###" + perkIdExisting[i]);                 
                  //$('input[name="perkIdInfo"][value="' + perkIdExisting[i] + '"]').trigger("click");
                  $('input[name="perkIdInfo"][value="' + perkIdExisting[i] + '"]').prop("checked", true);
                  $('input[name="perkIdInfo"][value="' + perkIdExisting[i] + '"]').attr("checked", true);
                }             
              
              }, 1000);

            $("#addPerksBtnDiv").show();
          }
         
        });    
      }

      const getCompanyProfileInfo = (companyId) => {
        services.getCompanyProfile(companyId).then((res) => {
            console.log("#####44");    
          if(res.data){
            setExistingPerks(res.data.perksList);   
            
            var ptemp =  res.data.perksList;
            var ptemAr = [];
            perkIdExisting = [];
            for(var p=0;p<ptemp.length;p++){
                 console.timeLog("#####52");
                 ptemAr.push(ptemp[p].perksId);
                 perkIdExisting.push(ptemp[p].perksId);
            }
            console.log("#####52");
            console.log(JSON.stringify(ptemAr));
           // setPerkIdExisting(ptemAr);
            //alert(JSON.stringify(ptemAr));
            
          
           /*
            setTimeout(() => {
              
                alert("87"+JSON.stringify(perkIdExisting));
                alert("87"+JSON.stringify(existingPerks));
              }, 3000);
           */
           

            
          }
         
        });    
      }
    useEffect(() => {
        if(companyId == null || companyId == "null"){            
            navigate('/login');
          }
          $("#successMessage").hide();
          $("#addPerksBtnDiv").hide();
          $("#successMessageDel").hide();
          getPerkCategoryList();
          getCompanyProfileInfo(companyId);

          $( "#perkCategoryId" ).change(function() {
            var pcId = $("#perkCategoryId").val();
            getPerksByCatId(pcId);
          });

          $( "#removeCompanyPerk" ).click(function() {
            
            removeCompanyPerk();
          });

          $( "#addPerksBtn" ).click(function() {
            
            addPerkstoCompany();
          });
          
          

    }, []);

    
    const addPerkstoCompany = () => {
        
        var pcId = $("#perkCategoryId").val();
        var companyUserPerkSelectArray = [];
        $.each($("input[name='perkIdInfo']:checked"), function(){
            companyUserPerkSelectArray.push($(this).val());
        });
        const requestObject=
        {
           
            "siteId": process.env.React_App_SITE_ID,
            "companyId": companyId,     
            "parkCategoryId": pcId,           
            "parkList": companyUserPerkSelectArray           
           
        }

        var accessTokenIn=sessionStorage.getItem("accToken");
       const requestOptions = {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json',
                                        'Authorization': "Bearer "+accessTokenIn 
                                        //'Authorization': accessTokenIn 
                                        },
                                body: JSON.stringify(requestObject)
                            };
        services.addPerksToCompany(requestOptions).then((res) => 
        {
           //getCompanyProfileInfo();    
            //getCompanyProfileInfo(companyId);
            $("#successMessage").fadeTo(2000, 500).slideUp(500, function () {
                $("#successMessage").slideUp(500);
              });
           setTimeout(() => {getCompanyProfileInfo(companyId);}, 1000);
        });
    }

    const removeCompanyPerk = () => {
       
        var companyUserPerkArray = [];

        $.each($("input[name='companyuserperk']:checked"), function(){
            companyUserPerkArray.push($(this).val());
        });
        
        const requestObject=
        {
           
            "siteId": process.env.React_App_SITE_ID,
            "companyId": companyId,           
            "parkList": companyUserPerkArray           
           
        }

       console.log(requestObject);
       var accessTokenIn=sessionStorage.getItem("accToken");
       const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
                  'Authorization': "Bearer "+accessTokenIn 
                  //'Authorization': accessTokenIn 
                },
        body: JSON.stringify(requestObject)
    };
    
    services.deletePerkFromCompany(requestOptions).then((res) => 
    {
       //getCompanyProfileInfo();    
        //getCompanyProfileInfo(companyId);
        $("#successMessageDel").fadeTo(2000, 500).slideUp(500, function () {
            $("#successMessageDel").slideUp(500);
          });
       setTimeout(() => {getCompanyProfileInfo(companyId);}, 1000);
    });
        

        //setTimeout(() => {getCompanyUsers();}, 1000);
       
    }

    const getCompanyUsers = () => {
        services.getCompanyusers(companyId).then((res) => {
            console.log("Company Users=" + JSON.stringify(res.data));
            //setCompanyUsers(res.data.userList);
            setCompanyUsers(res.data.data);
        });
    }


    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all", mode: "onBlur"
    });

    const submitValue = () => {

        const requestObject=
        {
            // "userId": 62,
            "siteId": process.env.React_App_SITE_ID,
            "companyId": companyId,
            // "schoolId": null,
            // "countyId": null,
            "firstName": $("#firstName").val(),
            "lastName": $("#lastName").val(),
            "email": $("#email").val(),
            "password": $("#password").val()
            // "phone": "9948684068",
            // "isStudent": "yes",
            // "status": null,
            // "userType": "company",
            // "createdOn": "2021-04-15T18:30:00.000+00:00",
            // "updatedOn": "2021-04-15T18:30:00.000+00:00"
           
        }

       console.log(requestObject);
       var accessTokenIn=sessionStorage.getItem("accToken");
       const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
                  'Authorization': "Bearer "+accessTokenIn 
                  //'Authorization': accessTokenIn 
                },
        body: JSON.stringify(requestObject)
    };
    
   

    }


    return (
        <>
            <main class="empr-directory-main">
                <div class="main-bgpatten"></div>
                {/* <!-- info typography --> */}
                <section class="headtitle-wrap">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <nav style={{ "--bs-breadcrumb-divider": "'>';" }} aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item headtitle "><a href="/companyJobs">Company Dashboard</a> </li>
                                        <li class="breadcrumb-item headtitle "><a href="#">Company Administrator</a></li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- info typography --> */}
                <section class="py-5">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 ">
                                <div class="white-bg p-5">
                                    <div class="row">
                                        {/* <!-- left side --> */}

                                        <Quick_links />
                                        {/* <!-- right side --> */}
                                        <div class="col-lg-8 mb-4">
                                            <form id="addUserForm" onSubmit={handleSubmit(submitValue)}>
                                                <div class="row resume-wrap mb-4">
                                                    <div class="col-lg-6 mb-4">
                                                    <label for="firstName" class="form-label">Perk Category <span class="red-text">*</span></label>
                                                        <select class="form-select" aria-label="Default select example" defaultValue="1" id="perkCategoryId" >
                                                        <option selected="" value=''>Select</option>  
                                                        {
                                                        perkCategory.map((data, index) => (
                                                            <option selected="" value={data.perkCategoryId}>{data.perkTitle}</option>                                                       
                                                        ))}    
                                                        </select>
                                                        {errors.school_district && errors.school_district.type === "required" && <div class="error">School district  is required</div>}
                                                    </div>

                                                    

                                                    <div >
                                                        <div class="category-check-perks"> 
                                                            <ul class="category-perks">
                                                            {
                                                                perkList.map((data, index) => (
                                                                <li><input class="form-check-input" name="perkIdInfo" type="checkbox" value={data.perksId} id="perkId"/> <span> <img src={data.perksImageUrl} alt="" /></span> <label class="form-check-label" for="defaultCheck1"> {data.perksTitle} </label></li>
                                                            ))}    

                                                                 {/* <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <span> <img src="http://career-sites-v3.s3-website.us-east-2.amazonaws.com/images/cofee.png" alt="" /></span> <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                                                 <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <span> <img src="http://career-sites-v3.s3-website.us-east-2.amazonaws.com/images/dental.png" alt="" /></span> <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                                                 <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <span> <img src="http://career-sites-v3.s3-website.us-east-2.amazonaws.com/images/discounts.png" alt="" /></span> <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                                                 <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <span> <img src="http://career-sites-v3.s3-website.us-east-2.amazonaws.com/images/dollars.png" alt="" /></span> <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                                                 <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <span> <img src="http://career-sites-v3.s3-website.us-east-2.amazonaws.com/images/hoiliday.png" alt="" /></span> <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                                                 <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <span> <img src="http://career-sites-v3.s3-website.us-east-2.amazonaws.com/images/lunch-plate.png" alt="" /></span> <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                                                 <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <span> <img src="http://career-sites-v3.s3-website.us-east-2.amazonaws.com/images/match.png" alt="" /></span> <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                                                 <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <span> <img src="http://career-sites-v3.s3-website.us-east-2.amazonaws.com/images/sick.png" alt="" /></span> <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                                                 <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <span> <img src="http://career-sites-v3.s3-website.us-east-2.amazonaws.com/images/stocks.png" alt="" /></span> <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                                                 <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <span> <img src="http://career-sites-v3.s3-website.us-east-2.amazonaws.com/images/vision.png" alt="" /></span> <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                                                 <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <span> <img src="http://career-sites-v3.s3-website.us-east-2.amazonaws.com/images/parantal.png" alt="" /></span> <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                                                 <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <span> <img src="http://career-sites-v3.s3-website.us-east-2.amazonaws.com/images/health.png" alt="" /></span> <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                                                 <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <span> <img src="http://career-sites-v3.s3-website.us-east-2.amazonaws.com/images/life-insurance.png" alt="" /></span> <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                                                 */}
                                                            
                                                                
                                                                
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    {/* <div class="col-lg-6 mb-4">
                                                    <label for="firstName" class="form-label">Perk Item <span class="red-text">*</span></label>
                                                        <select class="form-select" aria-label="Default select example" defaultValue="1" id="school_district" >
                                                        <option selected="" value=''>Select</option>
                                                       
                                                        </select>
                                                        {errors.school_district && errors.school_district.type === "required" && <div class="error">School district  is required</div>}
                                                    </div> */}

                                                  

                                                    <div id="addPerksBtnDiv" class="col-lg-12 mb-4">

                                                        <div class="d-flex justify-content-between align-items-end choose">

                                                            <div><button id="addPerksBtn" class="reg-button">Update Perks</button></div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </form>
                                            <div id="successMessage" class="alert alert-success" role="alert">
                                           Perks has been updated successfully</div>

                                           
                                           
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="current-job-tbl mb-4">
                                                        <table class="table perk-algn">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col"></th>
                                                                    <th scope="col">Perk Category</th>
                                                                    <th scope="col">Perk Title</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                
                                                            {
                                                            existingPerks.map((data, index) => ( 
                                                                        <tr>
                                                                        <td style={{maxWidth: "35px"}}><input name="companyuserperk" value={data.perksId} class="form-check-input" type="checkbox" id="defaultCheck1" /></td>
                                                                        <td>{data.perksCatTitle}</td>
                                                                        <td className="perk-icon">
                                                                        <span> <img src={data.perksImageUrl} alt="" /></span>  {data.perksTitle} 

                                                                        </td>
                                                                        </tr>
                                                             ))}                

                                                                  
                                                               

                                                              
                                                            </tbody> 
                                                        </table>
                                                    </div>
                                                    <div class="mt-3"><a  id="removeCompanyPerk" class="reg-button">Remove Perk</a></div>
                                                    <div id="successMessageDel" class="alert alert-success" role="alert">
                                           Selected Perk(s) has been deleted successfully</div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>
                {/* <Hiring /> */}
            </main>
        </>
    )
};

PerksList.propTypes = {};

PerksList.defaultProps = {};

export default PerksList;
