import http from "../http-common"
import pubhttp from "../http-public"
 
class Service {

      

  getCompaniesByIndustryCatId(siteId,catId)
  {
    
    return pubhttp.get(`/getCompaniesByIndustryCatId?categoryId=`+catId+`&siteId=`+siteId);
    
  }

  getHomePageVideos(siteId)
  {
    return pubhttp.get(`/companyVideos?siteId=`+siteId);        
  }

      getIndustryData()
      {
        
        //return pubhttp.get(`/getIndustryData`);
        return pubhttp.get(`/getIndustryData?name&pageSize=1250&pageNumber=1`);
        
      }

      /*
      getSchoolList()
      {
        
        return pubhttp.get(`/getSchoolnData`);
        
      }
      */
      

      viewSchool(requestJson)
      {
      return fetch(process.env.React_App_AWS_API+"viewSchool",requestJson)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
    }

      getSchoolList(requestJson)
      {
      return fetch(process.env.React_App_AWS_API+"getSchoolnData",requestJson)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
    }


    getVideosBySite(requestJson)
    {
    return fetch(process.env.React_App_AWS_API+"companyVideosListBySiteId",requestJson)
    .then(response => { return response.json();})
    .then(responseData => {return responseData;})
    .catch(error => {
      console.error(error);
      return { name: "network error", description: "" };
    });
  }

      getCMSPageContent(pageId)
      {      
          return pubhttp.get(process.env.React_App_CMS_PAGES_API+'wp-json/wp/v2/pages/'+pageId);
        
      }

      getCMSPageContentBySlug(slugTxt)
      {      
          return pubhttp.get(process.env.React_App_CMS_PAGES_API+'wp-json/wp/v2/pages?slug='+slugTxt);
        
      }

      getPerkCategoryList()
      {
        return pubhttp.get(`/getPerksCategoryIdTitle`);        
      }

      getPerksByCategoryId(perkCatId)
      {
        return pubhttp.get(`/getPerksByCategoryId?perksCategoryId=`+perkCatId);        
      }

      
      getPageInfo_downloadourapp()
      {
        
        return http.get(`https://snv3.herokuapp.com/api/pages/1`);
        
      }
      getJobType()
      {
        
       // return pubhttp.get(`/getJobType`);
       return pubhttp.get(`/getJobType?name&pageSize=1250&pageNumber=1`);
        
        
      }

      getSchoolnData()
      {
        
        return pubhttp.get(`/getSchoolnData`);
        
      }

      

      getEnrolledCollege()
      {
        
        return pubhttp.get(`/getEnrolledCollege`);
        
      }

      getEducation()
      {
        
       // return pubhttp.get(`/getEducationData`);
       return pubhttp.get(`/getEducationData?name&pageSize=1250&pageNumber=1`);
        
        
      }

      getLocationCounty()
      {
        
        //return pubhttp.get(`/getJobLocationCounty`);
        return pubhttp.get(`/getJobLocationCounty?name&pageSize=1250&pageNumber=1&siteId=2`);
       
        
      }

      getSurveyWageList(requestJson)
      {
      return fetch("https://wagesurvey.smallnation.site/api/getSurveyWageList",requestJson)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
    }

      getCountyByStateFromWage(requestJson)
      {
      return fetch("https://wagesurvey.smallnation.site/api/getCountyList",requestJson)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
    }

      getStatesFromWage()
      {

        return pubhttp.get(`https://wagesurvey.smallnation.site/api/statesList`);
        
      }
      getStatesExt()
      {

        //return http.get(`https://wagesurvey.smallnation.site/api/statesList`);
        
      }
      registerCompany(requestJson)
      {
      return fetch(process.env.React_App_AWS_API+"registerCompany",requestJson)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
    }

    contactNotification(requestJson)
    {
    return fetch(process.env.React_App_AWS_API+"contactNotification",requestJson)
    .then(response => { return response.json();})
    .then(responseData => {return responseData;})
    .catch(error => {
      console.error(error);
      return { name: "network error", description: "" };
    });
  }

    


    getSearchCompanyByName(name){
      return pubhttp.get(`/searchCompanyByName?name=`+name);
    }

    getCompanyJobExpiredCurrent(companyId,status,title,pageSize,pageNumber,sortBy,sortDir,siteId){
      return http.get(`/getCompanyJobExpiredCurrent?siteId=`+siteId+`&companyId=`+companyId+`&status=`+status+`&title=`+title+`&pageSize=`+pageSize+`&pageNumber=`+pageNumber+`&sortBy=`+sortBy+`&sortDir=`+sortDir);
    }

    getCompanyJobExpiredCurrentSp(companyId,siteId){         
      //return http.get(`/getCompanyJobExpiredCurrent?companyId=`+companyId+`&status=`+status+`&title=`+title+`&pageSize=`+pageSize+`&pageNumber=`+pageNumber+`&sortBy=`+sortBy+`&sortDir=`+sortDir);
      return pubhttp.get(`getCompanyJobExpiredCurrent?siteId=`+siteId+`&companyId=`+companyId+`&status=active&title=&pageSize=100&pageNumber=1&sortBy=created_date&sortDir=desc`);
    }

    getCompanyJobExpiredCurrentSpPaging(companyId,siteId,pageSize,pageNumber){         
      //return http.get(`/getCompanyJobExpiredCurrent?companyId=`+companyId+`&status=`+status+`&title=`+title+`&pageSize=`+pageSize+`&pageNumber=`+pageNumber+`&sortBy=`+sortBy+`&sortDir=`+sortDir);
      return pubhttp.get(`getCompanyJobExpiredCurrent?siteId=`+siteId+`&companyId=`+companyId+`&status=published&title=&pageSize=`+pageSize+`&pageNumber=`+pageNumber+`&sortBy=created_date&sortDir=desc`);
    }
    

    getRecentJob(siteId,pageSize,pageNumber){
      return pubhttp.post(`/recentJob?siteId=`+siteId+`&pageSize=`+pageSize+`&pageNumber=`+pageNumber);
    }

    getEventsbySiteId(siteId,pageSize,pageNumber){
      return pubhttp.get(`/listEvent?siteId=`+siteId+`&pageSize=`+pageSize+`&pageNumber=`+pageNumber+`&searchTxt=`);
    }

    getAnnouncementbySiteId(siteId,pageSize,pageNumber){
      return pubhttp.get(`/listNotification?siteId=`+siteId+`&pageSize=`+pageSize+`&pageNumber=`+pageNumber);
    }

    getEventsbySiteIdPrevious(siteId){
      return pubhttp.get(`/listEvent?siteId=`+siteId);
    }

    getEventDetailsById(eventId){
      return http.get(`/viewEvent?eventId=`+eventId);
    }
    getEventDetailsByIdPub(eventId){
      return pubhttp.get(`/viewEvent?eventId=`+eventId);
    }

    getNotificationDetailsById(notificationId){
      return http.get(`/viewNotification?notificationId=`+notificationId);
    }
    

    getCompanyProfile(companyId){
      return pubhttp.get(`/getCompanyProfile?companyId=`+companyId);
    }

    getVideoLinkById(siteId){
      return http.get(`/getVideoLinkById?siteId=`+siteId);
    }

    getNoJobInSite(companyId){
      return pubhttp.get(`/getNoJobInSite?companyId=`+companyId);
    }
    
    getJobDetailsById(jobId){
      return pubhttp.get(`/viewJobProfile?jobId=`+jobId);
    }
    

    getUserInfo(userId){
      return http.get(`/getUserInfo?userId=`+userId);
    }
    
    getStudentProfile(studentId){
      return http.get(`/getStudentDataById?studentId=`+studentId);
    }

    getSearchCompanyByAlpha(alphabet){
      return pubhttp.get(`/searchCompanyByAlphabet?alphabet=`+alphabet);
    }

    getProfile(jobSeekerId){
      return http.get(`/getProfile?profileId=`+jobSeekerId);
    }


    getTopCompanyHiring(siteId){
      return pubhttp.get(`/getTopCompany?siteId=`+siteId);
    }
    /*
    getSearchCompanyByName(requestJson)
    {
    return fetch(process.env.React_App_AWS_API+"searchCompanyByName",requestJson)
    .then(response => { return response.json();})
    .then(responseData => {return responseData;})
    .catch(error => {
      console.error(error);
      return { name: "network error", description: "" };
    });
  }
  */

    getAvailableJobs(requestJson)
      {
      return fetch(process.env.React_App_AWS_API+"getCompanyJobList",requestJson)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
    }

    getAvailableJobs99(requestJson)
      {
       // console.log("56--->"+JSON.stringify(requestJson));
      return fetch(process.env.React_App_AWS_API+"getCompanyJobList",requestJson)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
    }


    editJob(requestJson)
      {
      return fetch(process.env.React_App_AWS_API+"editJob",requestJson)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
    }
    

    postJob(requestJson)
      {
      return fetch(process.env.React_App_AWS_API+"addCompanyJob",requestJson)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
    }


    postWageSurvey(requestJson)
      {
      return fetch("https://wagesurvey.smallnation.site/api/addSurveyWageInfo",requestJson)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
    }


    studentRegistration(requestJson)
    {
    return fetch(process.env.React_App_AWS_API+"studentRegistration",requestJson)
    .then(response => { return response.json();})
    .then(responseData => {return responseData;})
    .catch(error => {
      console.error(error);
      return { name: "network error", description: "" };
    });
  }

  publicMailchimpReqbyJobSeeker(requestJson)
    {
    var mailChimpEndPoint = "https://us14.api.mailchimp.com/3.0/lists/00683a9323/members";
    return fetch(mailChimpEndPoint,requestJson)
    .then(response => { return response.json();})
    .then(responseData => {return responseData;})
    .catch(error => {
      console.error(error);
      return { name: "network error", description: "" };
    });
  }

  profileMemberSubscribe(requestJson)
  {
  var mailChimpEndPoint = "https://us14.api.mailchimp.com/3.0/lists/00683a9323/members";
 // return fetch(mailChimpEndPoint,requestJson)

 // return fetch(process.env.React_App_AWS_API+"profileMemberSubscribe",requestJson)
var temp = "http://localhost:8082/";
return fetch(temp+"profileMemberSubscribe",requestJson)
// return fetch(process.env.React_App_AWS_API+"profileMemberSubscribe",requestJson)
  .then(response => { return response.json();})
  .then(responseData => {return responseData;})
  .catch(error => {
    console.error(error);
    return { name: "network error", description: "" };
  });
}

profileMemberSubscribeTags(requestJson)
  {
  var mailChimpEndPoint = "https://us14.api.mailchimp.com/3.0/lists/00683a9323/members";
 // return fetch(mailChimpEndPoint,requestJson)

 // return fetch(process.env.React_App_AWS_API+"profileMemberSubscribeTags",requestJson)
var temp = "http://localhost:8082/";
//return fetch(temp+"profileMemberSubscribeTags",requestJson)
 return fetch(process.env.React_App_AWS_API+"profileMemberSubscribeTags",requestJson)
  .then(response => { return response.json();})
  .then(responseData => {return responseData;})
  .catch(error => {
    console.error(error);
    return { name: "network error", description: "" };
  });
}

getProfileMemberSubscribeTags(requestJson)
{
var mailChimpEndPoint = "https://us14.api.mailchimp.com/3.0/lists/00683a9323/members";
// return fetch(mailChimpEndPoint,requestJson)

return fetch(process.env.React_App_AWS_API+"getProfileMemberSubscribeTags",requestJson)
.then(response => { return response.json();})
.then(responseData => {return responseData;})
.catch(error => {
  console.error(error);
  return { name: "network error", description: "" };
});
}

  careerMemberSubscribe(requestJson)
  {
  var mailChimpEndPoint = "https://us14.api.mailchimp.com/3.0/lists/00683a9323/members";
 // return fetch(mailChimpEndPoint,requestJson)

  return fetch(process.env.React_App_AWS_API+"careerMemberSubscribe",requestJson)
  .then(response => { return response.json();})
  .then(responseData => {return responseData;})
  .catch(error => {
    console.error(error);
    return { name: "network error", description: "" };
  });
}

  findFilterProfile(requestJson)
  {
  return fetch(process.env.React_App_AWS_API+"findFilterProfile",requestJson)
  .then(response => { return response.json();})
  .then(responseData => {return responseData;})
  .catch(error => {
    console.error(error);
    return { name: "network error", description: "" };
  });
}

findFilterStudent(requestJson)
{
  return fetch(process.env.React_App_AWS_API+"findFilterStudent",requestJson)
  .then(response => { return response.json();})
  .then(responseData => {return responseData;})
  .catch(error => {
    console.error(error);
    return { name: "network error", description: "" };
  });
}

findWithExtraFilterStudent(requestJson)
{
  return fetch(process.env.React_App_AWS_API+"findWithExtraFilterStudent",requestJson)
  .then(response => { return response.json();})
  .then(responseData => {return responseData;})
  .catch(error => {
    console.error(error);
    return { name: "network error", description: "" };
  });
}

findFilterJob(requestJson)
{
return fetch(process.env.React_App_AWS_API+"findFilterJob",requestJson)
.then(response => { return response.json();})
.then(responseData => {return responseData;})
.catch(error => {
  console.error(error);
  return { name: "network error", description: "" };
});
}

getSchoolAdminInfoByUserId(userId)
{
  return http.get(`getSchoolAdminInfoByUserId?userId=`+userId);
  //return http.get(`getCompanyJobExpiredCurrent?companyId=`+companyId+`&status=active&title=&pageSize=100&pageNumber=1&sortBy=&sortDir=`);

}

favCompanyVideosListByUserId(userId,pageNumber,pageSize)
{
  return http.get(`favCompanyVideosListByUserId?userId=`+userId+`&pageNumber=`+pageNumber+`&pageSize=`+pageSize);
  //return http.get(`getCompanyJobExpiredCurrent?companyId=`+companyId+`&status=active&title=&pageSize=100&pageNumber=1&sortBy=&sortDir=`);
/*
  return fetch(process.env.React_App_AWS_API+"favCompanyVideosListByUserId",requestJson)
.then(response => { return response.json();})
.then(responseData => {return responseData;})
.catch(error => {
  console.error(error);
  return { name: "network error", description: "" };
});
*/
}

jobShadowCompanyListByUserId(siteId,pageNumber,pageSize)
{
  return http.get(`getShadowConcernCompany?siteId=`+siteId+`&pageNumber=`+pageNumber+`&pageSize=`+pageSize);
}


  editStudentProfile(requestJson)
  {
  return fetch(process.env.React_App_AWS_API+"editStudentProfile",requestJson)
  .then(response => { return response.json();})
  .then(responseData => {return responseData;})
  .catch(error => {
    console.error(error);
    return { name: "network error", description: "" };
  });
}

getUserInfoByEmail(requestJson)
{

return fetch(process.env.React_App_AWS_API+"getUserInfoByEmail",requestJson)
.then(response => { return response.json();})
.then(responseData => {return responseData;})
.catch(error => {
  console.error(error);
  return { name: "network error", description: "" };
});
} 


applicationlistbycompany(requestJson)
{
//return fetch(process.env.React_App_AWS_API+"applicationlistbycompany",requestJson)
//return fetch("http://localhost:8082/applicationlistbycompany",requestJson)
return fetch(process.env.React_App_AWS_API+"applicationlistbycompany",requestJson)
.then(response => { return response.json();})
.then(responseData => {return responseData;})
.catch(error => {
  console.error(error);
  return { name: "network error", description: "" };
});
}

viewJobApplication(requestJson)
{
//return fetch(process.env.React_App_AWS_API+"viewJobApplication",requestJson)
//return fetch("http://localhost:8082/viewJobApplication",requestJson)
return fetch(process.env.React_App_AWS_API+"viewJobApplication",requestJson)
.then(response => { return response.json();})
.then(responseData => {return responseData;})
.catch(error => {
  console.error(error);
  return { name: "network error", description: "" };
});
}

  addCompanyUser(requestJson)
      {
      return fetch(process.env.React_App_AWS_API+"addCompanyUser",requestJson)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
    }

  getCompanyusers(companyId){
    return http.get(`/getCompanyUsers?companyId=`+companyId);
  }

  deleteUser(userId){
    return http.get(`/inactivateUser?userId=`+userId);
  }
  /*
  deleteUser(userId){
    return http.get(`/deleteUser?userId=`+userId);
  }
  */
/*
  deletePerkFromCompany(userId){
    return http.get(`/deleteUser?userId=`+userId);
  }
  */

  deletePerkFromCompany(requestJson)
    {
      return fetch(process.env.React_App_AWS_API+"deletePerkFromCompany",requestJson)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
    }

    addPerksToCompany(requestJson)
    {
      return fetch(process.env.React_App_AWS_API+"addPerksToCompany",requestJson)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
    } 

  editCompanyProfile(requestJson)
      {
      return fetch(process.env.React_App_AWS_API+"editCompanyProfile",requestJson)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
    }

    

    convertStudentToProfile(requestJson)
    {
    return fetch(process.env.React_App_AWS_API+"convertStudentToProfile",requestJson)
    .then(response => { return response.json();})
    .then(responseData => {return responseData;})
    .catch(error => {
      console.error(error);
      return { name: "network error", description: "" };
    });
  }

    postProfile(requestJson)
      {
      return fetch(process.env.React_App_AWS_API+"postProfile",requestJson)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
    }

    addFavoriteCompoany(requestJson)
      {
      return fetch(process.env.React_App_AWS_API+"addFavoriteCompoany",requestJson)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
    }

    checkFavoriteCompoany(requestJson)
      {
      return fetch(process.env.React_App_AWS_API+"checkFavoriteCompoany",requestJson)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
    }


    applyJobAsJobSeeker(requestJson)
    {
    return fetch(process.env.React_App_AWS_API+"applyJobJobSeeker",requestJson)
    .then(response => { return response.json();})
    .then(responseData => {return responseData;})
    .catch(error => {
      console.error(error);
      return { name: "network error", description: "" };
    });
  }


    applyJobAsStudent(requestJson)
    {
    return fetch(process.env.React_App_AWS_API+"applyJobStudent",requestJson)
    .then(response => { return response.json();})
    .then(responseData => {return responseData;})
    .catch(error => {
      console.error(error);
      return { name: "network error", description: "" };
    });
  }


    applyJob(requestJson)
    {
    return fetch(process.env.React_App_AWS_API+"applyJob",requestJson)
    .then(response => { return response.json();})
    .then(responseData => {return responseData;})
    .catch(error => {
      console.error(error);
      return { name: "network error", description: "" };
    });
  }

    editProfile(requestJson)
      {
      return fetch(process.env.React_App_AWS_API+"editProfile",requestJson)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
    }

    editCompanyLogo(requestJson)
      {
      return fetch(process.env.React_App_AWS_API+"postLogo",requestJson)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
    }

    editCompanyBanner(requestJson)
      {
      return fetch(process.env.React_App_AWS_API+"postBanner",requestJson)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
    }

    

    
    updateUserProfile(requestJson)
      {
      return fetch(process.env.React_App_AWS_API+"updateUserProfile",requestJson)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
    }


    editCompanyUser(requestJson)
      {
      return fetch(process.env.React_App_AWS_API+"editCompanyUser",requestJson)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
    }


    editCompanyVideo(requestJson)
      {
      return fetch(process.env.React_App_AWS_API+"editCompanyVideo",requestJson)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
    }
    

}
 
export default new Service();