import React,{history,useEffect, useState, useMemo } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import Services from "../../services/services";
import $, { parseJSON } from 'jquery';
import UsNumberValidate from '../../utility/usNumberValidate';
import { useNavigate } from 'react-router-dom';
import Pagination from "../pagination/pagination";
import Recent_Jobs from "../Jobs/Recent_Jobs";
import Hiring from "../Jobs/Hiring";
import Stay_Connected from "../home/Stay_Connected";

const EmployerDirectory = () => {
  const [itemName, setFieldName] = useState('');
  const navigate = useNavigate();
  const [stateData, setStateData] = useState([]);
  //const [categories, setCategory] = useState([]);
  //const [locationCounty, setLocationCounty] = useState([]);



  const [availableJobsList, setAvailableJobsList] = useState([]);
  const [employerDirectoryList, setEmployerDirectoryList] = useState([]);
 // const [employerDirectoryList, set] = useState([]);
 
  const [pageOfItems, setPageOfItems] = useState([]);
  const [exampleItems, setExampleItems] = useState([]);

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    criteriaMode: "all",mode: "onBlur" 
  });

  

  
  
 useEffect(() => 
 {
   

   $("#norecords").hide();
   $(document).on('click', ".searchClsAlpha", function () {
      $("#employerSearchTxt").val("");

      let employerSearchAlphabet = $(this).attr("attr");
    

      Services.getSearchCompanyByAlpha(employerSearchAlphabet).then((res) => 
    {
      
      console.log("68------->-->");

       var rawData2 = eval(res);
      var rawData = rawData2.data;

      rawData =  rawData.sort(function(a, b) {
         return compareStrings(a.name, b.name);
       })
       console.log(rawData);
       console.log(typeof(rawData));
      // console.log(dVal.length);
      

      
      console.log("length-->"+res.data.length);
       if(typeof(rawData2) == "object" && rawData.length>0){

         console.log("79------->-->");

         let data = rawData.reduce((r, e) => {
            // get first letter of name of current element
            let group = e.name[0];
            // if there is no property in accumulator with this letter create it
            if(!r[group]) r[group] = {group, children: [e]}
            // if there is push current element to children array for that letter
            else r[group].children.push(e);
            // return accumulator
            return r;
          }, {})
          
          // since data at this point is an object, to get array of values
          // we use Object.values method
          let result = Object.values(data)
          
          console.log(result)
          //console.log(JSON.stringify(result));
          
          console.log("142-->"+JSON.stringify(result));
      
         console.log("##########99")
         console.log(JSON.stringify(res));

        

        setEmployerDirectoryList(result);
        $("#norecords").hide();
         
       }else{
         setEmployerDirectoryList([]);
         $("#norecords").show();
           
       }




        //navigate('/thankyou');
    });




   });
  
   function compareStrings(a, b) {
      // Assuming you want case-insensitive comparison
      a = a.toLowerCase();
      b = b.toLowerCase();
    
      return (a < b) ? -1 : (a > b) ? 1 : 0;
    }

   $(document).on('click', ".searchClsBtn", function () {
      console.log("###51###");

      let employerSearchTxt = $("#employerSearchTxt").val();
      const requestObject=
      {
        "name" : employerSearchTxt,
      }
      var accessTokenIn=sessionStorage.getItem("accToken");
      const requestOptions = {
         method: 'GET',
         headers: { 'Content-Type': 'application/json',
                   'Authorization': "Bearer "+accessTokenIn 
                   //'Authorization': accessTokenIn 
                 },
         body: JSON.stringify(requestObject)
     };

      Services.getSearchCompanyByName(employerSearchTxt).then((res) => 
    {
      
      console.log("68------->-->");

       var rawData2 = eval(res);
      var rawData = rawData2.data;
      /*
      rawData =  rawData.sort(function(a, b) {
         return compareStrings(a.name, b.name);
       })
       */
       console.log(rawData);
       console.log(typeof(rawData));
      // console.log(dVal.length);
      

      
      console.log("length-->"+res.data.length);
       if(typeof(rawData2) == "object" && rawData.length>0){

         console.log("79------->-->");

         let data = rawData.reduce((r, e) => {
            // get first letter of name of current element
            let group = e.name[0];
            // if there is no property in accumulator with this letter create it
            if(!r[group]) r[group] = {group, children: [e]}
            // if there is push current element to children array for that letter
            else r[group].children.push(e);
            // return accumulator
            return r;
          }, {})
          
          // since data at this point is an object, to get array of values
          // we use Object.values method
          let result = Object.values(data)
          
          console.log(result)
          //console.log(JSON.stringify(result));
          
          console.log("142-->"+JSON.stringify(result));
      
      
         console.log(JSON.stringify(res));
        setEmployerDirectoryList(result);
        $("#norecords").hide();
        
         
       }else{
         setEmployerDirectoryList([]);
         $("#norecords").show();
           
       }




        //navigate('/thankyou');
    });




   });
  


   $('#employerSearchTxt').keypress(function (e) {
      var key = e.which;
      if(key == 13)  // the enter key code
       {
         $(".searchClsBtn").trigger("click");
       }
     });   
   $(".searchClsBtn").trigger("click");
     
 },[]);
  return(
    <>
    <main  class="empr-directory-main">
    <div class="main-bgpatten"></div>
    {/* <!-- info typography --> */}
    <section class="headtitle-wrap">
       <div class="container">
          <div class="row">
             <div class="col-lg-12">
             <nav style={{bsBreadcrumbDivider: '>'}} aria-label="breadcrumb">
                   <ol class="breadcrumb">
                     <li class="breadcrumb-item headtitle "><a href="#">Employer Directory </a></li>
                   </ol>
                 </nav>
             </div>
          </div>
       </div>
    </section>
    {/* <!-- info typography --> */}
    <section class="info-typography py-5">
       <div class="container">
          <div class="row">
             <div class="col-lg-8">
                <div class="py-5 px-5 white-bg">
                   {/* <!-- form --> */}
                   {/* <form class=" search-bar mb-4"> */}
                   <div class="job-item-wrap position-relative">
                     <input class="form-control mb-3" type="search" id="employerSearchTxt" aria-label="Search" placeholder="Search Employer by Name"/>
                      <button class="border-0 p-0 dir-search searchClsBtn" type="button"><img src="images/search-icon.JPG" alt=""/></button>
                   {/* </form> */}</div>
                   <div class="m-auto alphabate">
                   <a  attr="a" class="searchClsAlpha">A</a>
                  <a attr="b" class="searchClsAlpha">B</a>
                  <a attr="c" class="searchClsAlpha">C</a>
                  <a attr="d" class="searchClsAlpha">D</a>
                  <a attr="e" class="searchClsAlpha">E</a>
                  <a attr="f" class="searchClsAlpha">F</a>
                  <a attr="g" class="searchClsAlpha">G</a>
                  <a attr="h" class="searchClsAlpha">H</a>
                  <a attr="i" class="searchClsAlpha">I</a>
                  <a attr="j" class="searchClsAlpha">J</a>
                  <a attr="k" class="searchClsAlpha">K</a>
                  <a attr="l" class="searchClsAlpha">L</a>
                  <a attr="m" class="searchClsAlpha">M</a>
                  <a attr="n" class="searchClsAlpha">N</a>
                  <a attr="o" class="searchClsAlpha">O</a>
                  <a attr="p" class="searchClsAlpha">P</a>
                  <a attr="q" class="searchClsAlpha">Q</a>
                  <a attr="r" class="searchClsAlpha">R</a>
                  <a attr="s" class="searchClsAlpha">S</a>
                  <a attr="t" class="searchClsAlpha">T</a>
                  <a attr="u" class="searchClsAlpha">U</a>
                  <a attr="v" class="searchClsAlpha">V</a>
                  <a attr="w" class="searchClsAlpha">W</a>
                  <a attr="x" class="searchClsAlpha">X</a>
                  <a attr="y" class="searchClsAlpha">Y</a>
                  <a attr="z" class="searchClsAlpha">Z</a>
                   </div>
                   {employerDirectoryList.map(item =>
                     <div key={item.id} class="mb-0">
                        {/* {item.name} */}
                   <div class="mt-2 empr-directory-wrap" >
                      <h4>{item.group}</h4>
                      <ul>
                      {item.children.map((iteml2) => {
                        return (
                           <li><a href={`/companyDetails?companyId=${iteml2.companyId}`}>{iteml2.name} </a></li>
                        );
                        })}

                       

                         {/* <li><a href="#"></a></li>
                         <li><a href="#">17 West </a></li>
                         <li><a href="#"> 3-Way Machine and Tool Co. </a></li>
                         <li><a href="#">  3Rivers Federal Credit Union </a></li>
                         <li><a href="#">  4 Star Vet Service 6s Products LLC </a></li>
                         <li><a href="#">  A & B Machine and Design </a></li>
                         <li><a href="#">  A Soothing Touch Massage </a></li>
                         <li><a href="#">  A&B Printing </a></li>
                         <li><a href="#"> A&M Pallet </a></li>
                         <li><a href="#"> A. L. Smith Trucking, Inc. </a></li>
                         <li><a href="#"> A1 Ag Services LLC </a></li>
                         <li><a href="#"> Above and Beyond Daycare and Learning Center</a></li>
                         <li><a href="#"> Access Engineering Solutions </a></li>
                    */}
                         
                      </ul>
                   </div>
                   </div>
              )}

                        <div id="norecords">
                           No records were found.
                        </div>

                
                   <div class="pagination-wrap text-center mt-3">
                   {/* <Pagination items={this.state.exampleItems} onChangePage={this.onChangePage} /> */}
                      {/* <nav aria-label="...">
                         <ul class="pagination m-auto d-inline-flex">
                            <li class="page-item disabled">
                               <span class="page-link"><img src="images/arrow-left.JPG" alt=""/></span>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                            <li class="page-item active" aria-current="page">
                               <span class="page-link">2</span>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item">
                               <a class="page-link" href="#"><img src="images/arrow-right.JPG" alt=""/></a>
                            </li>
                         </ul>
                      </nav> */}
                   </div>
                </div>
             </div>
             <div class="col-lg-4">
                <Recent_Jobs/>
                {/* <div class="white-bg mb-4">
                   <img src="images/ad-1.png" class="w-100" alt=""/>
                </div>
                <div class="white-bg mb-4">
                   <img src="images/ad-2.png" class="w-100" alt=""/>
                </div> */}
               {/* <div class="p-4 white-bg mb-4">
                        <h2 class="title-stay-connect">Stay connected. Get the app!</h2>
                        <p>Download the Hometown Opportunity app and stay constanly connected with companies and job oppportunities in your area. Hometown Opportunity is available on the Apple App Store or on Google Play.</p>
                        <img src="images/HTO-app-face.png" class="w-100" alt="" />

                        <div class="d-flex justify-content-center  mt-3">
                           <img src="images/apple-app-store.png" alt="" style={{"width":" 130px;"}} />
                           <img src="images/google-play.png" alt="" class="ms-2" style={{"max-width":"130px;"}} />
                        </div>
                </div>*/}
                
               
             </div>
          </div>
       </div>
    </section>

    {/* <!-- Top companies hiring in your area --> */}
    <Hiring/>
    {/* <section class="white-bg py-4">
       <div class="container">
          <div class="row mb-4">
             <div class="col-12">
                <div class="d-flex justify-content-between border-bottom  mb-4 pb-4 flex-lg-row flex-column">
                   <h4 class="headtitle">Top companies hiring in your area</h4>
                   <a href="#" class="text-uppercase black-link">See All</a>
                </div>
             </div>
             
          </div>
          <div class="row">
             <div class="col-lg-4">
                <div class="card p-3 mb-4">
                   <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                   <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                   <div class="text-center stock-wrap">
                      <h3>Midmark Corporation</h3>
                   <span>Versailles, Ohio</span>
                   <a href="#">15 OPEN JOBS</a>
                   </div>
                </div>
             </div>
             <div class="col-lg-4">
                <div class="card p-3 mb-4">
                   <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                   <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                   <div class="text-center stock-wrap">
                      <h3>Midmark Corporation</h3>
                   <span>Versailles, Ohio</span>
                   <a href="#">15 OPEN JOBS</a>
                   </div>
                </div>
             </div>
             <div class="col-lg-4">
                <div class="card p-3 mb-4">
                   <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                   <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                   <div class="text-center stock-wrap">
                      <h3>Midmark Corporation</h3>
                   <span>Versailles, Ohio</span>
                   <a href="#">15 OPEN JOBS</a>
                   </div>
                </div>
              
             </div>

           
          </div>
       </div>
    </section> */}
 </main>
</>
)};

EmployerDirectory.propTypes = {};

EmployerDirectory.defaultProps = {};

export default EmployerDirectory;
