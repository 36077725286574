import React,{history,useEffect, useState, useMemo } from "react";
import { useNavigate,useParams,useLocation,useHistory } from "react-router-dom";
import moment from "moment";
import ReactDOM from "react-dom";
//import { useParams } from "react-router";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
//import { useSearchParams } from "react-router-dom";
import TopCompaniesHiring from "../Jobs/top_comp_hiring";
import services from "../../services/services";

import Hiring from "../Jobs/Hiring";
/*

import React,{history,useEffect, useState, useMemo } from "react";
import { useNavigate,useParams,useLocation,useHistory } from "react-router-dom";
import ReactDOM from "react-dom";
import Quick_links from "../../utility/Quick_links";
import Hiring from "../Jobs/Hiring";
import TopCompaniesHiring from "../Jobs/top_comp_hiring";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import services from "../../services/services";
*/


const CompanySummary= () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const jobId = queryParams.get('jobId');
    const pagenum = queryParams.get('page');
    const [jobDetails, setJobDetails] = useState([]);
    const [companyDetails, setCompanyDetails] = useState([]);
    const [perksListInfo, setPerksListInfo] = useState([]);
    const [educations, setEducation] = useState([]);
    const [educationAr, setEducationAr] = useState([]);

    const companyIdChkLogin=sessionStorage.getItem("logCompanyId");
    const studentIdChkLogin=sessionStorage.getItem("logStudentId");
    const jobSeekerIdChkLogin=sessionStorage.getItem("logProfileId");
    const userIdChkLogin=sessionStorage.getItem("logUserId");
    const chKRoleAr=sessionStorage.getItem("roleAr");
    var eduArray2 = [];
    useEffect(() => {        
        getEducation();
        getJobDetailsById(); 

    }, []);

    const getEducation = () => {
        var eduArray = [];
        services.getEducation().then((res) => {
          console.log("getEducation data=" + JSON.stringify(res));
          setEducation(res.data.content);
           var dc = res.data.content;
           for(var i=0; i<dc.length; i++){

            var tempId = dc[i].educationId;
            var tempName = dc[i].name;
            var tobj = {
                "educationId":tempId,
                "name":tempName
            }
            //eduArray.push(tobj);
            educationAr[tempId] = tempName;
           } 

           //setEducationAr(educationAr);
           console.log("##########56#####")
           console.log(JSON.stringify(educationAr));

        });
      }

    function getEduInfo(ed){
        if(ed == ""){
            return "";
        }
        //var eduArray = [];
        //var ret = "ed--"+ed;
        console.log("##########64#####")
        console.log(JSON.stringify(educationAr));

      

        var ret = educationAr[ed];
       // alert(ret);
        return ret;
    }    
    function getJobDetailsById(){
    //alert(jobId);
        services.getJobDetailsById(jobId).then((res) => {
            if(res.data){
                console.log(res.data);
                setJobDetails(res.data.jobPostVo);
                setCompanyDetails(res.data.companyVo);
                if(res.data.companyVo.perksList){
                    setPerksListInfo(res.data.companyVo.perksList);
                }
                
            }
           
        });
    }

  return(
  <>
       <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        
        <section class="headtitle-wrap">
                <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        
                        <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                            
                            {(() => {
                                
                                if( jobSeekerIdChkLogin != null && jobSeekerIdChkLogin != "null"){            
                                    return (<><li class="breadcrumb-item headtitle"> <a href="/profileDashboard">Job Seeker Dashboard</a> </li> </>);
                                }

                                if( studentIdChkLogin != null && studentIdChkLogin != "null"){            
                                    return (<><li class="breadcrumb-item headtitle"> <a href="/studentDashboard">Student Dashboard</a> </li> </>);
                                }

                                if( chKRoleAr == "schoolsuper"){  
                                    return (<><li class="breadcrumb-item headtitle"> <a href="/schoolAdminDashboard">School Admin</a> </li> </>);
                                }

                           })()} 


                            <li class="breadcrumb-item headtitle "><a href="#">View Job - {jobDetails.title} </a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
                </div>
            </section>

        <section class="info-typography py-5">
            <div class="container">
                

            <div className="row pb-3">
                    
                        {(() => {   if( chKRoleAr == "schoolsuper" || chKRoleAr == "schooladmin"){  
                            <div className="d-flex">
                            <span className="mr-1 rotate-90">
                   <img src="images/mob-arrow.png" alt="" />
                    </span> <a href={"/availableJobs?page="+pagenum} style={{color:"#fff"}}>Back to Job List</a>
                    </div>
                }

            })()} 
                   
                </div>



                <div class="row">
                    <div class="col-lg-8">
                        <div class="py-5 px-5 white-bg position-relative cd-desktop" style={{"padding-bottom":"20px !important;"}}>
                            <div class="row">
                                <div class="company-summery">
                                    <div class="mb-4">
                                        <img src={(companyDetails.logoUrl == null || companyDetails.logoUrl == "") ? 'https://snv3-dev-fs.s3.us-east-2.amazonaws.com/HometownMigration/uploads/default-logo.png' :
                                            companyDetails.logoUrl } alt="" />
                                    </div>

                                    <div class="right-info">
                                        <h4>{jobDetails.title}</h4>
                                        <div class="d-flex">
                                            <span><a href={`/companyDetails?companyId=`+companyDetails.companyId+`&page=`+pagenum}>{companyDetails.name}</a> </span>
                                            <span class="mx-3">|</span>
                                            <span> {companyDetails.address1 != '' ? companyDetails.address1 : '' }
                                            {companyDetails.address1 != '' && companyDetails.city != '' ? ', ' : ''}
                                            {companyDetails.city != '' ? companyDetails.city : '' }
                                            {companyDetails.city != '' && companyDetails.state != '' ? ', ' : ''}
                                            {companyDetails.state != '' ? companyDetails.state : ''}
                                            </span>
                                        </div>
                                    </div>

                                    <div class="stock-wrap ">
                                        <a href={"/applyJobProfile?jobId="+jobDetails.jobId}>Apply Now</a>
                                    </div>

                                    <div class="date">
                                        <b class="d-flex text-uppercase">
                         {/* <span>August 19</span>,
                         <span class="ms-1"> 2021</span> */}
                         <span>{moment(jobDetails.createdDate).format("MMMM D, YYYY")}</span>
                     </b>
                                    </div>

                                </div>
                                <hr class="my-4" />

                                <div class="mb-4">
                                    <h4>JOB TYPE</h4>
                                    <p>{jobDetails.strJobType} </p>
                                </div>

                                <div class="mb-4">
                                    <h4>Job Summary</h4>
                                    {/* <p>{jobDetails.summary}</p> */}
                                    <p dangerouslySetInnerHTML={{ __html: jobDetails.summary  }} />
                                </div>

                                <div class="mb-4">
                                    <h4>CAREER LEVEL </h4>
                                    <p>{jobDetails.career} </p>
                                </div>

                               

                                 <div class="mb-4">
                                    <h4>EDUCATION LEVEL</h4>
                                    <p>{getEduInfo(jobDetails.educationId)}
                                    </p>
                                </div>
{/*
                                <div class="mb-4">
                                    <h4>CAREER LEVEL </h4>
                                    <p>Manager with Reports </p>
                                </div> */}
                                
                                <div class="mb-4">
                                    <h4>EXPERIENCE/CERTIFICATION REQUIRED:</h4>
                                    {/* <p>{jobDetails.experiance} </p> */}
                                    <p dangerouslySetInnerHTML={{ __html: jobDetails.experiance  }} />
                                </div>

                                

                                <div class="mb-4">
                                    <h4>SALARY/BENEFITS</h4>
                                    {/* <p>{jobDetails.salary}</p> */}
                                    <p dangerouslySetInnerHTML={{ __html: jobDetails.salary  }} />
                                </div>

                                <div class="mb-4">
                                    <h4>HOW TO APPLY:</h4>
                                    <p>{jobDetails.apply}</p>
                                    <div >
                                    <a href={"/applyJobProfile?jobId="+jobDetails.jobId} className="reg-button">Apply Now</a>
                                    </div>
                                </div>

                                

                               

                              

                            </div>

                        </div>

                        <div class="cd-mobile py-5 px-5 white-bg position-relative ">
                            <div class="card border-0 border-bottom p-3 ">
                                <div class="text-center">
                                    <img width="100%" src={(companyDetails.logoUrl == null || companyDetails.logoUrl == "") ? 'https://snv3-dev-fs.s3.us-east-2.amazonaws.com/HometownMigration/uploads/default-logo.png' : companyDetails.logoUrl } class="w-100 max-logo-sz m-auto p-3" alt="" />

                                </div>
                                <div class="text-left">
                                    <hr class="my-4" />
                                    <div class="date">
                                        <b class="d-flex text-uppercase">
                                 <span>{moment(jobDetails.createdDate).format("MMMM D, YYYY")}</span>
                                   

                                    

                                </b>
                                    </div>
                                    <h4>{jobDetails.title}</h4>
                                    <div class="d-flex mb-3">
                                        <span><a href={`/companyDetails?companyId=`+companyDetails.companyId+`&page=`+pagenum} class="company-name">{companyDetails.name} </a></span>
                                        <span class="mx-3">|</span>

                                        
                                        <span> {companyDetails.address1 != '' ? companyDetails.address1 : '' }
                                        {companyDetails.address1 != '' && companyDetails.state != '' ? ', ' : ''}
                                        {companyDetails.city != '' ? companyDetails.city : '' }
                                        {companyDetails.city != '' && companyDetails.city != '' ? ', ' : ''}
                                        {companyDetails.state != '' ? companyDetails.state : ''}</span>
                                    </div>

                                    <div class="text-left mb-5">
                                        <h4 class="text-uppercase"><small>JOB TYPE:</small></h4>
                                        <p>{jobDetails.strJobType}</p>
                                    </div>

                                    <div class="text-left mb-5">
                                        <h4 class="text-uppercase"><small>Job Summary</small></h4>
                                        {/* <p>{jobDetails.summary}</p> */}
                                        <p dangerouslySetInnerHTML={{ __html: jobDetails.summary  }} />
                                    </div>

                                    <div class="text-left mb-5">
                                        <h4 class="text-uppercase"><small>CAREER LEVEL: </small></h4>
                                        <p>{jobDetails.career}</p>
                                        
                                    </div>

                                    <div class="text-left mb-5">
                                        <h4 class="text-uppercase"><small>EXPERIENCE/CERTIFICATION REQUIRED:</small></h4>
                                        {/* <p>{jobDetails.experiance}</p> */}
                                        <p dangerouslySetInnerHTML={{ __html: jobDetails.experiance  }} />
                                    </div>

                                   

                                    {/* <div class="text-left mb-5">
                                        <h4 class="text-uppercase"><small>EDUCATION and/or EXPERIENCE:</small></h4>
                                        <p>{jobDetails.jobEducation}</p>
                                    </div> */}

                                    <div class="text-left mb-5">
                                        <h4 class="text-uppercase"><small>SALARY/BENEFITS: </small></h4>
                                        {/* <p>{jobDetails.salary}</p> */}
                                        <p dangerouslySetInnerHTML={{ __html: jobDetails.salary  }} />
                                    </div>

                                  

                                    

                                    <div class="text-left mb-5">
                                        <h4 class="text-uppercase"><small>How To Apply: </small></h4>
                                        <p>{jobDetails.apply}</p>
                                    </div>

                                    
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="p-4 white-bg mb-4">
                            <div class="bg-heading">
                                <h3 class="mb-0">job perks</h3>
                            </div>
                            <ul class="mt-4 job-perks">

                            {
                            perksListInfo.map((datap, index) => (
                                <li> <span> <img src={datap.perksImageUrl} alt="" /></span> {datap.perksTitle}</li>
                            ))}

                                {/* <li> <span> <img src="images/lunch-plate.png" alt="" /></span> <a href="#">1 Hour lunch Breaks</a></li>
                                <li> <span> <img src="images/cofee.png" alt="" /></span> <a href="#">Break Room cofee  Bar</a></li>
                                <li> <span> <img src="images/dollars.png" alt="" /></span> <a href="#">Refferal Bonuses</a></li>
                                <li> <span> <img src="images/health.png" alt="" /></span> <a href="#">Health Insurance</a></li>
                                <li> <span> <img src="images/dental.png" alt="" /></span> <a href="#">Dental Insurance</a></li>
                                <li> <span> <img src="images/vision.png" alt="" /></span> <a href="#">Vision Insurance</a></li>
                                <li> <span> <img src="images/life-insurance.png" alt="" /></span> <a href="#">Life Insurance</a></li>
                                <li> <span> <img src="images/discounts.png" alt="" /></span> <a href="#">Company Discounts</a></li>
                                <li> <span> <img src="images/tutions.png" alt="" /></span> <a href="#">Tution Reimbursment</a></li>
                                <li> <span> <img src="images/stocks.png" alt="" /></span> <a href="#">Stock Options</a></li>
                                <li> <span> <img src="images/hoiliday.png" alt="" /></span> <a href="#">2-week Vacation</a></li>
                                <li> <span> <img src="images/sick.png" alt="" /></span> <a href="#">Unlimited sick leave</a></li>
                                <li> <span> <img src="images/parantal.png" alt="" /></span> <a href="#">Parental Leave</a></li> */}
                            </ul>
                            <hr class="mt-4 mb-4" />
                            <div class="text-center mb-3">
                                <a href={"/applyJobProfile?jobId="+jobDetails.jobId} class="reg-button">Apply Now</a>

                               
                            </div>
                        </div>




                    </div>
                </div>
            </div>
        </section>
        <section class="white-bg py-5 p-4 cd-desktop">
            <div class="container">
                <div class="row mb-4 mt-4">
                {/* <TopCompaniesHiring/> */}
                <Hiring/>
                </div>
            </div>
        </section>

        {/* <section class="cd-mobile white-bg py-4">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2 class="border-bottom mb-4 py-3 headtitle ">Midmark Corporation Available Positions</h2>
                        <div class="card p-4 mb-4 ">
                            <div class="text-center">
                                <img src="images/midmark-logo.JPG" class="m-auto" alt="" />

                            </div>
                            <div class="text-left">
                                <hr class="my-5" />
                                <div class="date">
                                    <b class="d-flex text-uppercase">
                                <span>August 19</span>,
                                <span class="ms-1"> 2021</span>
                            </b>
                                </div>
                                <h4>PRODUCT AND SERVICES MANAGER</h4>
                                <div class="d-flex mb-3">
                                    <span><a href="#" class="company-name">Midmark Corporation </a></span>
                                    <span class="mx-3">|</span>
                                    <span> Versailles, Ohio</span>

                                </div>
                                <p>The Senior Product and Services Manager researches and develops the market for services to support the organization. Responsible for executing the divisional strategy through the delivery of products, services, and solutions
                                    to the market. Performs the following duties…</p>
                            </div>

                            <hr class="my-4" />
                            <div class="jobperks-mobile">
                                <h2 class="text-center text-uppercase">Job Perks</h2>
                                <div class="job-seek-mobile mb-3">
                                    <div> <img src="images/health.png" alt="" /></div>
                                    <div> <img src="images/lunch-plate.png" alt="" /></div>
                                    <div> <img src="images/dental.png" alt="" /></div>
                                    <div> <img src="images/cofee.png" alt="" /></div>
                                    <div> <img src="images/cofee.png" alt="" /></div>
                                    <div><a href="#" class="js-more">more </a></div>
                                </div>
                                <div class="text-center">
                                    <a href="#" class="reg-button">View JOB</a>
                                </div>
                            </div>
                        </div>

                        <div class="card p-4 mb-4 ">
                            <div class="text-center">
                                <img src="images/midmark-logo.JPG" class="m-auto" alt="" />

                            </div>
                            <div class="text-left">
                                <hr class="my-5" />
                                <div class="date">
                                    <b class="d-flex text-uppercase">
                                <span>August 19</span>,
                                <span class="ms-1"> 2021</span>
                            </b>
                                </div>
                                <h4>PRODUCT AND SERVICES MANAGER</h4>
                                <div class="d-flex mb-3">
                                    <span><a href="#" class="company-name">Midmark Corporation </a></span>
                                    <span class="mx-3">|</span>
                                    <span> Versailles, Ohio</span>

                                </div>
                                <p>The Senior Product and Services Manager researches and develops the market for services to support the organization. Responsible for executing the divisional strategy through the delivery of products, services, and solutions
                                    to the market. Performs the following duties…</p>
                            </div>

                            <hr class="my-4" />
                            <div class="jobperks-mobile">
                                <h2 class="text-center text-uppercase">Job Perks</h2>
                                <div class="job-seek-mobile mb-3">
                                    <div> <img src="images/health.png" alt="" /></div>
                                    <div> <img src="images/lunch-plate.png" alt="" /></div>
                                    <div> <img src="images/dental.png" alt="" /></div>
                                    <div> <img src="images/cofee.png" alt="" /></div>
                                    <div> <img src="images/cofee.png" alt="" /></div>
                                    <div><a href="#" class="js-more" data-bs-toggle="modal" data-bs-target="#exampleModal">more</a></div>
                                </div>
                                <div class="text-center">
                                    <a href="#" class="reg-button">View JOB</a>
                                </div>
                            </div>
                        </div>

                        <div class="card p-4 mb-4 ">
                            <div class="text-center">
                                <img src="images/midmark-logo.JPG" class="m-auto" alt="" />

                            </div>
                            <div class="text-left">
                                <hr class="my-5" />
                                <div class="date">
                                    <b class="d-flex text-uppercase">
                                <span>August 19</span>,
                                <span class="ms-1"> 2021</span>
                            </b>
                                </div>
                                <h4>PRODUCT AND SERVICES MANAGER</h4>
                                <div class="d-flex mb-3">
                                    <span><a href="#" class="company-name">Midmark Corporation </a></span>
                                    <span class="mx-3">|</span>
                                    <span> Versailles, Ohio</span>

                                </div>
                                <p>The Senior Product and Services Manager researches and develops the market for services to support the organization. Responsible for executing the divisional strategy through the delivery of products, services, and solutions
                                    to the market. Performs the following duties…</p>
                            </div>

                            <hr class="my-4" />
                            <div class="jobperks-mobile">
                                <h2 class="text-center text-uppercase">Job Perks</h2>
                                <div class="job-seek-mobile mb-3">
                                    <div> <img src="images/health.png" alt="" /></div>
                                    <div> <img src="images/lunch-plate.png" alt="" /></div>
                                    <div> <img src="images/dental.png" alt="" /></div>
                                    <div> <img src="images/cofee.png" alt="" /></div>
                                    <div> <img src="images/cofee.png" alt="" /></div>
                                    <div><a href="#" class="js-more" data-bs-toggle="modal" data-bs-target="#exampleModal">more</a></div>
                                </div>
                                <div class="text-center">
                                    <a href="#" class="reg-button">View JOB</a>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
            <div class="pagination-wrap text-center mt-3">
                <nav aria-label="...">
                    <ul class="pagination m-auto d-inline-flex">
                        <li class="page-item disabled">
                            <span class="page-link"><img src="images/arrow-left.JPG" alt="" /></span>
                        </li>
                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                        <li class="page-item active" aria-current="page">
                            <span class="page-link">2</span>
                        </li>
                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                        <li class="page-item">
                            <a class="page-link" href="#"><img src="images/arrow-right.JPG" alt="" /></a>
                        </li>
                    </ul>
                </nav>
            </div>
        </section> */}

        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">JOB PERKS</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body py-5">
                <div class="job-seek-modal">
                        <div class="d-flex mb-2 align-items-center">
                            <div> <img src="images/health.png" alt=""/></div>
                            <p>Health Insurance</p>
                        </div>

                        <div class="d-flex mb-2 align-items-center">
                            <div> <img src="images/lunch-plate.png" alt=""/></div>
                            <p>1-Hour Lunch Breaks</p>
                        </div>

                        <div class="d-flex mb-2 align-items-center">
                            <div> <img src="images/dental.png" alt=""/></div>
                            <p>Dental Insurance</p>
                        </div>

                        <div class="d-flex mb-2 align-items-center">
                            <div> <img src="images/cofee.png" alt=""/></div>
                            <p>Break Room Coffee Bar</p>
                        </div>

                        <div class="d-flex mb-2 align-items-center">
                            <div> <img src="images/sick.png" alt=""/></div>
                            <p>Unlimited Sick Leave</p>
                        </div>

                        <div class="d-flex mb-2 align-items-center">
                            <div> <img src="images/lunch-plate.png" alt=""/></div>
                            <p>1-Hour Lunch Breaks</p>
                        </div>

                        <div class="d-flex mb-2 align-items-center">
                            <div> <img src="images/life-insurance.png" alt=""/></div>
                            <p>Life Insurance</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    </main>
  </>
)};

CompanySummary.propTypes = {};

CompanySummary.defaultProps = {};

export default CompanySummary;
