import React, { history,useEffect, useState, useMemo } from "react";
import { ErrorMessage } from "@hookform/error-message";
import moment from "moment";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import UsNumberValidate from '../../utility/usNumberValidate';
import Services from "../../services/services";
//import PaginationComponent from "../../utility/Pagination/Pagination";
import Pagination from "../../utility/Pagination/Pagination";
import { useNavigate,useParams } from "react-router-dom";
const PersonalProfiles = () => {

    const navigate = useNavigate();
    const [categories, setCategory] = useState([]);
    const [profileList, setProfileList] = useState([]);
    
    const [jobTypes, setJobType] = useState([]);
    const [itemName, setFieldName] = useState('');
    const ITEMS_PER_PAGE = 10; // Per page show the data pagination
    const Pagesize=5;
    const [categoriesSelected, setCategoriesSelected] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [totalpages, settotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const companyId=sessionStorage.getItem("logCompanyId");

    function strCatArr(arr){
        if(Array.isArray(arr)){
          return arr.join(arr,", ");
        }else{
          return "";
        }
      }

    useEffect(() => {
        if(companyId == null || companyId == "null"){            
            //navigate('/login');
            navigate('/Home');
        }
        $('#phone').usPhoneFormat();
        getProfileInfo();
        getIndustryData();
        getJobType();
        filterContent(ITEMS_PER_PAGE,'1');

        $( document ).on( "click", ".searchContent", function() {
            filterContent(ITEMS_PER_PAGE,'1');
         });

         $( document ).on( "change", ".searchKeyInput", function() {
            filterContent(ITEMS_PER_PAGE,'1');
         });

         $(".paginationContent").hide();

    }, []);

    function getProfileInfo() {

        //var companyId = "24";
    
    
        Services.getCompanyProfile(companyId).then((res) => {
    
          
          setCategoriesSelected(res.data.companyCategory);
          var companyCatIn = res.data.companyCategory;
    
    
          setTimeout(() => {
            for (var i = 0; i < companyCatIn.length; i++) {
              //$("input[value='" + val + "']").prop('checked', true);
              //console.log("###" + companyCatIn[i]);
             // $('input[name="category"][value="' + companyCatIn[i] + '"]').prop("checked", true);
              //$('input[name="category_m"][value="' + companyCatIn[i] + '"]').prop("checked", true);
              //$('input[name="category"][value="' + companyCatIn[i] + '"]').trigger("click");
            }
           
    
            
    
            
    
         
          }, 1000);
    
    
    
          //navigate('/thankyou');
        });
      }
    const handlePageChange2 = (offset,triggerevent) => {
        // alert(offset);
        filterContent(ITEMS_PER_PAGE,offset);
      };

    const filterContent = (ITEMS_PER_PAGE,pageNumber) => {
        var categoriesArray = [];

        $.each($("input[name='category']:checked"), function(){
            categoriesArray.push($(this).val());
        });
        
        var jobTypeArray = [];
        $.each($("input[name='jobtype']:checked"), function(){
            jobTypeArray.push($(this).val());
        });

        console.log(categoriesArray);
        const requestObject={
            "siteId": process.env.React_App_SITE_ID,
            "categoriesOfExpertise": categoriesArray,
            "type": jobTypeArray,
            "eduExpInfo" : $(".searchKeyInput").val(),
            "titleSummary":$(".searchKeyInput").val(),
            "pageNumber" :pageNumber,
           // "pageSize":"3",
           "pageSize":ITEMS_PER_PAGE,            
            "sortBy" : "updatedOn"           
          }
          var accessTokenIn=sessionStorage.getItem("accToken");
          console.log(requestObject);
            const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                      'Authorization': "Bearer "+accessTokenIn 
                      //'Authorization': accessTokenIn 
                    },
            body: JSON.stringify(requestObject)
            };

            Services.findFilterProfile(requestOptions).then((res) => 
            {

                
                if(res.content){
                    //console.log(JSON.stringify(res)); 

                    $(".paginationContent").show();
                    setTotalItems(res.totalElements);         
                    settotalPages(Math.ceil(res.totalElements / ITEMS_PER_PAGE));                    
                    setCurrentPage(pageNumber);
        
                    var contAr = res.content;
                    setProfileList(res.content);
                    if(contAr.length>0){
                        $(".paginationContent").show();
                    }else{
                        $(".paginationContent").hide();
                    }

               }
                

            });



    }

    const getIndustryData = () => {
        Services.getIndustryData().then((res) => {
            console.log("Industry data=" + JSON.stringify(res.data));
            setCategory(res.data.content);
        });
    }

    const getJobType = () => {
        Services.getJobType().then((res) => {
            console.log("setJobType data=" + JSON.stringify(res.data));
            setJobType(res.data.content);
        });
    }

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all", mode: "onBlur"
    });

    const submitValue = () => {

    }

    return (
        <>
            <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        {/* <!-- info typography --> */}
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                            <li class="breadcrumb-item headtitle"> <a href="/companyJobs">Company Dashboard</a> </li>
                                <li class="breadcrumb-item headtitle "><a href="#">Personal Profiles</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="py-5 cd-desktop">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-5 ">
                            <div class="row">
                                {/* <!-- left side --> */}
                                <div class="col-lg-4 mb-2">
                                    <div class="card p-3 mb-0 ">
                                        <div class="bg-heading">
                                            <h3 class="mb-0">Filter Profiles</h3>
                                        </div>
                                        <div class="py-3 text-start">
                                            <b class="text-uppercase">Keywords</b>
                                            
                                                <input class="form-control me-2 searchKeyInput" type="search" aria-label="Search" placeholder="Search"/>
                                                {/* <button class="border-0 p-0 dir-search" type="submit"><img src="images/search-icon.JPG" alt=""/></button> */}
                                            
                                        </div>
                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Categories of Interest</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>
                                                {
                                                    categories.map((data, index) => (                                                    
                                                    <li><input class="form-check-input searchContent" type="checkbox" value={data.catId} name="category"/> <label class="form-check-label" for="defaultCheck1"> {data.name}</label></li>    
                                                ))}
                                                    
                                                </ul>

                                                
                                            </div>
                                        </div>
                                      

                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Type</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>
                                                {
                                                jobTypes.map((data, index) => (                                               
                                                    <li><input class="form-check-input searchContent" type="checkbox" value={data.jobTypeId} name="jobtype"/> <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                ))}
                                                    

                                                </ul>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                {/* <!-- right side --> */}
                                <div class="col-lg-8 mb-4">
                                    
                                    <div class="two-grid-wrap">
                                       

                                      
                                        
                                    {
                                       profileList.map((data, index) => (        

                                        <div class="grid-item p-3">
                                            <div class="grid-head">
                                                <div class="grid-img">
                                                    <img src={data.profilePicS3URL?data.profilePicS3URL:"images/user-default.jpg"} class="w-100" alt=""/>
                                                </div>
                                                <div class="grid-title-wrap">
                                                    <h4 class="margin-bottom-0">{data.firstName} {data.lastName}</h4>
                                                    <small class="d-flex">
                                                            <span class="mr-2">Seeking:</span>
                                                            <span>{data.strJobType}</span>
                                                        </small>
                                                </div>
                                            </div>
                                            <div class="grid-body mb-4">
                                                <div class="grid-body-item">
                                                    <div class="grid-body-inn">
                                                        <span>Updated:</span>
                                                        <span>{moment(data.updatedDate).format("D-MMM-YY")}</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>Email:</span>
                                                        <span>{data.email}</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>Phone:</span>
                                                        <span>{data.phoneno}</span>
                                                    </div>
                                                    <div class="grid-body-inn clamp">
                                                        <span>Category:</span>
                                                        <span class="char150">
                                                        {strCatArr(data.categoryName)}
                                                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="grid-footer">
                                                <a href={`/personalProfileView?profileId=`+data.profileId} class="reg-button">View</a>
                                            </div>
                                        </div>

                                        ))}

                                        
                                    </div>
                                       <div class="paginationContent mt-3">
                                       <Pagination               
                                        total={totalItems}
                                        itemsPerPage={ITEMS_PER_PAGE}
                                        currentPage={currentPage}
                                        onPageChange={handlePageChange2}/>

                                       </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="py-3 cd-mobile">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-5">
                            <div class="row">


                                {/* <!-- right side --> */}
                                <div class="col-lg-8 mb-4">
                                   
                                   
                                  
                                </div>

                                {/* <!-- left side --> */}
                                <div class="col-lg-4 mb-2">
                                    <div class="card p-3 mb-0 ">
                                        <div class="bg-heading">
                                            <h3 class="mb-0">Filter Profiles</h3>
                                        </div>
                                        <div class="py-3 text-start">
                                            <b class="text-uppercase">Keywords</b>
                                            
                                                <input class="form-control me-2 searchKeyInput" type="search" aria-label="Search" placeholder="Search"/>
                                                {/* <button class="border-0 p-0 dir-search" type="submit"><img src="images/search-icon.JPG" alt=""/></button> */}
                                           
                                        </div>
                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Categories of Interest</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>
                                                {
                                                    categories.map((data, index) => (                                                    
                                                    <li><input class="form-check-input searchContent" type="checkbox" value={data.catId} name="category_m"/> <label class="form-check-label" for="defaultCheck1"> {data.name}</label></li>    
                                                ))}
                                                   
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Type</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>

                                                {
                                                jobTypes.map((data, index) => (                                               
                                                    <li><input class="form-check-input searchContent" type="checkbox" value={data.jobTypeId} name="jobtype" /> <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                ))}
                                                    

                                                </ul>
                                            </div>
                                        </div>
                                    </div>


                                </div>




                                <div class="col-lg-8 mb-4">
                                    
                                    <div class="two-grid-wrap">
                                       

                                      
                                        
                                    {
                                       profileList.map((data, index) => (        

                                        <div class="grid-item p-3">
                                            <div class="grid-head">
                                                <div class="grid-img">
                                                    <img src={data.profilePicS3URL?data.profilePicS3URL:"images/user-default.jpg"} class="w-100" alt=""/>
                                                </div>
                                                <div class="grid-title-wrap">
                                                    <h4 class="margin-bottom-0">{data.firstName} {data.lastName}</h4>
                                                    <small class="d-flex">
                                                            <span class="mr-2">Seeking:</span>
                                                            <span>{data.strJobType}</span>
                                                        </small>
                                                </div>
                                            </div>
                                            <div class="grid-body mb-4">
                                                <div class="grid-body-item">
                                                    <div class="grid-body-inn">
                                                        <span>Updated:</span>
                                                        <span>{moment(data.updatedDate).format("D-MMM-YY")}</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>Email:</span>
                                                        <span>{data.email}</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>Phone:</span>
                                                        <span>{data.phoneno}</span>
                                                    </div>
                                                    <div class="grid-body-inn clamp">
                                                        <span>Category:</span>
                                                        <span class="char150">
                                                        {strCatArr(data.categoryName)}
                                                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="grid-footer">
                                                <a href={`/personalProfileView?profileId=`+data.profileId} class="reg-button">View</a>
                                            </div>
                                        </div>

                                        ))}

                                        
                                    </div>
                                       <div class="paginationContent mt-3">
                                       <Pagination               
                                        total={totalItems}
                                        itemsPerPage={ITEMS_PER_PAGE}
                                        currentPage={currentPage}
                                        onPageChange={handlePageChange2}/>

                                       </div>
                                    
                                </div>                        





                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </main>
        </>
    )
};

PersonalProfiles.propTypes = {};

PersonalProfiles.defaultProps = {};

export default PersonalProfiles;
