import React,{history,useEffect, useState, useMemo } from "react";

import Quick_links from "../../utility/Quick_links";

import services from "../../services/services";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import { useNavigate, useParams } from "react-router-dom";
import SidebarStudent from "../../utility/sidebar";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';





const ConvertToProfile = () => {
    const navigate = useNavigate();
    const [profileDetails, setProfileDetails] = useState([]);
    const [educations, setEducation] = useState([]);
    const [itemName, setFieldName] = useState('');
    const [jobTypes, setJobType] = useState([]);

    const [imageName, setImageName] = useState('');
    const [imageExtension, setImageExtension] = useState('');
    const [bannerBase64, setBannerBase64] = useState('');
    const [collegeInfo, setCollegeInfo] = useState([]);
    const [profileLookingFor, setProfileLookingFor] = useState("");

    const [fileName, setFileName] = useState('');
   const [fileExtension, setFileExtension] = useState('');
   const [fileBase64, setFileBase64] = useState('');
    //const userId=138;
    const userId=sessionStorage.getItem("logUserId");
    const companyId=sessionStorage.getItem("logCompanyId");

    const companyIdChkLogin=sessionStorage.getItem("logCompanyId");
    const studentIdChkLogin=sessionStorage.getItem("logStudentId");
    const jobSeekerIdChkLogin=sessionStorage.getItem("logProfileId");
    const userIdChkLogin=sessionStorage.getItem("logUserId");



  
    const [banner, setBanner] = useState('');
    const [emaildefault, setemaildefault] = useState('');
   
    const [profilePicS3URL, setProfilePicS3URL] = useState('');
   

    //var emaildefault = '';

    const {
        register,reset,
        formState: { errors },
        handleSubmit
      } = useForm({
        criteriaMode: "all",mode: "onBlur" 
      });

     

      function getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          // console.log(reader.result);
          var base64result = reader.result.split(',')[1];
          setFileBase64(base64result)
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
     }


     function getImage(){
        var files = document.getElementById('resume').files;  
        if (files.length > 0) {
           
          var fname = files[0].name;
          // var fextn = fname.substr(0, fname.lastIndexOf('.'));
          var fextn = fname.split(".").pop();
          // alert(fextn);
          var fileActName = fname.split(".")[0];
          // alert(fileActName);
          setFileName(fileActName);
          setFileExtension(fextn);
          getBase64(files[0]);
    
          if(fextn != "doc" && fextn != "docx" && fextn != "pdf"   ){
            
            $("#fileError").show();
                return false;
            }else{
                $("#fileError").hide();
            }
    
        }
      }
    
      function getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          // console.log(reader.result);
          var base64result = reader.result.split(',')[1];
          setFileBase64(base64result)
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
     }

      const getEducation = () => {
        services.getEducation().then((res) => {
          console.log("getEducation data=" + JSON.stringify(res.data));
          setEducation(res.data.content);
        });
      }

      const getJobType = () => {
        services.getJobType().then((res) => {
          console.log("setJobType data=" + JSON.stringify(res.data));
          var tempjobtypes = res.data.content;
          //console.log(tempjobtypes);
          tempjobtypes = tempjobtypes.filter(function(elem) {  
            return elem.name !== 'Job Shadow'; 
          });
          setJobType(tempjobtypes);
        });
      }

      const getCollegeInfo = () => {
        services.getEnrolledCollege().then((res) => {
          //console.log("getEducation data=" + JSON.stringify(res.data));
          setCollegeInfo(res.data);
        });
      }


      const handleChangeEdu = () => {
        
        var continueEducationSelect = $("#continueEducationSelect").val()
        if( continueEducationSelect == "Y"){
          $("#continueEduDiv").show();
        }else  if( continueEducationSelect == "N"){
          $("#continueEduDiv").hide();
        }

      }

    useEffect(() => {
        getJobType();
        getEducation();
        getCollegeInfo();
        $("#enrolledCollegeIdError").hide();
        $("#profileLookingForValidMsg").hide(); 
        $("#pursuingProgramError").hide(); 
     
        $("#continueEduDiv").show();
      /*
        $( "#continueEducationSelect" ).change(function() {
          var continueEducationSelect = $("#continueEducationSelect").val()
          if( continueEducationSelect == "Y"){
            $("#continueEduDiv").show();
          }else  if( selectedEdu == "N"){
            $("#continueEduDiv").hide();
          }

        });
        */

        $( "#inlineRadio1, #inlineRadio2" ).click(function() {
          var selectedEdu = $('input[name="continueEducation"]:checked').val();
          // alert(selectedEdu);
        if( selectedEdu == "Y"){
          $("#continueEduDiv").show();
        }else  if( selectedEdu == "N"){
          $("#continueEduDiv").hide();
        }
        });

       // logoutAction();
       // navigate("/Home?loginReset=1");
 
     
        /*
        if(companyId == null || companyId == "null"){            
            //navigate('/login');
            navigate('/Home');
          }
        
          if(userId == null || userId == "null"){            
            //navigate('/login');
            navigate('/Home');
          }
          */

          if( studentIdChkLogin == null && studentIdChkLogin == "null"){     
            navigate('/Home'); 
          }
      
        $("#fileError").hide();
        
        $("#successMessage").hide();
        $("#imgError").hide();
 
    }, []);



 

      const submitValue=()=>
      {

        var validateEditorTxt = 1;
        if(profileLookingFor == ""){
          //alert("profileLookingFor");
           $("#profileLookingForValidMsg").show();  
          $(window).scrollTop($('#profileLookingForValidMsg').position().top);
           validateEditorTxt = 0;     
         }else{
           $("#profileLookingForValidMsg").hide();  
         }

         if(validateEditorTxt == 0){
          return false;
         }

       //////////////////
       if( (fileExtension != "doc" && fileExtension != "docx" && fileExtension != "pdf" ) || (fileExtension == "")  ){
        //document.getElementById('logo').reset();
        $("#fileError").show();
        return false;
     }else{
        $("#fileError").hide();
     }
     /*
     var selectedEduction = $('input[name="continueEducation"]:checked').val();
     if(selectedEduction == "Y"){
      var enrolledCollege = $("#enrolledCollege").val();
      if(enrolledCollege == ""){
        $("#enrolledCollegeIdError").show();
        return false;
      }
     }else{
      $("#enrolledCollegeIdError").hide();
     }
     */

     var continueEducationSelect = $("#continueEducationSelect").val()
        if( continueEducationSelect == "Y"){
          var enrolledCollege = $("#enrolledCollege").val();
          if(enrolledCollege == ""){
            $("#enrolledCollegeIdError").show();
            return false;
          }else{
            $("#enrolledCollegeIdError").hide();
          }

          var pursuingProgram = $("#pursuingProgram").val();

          if(pursuingProgram == ""){
            $("#pursuingProgramError").show();
            return false;
          }else{
            $("#pursuingProgramError").hide();
          }

        }else  if( continueEducationSelect == "N"){
          $("#pursuingProgramError").hide();
          $("#enrolledCollegeIdError").hide();
        }
       const requestObject =
       {
          
          "siteId": process.env.React_App_SITE_ID,
          //"videoUrl": $("#videoUrl").val(),         
            "studentId": studentIdChkLogin,
            "jobType": $("#jobType").val(),
            //"lookingFor":  $("#lookingFor").val(),
            "lookingFor": profileLookingFor,            
            "enrolledCollege":  $("#enrolledCollege").val(),
            "pursuingProgram": $("#pursuingProgram").val(),
           // "continueEducation": "Y"
            "continueEducation":continueEducationSelect,
            "pdfByString":fileBase64,
            "fileName":fileName,
            "fileExtension":fileExtension                 
 
       }
      
      
 
       console.log(requestObject);
       var accessTokenIn=sessionStorage.getItem("accToken");
       const requestOptions = {
          method: 'POST',
          headers: {
             'Content-Type': 'application/json',
              'Authorization': "Bearer "+accessTokenIn 
              //'Authorization': accessTokenIn 
          },
          body: JSON.stringify(requestObject)
       };
       //editCompanyUser
       //alert("120");
       console.log(JSON.stringify(requestOptions));
      // return true;
       services.convertStudentToProfile(requestOptions).then((res) => {
          // navigate('/thankyou');
           // $("#editCompanyUserForm").trigger("reset");
           // getUserProfile();
           $("#successMessage").fadeTo(2000, 1000).slideUp(1000, function () {
            $("#successMessage").slideUp(1000);

            setTimeout(function() { navigate("/Home?loginReset=1");}, 500);
          });
         

          if( studentIdChkLogin != null && studentIdChkLogin != "null"){     
            //setTimeout(function() {navigate('/studentDashboard');}, 2000); 
            //setTimeout(function() {window.location.href=('/Home');}, 2000);  
            
          }
       });



       //////////////
      }

  return(
  <>
<main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        {/* <!-- info typography --> */}
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb">
                            <ol class="breadcrumb">

                            {(() => {
                                
                               

                                if( studentIdChkLogin != null && studentIdChkLogin != "null"){            
                                    return (<> <li class="breadcrumb-item headtitle "><a href="/studentDashboard">Student Dashboard</a> </li>
                                    <li class="breadcrumb-item headtitle "><a href="#">Promote to Job Seeker</a></li></>);
                                }

                                })()}    


                               
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-5">
                            <div class="row">
                                {/* <!-- left side --> */}

                                {(() => {
                                
                                

                                if( studentIdChkLogin != null && studentIdChkLogin != "null"){            
                                    return (<> <SidebarStudent/></>);
                                }

                                })()}    
                               

                                {/* <!-- right side --> */}
                                <div class="col-lg-8 mb-4">
                                <form onSubmit={handleSubmit(submitValue)} id="editCompanyUserForm">
                                   


                                    
                                  <div class="row mb-4">
                                   
                                    Did you graduate from High School and looking for career opprtunities? Please use the form below to convert your profile from Student to Job Seeker. Once completed, you will be redirected to Job Seeker dashboard .
                                    <br></br>
                                  </div>

                                    <div class="row resume-wrap">

                                    <div class="col-lg-6 mb-4">
                                        <label for="jobType" class="form-label">JOB TYPE <span class="red-text">*</span></label>
                                        <select class="form-select" aria-label="Default select example" id="jobType" onChange={e => setFieldName(e.target.value)} {...register("jobtype", {
                                                required: "Job type is required.",
                                                
                                                })}>
                                            <option selected value="">Select</option>
                                            {
                                            jobTypes.map((data, index) => (
                                            <option value={data.jobTypeId}>{data.name}</option>
                                            ))}
                                        </select>
                                        <ErrorMessage
                                                errors={errors}
                                                name="jobtype"
                                                class="invalid-feedback"
                                                render={({ messages }) => {
                                                console.log("messages", messages);
                                                return messages
                                                    ? Object.entries(messages).map(([type, message]) => (
                                                        <p key={type}  class="error">{message}</p>
                                                    ))
                                                    : null;
                                                }}
                                            /> 
                                    </div>


                                    <div class="col-lg-12 mb-4">

{/* <div class="d-flex">
  <label for="education" class="form-label mr-2">Are you currently continuing education? <span class="red-text">*</span></label>
  </div>

  <div class="form-check form-check-inline">
    <input class="form-check-input" type="radio" name="continueEducation" id="inlineRadio1"  value="Y"   style={{borderRadius: "30px" }}/>
    <label class="form-check-label" for="inlineRadio1">YES</label>
  </div>
  <div class="form-check form-check-inline">
    <input class="form-check-input" type="radio" name="continueEducation" id="inlineRadio2" checked value="N" />
    <label class="form-check-label" for="inlineRadio2">NO</label>
  </div> */}

                                    <div class="col-md-6 mt-4">
                                        <label for="firstName" class="form-label">  Are you currently continuing education?</label>
                                       
                                        {/* {...register("enrolled", {required: "Enrolled is required.",})} */}
                                        <select class="form-select" name="continueEducationSelect" aria-label="Default select example" id='continueEducationSelect'  onChange={handleChangeEdu} >
                                            <option value="Y" selected>Yes</option>
                                            <option value="N" >No</option>
                                           
                                        </select>
                                      

                                    </div>


       <div className="row" id="continueEduDiv">
       <div class="col-md-6 mt-4">
                                        <label for="firstName" class="form-label">  List of colleges<span class="red-text">*</span></label>
                                       
                                        {/* {...register("enrolled", {required: "Enrolled is required.",})} */}
                                        <select class="form-select" aria-label="Default select example" id='enrolledCollege'  onChange={e => setFieldName(e.target.value)} >
                                            <option value="">Select</option>
                                            {
                                            collegeInfo.map((data, index) => (
                                            <option value={data.enrolledCollegeId}>{data.name}</option>
                                            ))}  
                                        </select>
                                        <ErrorMessage
                                                errors={errors}
                                                name="enrolled"
                                                class="invalid-feedback"
                                                render={({ messages }) => {
                                                console.log("messages", messages);
                                                return messages
                                                    ? Object.entries(messages).map(([type, message]) => (
                                                        <p key={type}  class="error">{message}</p>
                                                    ))
                                                    : null;
                                                }}
                                            /> 

                                      <div id="enrolledCollegeIdError"  class="error">Required</div>
                                    </div>

            <div className="col-md-6 mt-4">
            <div class="d-flex"><label for="pursuingProgram" class="form-label mr-2">what program you are pursuing <span class="red-text">*</span></label></div>
            <input type="text" class="form-control " id="pursuingProgram" name="pursuingProgram"  placeholder="Enter "/>
            <div id="pursuingProgramError"  class="error">Required</div>
            </div>
       </div>

  </div>



                                   


                                    <div class="col-lg-12 mb-4">
                                        <div class="d-flex"><label for="lookingFor" class="form-label mr-2">TELL US WHAT YOU’RE LOOKING FOR <span class="red-text">*</span></label></div>
                                       
                                       
                                        <CKEditor
                                              editor={ ClassicEditor }
                                              id="profileLookingFor"  
                                                                 
                                              onReady={ editor => {              
                                                  console.log( 'Editor is ready to use!', editor );                                
                                              } }
                                              onChange={ ( event, editor ) => {
                                                  const data = editor.getData();
                                                  setProfileLookingFor(data);
                                                  console.log( { event, editor, data } );
                                              } }
                                              onBlur={ ( event, editor ) => {
                                                  console.log( 'Blur.', editor );
                                              } }
                                              onFocus={ ( event, editor ) => {
                                                  console.log( 'Focus.', editor );
                                              } }
                                          />
                                          <div id="profileLookingForValidMsg" class="error">What You're Looking For is required.</div>
      
                                       
                                        {/* <textarea class="form-control textarea" maxlength="500" placeholder="Leave a comment here" id="lookingFor" spellcheck="false" {...register("looking", {
                                                required: "This field is required.",
                                                maxLength: { value: 500, message: 'Should not allow more than 500 character' }
                                                })}></textarea>
                                            <ErrorMessage
                                                errors={errors}
                                                name="looking"
                                                class="invalid-feedback"
                                                render={({ messages }) => {
                                                console.log("messages", messages);
                                                return messages
                                                    ? Object.entries(messages).map(([type, message]) => (
                                                        <p key={type}  class="error">{message}</p>
                                                    ))
                                                    : null;
                                                }}
                                            />  */}
                                    </div>


                                        
                                    <div class="col-lg-12 mb-4">

                                        <div class="d-flex justify-content-between align-items-end choose">
                                            <div> 
                                                <label for="resumePath" class="form-label">UPLOAD A RESUME </label>
                                            <div class="d-flex align-items-center">
                                                {/* <input class="form-control form-control-lg choose-file mr-2" id="resumePath" type="file"/> */}
                                                <input class="form-control form-control-lg" id="resume" type="file" onChange={()=>getImage()}/>
                                            </div>
                                            <small> 1MB Max, File Type: PDF,DOC</small>
                                            <div id="fileError"  class="error">Invalid file format</div>
                                            </div>
                                            <div> <button type="submit" class="reg-button"  >Submit</button></div>
                                        </div>

                                    </div>    


                                        

                                        {/* <div class="col-lg-12 mb-4">

                                            <div class="d-flex justify-content-between align-items-end choose">

                                                <div><button type="submit" class="reg-button">Update</button></div>
                                            </div>

                                        </div> */}

                                        <div id="successMessage" class="alert">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <div class="d-flex align-items-center">
                                                    <img src="/images/success-alert.png" alt=""/>
                                                    <strong class="mx-2">Success!</strong> Your profile converted to Job Seeker. Please login again.
                                                </div>
                                                <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                                            </div>
                                        </div>


                                        
                                    </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </section>
        {/* <Hiring/> */}
    </main>
  </>
)};

ConvertToProfile.propTypes = {};

ConvertToProfile.defaultProps = {};

export default ConvertToProfile;
