import React, { history, useEffect, useState, useMemo } from "react";
import { useNavigate,useParams,useLocation,useHistory } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import Recent_Jobs from "./Jobs/Recent_Jobs";
import services from "../services/services";
import Hiring from "./Jobs/Hiring";


const CompanyListByIndustry = () => {
    const [categories, setCategory] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const[catTitle,setCatTitle]= useState('');


    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const catId = queryParams.get('catId');

    const siteId = process.env.React_App_SITE_ID;

    const getIndustryData = () => {
        services.getIndustryData().then((res) => {
          console.log("Industry data=" + JSON.stringify(res.data));
          setCategory(res.data.content);
        });
      }

      const getCompaniesByIndustryCatId = (siteId,catId) => {
        services.getCompaniesByIndustryCatId(siteId,catId).then((res) => {
          //console.log("Industry data=" + JSON.stringify(res.data));
          //setCompanyList(res.data);
          //alert(res[0].cat_Title)
          setCatTitle(res.data[0].cat_Title);

          var a = res.data;
            function SortArray(x, y){
                if (x.company_Name < y.company_Name) {return -1;}
                if (x.company_Name > y.company_Name) {return 1;}
                return 0;
            }
            var s = a.sort(SortArray);
            console.log(s);
            setCompanyList(s);
        });
      }

    useEffect(() => {


        //getIndustryData();
        getCompaniesByIndustryCatId(siteId,catId);
    }, []);

    return (<>
           <main class="empr-directory-main">
        <div class="main-bgpatten"></div>

        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">

                        <nav style={{"--bs-breadcrumb-divider": '>'}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item headtitle "><a href="/industries"> Industries</a><span style={{color: "#6c757d"}}>&#62;</span></li>
                                <li class="breadcrumb-item headtitle "><a href="#">{catTitle}</a></li>
                            </ol>
                        </nav>


                    </div>
                </div>
            </div>
        </section>

        <section class="info-typography py-5">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 mb-4">
                        <div style={{"padding-bottom":"20px !important;"}}>

                        <div class="p-4 white-bg">
                        <div class="row">

                        {
                        companyList.map((data, index) => (
                            <div class="col-lg-4 col-md-6 mb-4" >

                                {/* {item.name} */}
                                <a href={"/companyDetails?companyId="+data.company_Id}>
                                    <div class="card p-3 school-card justify-content-center align-items-center"  style={{minHeight: "220px", position:"relative"}}>
<a href="#" class="tool tool-pos-ab" data-bs-toggle="tooltip" data-bs-placement="top" data-tip="This company provides job shadow opportunities">

{data.job_Shadow_Concert == 'Y' ?  <div class="js-badge"> <img src="images/badge.png" alt="" class="w-100"/></div> : ''}
</a>


                                    {/* <img src="images/Arcanum-School-Logo.png" style={{maxWidth: "200px"}} class="p-4 mb-2 m-auto" alt=""/> */}
                                    <div class="school-img-wrp" >

                                    {(data.logo_URL == null || data.logo_URL == "") ?  <img src="https://snv3-dev-fs.s3.us-east-2.amazonaws.com/HometownMigration/uploads/default-logo.png" style={{maxWidth: "160px"}} class="mb-2" alt="" /> : <img src={data.logo_URL} style={{maxWidth: "200px"}} class="mb-2" alt="" /> }


                                   </div>

                                    <div class="text-center stock-wrap">
                                        <h3>{data.company_Name}</h3>
                                    {/* <span>{data.city != '' ? data.city : ''}{data.city != '' && data.state != '' ? ', ' : '' }{data.state != '' ? data.state : ''}</span> */}

                                    </div>
                                </div></a>
                            </div>
                         ))}


                        </div></div>

                        </div>
                    </div>
                    <div class="col-lg-4">
                    <Recent_Jobs/>
                        {/* <div class="white-bg mb-4">
                            <img src="images/ad-1.png" class="w-100" alt="" />
                        </div>
                        <div class="white-bg mb-4">
                            <img src="images/ad-2.png" class="w-100" alt="" />
                        </div> */}
                        {/*<div class="p-4 white-bg mb-4">
                            <h2 class="title-stay-connect">Stay connected. Get the app!</h2>
                            <p>Download the Hometown Opportunity app and stay constanly connected with companies and job oppportunities in your area. Hometown Opportunity is available on the Apple App Store or on Google Play.</p>
                            <img src="images/HTO-app-face.png" class="w-100" alt=""/>

                            <div class="d-flex justify-content-center  mt-3">
                                <img src="images/apple-app-store.png" alt="" style={{"width":" 130px;"}} />
                                <img src="images/google-play.png" alt="" class="ms-2" style={{"max-width": "130px;"}} />
                            </div>
                        </div>*/}


                    </div>
                </div>
            </div>

            <Hiring/>

        </section>


    </main>
    </>);

}

CompanyListByIndustry.propTypes = {};

CompanyListByIndustry.defaultProps = {};

export default CompanyListByIndustry;