import React,{history,useEffect, useState, useMemo } from "react";
import Quick_links from "../../utility/Quick_links";
import Hiring from "../Jobs/Hiring";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import services from "../../services/services";
import { useNavigate, useParams } from "react-router-dom";


const VideoUpload = () => {
   const [itemName, setFieldName] = useState('');

   //const companyId = 24;
   const companyId=sessionStorage.getItem("logCompanyId");
 
   const navigate = useNavigate();

   const [videoUrl, setVideo] = useState([]);

   useEffect(() => {
      if(companyId == null || companyId == "null"){            
         //navigate('/login');
         navigate('/Home');
       }
      getCompanyVideo();

   }, []);

   const getCompanyVideo = () => {
      services.getCompanyProfile(companyId).then((res) => {

         if(res.data.videoUrl==''){
           setVideo("https://www.youtube.com/embed/Gg1rEy7hClo&t=7s");
          }else{
            setVideo(res.data.videoUrl);
          }
      
      });
   }

   const {
      register,
      formState: { errors },
      handleSubmit
   } = useForm({
      criteriaMode: "all", mode: "onBlur"
   });

   const submitValue = () => {

      const requestObject =
      {
         "companyId": companyId,
         "siteId": process.env.React_App_SITE_ID,
         "videoUrl": $("#videoUrl").val()

      }

      console.log(requestObject);
      var accessTokenIn=sessionStorage.getItem("accToken");
      const requestOptions = {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer "+accessTokenIn 
            //'Authorization': accessTokenIn 
         },
         body: JSON.stringify(requestObject)
      };

      services.editCompanyVideo(requestOptions).then((res) => {
         // navigate('/thankyou');
           $("#addUserForm").trigger("reset");
     getCompanyVideo();
      });

   }

  return(
  <>
    <main  class="empr-directory-main">
         <div class="main-bgpatten"></div>
         {/* <!-- info typography --> */}
         <section class="headtitle-wrap">
            <div class="container">
               <div class="row">
                  <div class="col-lg-12">
                     <nav style={{"--bs-breadcrumb-divider": '>'}} aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item headtitle "><a href="/companyJobs">Company Dashboard</a> <span style={{color: "#6c757d"}}>&#62;</span></li>
                          <li class="breadcrumb-item headtitle "><a href="#">Edit Video</a></li>
                        </ol>
                      </nav>
                  </div>
               </div>
            </div>
         </section>
         {/* <!-- info typography --> */}
         <section class="py-5">
            <div class="container">
               <div class="row">
                  <div class="col-12 ">
                     <div class="white-bg p-5">
                        <div class="row">
                           {/* <!-- left side --> */}
                           <Quick_links/>
                           {/* <div class="col-lg-4 mb-4">
                               <div class="card p-3 ">
                                   <div class="bg-heading">
                                       <h3 class="mb-0">Quick Links </h3>
                                    </div>

                                    <div class="qlinks">
                                       <ul>
                                          
                                           <li><a href="profile-info.html">Profile Info </a></li>
                                           <li><a href="company-logo.html">Company Logo</a></li>
                                           <li><a href="video-upload.html" class="active">Upload Video</a></li>
                                          
                                    
                                       </ul>
                                       <div class="p-5 white-bg">
                                           <div class="text-center">
                                               <img src="images/Group 75.png" alt=""/>
                                           </div>
                                          <div class="text-cente">
                                           <h3 class="pb-3 border-bottom text-center">Upcoming Events</h3>
                                          <div class="pb-4 mt-4 border-bottom">
                                           <h4>CAREER FAIR</h4>
                                           <span>September 21, 2021</span>
                                           <p>Wright State University will be hosiint it’s 8th Annual Career Fair on October 21st.</p>
                                          </div>
       
                                          <div class="pb-4 mt-4 border-bottom">
                                           <h4>CAREER FAIR</h4>
                                           <span>September 21, 2021</span>
                                           <p>Wright State University will be hosiint it’s 8th Annual Career Fair on October 21st.</p>
                                          </div>
       
                                          <div class=" mt-4 ">
                                           <h4>CAREER FAIR</h4>
                                           <span>September 21, 2021</span>
                                           <p>Wright State University will be hosiint it’s 8th Annual Career Fair on October 21st.</p>
                                          </div>
                                          </div>
                                       </div>
                                   </div>
                               </div>
                               
                           </div> */}

                           {/* <!-- right side --> */}
                        
                           <div class="col-lg-8 mb-4">
                           <form id="addUserForm" onSubmit={handleSubmit(submitValue)}>
                            <div class="row resume-wrap">

                                <div class="col-lg-12 mb-4">
                                    <label for="firstName" class="form-label">Current Video </label>
                                  <div>
                                    <iframe width="100%" height="420" src={videoUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                  </div>
                                    
                                  </div>



                                <div class="col-lg-12 mb-4">
                                    <label for="videoUrl" class="form-label">Change Video </label>
                                    <input type="text" class="form-control" id="videoUrl" placeholder="" onChange={e => setFieldName(e.target.value)} {...register("videourl", {
                                    required: "Video url is required.",
                                    
                                    })}/>
                                 <ErrorMessage
                                    errors={errors}
                                    name="videourl"
                                    class="invalid-feedback"
                                    render={({ messages }) => {
                                    console.log("messages", messages);
                                    return messages
                                        ? Object.entries(messages).map(([type, message]) => (
                                            <p key={type}  class="error">{message}</p>
                                        ))
                                        : null;
                                    }}
                                /> 
                                  </div>

                               
                                <div class="d-flex justify-content-between align-items-end choose">
                                    <div><button type="submit" class="reg-button"  >Save</button></div>
                                </div>


                            </div>
                            </form>
                           </div>
                          
                       </div>
                     </div>
                  
                  </div>
                 
               </div>
            </div>
         </section>
         <section class="white-bg py-5">
            <div class="container">
            {/* <Hiring/> */}
               {/* <div class="row mb-4">
                  <div class="col-12">
                     <div class="d-flex justify-content-between border-bottom  mb-4 pb-4 flex-lg-row flex-column">
                        <h4 class="headtitle">Top companies hiring in your area</h4>
                        <a href="#" class="text-uppercase">See All</a>
                     </div>
                  </div>
                  
               </div>
               <div class="row">
                  <div class="col-lg-4">
                     <div class="card p-3">
                        <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                        <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                        <div class="text-center stock-wrap">
                           <h3>Midmark Corporation</h3>
                        <span>Versailles, Ohio</span>
                        <a href="#">15 OPEN JOBS</a>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-4">
                     <div class="card p-3">
                        <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                        <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                        <div class="text-center stock-wrap">
                           <h3>Midmark Corporation</h3>
                        <span>Versailles, Ohio</span>
                        <a href="#">15 OPEN JOBS</a>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-4">
                     <div class="card p-3">
                        <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                        <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                        <div class="text-center stock-wrap">
                           <h3>Midmark Corporation</h3>
                        <span>Versailles, Ohio</span>
                        <a href="#">15 OPEN JOBS</a>
                        </div>
                     </div>
                  </div>

                
               </div> */}
            </div>
         </section>
      </main>
  </>
  
)};

VideoUpload.propTypes = {};

VideoUpload.defaultProps = {};

export default VideoUpload;
