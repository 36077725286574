import React,{history,useEffect, useState, useMemo } from "react";
import services from "../services/services";
import Hiring from "./Jobs/Hiring";
import Recent_Jobs from "./Jobs/Recent_Jobs";


const MarketSupport = () => {

    const [marketSupportContent, setMarketSupportContent] = useState([]);
    const getPageInfo = (reqopt) => {

        
        services.getCMSPageContent('208').then((res) => {
            
            console.log(JSON.stringify(res));
            if(res.data){
               setMarketSupportContent(res.data.content.rendered);
            }
       
        });
       
     }

    useEffect(() => {        
        getPageInfo("marketsupport");
  
    }, []);

  return(
  <>
<main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav style={{"--bs-breadcrumb-divider": '>'}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item headtitle "><a href="#">Marketing Support</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="company-detail-wrap py-5 ">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="white-bg px-5 pt-5">
                            <div class="row mb-3">
                                <div class="col-lg-12 mb-4">
                                    

                                <div dangerouslySetInnerHTML={{ __html: marketSupportContent  }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <Recent_Jobs/>

                        {/* <div class="white-bg mb-4">
                            <img src="images/ad-1.png" class="w-100" alt="" />
                        </div>
                        <div class="white-bg mb-4">
                            <img src="images/ad-2.png" class="w-100" alt="" />
                        </div> */}
                        {/* <div class="p-4 white-bg mb-4">
                            <div class="bg-heading">
                                <h3 class="mb-0">Recent Posted</h3>
                            </div>
                            <ul class="mt-4 recent-job">
                                <li>
                                    <a href="#">
                                        <span>Quality Checker - $14.50/Hr</span>
                                        <span>By: Spherion Staffing</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>Quality Operations Clerk - $17.95/Hr</span>
                                        <span>By: Spherion Staffing</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>Mechanical Design Engineer</span>
                                        <span>By: PowerBuilt Material Handling Solutions</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>Production</span>
                                        <span>Pete DeLuke &amp; Associates, Inc.</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>Quality Operations Clerk - $17.95/Hr</span>
                                        <span>By: Spherion Staffing</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>Mechanical Design Engineer</span>
                                        <span>By: PowerBuilt Material Handling Solutions</span>
                                    </a>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                </div>
            </div>
            <Hiring/>
        </section>
    </main>
  </>
)};

MarketSupport.propTypes = {};

MarketSupport.defaultProps = {};

export default MarketSupport;
