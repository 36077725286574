import React,{history,useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import Services from "../../services/services";
const StudentRegistration2 = () => {

    const [categories, setCategory] = useState([]);

  useEffect(() => {
    
    getIndustryData();

  }, []);

  const getIndustryData = () => {
    Services.getIndustryData().then((res) => {
      console.log("Industry data=" + JSON.stringify(res.data));
      setCategory(res.data);
    });
  }

    const {
        register: register3,
        formState: { errors: errors3 },
        handleSubmit: handleSubmit3,
      } = useForm({
        criteriaMode: "all",mode: "onBlur" 
      });
    
      const submitValueStep3=()=>
      {
       
      $("#step1").hide();
      $("#step2").hide();
      $("#step3").show();
    
      }
  return(
  <>
     <section class="company-detail-wrap" id="step2">
            <div>
                <div class="col-12 ">
                <form onSubmit={handleSubmit3(submitValueStep3)}>
                    <div class="white-bg px-4">
                        <div class="row">
                            <div class="text-center">
                                <h2 class="headtitle ">Choose Your Industry</h2>
                            </div>
                            <div class="col-12 p-3 pb-0">
                                <div class="mobile-form-avl p-3">
                                    <div class="category-check-itm">
                                        <ul>
                                        {
                                        categories.map((data, index) => (
                                        <li><input class="form-check-input" type="checkbox" name="category_m" value={data.catId} id="category" {...register3('category', { required: true })}/>  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>
                                        ))}
                                  </ul>
                                       
                                </div>
                                
                            </div>
                            {errors3.category && errors3.category.type === "required" && <div class="error">Category is required</div>}
                                    </div>
                            <div class="mt-4 mb-4">
                            <button type="submit" class="reg-button">Next &gt;&gt;</button>
                            </div>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
        </section>
  </>
)};

StudentRegistration2.propTypes = {};

StudentRegistration2.defaultProps = {};

export default StudentRegistration2;
