import React, { useEffect, useState } from 'react';

import { ErrorMessage } from "@hookform/error-message";
import { set, useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import UsNumberValidate from '../../utility/usNumberValidate';
import StudentRegistration2 from "./student-registration2";
import StudentRegistration3 from "./student-registration3";
import Services from '../../services/services';
import { useNavigate,useParams } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup'
import Header from '../../layouts/header/header';
import * as Yup from 'yup';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const StudentRegistration = () => {

  const [categories, setCategory] = useState([]);
  const [jobTypes, setJobType] = useState([]);
  const [currentYearList, setCurrentYearList] = useState([]);
  const [schoolIds, setSchoolInData] = useState([]);

  const [fileName, setFileName] = useState('');
  const [fileExtension, setFileExtension] = useState('');
  const [fileBase64, setFileBase64] = useState('');

  const [fileNameMobile, setFileNameMobile] = useState('');
  const [fileExtensionMobile, setFileExtensionMobile] = useState('');
  const [fileBase64Mobile, setFileBase64Mobile] = useState('');

  const [studentCareers, setStudentCareers] = useState([]);
  const [coursesRelated, setCoursesRelated] = useState('');
  const [studentSkills, setStudentSkills] = useState('');
  const [studentCourses, setStudentCourses] = useState('');
  const [studentComments, setStudentComments] = useState('');

  const [studentCareersMobile, setStudentCareersMobile] = useState('');
  const [studentSkillsMobile, setStudentSkillsMobile] = useState('');
  const [studentCoursesMobile, setStudentCoursesMobile] = useState('');
  const [studentCommentsMobile, setStudentCommentsMobile] = useState('');
  const [educations, setEducation] = useState([]);

  const navigate = useNavigate();
  

  const {
    register: register4,
    formState: { errors: errors4 },
    handleSubmit: handleSubmit4,
  } = useForm({
    criteriaMode: "all",mode: "onBlur" 
  });

  const getEducation = () => {
    Services.getEducation().then((res) => {
      console.log("getEducation data=" + JSON.stringify(res));
      var tempedu = res.data.content;
      //console.log(tempjobtypes);
      tempedu = tempedu.filter(function(elem) {  
        return elem.name != 'PhD'; 
      });
      setEducation(tempedu);
    });
  }

  const submitValueStep4=()=>
      {
        
        
        var validateEditorTxt = 1;
        if(studentCareersMobile == ""){
          $("#studentCareersValidMsgMobile").show();  
         $(window).scrollTop($('#studentCareersValidMsgMobile').position().top);
          validateEditorTxt = 0;     
        }else{
          $("#studentCareersValidMsgMobile").hide();  
        }
       
      
        if(studentSkillsMobile == ""){
          $("#studentSkillsValidMsgMobile").show();  
         $(window).scrollTop($('#studentSkillsValidMsgMobile').position().top);
          validateEditorTxt = 0;     
        }else{
          $("#studentSkillsValidMsgMobile").hide();  
        }
      
        /*if(studentCoursesMobile == ""){
          $("#studentCoursesValidMsgMobile").show();  
         $(window).scrollTop($('#studentCoursesValidMsgMobile').position().top);
          validateEditorTxt = 0;     
        }else{
          $("#studentCoursesValidMsgMobile").hide();  
        } */
      
        if(studentCommentsMobile == ""){
          $("#studentCommentsValidMsgMobile").show();  
         $(window).scrollTop($('#studentCommentsValidMsgMobile').position().top);
          validateEditorTxt = 0;     
        }else{
          $("#studentCommentsValidMsgMobile").hide();  
        }

        if($('#pursuing_m').val() == 3){
          if($('#school_district_m').val() == ''){
            $("#school_districtValidMsg_m").show();  
            $(window).scrollTop($('#school_districtValidMsg_m').position().top);
            validateEditorTxt = 0; 
          }
        }
        else{
          if($('#university_m').val() == ''){
            $("#university_nameValidMsg_m").show();  
            $(window).scrollTop($('#university_nameValidMsg_m').position().top);
            validateEditorTxt = 0; 
          }
        }
      
        if(validateEditorTxt == 0){
         return false;
        }
        
        var categoriesArray = [];
        $('input:checkbox.seeking_mcls').each(function () {
          categoriesArray.push($(this).val());
       });
           /*
            $.each($("input[name='seeking_m']:checked"), function(){
                categoriesArray.push($(this).val());
            });
           */
            
        var catofExpertiseArray = [];
       /*
        $.each($("input[name='category_m']:checked"), function(){
            catofExpertiseArray.push($(this).val());
        }); 
       */
        $('input:checkbox.category_mcls').each(function () {
          catofExpertiseArray.push($(this).val());
       });
        
        var phone_val = $("#mobile1_m").val();

        var passwordConf = $("#passwordConf_m").val();
        var passwordCheck = $("#password_m").val();
        if(passwordConf != passwordCheck){
            $("#matchPwd").show();
            $("#passwordConf_m").focus();
            return false;
        }else{
            $("#matchPwd_m").hide();
        }
        //phone_val = "1234567899";
            const requestObject={
            "siteId": process.env.React_App_SITE_ID,
            "countyId": 2,
            "classYear": $("#classType_m").val(),
            "firstName": $('#firstName_m').val(),
            "lastName": $('#lastName_m').val(),
            "email": $('#email_m').val(),
            "password":$('#password_m').val(),
            "educationId" : $('#pursuing_m').val(),
            "nameOfCollegeUniversity" : $('#university_m').val(),
            //"phone":$('#yourphone2').val(),
            "phone":phone_val,
            "isStudent": true,////////////////////////////
           // "reasonForSelectCareers":$('#careers_m').val(),
           "reasonForSelectCareers":studentCareersMobile,
          //  "course": $('#courses_m').val(),
          "course": 'studentCourses',
            //"experiance":$('#skills_m').val(),
            "experiance":studentSkillsMobile,
            "helpPrepare": "yes",
           // "comments": $('#comments_m').val(),
           "comments": studentCommentsMobile,

            "status": "1",

            "schoolId": $("#district_m").val(),
            
            //"password": $('#password').val(),
            "categoriesOfExpertise":catofExpertiseArray,     
            //
            
            // ///////////////////////////////////
            // "interested": $('#interested').val(),
            "seekingEmploymentTypes": categoriesArray,
            // "seeking": $('#skills').val(),
            //"events": $('#events').val(),
            "authorization":$('#authorization_m').val(),
            "studentTalentConnectionForumIn": [1,2],
            "studentInterestedIn":[3,4]
            //"pdfByString":fileBase64,
            //"fileName":fileName,
            //"fileExtension":fileExtension
            }
            if(fileNameMobile !="" && fileExtensionMobile != "" ){
            requestObject.pdfByString = fileBase64Mobile;
            requestObject.fileName = fileNameMobile;
            requestObject.fileExtension = fileExtensionMobile;
            }
        console.log(requestObject);
        var accessTokenIn=sessionStorage.getItem("accToken");
        const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
                    //'Authorization': "Bearer "+accessTokenIn 
                    //'Authorization': accessTokenIn 
                },
        body: JSON.stringify(requestObject)
        };
        console.log(JSON.stringify(requestObject));
        $(".load-wrap").show();
        Services.studentRegistration(requestOptions).then((res) => 
        {
          console.log(JSON.stringify(res));
         
          $(".load-wrap").hide();
          if(res.success == "false" || res.success == false){
            //$("#alertFailMessage").html(res.message);
            //$("#alertFailMessage").show();
           
            $("#alertFailMessage").html(res.message);
            $("#alertFailMessage").fadeTo(2000, 1000).slideUp(1000, function () {
              
              $("#alertFailMessage").slideUp(1000);
            });
    
          }else{
            $("#successMessage").fadeTo(2000, 1000).slideUp(1000, function () {
              $("#successMessage").slideUp(1000);
            });
            navigate('/thankyou?r=2');
          }
         //navigate('/thankyou');

        });
        
        //navigate('/home');
        // history.push("/studentRegistration");
      }


      const {
        register: register3,
        formState: { errors: errors3 },
        handleSubmit: handleSubmit3,
      } = useForm({
        criteriaMode: "all",mode: "onBlur" 
      });

      const submitValueStep3=()=>
      {
       
       

      $("#step1").hide();
      $("#step2").hide();
      $("#step3").show();
    
      }
  
  useEffect(() => {
    $("#fileErrorMobile").hide();
    $(".load-wrap").hide();
    $("#alertFailMessage").hide();
    $("#successMessage").hide();    
    $("#alertFailMessage").hide();
    $("#studentCareersValidMsg").hide();
    $("#coursesRelatedValidMsg").hide();
    $("#studentSkillsValidMsg").hide();
    $("#studentCoursesValidMsg").hide();
    $("#studentCommentsValidMsg").hide();

    $("#studentCareersValidMsgMobile").hide();  
    $("#coursesRelatedValidMsgMobile").hide();
    $("#studentSkillsValidMsgMobile").hide();
    $("#studentCoursesValidMsgMobile").hide();
    $("#studentCommentsValidMsgMobile").hide();
    $('#school_districtValidMsg').hide();
    $('#university_nameValidMsg').hide();
    $('#school_districtValidMsg_m').hide();
    $('#university_nameValidMsg_m').hide();

    $("#matchPwd_m").hide();
    $("#matchPwd").hide();
    $('#yourphone2').usPhoneFormat();
    $('#mobile1_m').usPhoneFormat();
    $(document).ready(function(){
      $(this).scrollTop(0);
  });
    $('#mobile1').usPhoneFormat();
    $('#step2').hide();
    $('#step3').hide();
    getJobType();
    getIndustryData();
    getCurrentYear();
    getSchoolnData();
    getEducation();
    $("#fileError").hide();
    $("#schoolIdOtherDiv").hide();
  }, []);


  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      // console.log(reader.result);
      var base64result = reader.result.split(',')[1];
      setFileBase64(base64result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
 }

 function getBase64Mobile(file) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    // console.log(reader.result);
    var base64result = reader.result.split(',')[1];
    setFileBase64Mobile(base64result)
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
}

  function goLogin(){
    navigate("/Home?loginRedirect=1");
  }

  function getImage(){
    var files = document.getElementById('resume').files;  
    if (files.length > 0) {
       
      var fname = files[0].name;
      // var fextn = fname.substr(0, fname.lastIndexOf('.'));
      var fextn = fname.split(".").pop();
      // alert(fextn);
      var fileActName = fname.split(".")[0];
      // alert(fileActName);
      setFileName(fileActName);
      setFileExtension(fextn);
      getBase64(files[0]);

      if(fextn != "doc" && fextn != "docx" && fextn != "pdf" ){
        
        $("#fileError").show();
            return false;
        }else{
            $("#fileError").hide();
        }

    }
  }

  function getImageMobile(){
    var files = document.getElementById('resumeMobile').files;  
    if (files.length > 0) {
       
      var fname = files[0].name;
      // var fextn = fname.substr(0, fname.lastIndexOf('.'));
      var fextn = fname.split(".").pop();
      // alert(fextn);
      var fileActName = fname.split(".")[0];
      // alert(fileActName);
      setFileNameMobile(fileActName);
      setFileExtensionMobile(fextn);
      getBase64Mobile(files[0]);

      if(fextn != "doc" && fextn != "docx" && fextn != "pdf" ){
        
        $("#fileErrorMobile").show();
            return false;
        }else{
            $("#fileErrorMobile").hide();
        }

    }
  }

  const getIndustryData = () => {

    Services.getIndustryData().then((res) => {

      console.log("Industry data=" + JSON.stringify(res.data));

      setCategory(res.data.content);

    });

  }
  const getCurrentYear = () =>{
    var currentYear = new Date().getFullYear()
    var years = [];
    years.push(currentYear);
    for(var i=1;i<5;i++){
      years.push(currentYear+i);
    }
    
    //const now = new Date().getUTCFullYear();    
    //const years = Array(now - (now + 5)).fill('').map((v, idx) => now - idx);
    console.log("#41#41-->"+JSON.stringify(years));
    setCurrentYearList(years);
  }

  const getJobType = () => {
    Services.getJobType().then((res) => {
      console.log("setJobType data=" + JSON.stringify(res.data));
      var jobtype_unsort = res.data.content;
          var jobtypes_sorted= jobtype_unsort.sort((a, b)=> b.jobTypeId - a.jobTypeId)
          setJobType(jobtypes_sorted); 
    });
  }


  const formSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .min(4, 'Password length should be at least 4 characters'),
    passwordConf: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password')], 'Passwords must and should match'),
  })

  const validationOpt = { resolver: yupResolver(formSchema) }

  const [itemName, setFieldName] = useState('');
  
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    criteriaMode: "all", 
    mode: "onBlur"
    //resolver: yupResolver(formSchema)
  });
  

  //const { register, handleSubmit, reset, formState } = useForm(validationOpt);
    //const { errors } = formState;

  //const { register, formState: { errors },handleSubmit } = useForm(validationOpt);

  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm({
    criteriaMode: "all", mode: "onBlur"
  });

  const submitValueStep1 = () => { 

    var passwordConf = $("#passwordConf_m").val();
    var passwordCheck = $("#password_m").val();
    if(passwordConf != passwordCheck){
        $("#matchPwd_m").show();
        $("#passwordConf_m").focus();
        return false;
    }else{
        $("#matchPwd_m").hide();
    }

    $("#step1").hide();
    $("#step2").show();
    $(document).ready(function(){
      $(this).scrollTop(0);
  });
    $("#step3").hide();

  }

  $('#pursuing_degree').change(function(){
    //alert($('#pursuing_degree').val());
    $('#school_districtValidMsg').hide();
    $('#university_nameValidMsg').hide();
    if($('#pursuing_degree').val() == 3){
      $('#school_district').prop('disabled', false);
      $('#university_name').prop('disabled', true);
      $('#university_name').val('');
    }
    else{
      $('#school_district').prop('disabled', true);
      $('#university_name').prop('disabled', false);
      $('#school_district').val('');
    }
  })

  $('#pursuing_m').change(function(){
    //alert($('#pursuing_degree').val());
    $('#school_districtValidMsg_m').hide();
    $('#university_nameValidMsg_m').hide();
    if($('#pursuing_m').val() == 3){
      $('#school_district_m').prop('disabled', false);
      $('#university_m').prop('disabled', true);
      $('#university_m').val('');
      
    }
    else{
      $('#school_district_m').prop('disabled', true);
      $('#university_m').prop('disabled', false);
      $('#district_m').val('');
    }
  })

  const submitValue = (data) => {

    var categoriesArray = [];

    $.each($("input[name='seeking']:checked"), function(){
        categoriesArray.push($(this).val());
    });
  var catofExpertiseArray = [];
  $.each($("input[name='category']:checked"), function(){
      catofExpertiseArray.push($(this).val());
  });  


  var validateEditorTxt = 1;
  if(studentCareers == ""){
    $("#studentCareersValidMsg").show();  
   $(window).scrollTop($('#studentCareersValidMsg').position().top);
    validateEditorTxt = 0;     
  }else{
    $("#studentCareersValidMsg").hide();  
  }
  /*
  if(coursesRelated == ""){
    $("#coursesRelatedValidMsg").show();  
   $(window).scrollTop($('#coursesRelatedValidMsg').position().top);
    validateEditorTxt = 0;     
  }else{
    $("#coursesRelatedValidMsg").hide();  
  }
  */

  if(studentSkills == ""){
    $("#studentSkillsValidMsg").show();  
   $(window).scrollTop($('#studentSkillsValidMsg').position().top);
    validateEditorTxt = 0;     
  }else{
    $("#studentSkillsValidMsg").hide();  
  }

  /*if(studentCourses == ""){
    $("#studentCoursesValidMsg").show();  
   $(window).scrollTop($('#studentCoursesValidMsg').position().top);
    validateEditorTxt = 0;     
  }else{
    $("#studentCoursesValidMsg").hide();  
  } */

  if(studentComments == ""){
    $("#studentCommentsValidMsg").show();  
   $(window).scrollTop($('#studentCommentsValidMsg').position().top);
    validateEditorTxt = 0;     
  }else{
    $("#studentCommentsValidMsg").hide();  
  }

  console.log($('#pursuing_degree').val());
if($('#pursuing_degree').val() == 3){
  if($('#school_district').val() == ''){
    $("#school_districtValidMsg").show();  
    $(window).scrollTop($('#school_districtValidMsg').position().top);
    validateEditorTxt = 0; 
  }
}
else{
  console.log('here');
  if($('#university_name').val() == ''){
    $("#university_nameValidMsg").show();  
    $(window).scrollTop($('#university_nameValidMsg').position().top);
    validateEditorTxt = 0; 
  }
}



  if(validateEditorTxt == 0){
   return false;
  }

   var phone_val = $("#yourphone2").val();

   var passwordConf = $("#passwordConf").val();
   var passwordCheck = $("#password").val();
   if(passwordConf != passwordCheck){
    $("#matchPwd").show();
    $("#passwordConf").focus();
    return false;
   }else{
    $("#matchPwd").hide();
   }
   //phone_val = "1234567899";
    const requestObject={
      "siteId": process.env.React_App_SITE_ID,
      "countyId": 2,
      "classYear": $("#class_type").val(),
      "firstName": $('#firstName').val(),
      "lastName": $('#lastName').val(),
      "email": $('#email').val(),
      "password":$('#password').val(),
      "educationId" : $('#pursuing_degree').val(),
      "nameOfCollegeUniversity" : $('#university_name').val(),
      //"phone":$('#yourphone2').val(),
      "phone":phone_val,
      "isStudent": true,////////////////////////////
      //"reasonForSelectCareers":$('#careers').val(),
      "reasonForSelectCareers":studentCareers,

      //"course": $('#courses').val(),
      "course": 'studentCourses',

     // "experiance":$('#skills').val(),
     "experiance":studentSkills,

      "helpPrepare": "yes",
     // "comments": $('#comments').val(),
     "comments": studentComments,

      "status": "1",

     "schoolId": $("#school_district").val(),
     
     //"password": $('#password').val(),
      "categoriesOfExpertise":catofExpertiseArray,     
      //
     
     // ///////////////////////////////////
     // "interested": $('#interested').val(),
      "seekingEmploymentTypes": categoriesArray,
      // "seeking": $('#skills').val(),
      //"events": $('#events').val(),
      "authorization":$('#authorization').val(),
      "studentTalentConnectionForumIn": [1,2],
      "studentInterestedIn":[3,4]
      //"pdfByString":fileBase64,
      //"fileName":fileName,
      //"fileExtension":fileExtension
    }
    if(fileName !="" && fileExtension != "" ){
      requestObject.pdfByString = fileBase64;
      requestObject.fileName = fileName;
      requestObject.fileExtension = fileExtension;
    }
 console.log(requestObject);
 var accessTokenIn=sessionStorage.getItem("accToken");
const requestOptions = {
  method: 'POST',
  headers: { 'Content-Type': 'application/json',
            //'Authorization': "Bearer "+accessTokenIn 
            //'Authorization': accessTokenIn 
          },
  body: JSON.stringify(requestObject)
};
console.log(JSON.stringify(requestObject));
$(".load-wrap").show();
Services.studentRegistration(requestOptions).then((res) => 
{
  $(".load-wrap").hide();
  console.log(JSON.stringify(res));
// alert(res.error);
//  if(res.error){
//    console.log(res.error);
//  }else{
//   console.log(res.success);
//  }

if(res.success == "false" || res.success == false){
  //$("#alertFailMessage").html(res.message);
  //$("#alertFailMessage").show();
  $("#alertFailMessage").html(res.message);
  $("#alertFailMessage").fadeTo(2000, 1000).slideUp(1000, function () {
    
    $("#alertFailMessage").slideUp(1000);
  });

}else{
  $("#successMessage").fadeTo(2000, 1000).slideUp(1000, function () {
    $("#successMessage").slideUp(1000);
  });
  navigate('/thankyou?r=2');
}


  //navigate('/thankyou');

});

  }
  /*
  const getSchoolnData = () => {
    Services.getSchoolnData().then((res) => {
      console.log("setSchoolInData data=" + JSON.stringify(res.data));
      setSchoolInData(res.data);          
    });
  }
  */
  const getSchoolnData = () => {
    //alert("14");
  const requestObject={
      "siteId":process.env.React_App_SITE_ID,
      "pageNumber":1,
      "pageSize":150           
   }
 
   var accessTokenIn=sessionStorage.getItem("accToken");
   const requestOptions = {
     method: 'POST',
     headers: { 'Content-Type': 'application/json',
               //'Authorization': "Bearer "+accessTokenIn 
               //'Authorization': accessTokenIn 
             },
     body: JSON.stringify(requestObject)
   };

   Services.getSchoolList(requestOptions).then((res) => 
   {
      console.log("############36");
     console.log(JSON.stringify(res))
      if(res.content){
          console.log(JSON.stringify(res.data));
            
            
 
             var contAr = res.content;
             //setProfileList(res.content);
             if(res.content){
              setSchoolInData(res.content);
             }                
             

        }  
    //navigate('/thankyou');

   });
 
 
}
function handleChangeSchool(event) {
  //alert("teste")
  //alert(event.target.value);
  var sch = event.target.value;
  //console.log(event.target.value);
  //var sch = $("#schoolId'").val();    
  if(sch == "0"){
    $("#schoolIdOtherDiv").show();
  }else{
    $("#schoolIdOtherDiv").hide();
  }
}

  function validateMedium() {
    var mediumCheckboxes = document.getElementsByName("exp");
    var okay = false;

    for (var i = 0, len = mediumCheckboxes.length; i < len; i++) {
      if (mediumCheckboxes[i].checked) {
        okay = true;
        break;
      }
    }

    if (okay) {
      return true;
    } else {
      return false;
    }
  }
  return (
    <>
      <main class="empr-directory-main cd-desktop">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <nav style={{ "--bs-breadcrumb-divider": "'>';" }} aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item headtitle "><a href="#">Student Registration</a></li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="company-detail-wrap py-5 ">
          <div class="container">
            <div class="row">
              <div class="col-12 ">
                <form onSubmit={handleSubmit(submitValue)}>
                  <div class="white-bg px-5 pt-4 pb-4">
                    <div class="row mb-3">
                      <div class="col-lg-12 m-auto">
                      <div className='border-bottom pb-4 mb-3 d-flex align-items-center justify-content-between'>
                                    <h6 className='mb-0'>Do you already have a profile?</h6>
                                    <a onClick={() => goLogin() } className='reg-button  text-center'>Sign In</a>
                            </div>
                        <h1 class="text-center ">
                          What if we told you  <span class=" red-text"> your hometown</span> <br />  is a great place to plan your career?
                        </h1>
                        <div class="col-4 m-auto">
                          <hr />
                        </div>
                      </div>
                    </div>
                    <div style={{ margin: "30px -49px 30px -48px" }}>
                      <img src="images/student-montage.png" class="w-100" />
                    </div>
                    <div class="row text-center my-5">
                      <div class="col-8 m-auto">
                        <b>
                          Now, don’t go all scrunchy-faced on us. It’s true! Your local area is packed full of <span class="red-text">companies</span> who offer amazing jobs with great pay and plenty of room to grow.
                        </b>
                        <p class="my-3">
                          Hometown Opportunity is the place to learn who they are, what they do, and how they can offer you a chance to build the life you’ve always wanted… right here in your hometown. Plus, we have plenty of resources for you to learn more about your chosen career path, get internships and co-ops, get part-time jobs while you’re in school and build a strong foundation for your future.
                        </p>
                       {/* <b>
                          Register below and get started, then be sure to download the <span class="red-text">Hometown Opportunity</span> app to stay connected through your high school career.
                        </b> */}

                      
                      </div>
                     {/* <div class="col-8 m-auto ">
                     <br></br>
                        Are you a college student? Please <a href="/postResume" class="red-text">click here</a> to register as a Job Seeker to view exciting career opportunities.
                        
                      </div> */}
                    </div>
                    <div class="row mt-4 resume-wrap">
                      <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">First name <span class="red-text">*</span></label>
                        <input type="text" class="form-control" id="firstName" onChange={e => setFieldName(e.target.value)} {...register("FirstName", {
                          required: "First name is required.",

                        })} />
                        <ErrorMessage
                          errors={errors}
                          name="FirstName"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}
                        />
                      </div>

                      <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">Last name <span class="red-text">*</span></label>
                        <input type="text" class="form-control" id="lastName" onChange={e => setFieldName(e.target.value)} {...register("lastName", {
                          required: "Last name is required.",

                        })} />
                        <ErrorMessage
                          errors={errors}
                          name="lastName"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}
                        />
                      </div>

                      <div class="col-lg-6 mb-5">
                        <div class="d-flex"><label for="email" class="form-label mr-2">Email <span class="red-text">*</span></label> <small>Valid email address for notifications</small></div>
                        <input type="text" class="form-control" id="email" onChange={e => setFieldName(e.target.value)} {...register("Email", {
                          required: "Email is required.",

                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address"
                          }
                        })} />
                        <ErrorMessage
                          errors={errors}
                          name="Email"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}
                        />
                      </div>

                      <div class="col-lg-6 mb-5">
                        <div class="d-flex"><label for="phone" class="form-label mr-2">Phone<span class="red-text">*</span></label> <small>XXX-XXX-XXXX Format</small></div>
                        <input type="text" class="form-control" id="yourphone2" name="contactNumber" maxlength="14" onChangeText={(text) => this.onTextChange(text)} {...register("phone", {
                          required: "Phone field is required.", 
                        })}/>
                         <ErrorMessage
                          errors={errors}
                          name="phone"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}
                        />

                      </div>

                      <div class="col-lg-6 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">Password <span class="red-text">*</span></label></div>
                        <p style={{fontSize: "12px"}}>Minimum of 8 characters long, and a combination of lowercase & uppercase letters, numbers, and special characters.</p>
                        <input type="password" class="form-control" id="password" onChange={e => setFieldName(e.target.value)} {...register("Password", {
                          required: "Password is required.",
                          pattern: {
                            value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                            message: "Password contain uppercase,lowercase and special character"
                          },
                          minLength: { value: 8, message: 'Too short' }
                        })} />
                        <ErrorMessage
                          errors={errors}
                          name="Password"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}
                        />
                        
                      </div>

                      <div class="col-lg-6 mb-5">
                      <div class="d-flex"><label for="firstName" class="form-label mr-2">Confirm Password <span class="red-text">*</span></label>  </div>
                      <p style={{fontSize: "12px"}}>Minimum of 8 characters long, and a combination of lowercase & uppercase letters, numbers, and special characters.</p>
                      <input type="password" class="form-control" id="passwordConf" onChange={e => setFieldName(e.target.value)} {...register("PasswordConf", {
                          required: "Password is required.",
                          pattern: {
                            value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                            message: "Password contain uppercase,lowercase and special character"
                          },
                          minLength: { value: 8, message: 'Too short' }
                        })} />
                        <ErrorMessage
                          errors={errors}
                          name="PasswordConf"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}
                        />
                        <p id="matchPwd" class="error">Passwords must match</p>
                      
                      </div>

                      <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">I am Currently Pursuing <span class="red-text">*</span></label>
                        <select class="form-select" aria-label="Default select example" id="pursuing_degree" {...register('pursuing_degree', { required: true })}>
                          <option selected="" value=''>Select</option>
                          {
                                educations.map((data, index) => (
                                  <option value={data.educationId}>{data.name}</option>
                                  ))}
                        </select>
                        {errors.pursuing_degree && errors.pursuing_degree.type === "required" && <div class="error">This Field is required</div>}
                      </div>

                      <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">GRADUATING YEAR <span class="red-text">*</span></label>
                        <select class="form-select" aria-label="Default select example" id="class_type" {...register('class_type', { required: true })}>
                          <option selected="" value=''>Select</option>     
                          {
                          currentYearList.map((data, index) => (                                    
                          <option value={data}>{data}</option>                                
                            ))} 
                        </select>
                        {errors.class_type && errors.class_type.type === "required" && <div class="error">Class type is required</div>}
                      </div>

                      

                      <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">school district</label>
                        <select class="form-select" aria-label="Default select example" disabled id="school_district" onChange={handleChangeSchool}>
                          <option selected="" value=''>Select</option>
                          {
                            schoolIds.map((data, index) => (
                            <option value={data.schoolId}>{data.name}</option>
                            ))
                          }
                          <option value="0" >Name of the Institution</option>
                        </select>
                        <div id="school_districtValidMsg" class="error">School District is required.</div>
                      </div>

                      <div class="col-md-6 " id="schoolIdOtherDiv">
                                  <div class="d-flex ">
                                    <label for="schoolIdOther" class="form-label mr-2">Other</label>
                                  </div>
                                  <input type="text" class="form-control " id="schoolIdOther" aria-describedby="emailHelp" placeholder="Other"/>
                                  
                                </div>

                      <div class="col-lg-6 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">College / University </label></div>
                        <input type="text" disabled class="form-control" id="university_name" />
                        <div id="university_nameValidMsg" class="error">College / Universities is required.</div>
                      </div>

                      

                    
                      <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2 mb-3">FIELDS OF INTEREST <span class="red-text">*</span></label></div>

                        <div >
                          <div class="category-check-itm">
                            <ul class="category-check">
                              {
                                categories.map((data, index) => (


                                  <li><input class="form-check-input" type="checkbox" name="exp" value={data.catId} id="defaultCheck1" {...register('category', { required: true })}/>  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                  ))}
                                            {/* <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Advanced Manufacturing</label></li>
                                            <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                            <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> CNC  </label></li>
                                            <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Advertising and PR Services  </label></li>
                                            <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Agriculture and Food Processing  </label></li>
                                            <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Architectural and Design Services  </label></li>
                                            <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Art Athletic Training/Physical/ </label></li>
                                            <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Art Athletic Training/Physical/ </label></li>
                                            <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Occupational Therapy  </label></li>
                                            <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Auto/Diesel </label></li> */}
                                        </ul>
                          </div>
                          {errors.category && errors.category.type === "required" && <div class="error">Please check atleast one category</div>}
                          
                        </div>

                      </div>


                      <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">I SELECTED THESE CAREERS BECAUSE?<span class="red-text">*</span></label></div>
                       
                        <CKEditor
                            editor={ ClassicEditor }
                            id="careers"                            
                            onReady={ editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log( 'Editor is ready to use!', editor );
                                //editor.setData(profileDescription);
                                //editor.setData(profileDescription);
                                
                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setStudentCareers(data);
                                console.log( { event, editor, data } );
                            } }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                            } }
                        />
                         <div id="studentCareersValidMsg" class="error">This field is required.</div>

                        {/* <textarea class="form-control textarea" placeholder="Leave a comment here" placeholder="Leave a comment here" id="careers" onChange={e => setFieldName(e.target.value)} {...register("personalinfo", {
                          required: "This field is required",
                          maxLength: { value: 500, message: 'Should not allow more than 500 character' }
                        })} />
                        <ErrorMessage
                          errors={errors}
                          name="personalinfo"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}

                        /> */}
                      </div>

                      {/* <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2 mb-0">WHAT COURSES HAVE YOU TAKEN THAT ARE RELATED TO THIS CAREER? <span class="red-text">*</span></label> <small>Max 500 Characters</small></div>
                        
                        
                        <CKEditor
                            editor={ ClassicEditor }
                            id="coursesRelated"                            
                            onReady={ editor => {              
                                console.log( 'Editor is ready to use!', editor );                                
                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setCoursesRelated(data);
                                console.log( { event, editor, data } );
                            } }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                            } }
                        />
                         <div id="coursesRelatedValidMsg" class="error">This field is required.</div>
                        
                      
                      </div> */}

                      <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2 mb-0"> I HAVE THE FOLLOWING SKILLS /EXPERIENCE THAT MAKES ME A GOOD FIT.<span class="red-text">*</span></label></div>
                        {/* <span class="red-text">Required for Juniors and Seniors</span> */}
                        
                        
                        <CKEditor
                            editor={ ClassicEditor }
                            id="skills"                            
                            onReady={ editor => {              
                                console.log( 'Editor is ready to use!', editor );                                
                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setStudentSkills(data);
                                console.log( { event, editor, data } );
                            } }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                            } }
                        />
                         <div id="studentSkillsValidMsg" class="error">This field is required.</div>

                        {/* <textarea class="form-control textarea" placeholder="Leave a comment here" id="skills" spellcheck="false" {...register('skills', { required: true,maxLength: 500 })}></textarea>
                        {errors.skills && errors.skills.type === "required" && <div class="error">This field is required</div>}
                        {errors.skills && errors.skills.type === "maxLength" && <div class="error">Should not allow more than 500 character</div>} */}
                      </div>

                      {/*<div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">WHAT ADDITIONAL COURSES MIGHT HELP YOU PREPARE FOR THIS CAREER? <span class="red-text">*</span></label></div>
                        
                        <CKEditor
                            editor={ ClassicEditor }
                            id="studentCourses"                            
                            onReady={ editor => {              
                                console.log( 'Editor is ready to use!', editor );                                
                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setStudentCourses(data);
                                console.log( { event, editor, data } );
                            } }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                            } }
                        />
                         <div id="studentCoursesValidMsg" class="error">This field is required.</div>

                        
                        {/* <textarea class="form-control textarea" placeholder="Leave a comment here" id="courses" spellcheck="false" {...register('courses', { required: true,maxLength: 500 })}></textarea>
                        {errors.courses && errors.courses.type === "required" && <div class="error">This field is required</div>}
                        {errors.courses && errors.courses.type === "maxLength" && <div class="error">Should not allow more than 500 character</div>} *
                      </div> */}

                      <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">ANY ADDITIONAL THOUGHTS/COMMENTS THAT MIGHT BE HELPFUL? <span class="red-text">*</span></label></div>
                        
                        
                        <CKEditor
                            editor={ ClassicEditor }
                            id="studentComments"                            
                            onReady={ editor => {              
                                console.log( 'Editor is ready to use!', editor );                                
                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setStudentComments(data);
                                console.log( { event, editor, data } );
                            } }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                            } }
                        />
                         <div id="studentCommentsValidMsg" class="error">This field is required.</div>


                        {/* <textarea class="form-control textarea" placeholder="Leave a comment here" id="comments" spellcheck="false"  {...register('comments', { required: true,maxLength: 500 })}></textarea>
                        {errors.comments && errors.comments.type === "required" && <div class="error">This field is required</div>}
                        {errors.comments && errors.comments.type === "maxLength" && <div class="error">Should not allow more than 500 character</div>} */}
                      </div>

                      {/* <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">I am interested in: <span class="red-text">*</span></label></div>
                        <div class="category-check-itm">
                          <ul>
                      
                            <li><input class="form-check-input" type="checkbox" value="Job Shadow Opportunity" id="defaultCheck1" name='interested' {...register('interested', { required: true })}/>  <label class="form-check-label" for="defaultCheck1"> Job Shadow Opportunity </label></li>
                            <li><input class="form-check-input" type="checkbox" value="Internship/Co-Op Opportunity" id="defaultCheck1" name='interested' {...register('interested', { required: true })}/>  <label class="form-check-label" for="defaultCheck1"> Internship/Co-Op Opportunity </label></li>
                          </ul>
                        </div>
                        {errors.interested && errors.interested.type === "required" && <div class="error">Please check atleast one </div>}
                      
                      </div> */}

                      <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">I’M CURRENTLY SEEKING: <span class="red-text">*</span></label></div>
                        <div class="category-check-itm">
                          <ul>
                          {
                               jobTypes.map((data, index) => (
                                    

                                  <li><input class="form-check-input" type="checkbox" name="seeking" value={data.jobTypeId} id="seeking" {...register('seeking', { required: true })}/>  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                  ))}
                            {/* <li><input class="form-check-input" type="checkbox" value="Full Time" id="defaultCheck1" name='seeking' {...register('seeking', { required: true })}/>  <label class="form-check-label" for="defaultCheck1"> Full Time </label></li>
                            <li><input class="form-check-input" type="checkbox" value="Part Time" id="defaultCheck1" name='seeking' {...register('seeking', { required: true })}/>  <label class="form-check-label" for="defaultCheck1"> Part Time</label></li>
                            <li><input class="form-check-input" type="checkbox" value="Summer/Season work" id="defaultCheck1" name='seeking' {...register('seeking', { required: true })}/>  <label class="form-check-label" for="defaultCheck1"> Summer/Season work </label></li>
                            <li><input class="form-check-input" type="checkbox" value="Internship/Co-opportunity" id="defaultCheck1" name='seeking' {...register('seeking', { required: true })}/>  <label class="form-check-label" for="defaultCheck1"> Internship/Co-opportunity  </label></li> */}

                          </ul>
                        </div>
                        {errors.seeking && errors.seeking.type === "required" && <div class="error">Please check atleast one </div>}
                        {/* {errors.seeking && errors.seeking.type === "maxLength" && <div class="error">Should not allow more than 500 character</div>} */}
                      </div>

                      {/* <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">I plan to attend the Talent Connection Forum: <span class="red-text">*</span></label></div>
                        <div class="category-check-itm">
                          <ul>
                      
                            <li><input class="form-check-input" type="checkbox" value="Yes, I'm planning on it." id="defaultCheck1" name='talent' />  <label class="form-check-label" for="defaultCheck1"> Yes, I'm planning on it. </label></li>
                           
                          </ul>
                        </div> 
                      </div> */}

                    <div class="col-lg-12 mb-4">

                    <div class="d-flex justify-content-between align-items-end choose">
                        <div> 
                            <label for="resumePath" class="form-label">MY RESUME  (OPTIONAL) </label>
                          <div class="d-flex align-items-center">
                            {/* <input class="form-control form-control-lg choose-file mr-2" id="resumePath" type="file"/> */}
                            <input class="form-control" id="resume" type="file" onChange={()=>getImage()}/>
                           
                          </div>
                          <small> 1MB Max, File Type: PDF,DOC</small>
                          <div id="fileError"  class="error">Invalid file format</div>
                        </div>
                       
                    </div>

                    </div> 



                      <div class="col-lg-12 mb-5">

                        <div class="d-flex justify-content-between align-items-end">
                          <div>
                            <label for="firstName" class="form-label">MY PARENT/GUARDIAN AUTHORIZES MY INFORMATION TO BE SHARED</label>

                            <select class="form-select" aria-label="Default select example" style={{ maxWidth: "420px" }} id="authorization">
                            <option selected="yes">Yes</option>
                              <option value="no">No</option>   

                            </select>
                          </div>
                          <div>
                            {/* <a href="#" class="reg-button">Register</a> */}
                            <button type="submit" class="reg-button" >Register</button>
                          </div>
                        </div>

                      </div>

                      <div id="successMessage" class="alert">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="d-flex align-items-center">
                                    <img src="/images/success-alert.png" alt=""/>
                                    <strong class="mx-2">Success!</strong> Student  has been registered successfully.
                                </div>
                                <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                            </div>
                        </div>

                        <div id='alertFailMessage' class="error"></div>

                        

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section class="load-wrap">
          <div class="loadingIcon">
            <img src="images/loading.gif"></img>
             </div>
        </section>    
      </main>

      <main class="empr-directory-main cd-mobile">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item headtitle "><a href="#">Student Registration</a></li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- info typography Step 1--> */}
        <section class="company-detail-wrap py-5" id="step1">
          <div class="container">
            <div class="row">
              <div class="col-12 ">
                <form onSubmit={handleSubmit2(submitValueStep1)}>
                  <div class="white-bg px-5 pt-4 pb-4">
                    <img src="images/student-montage-mobile.jpg" class="w-100 mb-4" alt="" />
                    <div class="row mb-3">
                      <div class="col-lg-12 m-auto">
                        <h1 class="text-center ">
                          What if we told you <span class=" red-text"> your hometown</span> <br /> is a great place to plan your career?
                        </h1>
                        <div class="col-4 m-auto mt-4">
                          <hr />
                        </div>
                      </div>
                    </div>

                    <div class="row text-center">
                      <div class="col-10 m-auto">
                        <b>
                          Now, don’t go all scrunchy-faced on us. It’s true! Your local area is packed full of <span class="red-text">companies</span> who offer amazing jobs with great pay and plenty of room to grow.
                        </b>
                        <p class="my-3">
                          Hometown Opportunity is the place to learn who they are, what they do, and how they can offer you a chance to build the life you’ve always wanted… right here in your hometown. Plus, we have plenty of resources for you to learn more about your chosen career
                          path, get internships and co-ops, get part-time jobs while you’re in school and build a strong foundation for your future.
                        </p>
                        <b>
                          Register below and get started, then be sure to download the <span class="red-text">Hometown Opportunity</span> app to stay connected through your high school career.
                        </b>
                      </div>
                      <div class="col-10 m-auto ">
                     <br></br>
                        Are you a college student? Please <a href="/postResume" class="red-text">click here</a> to register as a Job Seeker to view exciting career opportunities.
                        
                      </div>
                    </div>
                    <div class="row mt-4 resume-wrap">
                      <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">First name <span class="red-text">*</span></label>
                        <input type="text" class="form-control" id="firstName_m" placeholder=""  {...register2('firstName', { required: true })} />
                        {errors2.firstName && errors2.firstName.type === "required" && <div class="error">First name is required</div>}
                      </div>

                      <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">Last name <span class="red-text">*</span></label>
                        <input type="text" class="form-control" id="lastName_m" placeholder="" {...register2('lastName', { required: true })} />
                        {errors2.lastName && errors2.lastName.type === "required" && <div class="error">Last name is required</div>}
                      </div>

                      <div class="col-lg-6 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">Email <span class="red-text">*</span></label> <small>Valid email address for notifications</small></div>
                        <input type="text" class="form-control" id="email_m" placeholder="" {...register2('email', { required: true, pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/ })} />
                        {errors2.email && errors2.email.type === "required" && <div class="error">Email is required</div>}
                        {errors2.email && errors2.email.type === "pattern" && <div class="error">Please provide valid email.</div>}
                      </div>

                      <div class="col-lg-6 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">Phone <span class="red-text">*</span></label> <small>XXX-XXX-XXXX Format</small></div>
                        <input type="text" class="form-control" id="mobile1_m" {...register2('phone', { required: true })} />
                        {errors2.phone && errors2.phone.type === "required" && <div class="error">Phone is required</div>}
                      </div>

                      <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">I am Currently Pursuing <span class="red-text">*</span></label>
                        <select class="form-select" aria-label="Default select example" id="pursuing_m" {...register2('pursuing', { required: true })}>
                        <option selected="" value=''>Select</option>
                          {
                            educations.map((data, index) => (
                              <option value={data.educationId}>{data.name}</option>
                              ))}
                        </select>
                        <div id="pursuingValidMsg_m" class="error">This Field is required.</div>
                      </div>

                      <div class="col-lg-6 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">College / University </label> </div>
                        <input type="text" class="form-control" id="university_m"/>
                        <div id="university_nameValidMsg_m" class="error">College / Universities is required.</div>
                      </div>

                      <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">school district </label>
                        <select class="form-select" aria-label="Default select example" id="district_m" {...register2('district', { required: true })}>
                        <option selected="" value=''>Select</option>
                          {
                            schoolIds.map((data, index) => (
                            <option value={data.schoolId}>{data.name}</option>
                            ))
                          }
                        </select>
                        <div id="school_districtValidMsg_m" class="error">School District is required.</div>
                      </div>

                      <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">GRADUATING YEAR <span class="red-text">*</span></label>
                        <select class="form-select" aria-label="Default select example" id="classType_m" {...register2('classType', { required: true })}>
                        <option selected="" value=''>Select</option>     
                          {
                          currentYearList.map((data, index) => (                                    
                          <option value={data}>{data}</option>                                
                            ))} 
                        </select>
                        {errors2.classType && errors2.classType.type === "required" && <div class="error">Class type is required</div>}
                      </div>

                      <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">Password <span class="red-text">*</span></label> </div>
                        <p style={{fontSize: "12px"}}>Minimum of 8 characters long, and a combination of lowercase & uppercase letters, numbers, and special characters.</p>
                        <input type="password" class="form-control" id="password_m" placeholder="" {...register2('password_m', { required: true })} />
                        {errors2.password_m && errors2.password_m.type === "required" && <div class="error">Password is required</div>}
                      </div>

                      <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">Confirm Password <span class="red-text">*</span></label></div>
                        <p style={{fontSize: "12px"}}>Minimum of 8 characters long, and a combination of lowercase & uppercase letters, numbers, and special characters.</p>
                        <input type="password" class="form-control" id="passwordConf_m" placeholder="" {...register2('passwordConf_m', { required: true })} />
                        {errors2.passwordConf_m && errors2.passwordConf_m.type === "required" && <div class="error">Password is required</div>}
                        <div class="error" id="matchPwd_m">Passwords must match</div> 
                      </div>
                      <div class="mt-4 mb-4">
                        {/* <a href="#" class="reg-button">Next &#62;&#62;</a> */}
                        <button type="submit" class="reg-button"  >Next &#62;&#62;</button>
                      </div>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* Step 2 */}
        {/* <StudentRegistration2 /> */}








        <section class="company-detail-wrap" id="step2">
            <div>
                <div class="col-12 ">
                <form onSubmit={handleSubmit3(submitValueStep3)}>
                    <div class="white-bg px-4">
                        <div class="row">
                            <div class="text-center">
                                <h2 class="headtitle ">FIELDS OF INTEREST</h2>
                            </div>
                            <div class="col-12 p-3 pb-0">
                                <div class="mobile-form-avl p-3">
                                    <div class="category-check-itm">
                                        <ul>
                                        {
                                        categories.map((data, index) => (
                                        <li><input class="form-check-input category_mcls" type="checkbox" name="category_m" value={data.catId} id="category" {...register3('category', { required: true })}/>  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>
                                        ))}
                                  </ul>
                                       
                                </div>
                                
                            </div>
                            {errors3.category && errors3.category.type === "required" && <div class="error">Category is required</div>}
                                    </div>
                            <div class="mt-4 mb-4">
                            <button type="submit" class="reg-button">Next &gt;&gt;</button>
                            </div>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
        </section>                   








        {/* Step 3 */}
        {/* <StudentRegistration3 /> */}





        <section class="company-detail-wrap " id="step3">
            <div>
                <div class="col-12 ">
                <form onSubmit={handleSubmit4(submitValueStep4)}>
                    <div class="white-bg px-4">
                        <div class="row stu-reg-st3 pt-3">

                            <div class="col-lg-12 mb-5">
                                <div><label for="firstName" class="form-label mr-2" >TELL US WHY YOU SELECTED THESE CAREERS?<span class="red-text">*</span></label> <small>Max 500 Characters</small></div>
                               
                               
                                <CKEditor
                                editor={ ClassicEditor }
                                id="careers"
                                data={ studentCareersMobile }                            
                                onReady={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log( 'Editor is ready to use!', editor );
                                    //editor.setData(profileDescription);
                                    //editor.setData(profileDescription);
                                    
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    setStudentCareersMobile(data);
                                    console.log( { event, editor, data } );
                                } }
                                onBlur={ ( event, editor ) => {
                                    console.log( 'Blur.', editor );
                                } }
                                onFocus={ ( event, editor ) => {
                                    console.log( 'Focus.', editor );
                                } }
                            />
                            <div id="studentCareersValidMsgMobile" class="error">This field is required.</div>
                               
                                {/* <textarea class="form-control textarea" placeholder="Leave a comment here" id="careers_m" spellcheck="false" {...register4('career', { required: true })}></textarea>
                                {errors4.career && errors4.career.type === "required" && <div class="error">Careers is required</div>} */}
                            </div>
                            {/* <div class="col-lg-12 mb-5">
                                <div><label for="firstName" class="form-label mr-2 mb-0">WHAT COURSES HAVE YOU TAKEN THAT ARE RELATED TO THIS CAREER? <span class="red-text">*</span></label> <small>Max 500 Characters</small></div>
                                <textarea class="form-control textarea" placeholder="Leave a comment here" id="courses_related_m" spellcheck="false" {...register4('courses', { required: true })}></textarea>
                                {errors4.courses && errors4.courses.type === "required" && <div class="error">Courses is required</div>}
                            </div> */}
                            <div class="col-lg-12 mb-5">
                                <div><label for="firstName" class="form-label mr-2 mb-0" > LIST SOME SKILLS/EXPERIENCES THAT MAKE THIS A GOOD CHOICE FOR YOU.<span class="red-text">*</span></label> <small>Max 500 Characters</small></div>
                                {/* <span class="red-text">Required for Juniors and Seniors</span> */}


                                <CKEditor
                                    editor={ ClassicEditor }
                                    id="skills"   
                                    data={ studentSkillsMobile }                         
                                    onReady={ editor => {              
                                        console.log( 'Editor is ready to use!', editor );                                
                                    } }
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setStudentSkillsMobile(data);
                                        console.log( { event, editor, data } );
                                    } }
                                    onBlur={ ( event, editor ) => {
                                        console.log( 'Blur.', editor );
                                    } }
                                    onFocus={ ( event, editor ) => {
                                        console.log( 'Focus.', editor );
                                    } }
                                />
                            <div id="studentSkillsValidMsgMobile" class="error">This field is required.</div>

                                {/* <textarea class="form-control textarea" placeholder="Leave a comment here" id="skills_m" spellcheck="false" {...register4('skills', { required: true })}></textarea>
                                {errors4.skills && errors4.skills.type === "required" && <div class="error">Skills is required</div>} */}
                            </div>
                            {/*<div class="col-lg-12 mb-5">
                                <div><label for="firstName" class="form-label mr-2" >WHAT ADDITIONAL COURSES MIGHT HELP YOU PREPARE FOR THIS CAREER? <span class="red-text">*</span></label> <small>Max 500 Characters</small></div>

                                <CKEditor
                                editor={ ClassicEditor }
                                id="studentCourses"   
                                data={studentCoursesMobile}                         
                                onReady={ editor => {              
                                    console.log( 'Editor is ready to use!', editor );                                
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    setStudentCoursesMobile(data);
                                    console.log( { event, editor, data } );
                                } }
                                onBlur={ ( event, editor ) => {
                                    console.log( 'Blur.', editor );
                                } }
                                onFocus={ ( event, editor ) => {
                                    console.log( 'Focus.', editor );
                                } }
                            />
                         <div id="studentCoursesValidMsgMobile" class="error">This field is required.</div>
                         
                                {/* <textarea class="form-control textarea" placeholder="Leave a comment here" id="courses_m" spellcheck="false" {...register4('additional_course', { required: true })}></textarea>
                                {errors4.additional_course && errors4.additional_course.type === "required" && <div class="error">Additional course is required</div>} *
                            </div> */}
                            <div class="col-lg-12 mb-5">
                                <div><label for="firstName" class="form-label mr-2" >ANY ADDITIONAL THOUGHTS/COMMENTS THAT MIGHT BE HELPFUL? <span class="red-text">*</span></label> <small>Max 500 Characters</small></div>
                               
                               
                                <CKEditor
                                editor={ ClassicEditor }
                                id="studentCommentsMobile"    
                                data={ studentComments }                        
                                onReady={ editor => {              
                                    console.log( 'Editor is ready to use!', editor );                                
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    setStudentCommentsMobile(data);
                                    console.log( { event, editor, data } );
                                } }
                                onBlur={ ( event, editor ) => {
                                    console.log( 'Blur.', editor );
                                } }
                                onFocus={ ( event, editor ) => {
                                    console.log( 'Focus.', editor );
                                } }
                            />
                            <div id="studentCommentsValidMsgMobile" class="error">This field is required.</div>
                                {/* <textarea class="form-control textarea" placeholder="Leave a comment here" id="comments_m" spellcheck="false" {...register4('thoughts', { required: true })}></textarea>
                                {errors4.thoughts && errors4.thoughts.type === "required" && <div class="error">Additional thoughts is required</div>} */}
                            </div>
                            <div class="col-lg-12 mb-5">
                                <div><label for="firstName" class="form-label mr-2" >I’M CURRENTLY SEEKING EMPLOYMENT FOR: <span class="red-text">*</span></label> <small>Max 500 Characters</small></div>
                                <div class="category-check-itm">
                                    <ul>
                                    {
                                jobTypes.map((data, index) => (
                                    <li><input name="seeking_m" class="form-check-input seeking_mcls" type="checkbox"  value={data.jobTypeId} id="defaultCheck1" {...register4('employment', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>
                                  ))}
                                        {/* <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register4('employment', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Full Time </label></li>
                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register4('employment', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Part Time</label></li>
                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register4('employment', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Summer/Season work </label></li>
                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register4('employment', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Internship/Co-opportunity  </label></li> */}
                                    </ul>
                                </div>
                                {errors4.employment && errors4.employment.type === "required" && <div class="error">Employment is required</div>}
                            </div>

                            <div class="col-lg-12">
                                <div class="justify-content-between align-items-end">
                                <div> 
                                  <label for="resumePath" class="form-label">UPLOAD A RESUME (Optional) </label>
                                <div class="d-flex align-items-center">
                                  {/* <input class="form-control form-control-lg choose-file mr-2" id="resumePath" type="file"/> */}
                                  <input class="form-control" id="resumeMobile" type="file" onChange={()=>getImageMobile()}/>
                                
                                </div>
                                <small> 1MB Max, File Type: PDF,DOC</small>
                                <div id="fileErrorMobile"  class="error">Invalid file format</div>
                              </div>
                              </div>
                            </div>               
                            
                            <div class="col-lg-12">
                                <div class="justify-content-between align-items-end">
                                    <div>
                                        <label for="firstName" class="form-label">MY PARENT/GUARDIAN AUTHORIZES MY INFORMATION TO BE SHARED</label>
                                        <select class="form-select" aria-label="Default select example" style={{maxWidth: "420px"}} id="authorization_m">
                                    <option selected="">Select</option>
                                    <option selected="yes">Yes</option>
                                  <option value="no">No</option>   
                                 </select>
                                    </div>

                                </div>
                            </div>

                            <div class="mt-4 mb-4">
                                <button type="submit" class="reg-button">Register</button>
                            </div>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
        </section>                 


        <section class="load-wrap">
          <div class="loadingIcon">
            <img src="images/loading.gif"></img>
             </div>
        </section>                                  


      </main>
    </>
  )
};

StudentRegistration.propTypes = {};

StudentRegistration.defaultProps = {};

export default StudentRegistration;
