import React,{history,useEffect, useState, useMemo } from "react";
import Sidebar from "../../utility/sidebar";
import AnnouncementWidget from "../Jobs/announcement-wid";
import Hiring from "../Jobs/Hiring";
import Services from "../../services/services";
import $, { parseJSON } from 'jquery';
import moment from "moment";
import Pagination from "../../utility/Pagination/Pagination";
import EventWidget from "../Jobs/eventwid";

import { useNavigate,useParams,useLocation,useHistory } from "react-router-dom";
const StudentDashboard = () => {
   const navigate = useNavigate();
   const [jobList, setJobList] = useState([]);
   const [categories, setCategory] = useState([]);
   const [totalItems, setTotalItems] = useState(0);
    const [totalpages, settotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [categoriesSelected, setCategoriesSelected] = useState([]);
    const [typeSelectedIn, setTypeSelectedIn] = useState([]);
    
    const ITEMS_PER_PAGE = 4; // Per page show the data pagination
    const Pagesize=5;
    //const studentId = 56;
    const studentId=sessionStorage.getItem("logStudentId");
    const loginDisplayName=sessionStorage.getItem("displayName");
    const userId=sessionStorage.getItem("logUserId");

    const FVITEMS_PER_PAGE = 2;
    const [fvtotalItems, setFvTotalItems] = useState(0);
    const [fvtotalpages, setFvtotalPages] = useState();
    const [fvcurrentPage, setFvCurrentPage] = useState(1);
    const [favVideoList, setFavVideoList] = useState([]);

    const JSITEMS_PER_PAGE = 5;
    const [jstotalItems, setjsTotalItems] = useState(0);
    const [jstotalpages, setjstotalPages] = useState();
    const [jscurrentPage, setjsCurrentPage] = useState(1);
    const [jobShadowList, setJobShadowList] = useState([]);

   useEffect(() => {

      if(studentId == null || studentId == "null"){
         //navigate('/login');
         navigate('/Home');
       }

      getIndustryData();
      getStudentProfile(studentId);
      //filterContent(ITEMS_PER_PAGE,'1');
      filterContentFav(ITEMS_PER_PAGE,'1');
      jobShadowCompanies(ITEMS_PER_PAGE,'1')

   }, []);


   const jobShadowCompanies = (ITEMS_PER_PAGE,pageNumber) => {
var siteidval = process.env.React_App_SITE_ID;
      const requestObject={
         "userId": userId,
         "siteId": process.env.React_App_SITE_ID,

          "pageNumber" :pageNumber,

         "pageSize":ITEMS_PER_PAGE,
          "sortBy" : "updatedOn"
        }
        var accessTokenIn=sessionStorage.getItem("accToken");
        console.log(requestObject);
          const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json',
                   // 'Authorization': "Bearer "+accessTokenIn
                    //'Authorization': accessTokenIn
                  },
          body: JSON.stringify(requestObject)
          };

          var pageSize = JSITEMS_PER_PAGE;
          //var pageSize = "1";

          Services.jobShadowCompanyListByUserId(siteidval,pageNumber,pageSize).then((res) =>
          {

              if(res){
               console.log(JSON.stringify(res));
                  $(".jspaginationContent").show();
                 // alert("fvtotalItems9-->"+res.data.totalElements);
                  setjsTotalItems(res.data.totalElements);
                  setjstotalPages(Math.ceil(res.data.totalElements / FVITEMS_PER_PAGE));
                 //alert(pageNumber)
                  setjsCurrentPage(pageNumber);

                  var contAr = res.content;
                  //setProfileList(res.content);
                  if(res.data.content){
                     setJobShadowList(res.data.content);
                     //alert("fvtotalItems-->"+fvtotalItems);
                  }

             }

          });

  }


   const filterContentFav = (ITEMS_PER_PAGE,pageNumber) => {

      const requestObject={
         "userId": userId,
         "siteId": process.env.React_App_SITE_ID,

          "pageNumber" :pageNumber,

         "pageSize":ITEMS_PER_PAGE,
          "sortBy" : "updatedOn"
        }
        var accessTokenIn=sessionStorage.getItem("accToken");
        console.log(requestObject);
          const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json',
                   // 'Authorization': "Bearer "+accessTokenIn
                    //'Authorization': accessTokenIn
                  },
          body: JSON.stringify(requestObject)
          };

          var pageSize = FVITEMS_PER_PAGE;
          //var pageSize = "1";

          Services.favCompanyVideosListByUserId(userId,pageNumber,pageSize).then((res) =>
          {

              if(res){
               console.log(JSON.stringify(res));
                  $(".fvpaginationContent").show();
                 // alert("fvtotalItems9-->"+res.data.totalElements);
                  setFvTotalItems(res.data.totalElements);
                  setFvtotalPages(Math.ceil(res.data.totalElements / FVITEMS_PER_PAGE));
                 //alert(pageNumber)
                  setFvCurrentPage(pageNumber);

                  var contAr = res.content;
                  //setProfileList(res.content);
                  if(res.data.content){
                     setFavVideoList(res.data.content);
                     //alert("fvtotalItems-->"+fvtotalItems);
                  }

             }

          });

  }
   const getStudentProfile = (studentId) => {
      Services.getStudentProfile(studentId).then((res) => {
         // setJobSeekerDetails(res);
         console.log("31-->"+JSON.stringify(res.data.categoriesOfExpertise))
         console.log("31-->"+JSON.stringify(res.data.seekingEmploymentTypes))
         setCategoriesSelected(res.data.categoriesOfExpertise);
         setTypeSelectedIn(res.data.seekingEmploymentTypes);
         filterContent(ITEMS_PER_PAGE,'1',res.data.categoriesOfExpertise,res.data.seekingEmploymentTypes);
      });
   }

   const getCatNameByCatID = (catId) =>{
      return categories[catId];
      //return setCategory(catId)
   }
   const getIndustryData = () => {
      Services.getIndustryData().then((res) => {
        console.log("Industry data=" + JSON.stringify(res.data));

        console.log("getLocationCounty data=" + JSON.stringify(res.data));
        var lc = res.data.content;
        var myObject = {}
        for(var i=0;i<lc.length;i++){
            var dstrVal = lc[i]['name'];
          myObject[lc[i]['catId']] = dstrVal;
          //lcObject.push(myObject);
        }
        setCategory(myObject);

        //setCategory(res.data);
      });

    }
   const handlePageChange2 = (offset,triggerevent) => {
      // alert(offset);
      filterContent(ITEMS_PER_PAGE,offset,categoriesSelected,typeSelectedIn);
    };

    const fvhandlePageChange2 = (offset,triggerevent) => {
      // alert(offset);
      filterContentFav(FVITEMS_PER_PAGE,offset);
    };

    const jshandlePageChange2 = (offset,triggerevent) => {
      // alert(offset);
      jobShadowCompanies(FVITEMS_PER_PAGE,offset);
    };

   const filterContent = (ITEMS_PER_PAGE,pageNumber,categoriesSelectedIn,typeSelectedIn) => {
      const requestObject={
          "siteId": process.env.React_App_SITE_ID,
          //"categoriesOfExpertise": [],
          "categoriesOfExpertise": categoriesSelectedIn,
         // "type": ["2","5"],
        // "type": ["8"],
        "type": typeSelectedIn,
          "titleSummary" : "",
          "pageNumber" :pageNumber,
         // "pageSize":"3",
         "pageSize":ITEMS_PER_PAGE,
          "sortBy" : "created_date",
          "sortDir": "desc"
        }

        var accessTokenIn=sessionStorage.getItem("accToken");
        console.log(requestObject);
          const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json',
                    'Authorization': "Bearer "+accessTokenIn
                    //'Authorization': accessTokenIn
                  },
          body: JSON.stringify(requestObject)
          };

          Services.findFilterJob(requestOptions).then((res) =>
          {

              console.log(JSON.stringify(res));
              if(res){

                  $(".paginationContent").show();
                  setTotalItems(res.totalElements);
                  settotalPages(Math.ceil(res.totalElements / ITEMS_PER_PAGE));
                  setCurrentPage(pageNumber);

                  var contAr = res.content;
                  //setProfileList(res.content);
                  if(res.content){
                     setJobList(res.content);
                  }



             }


          });
  }
  return(
  <>
   <main  class="empr-directory-main">
         <div class="main-bgpatten"></div>
         {/* <!-- info typography --> */}
         <section class="headtitle-wrap">
            <div class="container">
               <div class="row">
                  <div class="col-lg-12">
                    <nav style={{"--bs-breadcrumb-divider": '>'}} aria-label="breadcrumb">
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item headtitle "><a href="#">Student Dashboard </a></li>
                        </ol>
                      </nav>
                  </div>
               </div>
            </div>
         </section>
         {/* <!-- info typography --> */}
         <section class="py-5">
            <div class="container">
               <div class="row">
                  <div class="col-12 ">
                     <div class="white-bg p-5">
                        <div class="row">
                           <Sidebar/>
                           <div class="col-lg-8 mb-4">
                               <h4>Welcome back, {loginDisplayName}!</h4>
                               <p>This is your Hometown Opportunity Dashboard. Here you’ll see all of the most important information about your job or career search, all in one handy location.</p>

                              {/* <EventWidget></EventWidget>


                               <AnnouncementWidget></AnnouncementWidget> */}

                              {/* <div class="card p-3">
                               <div class="announce-wrap">
                                   <div class="orange-ribbon d-flex mb-4">
                                       <img src="images/reminders-icon.png" alt=""/>
                                       <span>REMINDERS & ANNOUNCEMENTS</span>
                                   </div>
                                   <div class="after-orange-ribbon mb-4">
                                   <a href="#"> <h4>ANNOUNCEMENT NUMBER 1</h4></a>
                                       <span>September 29, 2019</span>
                                       <p class="py-2">Wright State University Lake Campus in Celina is proud to announce the starting of the 15th MBA program. This program…</p>
                                   </div>

                                   <div class="after-orange-ribbon mb-4">
                                   <a href="#"><h4>ANNOUNCEMENT NUMBER 1</h4></a>
                                       <span>September 29, 2019</span>
                                       <p class="py-2">Wright State University Lake Campus in Celina is proud to announce the starting of the 15th MBA program. This program…</p>
                                   </div>
                                  </div>

                                  <div class="pagination-wrap text-center mt-3">
                                   <nav aria-label="...">
                                      <ul class="pagination m-auto d-inline-flex">
                                         <li class="page-item disabled">
                                            <span class="page-link"><img src="images/arrow-left.JPG" alt=""/></span>
                                         </li>
                                         <li class="page-item"><a class="page-link" href="#">1</a></li>
                                         <li class="page-item active" aria-current="page">
                                            <span class="page-link">2</span>
                                         </li>
                                         <li class="page-item"><a class="page-link" href="#">3</a></li>
                                         <li class="page-item">
                                            <a class="page-link" href="#"><img src="images/arrow-right.JPG" alt=""/></a>
                                         </li>
                                      </ul>
                                   </nav>
                                </div>
                              </div> */}

                              <div class="card p-3 mt-5">
                               <div class="announce-wrap mb-4">

                                   <div class="orange-ribbon d-flex mb-4">
                                       <img src="images/categories-icon.png" alt="" class="mr-2"/>
                                       <span>YOUR FIELDS OF INTEREST</span>
                                   </div>

                                   <div class="after-orange-ribbon mb-5">
                                   {
                                    categoriesSelected.map((data, index) => (
                                       <h4>{getCatNameByCatID(data)}   </h4>
                                       ))
                                    }
                                       {/* <h4>ARCHITECTUREAL AND DESIGN SERVICES</h4>
                                       <h4>ART</h4>
                                       <h4>GRAPHIC DESIGN</h4> */}
                                    </div>
                                    <div class="d-flex flex-md-row flex-column">
                                       <a href="/updateStudent" class="grey-button mt-3 mr-2  text-uppercase text-cente">update my categories</a>
                                    <a href={"/careerExploreJobs?cat="+categoriesSelected} class="carousel-button  mt-3 text-uppercase text-cente">explore jobs</a>
                                    </div>
                                  </div>


                              </div>

                              <div class="card p-3 mt-5">
                               <div class="announce-wrap">

                                   <div class="orange-ribbon d-flex mb-4">
                                       <img src="images/job-opp-icon.png" alt="" class="mr-2"/>
                                       <span>CAREER OPPORTUNITIES OF INTEREST</span>
                                   </div>
                                   {
                                                jobList.map((data, index) => (
                                   <div class="oppertunity-card">
                                       <div class="logo-org">
                                           {/* <img src={data.logo_url} alt=""/> */}
                                           <img src= {data.logo_url ? data.logo_url : "images/no-logo.jpg"} alt=""/>

                                       </div>
                                       <div class="right-info">
                                       <a href={`/jobsummary?jobId=`+data.jobId}><h4>{data.title}</h4></a>
                                           <div class="d-flex">
                                           <span><a href={`/companyDetails?companyId=`+data.companyId}>{data.name}</a> </span>
                                               <span class="mx-3">|</span>
                                               <span> {data.state}, {data.address1}</span>
                                           </div>
                                           <p class="mt-2">The Senior Product and Services Manager researches and develops the market for services to support the organization.…</p>
                                           <b class="text-uppercase">{moment(data.createdDate).format("D-MMM-YY")}</b>
                                       </div>
                                   </div>

                              ))
                              }

                                 <div class="pagination-wrap text-center mt-5 paginationContent">
                                                <Pagination
                                        total={totalItems}
                                        itemsPerPage={ITEMS_PER_PAGE}
                                        currentPage={currentPage}
                                        onPageChange={handlePageChange2}/>
                                                    {/* <Pagination items={this.state.exampleItems} onChangePage={this.onChangePage} /> */}
                                 </div>

                                   {/* <div class="oppertunity-card">
                                       <div class="logo-org">
                                           <img src="images/midmark-logo.JPG" alt=""/>
                                       </div>
                                       <div class="right-info">
                                       <a href="#"><h4>PRODUCT AND SERVICES MANAGER</h4></a>
                                           <div class="d-flex">
                                               <span>Midmark Corporation </span>
                                               <span class="mx-3">|</span>
                                               <span> Versailles, Ohio</span>
                                           </div>
                                           <p class="mt-2">The Senior Product and Services Manager researches and develops the market for services to support the organization.…</p>
                                           <b class="text-uppercase">August 19, 2021</b>
                                       </div>
                                   </div>
                                   <div class="oppertunity-card">
                                       <div class="logo-org">
                                           <img src="images/midmark-logo.JPG" alt=""/>
                                       </div>
                                       <div class="right-info">
                                       <a href="#"><h4>PRODUCT AND SERVICES MANAGER</h4></a>
                                           <div class="d-flex">
                                               <span>Midmark Corporation </span>
                                               <span class="mx-3">|</span>
                                               <span> Versailles, Ohio</span>
                                           </div>
                                           <p class="mt-2">The Senior Product and Services Manager researches and develops the market for services to support the organization.…</p>
                                           <b class="text-uppercase">August 19, 2021</b>
                                       </div>
                                   </div>
                                   <div class="oppertunity-card">
                                       <div class="logo-org">
                                           <img src="images/midmark-logo.JPG" alt=""/>
                                       </div>
                                       <div class="right-info">
                                       <a href="#">  <h4>PRODUCT AND SERVICES MANAGER</h4></a>
                                           <div class="d-flex">
                                               <span>Midmark Corporation </span>
                                               <span class="mx-3">|</span>
                                               <span> Versailles, Ohio</span>
                                           </div>
                                           <p class="mt-2">The Senior Product and Services Manager researches and develops the market for services to support the organization.…</p>
                                           <b class="text-uppercase">August 19, 2021</b>
                                       </div>
                                   </div> */}


                                   {/* <div class="pagination-wrap text-center mt-3">
                                       <nav aria-label="...">
                                          <ul class="pagination m-auto d-inline-flex">
                                             <li class="page-item disabled">
                                                <span class="page-link"><img src="images/arrow-left.JPG" alt=""/></span>
                                             </li>
                                             <li class="page-item"><a class="page-link" href="#">1</a></li>
                                             <li class="page-item active" aria-current="page">
                                                <span class="page-link">2</span>
                                             </li>
                                             <li class="page-item"><a class="page-link" href="#">3</a></li>
                                             <li class="page-item">
                                                <a class="page-link" href="#"><img src="images/arrow-right.JPG" alt=""/></a>
                                             </li>
                                          </ul>
                                       </nav>
                                    </div> */}

                                  </div>


                              </div>

                                    {/* Job Shadow Oppertunities (use code here)  */}


                                    <div class="card p-3 mt-5">
                               <div class="announce-wrap">

                                   <div class="orange-ribbon d-flex mb-4">
                                       <img src="images/job-shadow-oppertunity-icon.png" alt="" class="mr-2"/>
                                       <span>JOB SHADOW OPPORTUNITIES</span>
                                   </div>


                                   {
                                     jobShadowList.map((data, index) => (
                                    <div>
                                          <div class=" mb-4">
                                             
                                             <div class="">
                                                <a href={"/companyDetails?companyId="+data.companyId} alt=""><h4>{data.name}</h4></a>
                                             </div>
                                          </div>
                                    </div>

                                    ))}

                                 <div class="pagination-wrap text-center mt-5 fvpaginationContent">
                                    <Pagination
                                        total={jstotalItems}
                                        itemsPerPage={JSITEMS_PER_PAGE}
                                        currentPage={jscurrentPage}
                                        onPageChange={jshandlePageChange2}/>
                                    </div>

                                  </div>


                              </div>



                              <div class="card p-3 mt-5">
                               <div class="announce-wrap">

                                   <div class="orange-ribbon d-flex mb-4">
                                       <img src="images/youtube.png" alt="" class="mr-2"/>
                                       <span>YOUR FAVORITE COMPANY VIDEOS</span>
                                   </div>


                                   {
                                     favVideoList.map((data, index) => (
                                    <div>
                                       {(data.videoUrl != '' && data.videoUrl != null) ?
                                          <div class="video-section2 mb-4">
                                             {/* <div class="video-item"> <img src="images/screen-shot.png" class="w-100" alt=""/></div> */}
                                             <div class="video-item">
                                             <iframe width="100%" height="100%" src={data.videoUrl} />
                                             </div>
                                             <div class="info-vid">
                                                <h4>{data.name}</h4>
                                                <span> {data.address1},  {data.address2}</span>
                                                <div class="stock-wrap justify-content-between align-items-center">
                                                   <a href={`/jobsByCompany?companyId=`+data.companyId}>{data.jobCount} OPEN JOBS</a>
                                                </div>
                                             </div>
                                             {/* <div class="vid-close d-flex align-items-center">
                                                <a href="#"> <img src="images/close.jpg" alt=""/></a>
                                             </div> */}
                                          </div>
                                       : '' }

                                    </div>

                                    ))}

                                 <div class="pagination-wrap text-center mt-5 fvpaginationContent">
                                    <Pagination
                                        total={fvtotalItems}
                                        itemsPerPage={FVITEMS_PER_PAGE}
                                        currentPage={fvcurrentPage}
                                        onPageChange={fvhandlePageChange2}/>
                                    </div>

                                   {/* <div class="video-section2 mb-4">
                                    <div class="video-item"> <img src="images/screen-shot.png" class="w-100" alt=""/></div>
                                       <div class="info-vid">
                                           <h4>AGRANA FRUIT US, INC</h4>
                                           <span> Botkins, Ohio</span>
                                           <div class="stock-wrap justify-content-between align-items-center">
                                               <a href="#">15 OPEN JOBS</a>
                                           </div>
                                        </div>
                                        <div class="vid-close d-flex align-items-center">
                                           <a href="#"> <img src="images/close.jpg" alt=""/></a>
                                        </div>
                                   </div> */}
                                   {/* <div class="video-section2 mb-4">
                                    <div class="video-item"> <img src="images/screen-shot.png" class="w-100" alt=""/></div>
                                    <div class="info-vid">
                                        <h4>AGRANA FRUIT US, INC</h4>
                                        <span> Botkins, Ohio</span>
                                        <div class="stock-wrap justify-content-between align-items-center">
                                            <a href="#">15 OPEN JOBS</a>
                                        </div>
                                     </div>
                                     <div class="vid-close d-flex align-items-center">
                                        <a href="#"> <img src="images/close.jpg" alt=""/></a>
                                     </div>
                                </div> */}
                                {/* <div class="video-section2 mb-4">
                                 <div class="video-item"> <img src="images/screen-shot.png" class="w-100" alt=""/></div>
                                 <div class="info-vid">
                                     <h4>AGRANA FRUIT US, INC</h4>
                                     <span> Botkins, Ohio</span>
                                     <div class="stock-wrap justify-content-between align-items-center">
                                         <a href="#">15 OPEN JOBS</a>
                                     </div>
                                  </div>
                                  <div class="vid-close d-flex align-items-center">
                                     <a href="#"> <img src="images/close.jpg" alt=""/></a>
                                  </div>
                             </div> */}

{/*
                                   <div class="pagination-wrap text-center mt-3">
                                       <nav aria-label="...">
                                          <ul class="pagination m-auto d-inline-flex">
                                             <li class="page-item disabled">
                                                <span class="page-link"><img src="images/arrow-left.JPG" alt=""/></span>
                                             </li>
                                             <li class="page-item"><a class="page-link" href="#">1</a></li>
                                             <li class="page-item active" aria-current="page">
                                                <span class="page-link">2</span>
                                             </li>
                                             <li class="page-item"><a class="page-link" href="#">3</a></li>
                                             <li class="page-item">
                                                <a class="page-link" href="#"><img src="images/arrow-right.JPG" alt=""/></a>
                                             </li>
                                          </ul>
                                       </nav>
                                    </div> */}

                                  </div>


                              </div>


                           </div>
                       </div>
                     </div>

                  </div>

               </div>
            </div>
         </section>
         <section class="white-bg py-5">
            <div class="container">
            <Hiring/>
               {/* <div class="row mb-4">
                  <div class="col-12">
                     <div class="d-flex justify-content-between border-bottom  mb-4 pb-4 flex-lg-row flex-column">
                        <h4 class="headtitle">Top companies hiring in your area</h4>
                        <a href="#" class="text-uppercase">See All</a>
                     </div>
                  </div>

               </div>
               <div class="row">
                  <div class="col-lg-4">
                     <div class="card p-3">
                        <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                        <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                        <div class="text-center stock-wrap">
                           <h3>Midmark Corporation</h3>
                        <span>Versailles, Ohio</span>
                        <a href="#">15 OPEN JOBS</a>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-4">
                     <div class="card p-3">
                        <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                        <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                        <div class="text-center stock-wrap">
                           <h3>Midmark Corporation</h3>
                        <span>Versailles, Ohio</span>
                        <a href="#">15 OPEN JOBS</a>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-4">
                     <div class="card p-3">
                        <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                        <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                        <div class="text-center stock-wrap">
                           <h3>Midmark Corporation</h3>
                        <span>Versailles, Ohio</span>
                        <a href="#">15 OPEN JOBS</a>
                        </div>
                     </div>
                  </div>


               </div> */}
            </div>
         </section>
      </main>

  </>
)};

StudentDashboard.propTypes = {};

StudentDashboard.defaultProps = {};

export default StudentDashboard;
