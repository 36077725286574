import React,{history,useEffect, useState, useMemo } from "react";
import Recent_Jobs from "../Jobs/Recent_Jobs";
import Hiring from "../Jobs/Hiring";

import services from "../../services/services";
//import Pagination from "../pagination/pagination";
import Pagination from "../../utility/Pagination/Pagination";

import $, { parseJSON } from 'jquery';

const SchoolList = () => {

    const [schoolList, setSchoolList] = useState([]);
    const ITEMS_PER_PAGE = 12; // Per page show the data pagination
    const [totalItems, setTotalItems] = useState(0);
    const [totalpages, settotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);

    const getSchoolList = (ITEMS_PER_PAGE,pageNumber) => {
          //alert("14");
        const requestObject={
            "siteId":process.env.React_App_SITE_ID,
            "pageNumber":pageNumber,
            "pageSize":ITEMS_PER_PAGE           
         }
       
         var accessTokenIn=sessionStorage.getItem("accToken");
         const requestOptions = {
           method: 'POST',
           headers: { 'Content-Type': 'application/json',
                     //'Authorization': "Bearer "+accessTokenIn 
                     //'Authorization': accessTokenIn 
                   },
           body: JSON.stringify(requestObject)
         };
     
         services.getSchoolList(requestOptions).then((res) => 
         {
            console.log("############36");
           console.log(JSON.stringify(res))
            if(res.content){
                console.log(JSON.stringify(res.data));
                   $(".paginationContent").show();
                   setTotalItems(res.totalElements);         
                   settotalPages(Math.ceil(res.totalElements / ITEMS_PER_PAGE));                    
                   setCurrentPage(pageNumber);
       
                   var contAr = res.content;
                   //setProfileList(res.content);
                   if(res.content){
                    setSchoolList(res.content);
                   }                
                   
 
            }  
          //navigate('/thankyou');
      
         });
       
       
     }

     const handlePageChange = (offset,triggerevent) => {
        getSchoolList(ITEMS_PER_PAGE,offset)
      };

    useEffect(() => {        
        //getPageInfo("downloadourapp");
        //alert("64");
        getSchoolList(ITEMS_PER_PAGE,'1')
  
    }, []);
  return(
  <>
 <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        {/* <!-- info typography --> */}
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav style={{"--bs-breadcrumb-divider": '>'}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                {/* <li class="breadcrumb-item headtitle ">Company Dashboard <span style={{color: "#6c757d"}}>&#62;</span></li> */}
                                <li class="breadcrumb-item active headtitle "><a href="#"> School List</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="py-5">
            <div class="container">
                <div class="row">
                    {/* <!-- right side --> */}
                    <div class="col-lg-8 mb-4">

                        <div class="p-4 white-bg">
                        <div class="row">


                        {
                        schoolList.map((data, index) => (
                            <div class="col-lg-4 col-md-6 mb-4"  >
                                {/* {item.name} */}
                                <a href={"/schoolDetails?schoolId="+data.schoolId}><div class="card p-3 school-card justify-content-center align-items-center ">
                                    {/* <img src="images/Arcanum-School-Logo.png" style={{maxWidth: "200px"}} class="p-4 mb-2 m-auto" alt=""/> */}
                                    <div class="school-img-wrp"><img src={data.logoUrl}  class=" mb-2" alt=""/></div>
                                    
                                    <div class="text-center stock-wrap">
                                        <h3>{data.name}</h3>
                                    <span>{data.city != '' ? data.city : ''}{data.city != '' && data.state != '' ? ', ' : '' }{data.state != '' ? data.state : ''}</span>
                                
                                    </div>
                                </div></a> 
                            </div>
                         ))}    

                            

                            
                        
                        </div>
                            
                        <div class="pagination-wrap text-center mt-3 paginationContent">
                            
                           <Pagination               
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={handlePageChange}/>
                                            
                        </div>

                            
                           
                           


                      

                          

                         



                        </div>

                    </div>

                    {/* <!-- left side --> */}
                    <div class="col-lg-4">
                    <Recent_Jobs/>
                        {/* <div class="white-bg mb-4">
                            <img src="images/ad-1.png" class="w-100" alt=""/>
                        </div>
                        <div class="white-bg mb-4">
                            <img src="images/ad-2.png" class="w-100" alt=""/>
                        </div> */}
                        {/* <div class="p-4 white-bg mb-4">
                            <h2 class="title-stay-connect">Stay connected. Get the app!</h2>
                            <p>Download the Hometown Opportunity app and stay constanly connected with companies and job oppportunities in your area. Hometown Opportunity is available on the Apple App Store or on Google Play.</p>
                            <img src="images/HTO-app-face.png" class="w-100" alt=""/>

                            <div class="d-flex justify-content-center  mt-3">
                                <img src="images/apple-app-store.png" alt="" style={{width: "130px"}}/>
                                <img src="images/google-play.png" alt="" class="ms-2" style={{maxWidth: "130px"}}/>
                            </div>
                        </div> */}


                    </div>

                </div>
            </div>
        </section>

        <Hiring/>
        {/* <section class="white-bg py-5">
            <div class="container">
                <div class="row mb-4">
                    <div class="col-12">
                        <div class="d-flex justify-content-between border-bottom  mb-4 pb-4 flex-lg-row flex-column">
                            <h4 class="headtitle">Top companies hiring in your area</h4>
                            <a href="#" class="text-uppercase">See All</a>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="card p-3">
                            <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                            <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                            <div class="text-center stock-wrap">
                                <h3>Midmark Corporation</h3>
                                <span>Versailles, Ohio</span>
                                <a href="#">15 OPEN JOBS</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="card p-3">
                            <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                            <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                            <div class="text-center stock-wrap">
                                <h3>Midmark Corporation</h3>
                                <span>Versailles, Ohio</span>
                                <a href="#">15 OPEN JOBS</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="card p-3">
                            <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                            <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                            <div class="text-center stock-wrap">
                                <h3>Midmark Corporation</h3>
                                <span>Versailles, Ohio</span>
                                <a href="#">15 OPEN JOBS</a>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </section> */}
    </main>
  </>
)};

SchoolList.propTypes = {};

SchoolList.defaultProps = {};

export default SchoolList;
