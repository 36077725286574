import React,{history,useEffect, useState, useMemo } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import Services from "../../services/services";
import Recent_Jobs from "../Jobs/Recent_Jobs";
import { useNavigate } from 'react-router-dom';
import Hiring from "../Jobs/Hiring";
const Contactus=()=>
{
      const [itemName, setFieldName] = useState('');
      const navigate = useNavigate();
      const [contactUsContent, setContactusContent] = useState([]);
      const {
        register,
        formState: { errors },
        handleSubmit
      } = useForm({
        criteriaMode: "all",mode: "onBlur" 
      });

      const getPageInfo = () => {        
        Services.getCMSPageContent('77').then((res) => {            
            console.log(JSON.stringify(res));
            if(res.data){
                setContactusContent(res.data.content.rendered);
            }       
        });       
     }

      const submitValue=()=>
      {
        const requestObject = {
                                "firstName": $("#firstName").val(),
                                "lastName": $("#lastName").val(),
                                "phone": $("#yourphone2").val(),
                                "email": $("#email").val(),
                                "comment": $("#comment").val(),
                                "siteId":process.env.React_App_SITE_ID
                             };
        const requestOptions = {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json',
                                          //'Authorization': "Bearer "+accessTokenIn 
                                          //'Authorization': accessTokenIn 
                                        },
                                body: JSON.stringify(requestObject)
                            }; 
        Services.contactNotification(requestOptions).then((res) => 
        {
            console.log(JSON.stringify(res));
            navigate('/thankyou');
        });                                        
      }
      useEffect(() => 
      {
            $('#yourphone2').usPhoneFormat();
            getPageInfo();
          
      },[]);  

   return(<>
            <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                       <nav style={{"--bs-breadcrumb-divider": '>'}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item headtitle "><a href="#">Contact Us</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
    
        <section class="company-detail-wrap py-5 ">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="white-bg px-5 pt-5 pb-4">
                            <div class="row mb-3">
                            <div dangerouslySetInnerHTML={{ __html: contactUsContent }} />
                                {/* <div class="col-lg-12 mb-4">
                                    <h4 class="red-text">Logan County Chamber of Commerce</h4>
                                    <address>
                                 100 S Main St <br></br>
                                 Bellefontaine, OH 43311 <br></br>
                                 <a href="mailto:info@logancountyohio.com">info@logancountyohio.com</a>
                              </address>
                                </div>

                                <div class="col-lg-12">
                                    <h4 class="red-text">Union County-Marysville Economic Development Partnership</h4>
                                    <address>
                                 227 E 5th St <br></br>
                                 Marysville, OH 43040 <br></br>
                                 <a href="mailto:info@unioncounty.org">info@unioncounty.org</a>
                              </address>
                                </div> */}



                            </div>
                            <form onSubmit={handleSubmit(submitValue)}>
                            <div class="row resume-wrap">
                           
                                <div class="col-12 my-4">
                                    <h2 class="headtitle">Send Us a Message</h2>
                                </div>

                                <div class="col-lg-6 mb-4">
                                    <label for="firstName" class="form-label">First Name<span class="red-text">*</span></label>
                                    <input type="text" class="form-control" id="firstName" placeholder="" onChange={e => setFieldName(e.target.value)} {...register("FirstName", {
                                    required: "First name is required.",
                                    
                                    })}/>
                                 <ErrorMessage
                                    errors={errors}
                                    name="FirstName"
                                    class="invalid-feedback"
                                    render={({ messages }) => {
                                    console.log("messages", messages);
                                    return messages
                                        ? Object.entries(messages).map(([type, message]) => (
                                            <p key={type}  class="error">{message}</p>
                                        ))
                                        : null;
                                    }}
                                /> 
                                </div>

                                <div class="col-lg-6 mb-4">
                                    <label for="firstName" class="form-label">Last Name<span class="red-text">*</span></label>
                                    <input type="text" class="form-control" id="lastName" placeholder="" onChange={e => setFieldName(e.target.value)} {...register("LastName", {
                                    required: "Last name is required.",
                                    
                                    })}/>
                                 <ErrorMessage
                                    errors={errors}
                                    name="LastName"
                                    class="invalid-feedback"
                                    render={({ messages }) => {
                                    console.log("messages", messages);
                                    return messages
                                        ? Object.entries(messages).map(([type, message]) => (
                                            <p key={type}  class="error">{message}</p>
                                        ))
                                        : null;
                                    }}
                                /> 
                                </div>

                                <div class="col-lg-6 mb-4">
                                    <label for="firstName" class="form-label">Email<span class="red-text">*</span></label> 
                                    <input type="text" class="form-control" id="email" onChange={e => setFieldName(e.target.value)} {...register("Email", {
                                    required: "Email is required.",
                                   
                                    pattern: {
                                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                      message: "invalid email address"
                                    }
                                    })}/>
                                 <ErrorMessage
                                    errors={errors}
                                    name="Email"
                                    class="invalid-feedback"
                                    render={({ messages }) => {
                                    console.log("messages", messages);
                                    return messages
                                        ? Object.entries(messages).map(([type, message]) => (
                                            <p key={type}  class="error">{message}</p>
                                        ))
                                        : null;
                                    }}
                                /> 
                                </div>

                                <div class="col-lg-6 mb-4">
                                    <label for="firstName" class="form-label">Phone</label> <small>XXX-XXX-XXXX Format</small>
                                    <input type="text" class="form-control" id="yourphone2"  name="contactNumber"    maxlength="14" onChangeText={(text) => this.onTextChange(text) } />
                                    <div class="invalid-feedback">
                                        Valid first name is required.
                                    </div>
                                </div>

                                <div class="col-lg-12 mb-4">
                                    <div><label for="firstName" class="form-label mr-2">Your Question or Comment<span class="red-text">*</span></label> <small>Max 2000 Characters</small></div>
                                    <textarea class="form-control textarea" placeholder="Leave a comment here" id="comment" onChange={e => setFieldName(e.target.value)} {...register("comment", {
                                    required: "Leave a comment here",
                                    
                                    })}/>
                                    <ErrorMessage
                                          errors={errors}
                                          name="comment"
                                          class="invalid-feedback"
                                          render={({ messages }) => {
                                          console.log("messages", messages);
                                          return messages
                                          ? Object.entries(messages).map(([type, message]) => (
                                                <p key={type}  class="error">{message}</p>
                                          ))
                                          : null;
                                          }}
                                    /> 
                                </div>

                                {/* <div><a href="#" class="reg-button">Send</a></div> */}
                               
                                <div><button type="submit" class="reg-button"  >Send</button></div>
                            </div>
                            
                            </form>
                        </div>
                      
                    </div>
                    <div class="col-lg-4">
                          <Recent_Jobs/>
                          {/* <div class="white-bg mb-4">
                            <img src="images/ad-1.png" class="w-100" alt=""/>
                        </div>
                        <div class="white-bg mb-4">
                            <img src="images/ad-2.png" class="w-100" alt=""/>
                        </div> */}
                        {/*<div class="p-4 white-bg mb-4">
                            <h2 class="title-stay-connect">Stay connected. Get the app!</h2>
                            <p>Download the Hometown Opportunity app and stay constanly connected with companies and job oppportunities in your area. Hometown Opportunity is available on the Apple App Store or on Google Play.</p>
                            <img src="images/HTO-app-face.png" class="w-100" alt=""/>

                            <div class="d-flex justify-content-center  mt-3">
                                <img src="images/apple-app-store.png" alt="" style={{width: "130px"}}/>
                                <img src="images/google-play.png" alt="" class="ms-2" style={{maxWidth: "130px"}}/>
                            </div>
                        </div>*/}
                        {/* <div class="p-4 white-bg mb-4">
                            <div class="bg-heading">
                                <h3 class="mb-0">Recent Careers Posted</h3>
                            </div>
                            <ul class="mt-4 recent-job">
                                <li>
                                    <a href="#">
                                        <span>Quality Checker - $14.50/Hr</span>
                                        <span>By: Spherion Staffing</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>Quality Operations Clerk - $17.95/Hr</span>
                                        <span>By: Spherion Staffing</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>Mechanical Design Engineer</span>
                                        <span>By: PowerBuilt Material Handling Solutions</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>Production</span>
                                        <span>Pete DeLuke &amp; Associates, Inc.</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>Quality Operations Clerk - $17.95/Hr</span>
                                        <span>By: Spherion Staffing</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>Mechanical Design Engineer</span>
                                        <span>By: PowerBuilt Material Handling Solutions</span>
                                    </a>
                                </li>

                            </ul>
                        </div> */}



                    </div>
                </div>
            </div>
        </section>
        <Hiring/>
    </main>
   </>);
 
}

Contactus.propTypes = {};

Contactus.defaultProps = {};

export default Contactus;