import React, { history, useEffect, useState, useMemo } from "react";
import Quick_links from "../../utility/Quick_links";
import Hiring from "../Jobs/Hiring";
import services from "../../services/services";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import { useNavigate,useParams } from "react-router-dom";
const CompanyUsers = () => {

    // const { companyId } = useParams(); 
    //const companyId=24;
    const companyId=sessionStorage.getItem("logCompanyId");
    const roleArIn=sessionStorage.getItem("roleAr");

    const loggedUserId=sessionStorage.getItem("logUserId");
    
   
    const navigate = useNavigate();
      
    const [companyUsers, setCompanyUsers] = useState([]);
    
    useEffect(() => {
        if(companyId == null || companyId == "null"){            
            //navigate('/login');
            navigate('/Home');
          }
          
        $("#successMessage").hide();
        $("#successMessageDel").hide();
        
        getCompanyUsers();
        //
        $( "#removeCompanyUser" ).click(function() {
            removeCompanyUser();
          });

    }, []);


    const removeCompanyUser = () => {
       
        var companyUserArray = [];

        $.each($("input[name='companyuser']:checked"), function(){
            companyUserArray.push($(this).val());
        });
        console.log(JSON.stringify(companyUserArray))
        for(var i=0; i<companyUserArray.length; i++){
            var t_userId = companyUserArray[i];
            if(i==companyUserArray.length-1){
                console.log("---Last--"+i+"---"+t_userId);


                
                services.deleteUser(t_userId).then((res) => {
                    //console.log("Company Users=" + JSON.stringify(res.data));
                    //setCompanyUsers(res.data.userList);
                    
                    
                    $("#successMessageDel").fadeTo(2000, 500).slideUp(500, function () {
                        $("#successMessageDel").slideUp(500);
                      });
                      //navigate('/companyUsers');

                      setTimeout(() => {window.location.reload();}, 500);
                      
                      //getCompanyUsers();
                });
                
            }else{
                console.log("---mid--"+i+"---"+t_userId);
                
                services.deleteUser(t_userId).then((res) => {
                    //console.log("Company Users=" + JSON.stringify(res.data));
                    //setCompanyUsers(res.data.userList);                
                });
                
            }
           
        }
        

        //setTimeout(() => {getCompanyUsers();}, 1000);
       
    }

    const getCompanyUsers = () => {
        services.getCompanyusers(companyId).then((res) => {
            console.log("Company Users=" + JSON.stringify(res.data));
            //setCompanyUsers(res.data.userList);
            setCompanyUsers(res.data.data);
        });
    }


    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all", mode: "onBlur"
    });

    const submitValue = () => {

        const requestObject=
        {
            // "userId": 62,
            "siteId": process.env.React_App_SITE_ID,
            "companyId": companyId,
            // "schoolId": null,
            // "countyId": null,
            "firstName": $("#firstName").val(),
            "lastName": $("#lastName").val(),
            "email": $("#email").val(),
            "password": $("#password").val()
            // "phone": "9948684068",
            // "isStudent": "yes",
            // "status": null,
            // "userType": "company",
            // "createdOn": "2021-04-15T18:30:00.000+00:00",
            // "updatedOn": "2021-04-15T18:30:00.000+00:00"
           
        }

       console.log(requestObject);
       var accessTokenIn=sessionStorage.getItem("accToken");
       const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
                  'Authorization': "Bearer "+accessTokenIn 
                  //'Authorization': accessTokenIn 
                },
        body: JSON.stringify(requestObject)
    };
    
    services.addCompanyUser(requestOptions).then((res) => 
    {

    //  navigate('/thankyou');

/*
    setTimeout(function(){
        console.log("Hello World");
    }, 2000);
*/  
console.log(res);
    if(res.success == "false"){
        //$("#successMessage").html(res.message);
        $("#successMessage").html('User Exist with the same EmailId').fadeTo(2000, 500).slideUp(500, function () {
            $("#successMessage").slideUp(500);
          });
    }else if(res.success == "true"){
        $("#addUserForm").trigger("reset");
        //alert("test=="+JSON.stringify(res));
       // $("#successMessage").html(res);
      $("#successMessage").html('User has been added successfully').fadeTo(2000, 500).slideUp(500, function () {
        $("#successMessage").slideUp(500);
      });
      getCompanyUsers(); 
    }
    /*
     $("#addUserForm").trigger("reset");
       //alert("test=="+JSON.stringify(res));
      // $("#successMessage").html(res);
     $("#successMessage").fadeTo(2000, 500).slideUp(500, function () {
       $("#successMessage").slideUp(500);
     });
     getCompanyUsers();
     */
    
    });

    }


    return (
        <>
            <main class="empr-directory-main">
                <div class="main-bgpatten"></div>
                {/* <!-- info typography --> */}
                <section class="headtitle-wrap">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <nav style={{ "--bs-breadcrumb-divider": "'>';" }} aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item headtitle "><a href="/companyJobs">Company Dashboard</a> </li>
                                        <li class="breadcrumb-item headtitle "><a href="#">Company Administrator</a></li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- info typography --> */}
                <section class="py-5">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 ">
                                <div class="white-bg p-5">
                                    <div class="row">
                                        {/* <!-- left side --> */}

                                        <Quick_links />
                                        {/* <!-- right side --> */}
                                        <div class="col-lg-8 mb-4">
                                            <form id="addUserForm" onSubmit={handleSubmit(submitValue)}>
                                                <div class="row resume-wrap mb-4">
                                                    <div class="col-lg-6 mb-4">
                                                        <label for="firstName" class="form-label">First Name<span class="red-text">*</span></label>
                                                        <input type="text" class="form-control" id="firstName" placeholder="" {...register('firstName', { required: true })} />
                                                        {errors.firstName && errors.firstName.type === "required" && <div class="error">First name is required</div>}
                                                    </div>

                                                    <div class="col-lg-6 mb-4">
                                                        <label for="lastName" class="form-label">Last Name<span class="red-text">*</span></label>
                                                        <input type="text" class="form-control" id="lastName" placeholder="" {...register('lastName', { required: true })} />
                                                        {errors.lastName && errors.lastName.type === "required" && <div class="error">Last name is required</div>}
                                                    </div>

                                                    <div class="col-lg-12 mb-4">
                                                        <label for="email" class="form-label">Email<span class="red-text">*</span></label>
                                                        <input type="text" class="form-control" id="email" placeholder=""  {...register('email', { required: true, pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/ })} />
                                                        {errors.email && errors.email.type === "required" && <div class="error">Email is required</div>}
                                                        {errors.email && errors.email.type === "pattern" && <div class="error">Invalid email address.</div>}
                                                    </div>

                                                    <div class="col-lg-6 mb-4">
                                                        <label for="password" class="form-label">Password<span class="red-text">*</span></label>
                                                        <p style={{fontSize: "12px"}}>Minimum of 8 characters long, and a combination of lowercase & uppercase letters, numbers, and special characters.</p>
                                                        <input type="password" class="form-control" id="password" placeholder="" {...register('password', { required: true, pattern: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/ })}/>
                                                        {errors.password && errors.password.type === "required" && <div class="error">Password is required</div>}
                                                        {errors.password && errors.password.type === "pattern" && <div class="error">Password contain uppercase,lowercase and special character</div>}   
                                                    </div>

                                                    <div class="col-lg-12 mb-4">

                                                        <div class="d-flex justify-content-between align-items-end choose">

                                                            <div><button type="submit" class="reg-button">Add User</button></div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </form>
                                            <div id="successMessage" class="alert alert-success" role="alert">
                                           User has been added successfully</div>

                                           
                                           
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="current-job-tbl mb-4">
                                                        <table class="table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col"></th>
                                                                    <th scope="col">Name</th>
                                                                    <th scope="col">Email</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    companyUsers.map((data, index) => (
                                                                        (data.userId != loggedUserId)?
                                                                            <tr>
                                                                            <td><input name="companyuser" class="form-check-input" type="checkbox" value={data.userId} id="defaultCheck1" /></td>
                                                                            <td>{data.firstName}</td>
                                                                            <td>{data.email}</td>
                                                                            </tr>
                                                                        : null
                                                                        

                                                                    ))}
                                                               
  

                                                              
                                                            </tbody> 
                                                        </table>
                                                    </div>
                                                    <div class="mt-3"><a  id="removeCompanyUser" class="reg-button">Remove User</a></div>
                                                    <div id="successMessageDel" class="alert alert-success" role="alert">
                                           Selected User(s) has been deleted successfully</div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>
                {/* <Hiring /> */}
            </main>
        </>
    )
};

CompanyUsers.propTypes = {};

CompanyUsers.defaultProps = {};

export default CompanyUsers;
