import React,{history,useEffect, useState, useMemo } from "react";
import Quick_links from "../../utility/Quick_links";
import Hiring from "./Hiring";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import services from "../../services/services";
import { useNavigate, useParams } from "react-router-dom";
//import Pagination3 from "../../utility/Pagination/Pagination";
import Pagination3 from "../../utility/Pagination/Pagination";
import Services from "../../services/services";
import moment from "moment";


const EventWidget = () => {
   const [itemName, setFieldName] = useState('');

   //const companyId = 24;
   const companyId=sessionStorage.getItem("logCompanyId");
   const siteId = process.env.React_App_SITE_ID;
 
   const navigate = useNavigate();
   const ITEMS_PER_PAGE = 3; // Per page show the data pagination
   const [totalItems, setTotalItems] = useState(0);
   const [totalpages, settotalPages] = useState();
   const [currentPage, setCurrentPage] = useState(1);

   const [videoUrl, setVideo] = useState([]);
   const [jobList, setJobList] = useState([]);
   const [eventList, setEventList] = useState([]);

   useEffect(() => {

      //getTopCompanyHiringList();
      //filterContent(ITEMS_PER_PAGE,offset);
      //filterEvents(ITEMS_PER_PAGE,'1');
      filterRecentJobs(ITEMS_PER_PAGE,'1');

   }, []);

  

  
   const handlePageChange3 = (offset,triggerevent) => {
      filterRecentJobs(ITEMS_PER_PAGE,offset)
    };

    const filterRecentJobs = (ITEMS_PER_PAGE,pageNumber) => {     
     
          
          var siteIdIn = process.env.React_App_SITE_ID;//"2";
         
          Services.getEventsbySiteId(siteIdIn,ITEMS_PER_PAGE,pageNumber).then((res) => 
          {

            //console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@events widg 113");
              if(res.data.content){
               console.log(JSON.stringify(res.data));
                  $(".paginationContent").show();
                  setTotalItems(res.data.totalElements);         
                  settotalPages(Math.ceil(res.data.totalElements / ITEMS_PER_PAGE));                    
                  setCurrentPage(pageNumber);
      
                  var contAr = res.data.content;
                  //setProfileList(res.content);
                  if(res.data.content){
                     setEventList(res.data.content);
                  }                
                  

             }              

          });
  }  

   const {
      register,
      formState: { errors },
      handleSubmit
   } = useForm({
      criteriaMode: "all", mode: "onBlur"
   });

   const submitValue = () => {

     

   }

  return(
 
    <>
        <div class="card p-3 mt-5">
            <div class="announce-wrap">

               <div class="orange-ribbon d-flex mb-4">
                  {/* <img src="images/job-opp-icon.png" alt=""/> */}
                  <img src="images/events-icon.png" alt="" />
                  <span>EVENTS</span>
               </div>
               
               {/* {
                           jobList.map((data, index) => (
               <div class="oppertunity-card">
                  <div class="logo-org">
                       
                        <img src= {data.logo_url ? data.logo_url : "images/no-logo.jpg"} alt=""/>
                     
                  </div>
                  <div class="right-info">
                  <a href={`/jobsummary?jobId=`+data.jobId}><h4>{data.title}</h4></a>
                        <div class="d-flex">
                           <span><a href={`/companyDetails?companyId=`+data.companyId}>{data.name}</a> </span>
                           <span class="mx-3">|</span>
                           <span> Versailles, Ohio</span>
                        </div>
                        <p class="mt-2">The Senior Product and Services Manager researches and develops the market for services to support the organization.…</p>
                        <b class="text-uppercase">{moment(data.createdDate).format("D-MMM-YY")}</b>
                  </div>
               </div>

         ))
         } */}

                  {
                     eventList.map((data, index) => (

                     <div class="pb-4 mt-4 border-bottom">
                        <a href={'/eventDetails?eventId='+data.eventId}><h4 class="mb-0">{data.eventTitle}</h4></a>
                        <span><strong>Venue: {data.eventAddress}</strong></span><br></br>
                        <span><strong>{moment(data.eventDate).format("D-MMM-YY")}</strong></span>

                       

                        {/* <p>{`${data.eventDescription.substring(0, 100)}...`}<a href={`/eventDetails?eventId=`+data.eventId}></a></p> */}
                        {/* <p dangerouslySetInnerHTML={{ __html: data.eventDescription.substring(0, 200)  }} />     */}
                       <br></br>
                       <br></br>
                        <span class="mb-2 mr-2"><a href={'/eventDetails?eventId='+data.eventId} class="reg-button">View Details</a></span>
                        {/* <span class="mb-2"><a href={data.eventExternalLink} class="reg-button" target="_blank">Learn more</a></span> */}
                     </div>
                     ))} 
         
            <div class="pagination-wrap text-center mt-3 paginationContent">
                           <Pagination3               
                     total={totalItems}
                     itemsPerPage={ITEMS_PER_PAGE}
                     currentPage={currentPage}
                     onPageChange={handlePageChange3}/>
                                 
            </div>
            
               </div>
            
            
         </div>
        </>
  
)};

EventWidget.propTypes = {};

EventWidget.defaultProps = {};

export default EventWidget;