import React, { history, useEffect, useState, useMemo } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import Recent_Jobs from "./Jobs/Recent_Jobs";
import services from "../services/services";
import Hiring from "./Jobs/Hiring";


const Industries = () => {
    const [categories, setCategory] = useState([]);
    const getIndustryData = () => {
        services.getIndustryData().then((res) => {
          console.log("Industry data=" + JSON.stringify(res.data));
          setCategory(res.data.content);
        });
      }

    useEffect(() => {


        getIndustryData();
    }, []);

    return (<>
           <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
      
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">                  

                        <nav style={{"--bs-breadcrumb-divider": '>'}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item headtitle "><a href=""> Industries</a><span style={{color: "#6c757d"}}>&#62;</span></li>
                                <li class="breadcrumb-item headtitle "><a href="#">Key Industries</a></li>
                            </ol>
                        </nav>


                    </div>
                </div>
            </div>
        </section>

        <section class="info-typography py-5">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 mb-4">
                        <div class="py-5 px-5 white-bg" style={{"padding-bottom":"20px !important;"}}>
                         

                            <div class="empr-directory-wrap">
                                <h4>Key Industries</h4>
                                <ul>
                                {
                                categories.map((data, index) => (
                                    <li><a href={"/CompanyListByIndustry?catId="+data.catId} key={data.catId}> {data.name}</a></li>                                 

                                  ))}
                                    

                                </ul>
                            </div>




                        </div>
                    </div>
                    <div class="col-lg-4">
                    <Recent_Jobs/>
                        {/* <div class="white-bg mb-4">
                            <img src="images/ad-1.png" class="w-100" alt="" />
                        </div>
                        <div class="white-bg mb-4">
                            <img src="images/ad-2.png" class="w-100" alt="" />
                        </div> */}
                        {/*<div class="p-4 white-bg mb-4">
                            <h2 class="title-stay-connect">Stay connected. Get the app!</h2>
                            <p>Download the Hometown Opportunity app and stay constanly connected with companies and job oppportunities in your area. Hometown Opportunity is available on the Apple App Store or on Google Play.</p>
                            <img src="images/HTO-app-face.png" class="w-100" alt=""/>

                            <div class="d-flex justify-content-center  mt-3">
                                <img src="images/apple-app-store.png" alt="" style={{"width":" 130px;"}} />
                                <img src="images/google-play.png" alt="" class="ms-2" style={{"max-width": "130px;"}} />
                            </div>
                        </div>*/}


                    </div>
                </div>
            </div>

            <Hiring/>
        </section>


    </main>
    </>);

}

Industries.propTypes = {};

Industries.defaultProps = {};

export default Industries;