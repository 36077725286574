import React, { history, useEffect, useState, useMemo } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import Recent_Jobs from "./Jobs/Recent_Jobs";
import services from "../services/services";
import { useNavigate } from 'react-router-dom';

const HTOADV = () => {
    const [currentPageContent, setCurrentPageContent] = useState([]);
    const navigate = useNavigate();
    const getPageInfo = (reqopt) => {
        services.getCMSPageContent('3').then((res) => {
            console.log(JSON.stringify(res));
            if(res.data){
                setCurrentPageContent(res.data.content.rendered);
            }
        });
     }

     const submitValue=()=>
      {
        const requestObject = {
                                "firstName": $("#firstName").val(),
                                "lastName": $("#lastName").val(),
                                "phone": $("#yourphone2").val(),
                                "email": $("#email").val(),
                                "comment": $("#comment").val(),
                                "siteId":process.env.React_App_SITE_ID
                             };
        const requestOptions = {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json',
                                          //'Authorization': "Bearer "+accessTokenIn
                                          //'Authorization': accessTokenIn
                                        },
                                body: JSON.stringify(requestObject)
                            };
        services.contactNotification(requestOptions).then((res) =>
        {
            console.log(JSON.stringify(res));
            navigate('/thankyou');
        });
      }


      function validateForm(){
        var checked_role = '';
        var valideform = 1;
        var email_regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(document.getElementById('jobseeker').checked){
            checked_role = 'jobseeker';
        }
        if(document.getElementById('student').checked){
            checked_role = 'student';
        }
        if(document.getElementById('employer').checked){
            checked_role = 'employer';
        }
        //alert(checked_role);
        if(checked_role == ''){
            document.getElementById('roleEmptyValid').style.display = "block";
            valideform = 0;
            document.getElementById('roleEmptyValid').focus();
        }
        else {
            document.getElementById('roleEmptyValid').style.display = "none";
        }

        if(document.getElementById('firstname').value == ''){
            document.getElementById('firstnameValid').style.display = "block";
            valideform = 0;
            document.getElementById('firstnameValid').focus();
        }
        else {
            document.getElementById('firstnameValid').style.display = "none";
        }

        if(document.getElementById('lastname').value == ''){
            document.getElementById('lastnameValid').style.display = "block";
            valideform = 0;
            document.getElementById('lastnameValid').focus();
        }
        else {
            document.getElementById('lastnameValid').style.display = "none";
        }

        var emailid = document.getElementById('email').value;
        if(emailid == '' || !email_regex.test(emailid)){
            document.getElementById('emailValid').style.display = "block";
            valideform = 0;
            document.getElementById('emailValid').focus();
        }
        else {
            document.getElementById('emailValid').style.display = "none";
        }

        if(valideform == 0){
            return false
        }
        else {
            var signup_for = checked_role;
            var firstname =  document.getElementById('firstname').value;
            var lastname = document.getElementById('lastname').value;
            var signupemail = emailid;
            var phone = document.getElementById('phone').value;
            var comment_input = checked_role + "-"+ $("#comment").val();

            const requestObject = {
                            "firstName": $("#firstname").val(),
                            "lastName": $("#lastname").val(),
                            //"phone": "1111111111",//$("#phone").val(),
                            "phone": $("#phone").val(),
                            "email": signupemail,
                            "comment": comment_input,
                            "siteId":process.env.React_App_SITE_ID
                        };
            const requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json',
                                    //'Authorization': "Bearer "+accessTokenIn
                                    //'Authorization': accessTokenIn
                                    },
                            body: JSON.stringify(requestObject)
                        };
            services.contactNotification(requestOptions).then((res) =>
            {
            console.log(JSON.stringify(res));
                navigate('/thankyou');
            });

            //console.log(signup_for+'-'+firstname+'-'+lastname+'-'+signupemail+'-'+phone);
        }



    return false;

    }

    function bindEvent(){
        $(".phonev").bind("keypress", function (e) {
              var keyCode = e.which ? e.which : e.keyCode
              if (!(keyCode >= 48 && keyCode <= 57)) {
                return false;
              }
          });

          //
          $('.phonev')
    .on('keypress', function(e) {

        /*
       var keyCode = e.which ? e.which : e.keyCode
      if (!(keyCode >= 48 && keyCode <= 57)) {
        return false;
      }
      */

      var key = e.charCode || e.keyCode || 0;
      var phone = $(this);
      if (phone.val().length === 0) {
        phone.val(phone.val() + '(');
      }
      // Auto-format- do not expose the mask as the user begins to type
      if (key !== 8 && key !== 9) {
        if (phone.val().length === 4) {
          phone.val(phone.val() + ')');
        }
        if (phone.val().length === 5) {
          phone.val(phone.val() + ' ');
        }
        if (phone.val().length === 9) {
          phone.val(phone.val() + '-');
        }
        if (phone.val().length >= 14) {
          phone.val(phone.val().slice(0, 13));
        }
      }
      // Allow numeric (and tab, backspace, delete) keys only
      return (key == 8 ||
        key == 9 ||
        key == 46 ||
        (key >= 48 && key <= 57) ||
        (key >= 96 && key <= 105));
    })
    .on('focus', function() {
      var phone = $(this);
      if (phone.val().length === 0) {
        phone.val('(');
      } else {
        var val = phone.val();
        phone.val('').val(val); // Ensure cursor remains at the end
      }
    })
    .on('blur', function() {
      var phone_v = $(this).val();

      if (phone_v === '(') {
        //$phone.val('');
        $(this).val('');
      }
    });
          //
    }
    useEffect(() => {
        bindEvent();
        $("#msg_success").hide();
        $( "#regBtn" ).click(function() {
            validateForm();
          });

    }, []);

    return (<>


        <header className="cs_header">
            <div className="container">
                <div className="col-auto">
                    <a href="#"><img src="images/logo-white.png" className="cs_logo" /></a>
                </div>
            </div>
        </header>


        <main>





                    <section className="cs_section employer_bg">

                        <div className="cs_section-caption">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                        <h1>Recruiting for important position? </h1>
                                        <h3>There is plenty of local talent!</h3>
                                        <p className="pb-3">We are building Hometown Advantage to help you find the right local talent.</p>
                                        <a href="/companyRegister" className="carousel-button mt-2">Register for employer account</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </section>


                        <section className="cs_section jobseeker_bg">

                            <div className="cs_section-caption" >
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12">
                                            <h1>Looking for a Job?</h1>
                                            <h3>There are local options!</h3>
                                            <p className="pb-3">We are building Hometown Advantage<br />to help you find rewarding local careers.</p>
                                            <a href="#signup_section" className="carousel-button mt-2">Sign up to receive updates</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            </section>



                        <section className="cs_contact_section" id="signup_section">
                        <div className="container">
                            <div className="row d-flex justify-content-center">
                                <div className="col-lg-7 col-md-12">

                                    <form id="contact_form" name="contact_form" method="post" >

                                        <div className="cs_contact_wp">
                                            <div className="row">
                                                <div className="cs_title_wp">
                                                    <h4>Signup for updates</h4>
                                                </div>
                                                <div className="col-lg-12 mb-4">
                                                    <label for="firstName" className="form-label mr-2">I am a <span className="red-text">*</span></label>
                                                    <div className="d-flex">
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input radio-chk" type="radio" name="person_role" id="jobseeker" value="jobseeker" />
                                                            <label className="form-check-label" for="flexRadioDefault1">Job Seeker</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input radio-chk" type="radio" name="person_role" id="student" value="student" />
                                                            <label className="form-check-label" for="flexRadioDefault1">Student</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input radio-chk" type="radio" name="person_role" id="employer" value="employer" />
                                                            <label className="form-check-label" for="flexRadioDefault1">Employer</label>
                                                        </div>
                                                    </div>
                                                    <div id="roleEmptyValid" className="invalid-feedback" >Please select at least one option</div>
                                                </div>
                                                <div className="col-lg-6 mb-4">
                                                    <label for="firstName" className="form-label">First name <span className="red-text">*</span></label>
                                                    <input type="text" className="form-control" id="firstname" name="firstname" />
                                                    <div id="firstnameValid" className="invalid-feedback" >First Name is required</div>
                                                </div>
                                                <div className="col-lg-6 mb-4">
                                                    <label for="firstName" className="form-label">LAST NAME <span className="red-text">*</span></label>
                                                    <input type="text" className="form-control" id="lastname" name="lastname"  />
                                                    <div id="lastnameValid" className="invalid-feedback" >Last Name is required</div>
                                                </div>
                                                <div className="col-lg-12 mb-4">
                                                    <label for="firstName" className="form-label">EMAIL ADDRESS <span className="red-text">*</span></label>
                                                    <input type="text" className="form-control" id="email" name="email"  />
                                                    <div id="emailValid" className="invalid-feedback" >Valid email address is required.</div>
                                                </div>
                                                <div className="col-lg-12 mb-4">
                                                    <div className="d-flex">
                                                        <label for="firstName" className="form-label ">PHONE NUMBER </label>
                                                        <small >(optional)</small>
                                                    </div>
                                                    <input type="text" className="form-control phonev" id="phone" name="phone" onKeyPress="return AllDigits(event);"  />
                                                </div>
                                                <div class="col-lg-12 mb-4"><div><label for="firstName" class="form-label mr-2">Your Question or Comment<span class="red-text">*</span></label> <small>Max 2000 Characters</small></div><textarea class="form-control textarea" placeholder="Leave a comment here" id="comment" name="comment"></textarea></div>
                                                <div className="col-lg-12 mb-4">
                                                    <input type="button" className="reg-button" id="regBtn"  value="Sign Up" />
                                                </div>

                                                <div className="col-lg-12 mb-4">
                                                    <span id="msg_success" >Thank you for submitting the form.</span>
                                                </div>



                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>

    </main>
                <section className="cs_footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <p>©2022 Hometown Advantage. All Rights Reserved. <a href="/Privacypolicy">Privacy policy</a></p>
                            </div>
                        </div>
                    </div>
                </section>

    </>);

}

HTOADV.propTypes = {};

HTOADV.defaultProps = {};

export default HTOADV;