import React,{history,useEffect, useState, useMemo } from "react";
import services from "../services/services";
import Recent_Jobs from "./Jobs/Recent_Jobs";
import Hiring from "./Jobs/Hiring";
const MovingToTheArea = () => {
    const [movingToAreaContent, setMovingToAreaContent] = useState([]);
    const getPageInfo = (reqopt) => {        
        services.getCMSPageContent('29').then((res) => {            
            console.log(JSON.stringify(res));
            if(res.data){
                setMovingToAreaContent(res.data.content.rendered);
            }       
        });       
     }
    useEffect(() => {        
        getPageInfo("downloadourapp");
  
    }, []);

  return(
  <>
     <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav style={{"--bs-breadcrumb-divider": '>'}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item headtitle "><a href="#">Moving to the Area</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

          

        </section>

       
        {/* <!-- info typography --> */}
        <section class="company-detail-wrap py-4 ">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="white-bg px-4">
                            <div class="row mb-3">
                                <div class="col-lg-12 mb-4">
                                <div class="white-bg px-4 pt-4">
                                    <div class="row mb-3">
                                        <div class="col-lg-12">
                                            <div dangerouslySetInnerHTML={{ __html: movingToAreaContent }} />
                                            </div></div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                            <Recent_Jobs/>

                            {/* <div class="white-bg mb-4">
                        
                                <img src="images/ad-1.png" class="w-100" alt="" />
                            </div>
                            <div class="white-bg mb-4">
                                <img src="images/ad-2.png" class="w-100" alt="" />
                            </div> */}
                        
                    </div>
                </div>
            </div>

            <Hiring/>
        </section>
    </main>
  </>
)};

MovingToTheArea.propTypes = {};

MovingToTheArea.defaultProps = {};

export default MovingToTheArea;
