import React, { history,useEffect, useState, useMemo } from "react";
import { ErrorMessage } from "@hookform/error-message";
import moment from "moment";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import UsNumberValidate from '../../utility/usNumberValidate';
import Services from "../../services/services";
//import PaginationComponent from "../../utility/Pagination/Pagination";
import Pagination from "../../utility/Pagination/Pagination";
import { useNavigate,useParams } from "react-router-dom";
import ReactExport from "react-data-export";
const StudentList = () => {

    const navigate = useNavigate();
    const [categories, setCategory] = useState([]);
    const [profileList, setProfileList] = useState([]);
    const [profileListFull2, setProfileListFull] = useState([]);


    const [currentYearList, setCurrentYearList] = useState([]);
    const [jobTypes, setJobType] = useState([]);
    const [schoolList, setSchoolList] = useState([]);
    const [itemName, setFieldName] = useState('');
    const ITEMS_PER_PAGE = 10; // Per page show the data pagination
    const Pagesize=5;
    const [categoriesSelected, setCategoriesSelected] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [totalpages, settotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const companyId=sessionStorage.getItem("logCompanyId");
    const userIdChkLogin=sessionStorage.getItem("logUserId");
    const chKRoleAr=sessionStorage.getItem("roleAr");
    var FileSaver = require('file-saver');

    const getSchoolIdbyUserId = () =>{

        //setCurrentYearList(years);
      }

      function download_excel(profileListFull){
        console.log(profileListFull);

        var today = new Date();
        var month = today.toLocaleString('en-US', {month: 'short'});
        var date= today.getDate();
        var year = today.getFullYear();

        var filename = "student-export-"+month+"-"+date+"-"+year+".csv";
       // console.log(filename);
        let csv = '';
        let row = '';
        let headers = '';
       // headers = headers.concat('Updated On', ',', 'Name', ',', 'Email', ',', 'Phone', ',', 'School District', ',', 'Class Year', ',', 'Seeking Job Type', ',', 'Interested Categories');
       var jobtypeslisthead = 'Updated On'+','+'Name'+','+'Email'+','+'Phone'+','+'School District'+','+'Class Year'+',';
        var jobtypes_sorted= jobTypes.sort((a, b)=> b.jobTypeId - a.jobTypeId)
        for(var jb=0; jb < jobtypes_sorted.length; jb++){
            jobtypeslisthead = jobtypeslisthead+jobtypes_sorted[jb].name+',';
        }
        jobtypeslisthead = jobtypeslisthead+'Interested Categories';
        headers = headers.concat(jobtypeslisthead);

        csv = csv.concat(headers);
        row = '';
        //alert(profileListFull.length);
        for(var i=0; i<profileListFull.length; i++){
            var cats = profileListFull[i].categoryName;
            var jobtypenames = profileListFull[i].jobTypeName;
            var dateUpdated = new Date(profileListFull[i].dateUpdated);
                var displayupdated =  dateUpdated.getDate()+'-'+dateUpdated.toLocaleString('en-US', {month: 'short'})+'-'+dateUpdated.getFullYear();
                var jobtypeslistrow = "";

            var selectedcategs = cats.join('/');

               // row = row.concat("\n",                displayupdated+","+profileListFull[i].firstName+" "+profileListFull[i].lastName+","+profileListFull[i].email+","+profileListFull[i].name+","+profileListFull[i].phoneno+","+profileListFull[i].classYear+","+jobtypenames.join("/")+","+'"'+cats.join("/")+'"')

               jobtypeslistrow = "\n"+displayupdated+','+profileListFull[i].firstName+" "+profileListFull[i].lastName+','+profileListFull[i].email+','+profileListFull[i].phoneno+','+profileListFull[i].name+','+profileListFull[i].classYear+',';

               for(var jb=0; jb < jobtypes_sorted.length; jb++){
                if(jobtypenames.includes(jobtypes_sorted[jb].name)){
                    jobtypeslistrow = jobtypeslistrow+'Y ,';
                }
                else {
                    jobtypeslistrow = jobtypeslistrow+' ,';
                }

            }
            jobtypeslistrow = jobtypeslistrow+selectedcategs.replace(/,/g, "-");

            row = row.concat(jobtypeslistrow);

        }
        csv = csv.concat(row);

        var file = new File([csv], filename, {type: "application/csv"});
        FileSaver.saveAs(file);
        $(".load-wrap").hide();
    }

    const getCurrentYear = () =>{
        var currentYear = new Date().getFullYear()
        var years = [];
        years.push(currentYear);
        for(var i=1;i<4;i++){
          years.push(currentYear+i);
        }
        //const now = new Date().getUTCFullYear();
        //const years = Array(now - (now + 5)).fill('').map((v, idx) => now - idx);
        console.log("#41#41-->"+JSON.stringify(years));
        setCurrentYearList(years);
      }


    function strCatArr(arr){
        if(Array.isArray(arr)){
          return arr.join(", ");
        }else{
          return "";
        }
      }

      const getSchoolList = () => {
        //alert("14");
      const requestObject={
          "siteId":process.env.React_App_SITE_ID,
          "pageNumber":'1',
          "pageSize":'500'
       }

       var accessTokenIn=sessionStorage.getItem("accToken");
       const requestOptions = {
         method: 'POST',
         headers: { 'Content-Type': 'application/json',
                   //'Authorization': "Bearer "+accessTokenIn
                   //'Authorization': accessTokenIn
                 },
         body: JSON.stringify(requestObject)
       };

       Services.getSchoolList(requestOptions).then((res) =>
       {

         console.log(JSON.stringify(res))
          if(res.content){
              console.log(JSON.stringify(res.data));
                 var contAr = res.content;
                 //setProfileList(res.content);
                 if(res.content){
                  setSchoolList(res.content);
                 }
            }
        //navigate('/thankyou');

       });

   }

    useEffect(() => {
       // $('#export_studentbtn').hide();
        //filterContent_full();
        $(".load-wrap").hide();
        if( chKRoleAr == "schoolsuper"){
            filterContent(ITEMS_PER_PAGE,'1');
            $( document ).on( "click", ".searchContent", function() {
                filterContent(ITEMS_PER_PAGE,'1');
                //filterContent_full();
            });
            $( document ).on( "change", ".searchKeyInput", function() {
                filterContent(ITEMS_PER_PAGE,'1');
               // filterContent_full();
            });
            //navigate('/login');
            //navigate('/Home');
        }else{
            navigate('/Home');
        }
        $('#phone').usPhoneFormat();
        getProfileInfo();
        getIndustryData();
        getJobType();
        getSchoolList();
        getCurrentYear();





         $(".paginationContent").hide();

    }, []);

    function getProfileInfo() {

        //var companyId = "24";


        Services.getCompanyProfile(companyId).then((res) => {


          setCategoriesSelected(res.data.companyCategory);
          var companyCatIn = res.data.companyCategory;

         /*
          setTimeout(() => {
            for (var i = 0; i < companyCatIn.length; i++) {
              //$("input[value='" + val + "']").prop('checked', true);
              console.log("###" + companyCatIn[i]);
              $('input[name="category"][value="' + companyCatIn[i] + '"]').prop("checked", true);
              //$('input[name="category"][value="' + companyCatIn[i] + '"]').trigger("click");
            }
          }, 1000);
          */



          //navigate('/thankyou');
        });
      }
    const handlePageChange2 = (offset,triggerevent) => {
        // alert(offset);
        filterContent(ITEMS_PER_PAGE,offset);
      };

    const filterContent = (ITEMS_PER_PAGE,pageNumber) => {
        var categoriesArray = [];

        $.each($("input[name='category']:checked"), function(){
            categoriesArray.push($(this).val());
        });
        //alert(JSON.stringify(categoriesArray));

        var schoolIdArray = [];
        $.each($("input[name='schoolId']:checked"), function(){
            schoolIdArray.push($(this).val());
        });

        var schoolYearArray = [];
        $.each($("input[name='schoolYear']:checked"), function(){
            schoolYearArray.push($(this).val());
        });

        const requestObject={
            "siteId": process.env.React_App_SITE_ID,
            "categoriesOfExpertise": categoriesArray,
            "classYear": schoolYearArray,
            "schoolId": schoolIdArray,
            "pageNumber" :pageNumber,
            "pageSize":ITEMS_PER_PAGE,
            "titleSummary":$(".searchKeyInput").val(),
            "sortBy" : "updatedOn"
          }

          var accessTokenIn=sessionStorage.getItem("accToken");
          console.log(requestObject);
            const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                      'Authorization': "Bearer "+accessTokenIn
                      //'Authorization': accessTokenIn
                    },
            body: JSON.stringify(requestObject)
            };

            Services.findFilterStudent(requestOptions).then((res) =>
            {


                if(res.content){
                    console.log(JSON.stringify(res));

                    $(".paginationContent").show();
                    setTotalItems(res.totalElements);
                    settotalPages(Math.ceil(res.totalElements / ITEMS_PER_PAGE));
                    setCurrentPage(pageNumber);

                    var contAr = res.content;
                    setProfileList(res.content);
                    if(contAr.length>0){
                        $(".paginationContent").show();
                    }else{
                        $(".paginationContent").hide();
                    }
                    //filterContent_full();
               }


            });



    }


    const filterContent_full = () => {
        var categoriesArray = [];

        $.each($("input[name='category']:checked"), function(){
            categoriesArray.push($(this).val());
        });
        //alert(JSON.stringify(categoriesArray));

        var schoolIdArray = [];
        $.each($("input[name='schoolId']:checked"), function(){
            schoolIdArray.push($(this).val());
        });

        var schoolYearArray = [];
        $.each($("input[name='schoolYear']:checked"), function(){
            schoolYearArray.push($(this).val());
        });

        const requestObject={
            "siteId": process.env.React_App_SITE_ID,
            "categoriesOfExpertise": categoriesArray,
            "classYear": schoolYearArray,
            "schoolId": schoolIdArray,
            "pageNumber" :1,
            "pageSize":'5000',
            "titleSummary":$(".searchKeyInput").val(),
            "sortBy" : "updatedOn"
          }

          var accessTokenIn=sessionStorage.getItem("accToken");
          console.log(requestObject);
            const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                      'Authorization': "Bearer "+accessTokenIn
                      //'Authorization': accessTokenIn
                    },
            body: JSON.stringify(requestObject)
            };
            $(".load-wrap").show();
            Services.findFilterStudent(requestOptions).then((res) =>
            {


                if(res.content){
                   // console.log(JSON.stringify(res));
//alert(res.content.length);
                    var contAr = res.content;
                    setProfileListFull(res.content);
                    //$('#export_studentbtn').show();
                    download_excel(contAr);
                    /*setTimeout(() => {
                        download_excel(contAr);
                        }, 2000) */



               }


            });



    }

    const getIndustryData = () => {
        Services.getIndustryData().then((res) => {
            console.log("Industry data=" + JSON.stringify(res.data));
            setCategory(res.data.content);
        });
    }

    const getJobType = () => {
        Services.getJobType().then((res) => {
            console.log("setJobType data=" + JSON.stringify(res.data));
            setJobType(res.data.content);
        });
    }

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all", mode: "onBlur"
    });

    const submitValue = () => {

    }

    return (
        <>
            <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        {/* <!-- info typography --> */}
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                            <li class="breadcrumb-item headtitle"> <a href="/schoolAdminDashboard">School Admin</a> </li>
                                <li class="breadcrumb-item headtitle "><a href="#">Student Profiles</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="py-5 cd-desktop">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-5 ">
                            <div class="row">
                                {/* <!-- left side --> */}
                                <div class="col-lg-4 mb-2">
                                    <div class="card p-3 mb-0 ">
                                    <span className="mb-3">
                                        <a onClick={filterContent_full} id="export_studentbtn" class="reg-button text-center mx-0">Export to Excel</a>
                                        </span>
                                        <div class="bg-heading">
                                            <h3 class="mb-0">Filter Profiles</h3>
                                        </div>
                                        <div class="py-3 text-start">
                                            <b class="text-uppercase">Keywords</b>

                                                <input class="form-control me-2 searchKeyInput" type="search" aria-label="Search" placeholder="Search"/>
                                                {/* <button class="border-0 p-0 dir-search" type="submit"><img src="images/search-icon.JPG" alt=""/></button> */}

                                        </div>
                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Categories of Interest</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>
                                                {
                                                    categories.map((data, index) => (
                                                    <li><input class="form-check-input searchContent" type="checkbox" value={data.catId} name="category"/> <label class="form-check-label" for="defaultCheck1"> {data.name}</label></li>
                                                ))}

                                                </ul>


                                            </div>
                                        </div>


                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">School</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>
                                                {
                                                schoolList.map((data, index) => (
                                                    <li><input class="form-check-input searchContent" type="checkbox" value={data.schoolId} name="schoolId"/> <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                ))}
                                                </ul>
                                            </div>
                                        </div>


                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Year</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>
                                                {
                                                currentYearList.map((data, index) => (
                                                    <li><input class="form-check-input searchContent" id="schoolYear" type="checkbox" value={data} name="schoolYear"/> <label class="form-check-label" for="defaultCheck1"> {data} </label></li>

                                                ))}
                                                </ul>
                                            </div>
                                        </div>

                                    </div>


                                </div>

                                {/* <!-- right side --> */}
                                <div class="col-lg-8 mb-4">

                                    <div class="two-grid-wrap">




                                    {
                                       profileList.map((data, index) => (

                                        <div class="grid-item p-3">
                                            <div class="grid-head">
                                                <div class="grid-img">
                                                    <img src={data.profilePicS3Url?data.profilePicS3Url:"images/user-default.jpg"} class="w-100" alt=""/>
                                                </div>
                                                <div class="grid-title-wrap">
                                                    <h4 class="margin-bottom-0">{data.firstName} {data.lastName}</h4>
                                                    <small class="d-flex">
                                                    <b class="mr-2" style={{minWidth:"65px"}}>Seeking:</b>
                                                            <p class="mb-0">{strCatArr(data.jobTypeName)}</p>
                                                        </small>
                                                </div>
                                            </div>
                                            <div class="grid-body mb-4">
                                                <div class="grid-body-item">
                                                    <div class="grid-body-inn">
                                                        <span>Updated:</span>
                                                        <span>{moment(data.dateUpdated).format("D-MMM-YY")}</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>School:</span>
                                                        <span>{(data.name =='' || data.name ==null) ? '--' : data.name}</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>Email:</span>
                                                        <span>{data.email}</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>Phone:</span>
                                                        <span>{data.phoneno}</span>
                                                    </div>
                                                    <div class="grid-body-inn clamp">
                                                        <span>Category:</span>
                                                        <span class="char150">
                                                        {strCatArr(data.categoryName)}
                                                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-center">
                                                <a href={`/studentProfileView?studentId=`+data.studentId} class="reg-button text-center mx-2">View</a>
                                                <br></br>
                                                <a href={`/editStudent?studentId=`+data.studentId} class="reg-button text-center">Edit</a>
                                            </div>
                                        </div>

                                        ))}


                                    </div>
                                       <div class="paginationContent mt-3">
                                       <Pagination
                                        total={totalItems}
                                        itemsPerPage={ITEMS_PER_PAGE}
                                        currentPage={currentPage}
                                        onPageChange={handlePageChange2}/>

                                       </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="py-3 cd-mobile">
            <div class="container">
                <div class="row">

                    <div class="col-12 ">
                        <div class="white-bg p-5">
                            <div class="row">


                                {/* <!-- right side --> */}
                                <div class="col-lg-8 mb-4">



                                </div>

                                {/* <!-- left side --> */}
                                <div class="col-lg-4 mb-2">
                                    <div class="card p-3 mb-0 ">
                                        <span className="mb-3">
                                        <a onClick={filterContent_full} id="export_studentbtn" class="reg-button text-center mx-0">Export to Excel</a>
                                        </span>
                                        <div class="bg-heading">
                                            <h3 class="mb-0">Filter Profiles</h3>
                                        </div>
                                        <div class="py-3 text-start">
                                            <b class="text-uppercase">Keywords</b>

                                                <input class="form-control me-2 searchKeyInput" type="search" aria-label="Search" placeholder="Search"/>
                                                {/* <button class="border-0 p-0 dir-search" type="submit"><img src="images/search-icon.JPG" alt=""/></button> */}

                                        </div>
                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Categories of Interest</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>
                                                {
                                                    categories.map((data, index) => (
                                                    <li><input class="form-check-input searchContent" type="checkbox" value={data.catId} name="category"/> <label class="form-check-label" for="defaultCheck1"> {data.name}</label></li>
                                                ))}

                                                </ul>
                                            </div>
                                        </div>

                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Type</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>

                                                {
                                                jobTypes.map((data, index) => (
                                                    <li><input class="form-check-input searchContent" type="checkbox" value={data.jobTypeId} name="jobtype" /> <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                ))}


                                                </ul>
                                            </div>
                                        </div>
                                    </div>


                                </div>




                                <div class="col-lg-8 mb-4">

                                    <div class="two-grid-wrap">




                                    {
                                       profileList.map((data, index) => (

                                        <div class="grid-item p-3">
                                            <div class="grid-head">
                                                <div class="grid-img">
                                                    <img src={data.profilePicS3Url?data.profilePicS3Url:"images/user-default.jpg"} class="w-100" alt=""/>
                                                </div>
                                                <div class="grid-title-wrap">
                                                    <h4 class="margin-bottom-0">{data.firstName} {data.lastName}</h4>
                                                    <small class="d-flex">
                                                    <b class="mr-2" style={{minWidth:"65px"}}>Seeking:</b>
                                                            <p class="mb-0">{strCatArr(data.jobTypeName)}</p>
                                                        </small>
                                                </div>
                                            </div>
                                            <div class="grid-body mb-4">
                                                <div class="grid-body-item">
                                                    <div class="grid-body-inn">
                                                        <span>Updated:</span>
                                                        <span>{moment(data.dateUpdated).format("D-MMM-YY")}</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>School:</span>
                                                        <span>{(data.name =='' || data.name ==null) ? '--' : data.name}</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>Email:</span>
                                                        <span>{data.email}</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>Phone:</span>
                                                        <span>{data.phoneno}</span>
                                                    </div>
                                                    <div class="grid-body-inn clamp">
                                                        <span>Category:</span>
                                                        <span class="char150">
                                                        {strCatArr(data.categoryName)}
                                                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="grid-footer">
                                                <a href={`/personalProfileView?profileId=`+data.profileId} class="reg-button">View</a>
                                            </div>
                                        </div>

                                        ))}


                                    </div>
                                       <div class="paginationContent mt-3">
                                       <Pagination
                                        total={totalItems}
                                        itemsPerPage={ITEMS_PER_PAGE}
                                        currentPage={currentPage}
                                        onPageChange={handlePageChange2}/>

                                       </div>

                                </div>





                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="load-wrap">
          <div class="loadingIcon">
            <img src="images/loading.gif"></img>
             </div>
        </section>
    </main>
        </>
    )
};

StudentList.propTypes = {};

StudentList.defaultProps = {};

export default StudentList;
