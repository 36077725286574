import React, { Component, PropTypes } from 'react';

class Carousel extends React.Component
{
      constructor(props)
      {
            super(props);

      }
      componentDidMount()
      {


      }
      render()
      {
        return (

        <section class="prime-banner">
        <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
           <div class="carousel-indicators">
              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
           </div>
           <div class="carousel-inner">
              <div class="carousel-item active">
                 <img src="images/home-hero/hero-1.jpg" class="d-block w-100 banner-img" alt="hero-1"/>
                 <div class="carousel-caption d-none d-md-block">
                    <div class="container">
                       <h1>Looking for a job? <br/> There are local options!</h1>
                       <p>Check out all of the careers that are
                          <br/>available right here in your home town.
                       </p>
                       <a href="/availableJobs" class="carousel-button mt-2">LET’S GO!</a>
                    </div>
                    <div class="text-center avl-but"><a href='/availableJobs' class="btn btn-primary mb-2 reg-button text-uppercase ">Available Jobs</a></div>
                 </div>

              </div>
              <div class="carousel-item">
                 <img src="images/home-hero/hero-2.jpg" class="d-block w-100 banner-img" alt="hero-2"/>
                 <div class="carousel-caption d-none d-md-block">
                    <div class="container">
                       <h1>Recruiting for an <br/> important position?</h1>
                       <p>Create a company profile and
                          <br/>start reviewing local resumes.
                       </p>
                       <a href="/companyRegister" class="carousel-button mt-2">LET’S GO!</a>
                    </div>
                    <div class="text-center avl-but"><a href='/availableJobs' class="btn btn-primary mb-2 reg-button text-uppercase ">Available Jobs</a></div>
                 </div>
              </div>
              <div class="carousel-item">
                 <img src="images/home-hero/hero-3.jpg" class="d-block w-100 banner-img" alt="hero-3"/>
                 <div class="carousel-caption d-none d-md-block">
                    <div class="container">
                       <h1>Your skills are <br/> in high demand.</h1>
                       <p>Post your resume and connect with
                          <br/>local employers who value your experience.
                       </p>
                       <a href="/availableJobs" class="carousel-button mt-2">LET’S GO!</a>
                    </div>
                    <div class="text-center avl-but"><a href='/availableJobs' class="btn btn-primary mb-2 reg-button text-uppercase ">Available Jobs</a></div>
                 </div>
              </div>
              <div class="carousel-item">
                 <img src="images/home-hero/hero-4.jpg" class="d-block w-100 banner-img" alt="hero-4"/>
                 <div class="carousel-caption d-none d-md-block">
                    <div class="container">
                       <h1>Have industry <br/> experience?</h1>
                       <p>Check out the available local
                          <br/>job listings and apply today.
                       </p>
                       <a href="/availableJobs" class="carousel-button mt-2">LET’S GO!</a>
                    </div>
                    <div class="text-center avl-but"><a href='/availableJobs' class="btn btn-primary mb-2 reg-button text-uppercase ">Available Jobs</a></div>
                 </div>
              </div>
              <div class="carousel-item">
                 <img src="images/home-hero/hero-5.png" class="d-block w-100 banner-img" alt="hero-5"/>
                 <div class="carousel-caption d-none d-md-block">
                    <div class="container">
                       <h1>Want to find <br/> a Job Shadow?</h1>
                       <p>Easily find companies
                          <br/>willing to host your exploration!
                       </p>
                       <a href="/educationTraining" class="carousel-button mt-2">LET’S GO!</a>
                    </div>
                    <div class="text-center avl-but"><a href='/availableJobs' class="btn btn-primary mb-2 reg-button text-uppercase ">Available Jobs</a></div>
                 </div>
              </div>
           </div>
           <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev"> <span class="carousel-control-prev-icon" aria-hidden="true"></span>
           <span class="visually-hidden">Previous</span>
           </button>
           <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next"> <span class="carousel-control-next-icon" aria-hidden="true"></span>
           <span class="visually-hidden">Next</span>
           </button>
        </div>
     </section>

     );
      }
}

export default Carousel;