import React,{history,useEffect, useState, useMemo } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import { useHistory,useNavigate  } from "react-router-dom";
import Services   from "../../services/services";
const SubscribeToCareer = () => {
    const [categories, setCategory] = useState([]); 
    const navigate = useNavigate();
    const {
        register,
        formState: { errors },
        handleSubmit
      } = useForm({
        criteriaMode: "all",mode: "onBlur" 
      });

      const {
        register: register1,
        formState: { errors: errors1 },
        handleSubmit: handleSubmit1,
      } = useForm({
        criteriaMode: "all",mode: "onBlur" 
      });

      const {
        register: register2,
        formState: { errors: errors2 },
        handleSubmit: handleSubmit2,
      } = useForm({
        criteriaMode: "all",mode: "onBlur" 
      });

      const submitValue=()=>
      {
       

        var catofExpertiseArray = [];
        $.each($("input[name='category']:checked"), function(){
            catofExpertiseArray.push($(this).val());
        });  
         var phone_val = $("#yourphone2").val();
         //phone_val = "1234567899";
          const requestObject={           
          
            "email_address": $('#email').val(),
            "status":"subscribed",
            "merge_fields":
                {   
                    "FNAME":$('#firstName').val(),
                    "LNAME":$('#lastName').val(),
                },            
            "tags":catofExpertiseArray    
          
          }
       console.log(requestObject);
       var mailchimpAPIkey = "683b53336a0d9d79dad6e9e6e9da46d4";
       var accessTokenIn=sessionStorage.getItem("accToken");
      const requestOptions = {
        method: 'POST',
        headers: { 
                  'Content-Type': 'application/json',
                  //'Content-Type': 'application/javascript',
                  'data-type': 'jsonp',
                 // 'Authorization': "apikey "+mailchimpAPIkey 
                  //'Authorization': accessTokenIn 
                  'Authorization': "Bearer "+accessTokenIn 
                },
        body: JSON.stringify(requestObject)
      };
      
      Services.careerMemberSubscribe(requestOptions).then((res) => 
      {
      
    
        navigate('/thankyou?r=3');
      
      });



    
      }

      const submitValue1=()=>
      {
       
        $('#step1').hide();
        $('#step2').show();
        
      }

      const getIndustryData = () => {
        Services.getIndustryData().then((res) => {    
          console.log("Industry data=" + JSON.stringify(res.data));    
          setCategory(res.data.content);    
        });    
      }

      const submitValue2=()=>
      {
        
        
            var catofExpertiseArray = [];
            $.each($("input[name='category_m']:checked"), function(){
                catofExpertiseArray.push($(this).val());
            });  
            //alert(JSON.stringify(catofExpertiseArray))
            var phone_val = $("#yourphone2").val();
            //phone_val = "1234567899";
            const requestObject={           
            
                "email_address": $('#email_m').val(),
                "status":"subscribed",
                "merge_fields":
                    {   
                        "FNAME":$('#firstName_m').val(),
                        "LNAME":$('#lastName_m').val(),
                    },            
                "tags":catofExpertiseArray    
            
            }
        console.log(requestObject);
        var mailchimpAPIkey = "683b53336a0d9d79dad6e9e6e9da46d4";
        var accessTokenIn=sessionStorage.getItem("accToken");
        const requestOptions = {
            method: 'POST',
            headers: { 
                    'Content-Type': 'application/json',
                    //'Content-Type': 'application/javascript',
                    'data-type': 'jsonp',
                    // 'Authorization': "apikey "+mailchimpAPIkey 
                    //'Authorization': accessTokenIn 
                    'Authorization': "Bearer "+accessTokenIn 
                    },
            body: JSON.stringify(requestObject)
        };
        
        Services.careerMemberSubscribe(requestOptions).then((res) => 
        {
        
        
            navigate('/thankyou?r=3');
        
        });
        
        
        //navigate('/subscribeToCareer');
  
      }
     
      useEffect(() => 
      {
        $('#step1').show();
        $('#step2').hide();
        getIndustryData();
        /*
        var dataObj = {"email_address":"abcxyz@nexasi.com","status":"subscribed","tags":["Agriculture","Construction\/Skilled Trades","Government & Public Safety"],"merge_fields":{"FNAME":"abc","LNAME":"xyz"}};

        $.ajax({
            type: 'post',
            url: 'https://us14.api.mailchimp.com/3.0/lists/00683a9323/members',
            data: dataObj,
            cache: false,
            dataType: 'json',
            contentType: 'application/json; charset=utf-8',
            error: function (err) { alert('Could not connect to the registration server. Please try again later.') },
            success: function (data) {
             console.log(JSON.stringify(data));
             
            }
          })
          */





          
      },[]);
  return(
  <>
     <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav style={{"--bs-breadcrumb-divider": "'>'"}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item headtitle "><a href="#">Subscribe to Career</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>

        {/* <!-- info typography --> */}
        <section class="company-detail-wrap py-5 cd-desktop ">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                    <form onSubmit={handleSubmit(submitValue)}>
                        <div class="white-bg px-5 pt-4 pb-4">
                            <div class="row mt-4 resume-wrap">


                                <div class="col-lg-6 mb-4">
                                    <label for="firstName" class="form-label">First Name<span class="red-text">*</span></label>
                                    <input type="text" class="form-control" id="firstName" placeholder=""   {...register('firstName', { required: true })}/>
                                    {errors.firstName && errors.firstName.type === "required" && <div class="error">First name is required</div>}
                                </div>

                                <div class="col-lg-6 mb-4">
                                    <label for="firstName" class="form-label">Last Name<span class="red-text">*</span></label>
                                    <input type="text" class="form-control" id="lastName" placeholder=""   {...register('lastName', { required: true })}/>
                                    {errors.lastName && errors.lastName.type === "required" && <div class="error">Last name is required</div>}
                                </div>

                                <div class="col-lg-6 mb-4">
                                    <label for="firstName" class="form-label">Email<span class="red-text">*</span></label>
                                    <input type="text" class="form-control" id="email" placeholder=""  {...register('email', { required: true, pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/ })}/>
                                    {errors.email && errors.email.type === "required" && <div class="error">Email is required</div>}
                                    {errors.email && errors.email.type === "pattern" && <div class="error">Please provide valid email.</div> }
                                </div>

                                <div class="col-lg-12 mb-4">
                                    <div class="d-flex"><label for="firstName" class="form-label mr-2 mb-3">Career Industry<span class="red-text">*</span></label></div>

                                    <div>
                                        <div class="category-check-itm">
                                            <ul class="category-check">
                                            {
                                            categories.map((data, index) => (
                                            <li><input class="form-check-input" type="checkbox" name="category" value={data.name} id="defaultCheck1" {...register('category', { required: true })}/>  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>
                                            ))}
                                              
                                            </ul>
                                        </div>
                                        {/* <div class="category-check-itm">
                                            <ul>
                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register('career', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Accounting </label></li>
                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register('career', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Advanced Manufacturing</label></li>
                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register('career', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register('career', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> CNC  </label></li>
                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register('career', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Advertising and PR Services  </label></li>
                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register('career', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Agriculture and Food Processing  </label></li>
                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register('career', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Architectural and Design Services  </label></li>
                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register('career', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Art Athletic Training/Physical/ </label></li>
                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register('career', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Art Athletic Training/Physical/ </label></li>
                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register('career', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Occupational Therapy  </label></li>
                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register('career', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Auto/Diesel </label></li>
                                            </ul>
                                        </div> */}
                                        {errors.career && errors.career.type === "required" && <div class="error">Career Industry is required</div>}
                                    </div>

                                </div>

                                <div class="col-lg-12 mb-4">

                                    <div class="d-flex justify-content-between align-items-end choose">

                                        <div><button type="submit" class="reg-button">Subscribe</button></div>
                                    </div>

                                </div>

                            </div>



                        </div>

                    </form>

                    </div>

                </div>
            </div>
        </section>

        <section class="company-detail-wrap cd-mobile ">
            <div class="white-bg px-5 pt-4 pb-4">

                {/* <!-- step one --> */}
                
                <div class="row" id="step1">
                <form onSubmit={handleSubmit1(submitValue1)}>
                    <div class="text-center">
                        <h1 class="mb-4">
                            <span class=" red-text pb-2 border-bottom ">Step 1:</span>
                        </h1>
                    </div>
                    <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">First name <span class="red-text">*</span></label>
                        <input type="text" class="form-control" id="firstName_m" placeholder=""   {...register1('firstName', { required: true })}/>
                                    {errors1.firstName && errors1.firstName.type === "required" && <div class="error">First name is required</div>}
                    </div>
                    <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">Last name <span class="red-text">*</span></label>
                        <input type="text" class="form-control" id="lastName_m" placeholder=""   {...register1('lastName', { required: true })}/>
                                    {errors1.lastName && errors1.lastName.type === "required" && <div class="error">Last name is required</div>}
                    </div>
                    <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">Email <span class="red-text">*</span></label>
                        <input type="text" class="form-control" id="email_m" placeholder=""  {...register1('email', { required: true, pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/ })}/>
                                    {errors1.email && errors1.email.type === "required" && <div class="error">Email is required</div>}
                                    {errors1.email && errors1.email.type === "pattern" && <div class="error">Please provide valid email.</div> }
                    </div>

                    <div class="mt-4 mb-4">
                        <button type="submit" class="reg-button">Next &gt;&gt;</button>
                    </div>
                    </form>
                </div>

                {/* <!-- step Two --> */}

                <div class="row" id="step2">
                <form onSubmit={handleSubmit2(submitValue2)}>
                    <div class="text-center">
                        <h1 class="mb-4">
                            <span class=" red-text pb-2 border-bottom ">Step 2:</span>
                        </h1>
                    </div>

                    <div class="col-12 mt-3 p-0">
                        <div class="mobile-form-avl p-3">
                            <div class="category-check-itm">
                                <ul>
                                {
                                categories.map((data, index) => (
                                <li><input class="form-check-input" type="checkbox" name="category_m" value={data.name} id="defaultCheck1" {...register('category_m', { required: true })}/>  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>
                                ))}
                                    {/* <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register2('career', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Accounting </label></li>
                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register2('career', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Advanced Manufacturing</label></li>
                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register2('career', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register2('career', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> CNC  </label></li>
                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register2('career', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Advertising and PR Services  </label></li>
                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register2('career', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Agriculture and Food Processing  </label></li>
                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register2('career', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Architectural and Design Services  </label></li>
                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register2('career', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Art Athletic Training/Physical/ </label></li>
                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register2('career', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Art Athletic Training/Physical/ </label></li>
                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register2('career', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Occupational Therapy  </label></li>
                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register2('career', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Auto/Diesel </label></li> */}
                                </ul>
                            </div>
                            
                        </div>
                        {errors2.career && errors2.career.type === "required" && <div class="error">Career Industry is required</div>}
                    </div>
                    <div class="mt-4 mb-4">
                        {/* <a href="#" class="reg-button">Subscribe &gt;&gt;</a> */}
                        <button type="submit" class="reg-button">Subscribe &gt;&gt;</button>
                    </div>
                    </form>
                </div>
            </div>
        </section>

    </main>
  </>
)};

SubscribeToCareer.propTypes = {};

SubscribeToCareer.defaultProps = {};

export default SubscribeToCareer;
