import React,{history,useEffect, useState, useMemo } from "react";
import Services from "../services/services";
import moment from "moment";

const SidebarSchoolAdmin = () => {

    const[path,setPathName]=useState([]);
    const [eventList, setEventList] = useState([]);
    const siteId = 2;
    const chKRoleAr=sessionStorage.getItem("roleAr");
    
    const getEvents2 = () => {
        Services.getEventsbySiteId(siteId).then((res) => {    
          console.log("Industry data=" + JSON.stringify(res.data));    
          if(res){
            //setEventList(res.data);
            var evArr = res.data;
            var evList = [];
            for(var i=0;i<3;i++){
                evList[i]=res.data[i];
            }
            setEventList(evList);
          }    
        });    
    }

    const getEvents = () => {
        Services.getEventsbySiteId(siteId).then((res) => {    
          console.log("Industry data=" + JSON.stringify(res.data));    
          if(res.data){
            //setEventList(res.data);
            var evArr = res.data;
            var evList = [];
            if(res.data.length >0){
                for(var i=0;i<3;i++){
                    evList[i]=res.data[i];
                }
            }
          
            //alert(JSON.stringify(evList));
            setEventList(evList);
          }    
        });    
    }
    useEffect(() => {
        //getEvents();    
        const pathName=window.location.pathname;
        setPathName(pathName);
      }, []);
    

  return(
  <>
    <div class="col-lg-4 mb-4">
                               <div class="card p-3 ">
                                   <div class="bg-heading">
                                       <h3 class="mb-0">Quick Links </h3>
                                    </div>
                                    <div class="mb-5 mt-5 text-center">
                                    <a href="https://ohiomeansjobs.ohio.gov/" target="_blank"><img src="images/OHIO MEANS JOBS.png" alt=""/></a>
                                    </div>
                                    <div class="mb-5 text-center   ">
                                    <a href="https://www.edgefactor.com" target="_blank"><img src="images/EFLogo_Black.png" class="w-100" alt=""/> </a>
                                   </div>
                                  
                               </div>
                               <div class="qlinks">
                                   <ul>
                                    
                                    <li><a href="/editAdminstrationLogin" class={"/editAdminstrationLogin"!=path?'':"active"}>User profile</a></li>
                                    <li><a href="/careerExploreJobs" class={"/careerExploreJobs"!=path?'':"active"}>Explore jobs </a></li>
                                  
                                    
                                    <li><a href="/videoLibrary" class={"/careerExploreJobs"!=path?'':"active"}>Watch videos </a></li>
                                    {/* <li><a href="/sampleCareerPath" class={"/careerPath"!=path?'':"active"}>  Sample Career Paths </a></li> */}
                                    <li><a href="/educationTraining" class={"/educationTraning"!=path?'':"active"}> Education & Training </a></li>
                                  {/*  <li><a href="/ourlibrary" class={"/ourLibraries"!=path?'':"active"}> Local Libraries </a></li> */}
                                  
                                    {/* <li><a href="/RelevantArticles" class={"/articales"!=path?'':"active"}>   Relevant Articles </a></li>
                                    <li><a href="/helplink" class={"/helpfullLinks"!=path?'':"active"}>  Helpful Links </a></li> */}
                                    <li><a href="/events" class={"/events"!=path?'':"active"}>  Events Calendar</a></li>
                                    
                                    

                                    {(() => {
                                    if( chKRoleAr == "schooladmin" ){                    
                                        return (<>  <li><a href="/studentListBySchool" class={"/studentListBySchool"!=path?'':"active"}>  Student List</a></li>
                                        
                          
                                        
                                        </>);
                                    }
                                  })()}   

                                  {(() => {
                                    if(  chKRoleAr == "schoolsuper"){                    
                                        return (<>  <li><a href="/studentList" class={"/studentListstudentList"!=path?'':"active"}>  Student List</a></li></>);
                                    }
                                  })()}   
<li><a href="/studentReportBySchool" class={"/studentReportBySchool"!=path?'':"active"}>  Student Report</a></li>
                                   </ul>
                                  
                               </div>
                           </div>
  </>
)};

SidebarSchoolAdmin.propTypes = {};

SidebarSchoolAdmin.defaultProps = {};

export default SidebarSchoolAdmin;
