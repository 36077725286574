import React,{history,useEffect, useState, useMemo } from "react";
import Quick_links from "../../utility/Quick_links";
import Hiring from "../Jobs/Hiring";
const CompanyDashboard = () => {

  return(
  <>
    <main  class="empr-directory-main">
         <div class="main-bgpatten"></div>
         {/* <!-- info typography --> */}
         <section class="headtitle-wrap">
            <div class="container">
               <div class="row">
                  <div class="col-lg-12">
                     <nav style={{"--bs-breadcrumb-divider": '>'}} aria-label="breadcrumb">
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item headtitle "><a href="#">Company Dashboard </a></li>
                        </ol>
                      </nav>
                  </div>
               </div>
            </div>
         </section>
         {/* <!-- info typography --> */}
         <section class="py-5">
            <div class="container">
               <div class="row">
                  <div class="col-12 ">
                     <div class="white-bg p-5">
                        <div class="row">
                           {/* <!-- left side --> */}
                           <Quick_links/>
                           {/* <div class="col-lg-4 mb-4">
                               <div class="card p-3 ">
                                   <div class="bg-heading">
                                       <h3 class="mb-0">Quick Links </h3>
                                    </div>

                                    <div class="qlinks">
                                       <ul>
                                          
                                           <li><a href="profile-info.html">Profile Info </a></li>
                                           <li><a href="company-logo.html">Company Logo</a></li>
                                           <li><a href="video-upload.html">Upload Video</a></li>
                                          
                                    
                                       </ul>
                                       <div class="p-5 white-bg">
                                           <div class="text-center">
                                               <img src="images/Group 75.png" alt=""/>
                                           </div>
                                          <div class="text-cente">
                                           <h3 class="pb-3 border-bottom text-center">Upcoming Events</h3>
                                          <div class="pb-4 mt-4 border-bottom">
                                           <h4>CAREER FAIR</h4>
                                           <span>September 21, 2021</span>
                                           <p>Wright State University will be hosiint it’s 8th Annual Career Fair on October 21st.</p>
                                          </div>
       
                                          <div class="pb-4 mt-4 border-bottom">
                                           <h4>CAREER FAIR</h4>
                                           <span>September 21, 2021</span>
                                           <p>Wright State University will be hosiint it’s 8th Annual Career Fair on October 21st.</p>
                                          </div>
       
                                          <div class=" mt-4 ">
                                           <h4>CAREER FAIR</h4>
                                           <span>September 21, 2021</span>
                                           <p>Wright State University will be hosiint it’s 8th Annual Career Fair on October 21st.</p>
                                          </div>
                                          </div>
                                       </div>
                                   </div>
                               </div>
                               
                           </div> */}

                           {/* <!-- right side -->/ */}
                           <div class="col-lg-8 mb-4">
                               <h4>Welcome back, Adam!</h4>
                               <p>This is your Hometown Opportunity Dashboard. Here you’ll see all of the most important information about your job or career search, all in one handy location.</p>
                              
                              <div class="card p-3">
                               <div class="announce-wrap">
                                   <div class="orange-ribbon d-flex mb-4">
                                       <img src="images/reminders-icon.png" alt=""/>
                                       <span>REMINDERS & ANNOUNCEMENTS</span>
                                   </div>
                                   <div class="after-orange-ribbon mb-4">
                                      <a href="#"> <h4>ANNOUNCEMENT NUMBER 1</h4></a>
                                       <span>September 29, 2019</span>
                                       <p class="py-2">Wright State University Lake Campus in Celina is proud to announce the starting of the 15th MBA program. This program…</p>
                                   </div>
   
                                   <div class="after-orange-ribbon mb-4">
                                    <a href="#"> <h4>ANNOUNCEMENT NUMBER 1</h4></a>
                                       <span>September 29, 2019</span>
                                       <p class="py-2">Wright State University Lake Campus in Celina is proud to announce the starting of the 15th MBA program. This program…</p>
                                   </div>
                                  </div>
                                
                                  <div class="pagination-wrap text-center mt-3">
                                   <nav aria-label="...">
                                      <ul class="pagination m-auto d-inline-flex">
                                         <li class="page-item disabled">
                                            <span class="page-link"><img src="images/arrow-left.JPG" alt=""/></span>
                                         </li>
                                         <li class="page-item"><a class="page-link" href="#">1</a></li>
                                         <li class="page-item active" aria-current="page">
                                            <span class="page-link">2</span>
                                         </li>
                                         <li class="page-item"><a class="page-link" href="#">3</a></li>
                                         <li class="page-item">
                                            <a class="page-link" href="#"><img src="images/arrow-right.JPG" alt=""/></a>
                                         </li>
                                      </ul>
                                   </nav>
                                </div>
                              </div>
   
   
   
                           </div>
                       </div>
                     </div>
                  
                  </div>
                 
               </div>
            </div>
         </section>
         <section class="white-bg py-5">
            <div class="container">
            <Hiring/>
               {/* <div class="row mb-4">
                  <div class="col-12">
                     <div class="d-flex justify-content-between border-bottom  mb-4 pb-4 flex-lg-row flex-column">
                        <h4 class="headtitle">Top companies hiring in your area</h4>
                        <a href="#" class="text-uppercase">See All</a>
                     </div>
                  </div>
                  
               </div>
               <div class="row">
                  <div class="col-lg-4">
                     <div class="card p-3">
                        <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                        <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                        <div class="text-center stock-wrap">
                           <h3>Midmark Corporation</h3>
                        <span>Versailles, Ohio</span>
                        <a href="#">15 OPEN JOBS</a>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-4">
                     <div class="card p-3">
                        <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                        <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                        <div class="text-center stock-wrap">
                           <h3>Midmark Corporation</h3>
                        <span>Versailles, Ohio</span>
                        <a href="#">15 OPEN JOBS</a>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-4">
                     <div class="card p-3">
                        <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                        <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                        <div class="text-center stock-wrap">
                           <h3>Midmark Corporation</h3>
                        <span>Versailles, Ohio</span>
                        <a href="#">15 OPEN JOBS</a>
                        </div>
                     </div>
                  </div>

                
               </div> */}
            </div>
         </section>
      </main>
  </>
)};

CompanyDashboard.propTypes = {};

CompanyDashboard.defaultProps = {};

export default CompanyDashboard;
