import React,{history,useEffect, useState, useMemo } from "react";
import { useNavigate,useParams,useLocation,useHistory } from "react-router-dom";
import ReactDOM from "react-dom";
//import { useParams } from "react-router";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
//import { useSearchParams } from "react-router-dom";
import TopCompaniesHiring from "../Jobs/top_comp_hiring";
import services from "../../services/services";
import Hiring from "../Jobs/Hiring";
import $, { parseJSON } from 'jquery';


const CompanyDetails = () =>
{
    // const { companyId22 } = useParams();
    //const companyId=24;
    //alert("test");

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const companyId = queryParams.get('companyId');
    const pagenum = queryParams.get('page');

    const companyIdChkLogin=sessionStorage.getItem("logCompanyId");
    const studentIdChkLogin=sessionStorage.getItem("logStudentId");
    const jobSeekerIdChkLogin=sessionStorage.getItem("logProfileId");
    const userIdChkLogin=sessionStorage.getItem("logUserId");

    const [companyProfile, setCompanyProfile] = useState([]);
    const [companyIdVal, setCompanyIdVal] = useState('');
    const [googleMapAddress, setGoogleMapAddress] = useState('');
    const [perksGroupList, setPerksGroupList] = useState([]);
    const [jobsCountPostedByCompany, setJobsCountPostedByCompany] = useState([]);

    function checkFavStatus(companyIdV,userIdV)
    {
      //  console.log("companyId-->"+companyId);
        const requestObject={
            "userId":userIdV,
            "companyId":companyIdV,
            "favFlag":"1"
        }
        console.log("Post Resume request object==="+requestObject);
        var accessTokenIn=sessionStorage.getItem("accToken");
        const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
                    //'Authorization': "Bearer "+accessTokenIn
                    //'Authorization': accessTokenIn
                },
        body: JSON.stringify(requestObject)
        };

        services.checkFavoriteCompoany(requestOptions).then((res) =>
        {

            console.log(JSON.stringify(res));
            if(res){
                if(res.length>0){
                    $("#addToFavoriteBtn").hide();
                }
            }

        //navigate('/thankyou');

        });
    }
    function addToFavorite(companyIdV,userIdV)
    {
      //  console.log("companyId-->"+companyId);
        const requestObject={
            "userId":userIdV,
            "companyId":companyIdV,
            "favFlag":"1"
        }
        console.log("Post Resume request object==="+requestObject);
        var accessTokenIn=sessionStorage.getItem("accToken");
        const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
                    //'Authorization': "Bearer "+accessTokenIn
                    //'Authorization': accessTokenIn
                },
        body: JSON.stringify(requestObject)
        };

        services.addFavoriteCompoany(requestOptions).then((res) =>
        {
            $("#addToFavoriteBtn").hide();
            $("#successMessage").fadeTo(2000, 1000).slideUp(1000, function () {
                $("#successMessage").slideUp(1000);
            });

        //navigate('/thankyou');

        });
    }

    useEffect(() => {
        //alert("test");
        //alert(siv);
        //const [searchParams, setSearchParams] = useSearchParams();
        //var companyId = searchParams.get("companyId");
        //console.log("companyId--->"+companyId);



        $("#successMessage").hide();
        $(".successMessageCls").hide();


        getCompanyProfile();
        getTotalJobsPostedByCompany();

        $( "#addToFavoriteBtn" ).click(function() {
            if(userIdChkLogin != null && userIdChkLogin != "null"){
                addToFavorite(companyId,userIdChkLogin);
              }
        });

        if(userIdChkLogin != ""){
            checkFavStatus(companyId,userIdChkLogin);
        }

       // console.log("companyId--->"+companyId22);
       //alert(companyId);

    }, []);

    // Accepts the array and key
    const groupBy = (array, key) => {
        // Return the end result
        return array.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
            currentValue
        );
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
        }, {}); // empty object is the initial value for result object
    };


    const getCompanyProfile = () => {
        services.getCompanyProfile(companyId).then((res) => {
           // console.log(res);
            setCompanyProfile(res.data);
            setCompanyIdVal(res.data.companyId);
            console.log("40$$$$$$$$$$$$$$$$$$$");
            if(res.data.perksList){
                console.log("42$$$$$$$$$$$$$$$$$$$");
                var perksList = res.data.perksList;
                var perksTarr = [];
                console.log("42$$$$$$$$$$$$$$$$$$$");
                console.log(JSON.stringify(perksList));
                const perksGroupedByPerksCatTitle = groupBy(perksList, 'perksCatTitle');
                console.log(JSON.stringify(perksGroupedByPerksCatTitle));

                setPerksGroupList(perksGroupedByPerksCatTitle);
               /*
                var address = encodeURI(res.data.address1);
                var city = encodeURI(res.data.city);
                 var state = encodeURI(res.data.state);
                var zip = encodeURI(res.data.zip);
               */
                var address = res.data.address1;
                var city = res.data.city;
                 var state = res.data.state;
                var zip = res.data.zip;
                var urlcode = address+'+'+city+'+'+state+'+'+zip;
               var urlcode2 = "https://www.google.com/maps/embed/v1/search?key=AIzaSyAIdz2pY_NwIEnW6T4kxtHIMpMizrVdePg&q="+urlcode;

                setGoogleMapAddress(urlcode2);
                /*
                for(var j=0;j<perksList.length;j++){
                    var pobj ={
                        "perksId": perksList[j].perksId,
                        "perksTitle": perksList[j].perksTitle,
                        "perksImageUrl": perksList[j].perksImageUrl,
                        "perksdescription": perksList[j].perksdescription,
                        "perksCategoryId":perksList[j].perksCategoryId,
                        "createdDate": perksList[j].createdDate,
                        "perksCatTitle":perksList[j].perksCatTitle,
                    }
                   // perksTarr[perksList[j].perksCatTitle].push(pobj);
                   perksTarr[perksList[j].perksCatTitle].push(perksList[j]);
                }
                */
               // console.log("$$$$$$$$$$$$$$$$$$$");
               // console.log(JSON.stringify(perksTarr))
            }

        });
     }


     const getTotalJobsPostedByCompany = () => {
        services.getNoJobInSite(companyId).then((res) => {
            setJobsCountPostedByCompany(res.data);
        });
     }

  return(<>
  <main class="empr-directory-main">
        <div class="main-bgpatten"></div>


        <section class="headtitle-wrap">
                <div class="container">
                <div class="row">
                    <div class="col-lg-12">

                        <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb">
                            <ol class="breadcrumb">

                            {(() => {

                                if( jobSeekerIdChkLogin != null && jobSeekerIdChkLogin != "null"){
                                    return (<><li class="breadcrumb-item headtitle"> <a href="/profileDashboard">Job Seeker Dashboard</a> </li> </>);
                                }

                                if( studentIdChkLogin != null && studentIdChkLogin != "null"){
                                    return (<><li class="breadcrumb-item headtitle"> <a href="/studentDashboard">Student Dashboard</a> </li> </>);
                                }

                           })()}


                            <li class="breadcrumb-item headtitle "><a href="#">Company Details </a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
                </div>
            </section>

        <section class="company-detail-wrap py-5 ">
            <div class="container">
                <div className="row pb-3">
                    <div className="d-flex">
                   <span className="mr-1 rotate-90">
                   <img src="images/mob-arrow.png" alt="" />
                    </span> {/*<a href="#" style={{color:"#fff"}}>Back to Company List</a>*/}
                    <a href={"/availableJobs?page="+pagenum} style={{color:"#fff"}}>Back to Job List</a>
                    </div>


                </div>
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg px-5 pt-4 pb-4">


                            <div class="row cd-desktop">
                                <div class="col-lg-12">
                                    <div class="company-detail-inn">
                                        <div class="company-header">
                                            <div class="logo-org justify-content-start">
{(companyProfile.logoUrl == null || companyProfile.logoUrl == "") ?  <img src="https://snv3-dev-fs.s3.us-east-2.amazonaws.com/HometownMigration/uploads/default-logo.png" alt="" /> : <img src={companyProfile.logoUrl} alt="" /> }
                                               {/* <img src={companyProfile.logoUrl} alt="" /> */}
                                            </div>
                                            <div class="right-info px-3">
                                                <h4>{companyProfile.name}</h4>
                                                {/* <h4>Midmark Corporation</h4> */}
                                                <div class="d-flex mt-3 align-items-center">
                                                   {/* <span><a href="#">{companyProfile.name}</a> </span>
                                                    <span class="mx-3">|</span>*/}
                                                    <span class="mr-2 f18">
                                                        {companyProfile.address1 != '' ? companyProfile.address1 : '' }
                                                        {companyProfile.address1 != '' && companyProfile.city != '' ? ', ' : ''}
                                                        {companyProfile.city != '' ? companyProfile.city : ''}
                                                        {companyProfile.city != '' && companyProfile.state != '' ? ', ' : ''}
                                                        {companyProfile.state != '' ? companyProfile.state : ''}{companyProfile.state != '' && companyProfile.zip != '' ? ', ' : ''}
                                                    {companyProfile.zip != '' ? companyProfile.zip : ''}
                                                    </span>
                                                    <span>
                                                    <a href="#" class="tool" data-bs-toggle="tooltip" data-bs-placement="top" data-tip="This company provides job shadow opportunities">

                                                        {companyProfile.jobShadowConcert == 'Y' ?  <div style={{ width:"55px"}}> <img src="images/badge.png" alt="" class="w-100"/></div> : ''}
                                                        </a>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="stock-wrap justify-content-between align-items-center position-relative">
                                                {/* {companyProfile.jobShadowConcert == 'Y' ?  <div class="js-badge-d"> <img src="images/badge.svg" alt="" class="w-100"/></div> : ''} */}

                                                <a href={`/jobsByCompany?companyId=`+companyProfile.companyId}>{jobsCountPostedByCompany} OPEN JOBS</a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-4 cd-desktop">
                                <div class="col-lg-8">
                                    {(companyProfile.bannerUrl == null || companyProfile.bannerUrl == "") ? <img src=" https://snv3-dev-fs.s3.us-east-2.amazonaws.com/HometownMigration/uploads/default-image.png" class="w-100 h-100 object-fit" alt="" /> :
                                        <img src={companyProfile.bannerUrl} class="w-100 object-fit" alt="" />
                                    }

                                   {/* <img src={companyProfile.bannerUrl} class="w-100" alt="" /> */}
                                </div>
                                <div class="col-lg-4">
                                    <div class="card p-4">
                                        <div class="bg-heading">
                                            <h3 class="mb-0">Quick Info</h3>
                                        </div>
                                        <ul class="quick-info">
                                            <li>
                                                {/* <span><img src={companyProfile.logoUrl==''?"images/map.png":companyProfile.logoUrl} alt="" /></span> */}
                                                <span><img src="images/map.png" alt="" /></span>
                                                <span>
                                        {companyProfile.address1 != '' ? companyProfile.address1 : '' }
                                        {companyProfile.address1 != '' ? ', ' : '' }<br></br>
                                        {companyProfile.city != '' ? companyProfile.city : '' }
                                        {companyProfile.city != '' && companyProfile.state != '' ? ', ' : ''}
                                        {companyProfile.state != '' ? companyProfile.state : '' }
                                        {companyProfile.state != '' && companyProfile.zip != '' ? ', ' : ''}
                                        {companyProfile.zip != '' ? companyProfile.zip : ''}</span>
                                            </li>
                                            <li>
                                                <span><img src="images/phone.png" alt="" /></span>
                                                <span>{companyProfile.phone} </span>
                                            </li>
                                            <li>
                                                <span><img src="images/website.png" alt="" /></span>
                                                <span><a target="_blank" href={companyProfile.webSite?companyProfile.webSite:'#'}>Website </a></span>
                                            </li>
                                            <li>
                                                <span><img src="images/user.png" alt="" /></span>
                                                <span><b>{companyProfile.employeeCount}</b> Local Employees</span>
                                            </li>
                                             <li>
                                             <button class="addFav" id="addToFavoriteBtn">
                                                    <span>

                                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
                                                            <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
                                                            </svg>

                                                    </span>
                                                    <span ><a  >Add to Favorite</a></span>
                                                </button>
                                            </li>
                                        </ul>

                                        <div id="successMessage" class="alert successMessageCls">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <div class="d-flex align-items-center">
                                                    <img src="/images/success-alert.png" alt=""/>
                                                    <strong class="mx-2">Success!</strong> Added to favorite list.
                                                </div>
                                                <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                                            </div>
                                        </div>



                                        {/* <div class="quickinfo-social ">
                                            <a href="#"><img src="images/FACEBOOK 2.png" alt="" /></a>
                                            <a href="#"><img src="images/INSTA 2.png" alt="" /></a>
                                            <a href="#"><img src="images/TWITTER 2.png" alt="" /></a>
                                            <a href="#"><img src="images/LINKEDIN 2.png" alt="" /></a>
                                            <a href="#"><img src="images/YOUTUBE 2.png" alt="" /></a>
                                        </div> */}


                                        <div class="mt-3">
                                            <iframe src={googleMapAddress} allowfullscreen    frameborder='0' class="w-100">
                                        </iframe>

                                            </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row cd-mobile">
                                <div class="col-12">
                                    <div class="company-detail-mobile position-relative">
                                    <div class="js-badge"> <img src="images/badge.png" alt="" class="w-100"/></div>
                                        <div class="text-center mb-3">
                                        <img src={(companyProfile.logoUrl == null || companyProfile.logoUrl == "") ? "https://snv3-dev-fs.s3.us-east-2.amazonaws.com/HometownMigration/uploads/default-logo.png" : companyProfile.logoUrl} class="w-100 max-logo-sz m-auto p-3" alt="" />
                                        </div>

                                        <div class="company-txt-mobile text-center">
                                            <h2>{companyProfile.name}</h2>
                                            <small>
                                            {companyProfile.address1 != '' ? companyProfile.address1 : '' }
                                            {companyProfile.address1 != '' && companyProfile.city != '' ? ', ' : ''}
                                            {companyProfile.city != '' ? companyProfile.city : ''}
                                            {companyProfile.city != '' && companyProfile.state != '' ? ', ' : ''}
                                            {companyProfile.state != '' ? companyProfile.state : ''}

                                            </small>
                                        </div>
                                        <div class="stock-wrap text-center">
                                            <a href={`/jobsByCompany?companyId=`+companyProfile.companyId}>{jobsCountPostedByCompany} OPEN JOBS</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12" style={{"margin-bottom": "40px;"}}>
                                    <div class="card p-4 ">
                                        <div class="bg-heading">
                                            <h3 class="mb-0">Quick Info</h3>
                                        </div>
                                        <ul class="quick-info">
                                            <li>
                                                <span><img src="images/map.png" alt="" /></span>
                                                <span>
                                                {companyProfile.address1 != '' ? companyProfile.address1 : '' }
                                                {companyProfile.address1 != '' ? ', ' : '' }<br></br>
                                                {companyProfile.city != '' ? companyProfile.city : '' }
                                                {companyProfile.city != '' && companyProfile.state != '' ? ', ' : ''}
                                                {companyProfile.state != '' ? companyProfile.state : '' }
                                                {companyProfile.state != '' && companyProfile.zip != '' ? ', ' : ''}
                                                {companyProfile.zip != '' ? companyProfile.zip : ''}</span>
                                                    </li>

                                            <li>
                                                <span><img src="images/phone.png" alt="" /></span>
                                                <span>{companyProfile.phone} </span>
                                            </li>
                                            <li>
                                                <span><img src="images/website.png" alt="" /></span>
                                                <span><a target="_blank" href={companyProfile.webSite?companyProfile.webSite:'#'}>Website </a> </span>
                                            </li>
                                            <li>
                                                <span><img src="images/user.png" alt="" /></span>
                                                <span><b>{companyProfile.employeeCount}</b> Local Employees</span>
                                            </li>
                                            <li>
                                            <button class="addFav" id="addToFavoriteBtn">
                                                    <span>

                                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
                                                            <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
                                                            </svg>

                                                    </span>
                                                    <span ><a  >Add to Favorite</a></span>
                                                </button>
                                            </li>
                                        </ul>

                                        <div id="successMessage" class="alert successMessageCls">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <div class="d-flex align-items-center">
                                                    <img src="/images/success-alert.png" alt=""/>
                                                    <strong class="mx-2">Success!</strong> Added to favorite list.
                                                </div>
                                                <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                                            </div>
                                        </div>
                                        {/* <div class="quickinfo-social ">
                                            <a href="#"><img src="images/FACEBOOK 2.png" alt="" /></a>
                                            <a href="#"><img src="images/INSTA 2.png" alt="" /></a>
                                            <a href="#"><img src="images/TWITTER 2.png" alt="" /></a>
                                            <a href="#"><img src="images/LINKEDIN 2.png" alt="" /></a>
                                            <a href="#"><img src="images/YOUTUBE 2.png" alt="" /></a>
                                        </div> */}

                                            <div class="mt-3">
                                                <iframe src={googleMapAddress} allowfullscreen    frameborder='0' class="w-100">
                                                </iframe>
                                            </div>
                                    </div>
                                </div>


                            </div>




                            <div class="row mb-4">
                                {/* <div class="col-12 company-detrail-cont">
                                    <h4> Be Extraordinary. </h4>
                                    <p>
                                        Take a chance and step outside the box, no matter how big or small the step is, make it a step towards an extraordinary career with Midmark. We want you to reach your full potential because we realize our teammates are our most valuable assets. Wherever
                                        your passions lie, you can find challenging and rewarding work at Midmark.
                                    </p>

                                    <h4>Our Culture is Unique.</h4>
                                    <p>
                                        Because we care® is not just a tagline, but the way we live. We strive to make a difference each day and challenge ourselves to be better than the best. Our teammates chose us because of our reputation for excellence and our work environment in which
                                        our values are part of our culture.
                                    </p>

                                    <h4> WHO WE ARE </h4>
                                    <p>
                                        Midmark Corporation brings efficient patient care to millions of people each day in the human and animal healthcare industries around the world. A leading provider of medical, dental and veterinary healthcare equipment solutions, Midmark is committed
                                        to providing innovative products and services for the healthcare professional, integrating value-added technology into everything it does. With more than 1,600 teammates worldwide, Midmark is dedicated to making
                                        a positive difference in the practice of healthcare. The company offers a complete line of innovative equipment solutions for operatories, instrument processing and central utilities.
                                    </p>

                                    <h4> WHAT WE DO </h4>
                                    <p>
                                        Midmark is redefining the future of the clinical space from the examination room to the procedure room. Its line of Midmark and Ritter products have become the industry standards in medical practices of all sizes, helping improve the patient experience
                                        while improving safety, ergonomics, productivity and profitability for the practice. The Midmark Artizan™ Dental Furniture line and Integra™ Dental Casework offer the most comprehensive cabinetry solutions available
                                        from a dental manufacturer. And, its line of ergonomic dental chairs with patented integrated armrests is recognized for comfort and style. Midmark also offers innovative veterinary exam, treatment and dental equipment
                                        solutions.
                                    </p>

                                    <h4> DISCIPLINES/POSITIONS (NOT ALL INCLUSIVE) </h4>
                                    <p>
                                        Midmark Corporation is currently looking for a number of positions in the following areas: <br></br> Corporate Support <br></br> Engineering <br></br> Global Supply Chain<br></br> Information Technology <br></br> Manufacturing <br></br> Sales
                                        & Marketing <br></br>
                                    </p>

                                    <h4>JOB SKILLS AND CAPABILITIES </h4>
                                    Qualified teammates require good:
                                    <br></br> Teamwork<br></br>Communication skills<br></br> Customer service <br></br>Willingness to learn <br></br> Open to change



                                </div> */}

                                <div class="col-12 company-detrail-cont p-3">

                                    <div dangerouslySetInnerHTML={{ __html: companyProfile.description  }} />
                                </div>


                                {/* <div class="col-12 company-detrail-cont p-3">

                                {Object.entries(perksGroupList).map(([key, value]) => {
                                        return (
                                            <div class="culture-inn">
                                                <div><span class="culture-head">{key}</span></div>
                                                <div class="vh-line"></div>
                                                <div class="culture-item">
                                                 {
                                                    value.map((datap, index) => (
                                                    <div class="d-flex align-items-center">
                                                        <span><img src={datap.perksImageUrl} alt="" /></span>
                                                        <span>{datap.perksTitle}</span>
                                                    </div>
                                                     ))}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div> */}

                            </div>

                            <div class="row mb-5 mt-4 ">
                                <div class="d-flex align-items-center p-2 border position-relative">
                                <div class="js-d-badge"> <img src="images/badge.png" alt="" class="w-100"/></div>
                            <span class="culture-head">This company provides job shadow opportunities.</span>
                            </div>
                            </div>

                            <div class="row mb-5 mt-4 ">
                                <h3 class="border-bottom mb-4 py-3 headtitle ">Perks & Benefits</h3>
                                <div class="culture-wrap">

                                {/* {Object.keys(perksGroupList).map(key => (
                                    <p>

                                    {perksGroupList[key]}
                                    </p>
                                ))}
                                 */}

                                     {Object.entries(perksGroupList).map(([key, value]) => {
                                        return (
                                            <div class="culture-inn">
                                                <div><span class="culture-head">{key}</span></div>
                                                <div class="vh-line"></div>
                                                <div class="culture-item">
                                                 {
                                                    value.map((datap, index) => (
                                                    <div class="d-flex align-items-center">
                                                        <span><img src={datap.perksImageUrl} alt="" /></span>
                                                        <span>{datap.perksTitle}</span>
                                                    </div>
                                                     ))}
                                                </div>
                                            </div>
                                        );
                                    })}

                                   {/* <div class="culture-inn">
                                        <div><span class="culture-head">Company Culture</span></div>
                                        <div class="vh-line"></div>
                                        <div class="culture-item">
                                            <div class="d-flex align-items-center">
                                                <span><img src="images/lunch-plate.png" alt="" /></span>
                                                <span>1-Hour Lunch Breaks</span>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span><img src="images/cofee.png" alt="" /></span>
                                                <span>Break Room Coffee Bar</span>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span><img src="images/dollars.png" alt="" /></span>
                                                <span>Referral Bonuses</span>
                                            </div>

                                        </div>
                                    </div> */}





                                    {/* <div class="culture-inn">
                                        <div><span class="culture-head">Insurance & Wellness </span></div>
                                        <div class="vh-line"></div>
                                        <div class="culture-item">
                                            <div class="d-flex align-items-center">
                                                <span><img src="images/health.png" alt="" /></span>
                                                <span>Health Insurance</span>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span><img src="images/dental.png" alt="" /></span>
                                                <span>Dental Insurance</span>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span><img src="images/vision.png" alt="" /></span>
                                                <span>Vision Insurance</span>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span><img src="images/life-insurance.png" alt="" /></span>
                                                <span>Life Insurance</span>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="culture-inn">
                                        <div><span class="culture-head">Perks & Discounts </span></div>
                                        <div class="vh-line"></div>
                                        <div class="culture-item">
                                            <div class="d-flex align-items-center">
                                                <span><img src="images/discounts.png" alt="" /></span>
                                                <span>Company Restaurant & Golf Course Discounts</span>
                                            </div>


                                        </div>
                                    </div>

                                    <div class="culture-inn">
                                        <div><span class="culture-head">Professional Development </span></div>
                                        <div class="vh-line"></div>
                                        <div class="culture-item">
                                            <div class="d-flex align-items-center">
                                                <span><img src="images/tutions.png" alt="" /></span>
                                                <span>Tuition Reimbursemnt Program</span>
                                            </div>


                                        </div>
                                    </div>

                                    <div class="culture-inn">
                                        <div><span class="culture-head">Retirement & Stock Options  </span></div>
                                        <div class="vh-line"></div>
                                        <div class="culture-item">
                                            <div class="d-flex align-items-center">
                                                <span><img src="images/match.png" alt="" /></span>
                                                <span>401k Match</span>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span><img src="images/stocks.png" alt="" /></span>
                                                <span>Stock Options</span>
                                            </div>


                                        </div>
                                    </div> */}

                                    {/* <div class="culture-inn">
                                        <div><span class="culture-head">Vacation & Time Off</span></div>
                                        <div class="vh-line"></div>
                                        <div class="culture-item">
                                            <div class="d-flex align-items-center">
                                                <span><img src="images/hoiliday.png" alt="" /></span>
                                                <span>2-weeks Vacation</span>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span><img src="images/sick.png" alt="" /></span>
                                                <span>Unlimited Sick Leave</span>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span><img src="images/parantal.png" alt="" /></span>
                                                <span>Parental Leave</span>
                                            </div>

                                        </div>
                                    </div> */}

                                </div>
                            </div>




                            <div class="row mb-4 mt-4 cd-desktop">
                                 {/* <TopCompaniesHiring/> */}
                                 <Hiring/>
                                {/* <div class="col-lg-12">
                                    <div class="d-flex justify-content-between border-bottom  mb-4 pb-4 flex-lg-row flex-column">
                                        <h4 class="headtitle">Top companies hiring in your area</h4>
                                        <span>Total of <b>35</b> Jobs</span>
                                    </div>
                                </div>
                                <div class="oppertunity-card2">
                                    <div class="logo-org">
                                        <img src="images/midmark-logo.JPG" alt="" />
                                    </div>
                                    <div class="right-info">
                                        <div class="d-flex justify-content-between flex-lg-row flex-column">
                                            <h4>PRODUCT AND SERVICES MANAGER</h4>
                                            <div class="date">
                                                <b class="d-flex text-uppercase">
                                            <span>August 19</span>,
                                            <span class="ms-1"> 2021</span>
                                        </b>
                                            </div>
                                        </div>
                                        <div class="d-flex mb-3">
                                            <span><a href="#" class="company-name">Midmark Corporation </a></span>
                                            <span class="mx-3">|</span>
                                            <span> Versailles, Ohio</span>
                                        </div>
                                        <p class="mt-2">The Senior Product and Services Manager researches and develops the market for services to support the organization.…</p>

                                    </div>
                                    <div class="job-seeker">
                                        <b class="text-uppercase mb-2">job-seeker</b>
                                        <div class="job-seek-icon mb-3">
                                            <div> <img src="images/health.png" alt="" /></div>
                                            <div> <img src="images/lunch-plate.png" alt="" /></div>
                                            <div> <img src="images/dental.png" alt="" /></div>
                                            <div> <img src="images/cofee.png" alt="" /></div>
                                            <div> <img src="images/cofee.png" alt="" /></div>
                                            <div><a href="#" class="js-more" data-bs-toggle="modal" data-bs-target="#exampleModal">more </a></div>

                                        </div>
                                        <div class="text-center">
                                            <a href="#" class="reg-button">View JOB</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="oppertunity-card2">
                                    <div class="logo-org">
                                        <img src="images/midmark-logo.JPG" alt="" />
                                    </div>
                                    <div class="right-info">
                                        <div class="d-flex justify-content-between flex-lg-row flex-column">
                                            <h4>PRODUCT AND SERVICES MANAGER</h4>
                                            <div class="date">
                                                <b class="d-flex text-uppercase">
                                            <span>August 19</span>,
                                            <span class="ms-1"> 2021</span>
                                        </b>
                                            </div>
                                        </div>
                                        <div class="d-flex mb-3">
                                            <span><a href="#" class="company-name">Midmark Corporation </a></span>
                                            <span class="mx-3">|</span>
                                            <span> Versailles, Ohio</span>
                                        </div>
                                        <p class="mt-2">The Senior Product and Services Manager researches and develops the market for services to support the organization.…</p>

                                    </div>
                                    <div class="job-seeker">
                                        <b class="text-uppercase mb-2">job-seeker</b>
                                        <div class="job-seek-icon mb-3">
                                            <div> <img src="images/health.png" alt="" /></div>
                                            <div> <img src="images/lunch-plate.png" alt="" /></div>
                                            <div> <img src="images/dental.png" alt="" /></div>
                                            <div> <img src="images/cofee.png" alt="" /></div>
                                            <div> <img src="images/cofee.png" alt="" /></div>
                                            <div><a href="#" class="js-more" data-bs-toggle="modal" data-bs-target="#exampleModal">more </a></div>
                                        </div>
                                        <div class="text-center">
                                            <a href="#" class="reg-button">View JOB</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="oppertunity-card2">
                                    <div class="logo-org">
                                        <img src="images/midmark-logo.JPG" alt="" />
                                    </div>
                                    <div class="right-info">
                                        <div class="d-flex justify-content-between flex-lg-row flex-column">
                                            <h4>PRODUCT AND SERVICES MANAGER</h4>
                                            <div class="date">
                                                <b class="d-flex text-uppercase">
                                            <span>August 19</span>,
                                            <span class="ms-1"> 2021</span>
                                        </b>
                                            </div>
                                        </div>
                                        <div class="d-flex mb-3">
                                            <span><a href="#" class="company-name">Midmark Corporation </a></span>
                                            <span class="mx-3">|</span>
                                            <span> Versailles, Ohio</span>
                                        </div>
                                        <p class="mt-2">The Senior Product and Services Manager researches and develops the market for services to support the organization.…</p>

                                    </div>
                                    <div class="job-seeker">
                                        <b class="text-uppercase mb-2">job-seeker</b>
                                        <div class="job-seek-icon mb-3">
                                            <div> <img src="images/health.png" alt="" /></div>
                                            <div> <img src="images/lunch-plate.png" alt="" /></div>
                                            <div> <img src="images/dental.png" alt="" /></div>
                                            <div> <img src="images/cofee.png" alt="" /></div>
                                            <div> <img src="images/cofee.png" alt="" /></div>
                                            <div><a href="#" class="js-more" data-bs-toggle="modal" data-bs-target="#exampleModal">more </a></div>
                                        </div>
                                        <div class="text-center">
                                            <a href="#" class="reg-button">View JOB</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="pagination-wrap text-center mt-3">
                                    <nav aria-label="...">
                                        <ul class="pagination m-auto d-inline-flex">
                                            <li class="page-item disabled">
                                                <span class="page-link"><img src="images/arrow-left.JPG" alt="" /></span>
                                            </li>
                                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                                            <li class="page-item active" aria-current="page">
                                                <span class="page-link">2</span>
                                            </li>
                                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                                            <li class="page-item"><a class="page-link" href="#">...</a></li>
                                            <li class="page-item">
                                                <a class="page-link" href="#"><img src="images/arrow-right.JPG" alt="" /></a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div> */}
                            </div>



                        </div>

                    </div>
                </div>
                </div>
        </section>



    </main>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">JOB PERKS</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body py-5">
                <div class="job-seek-modal">
                        <div class="d-flex mb-2 align-items-center">
                            <div> <img src="images/health.png" alt=""/></div>
                            <p>Health Insurance</p>
                        </div>

                        <div class="d-flex mb-2 align-items-center">
                            <div> <img src="images/lunch-plate.png" alt=""/></div>
                            <p>1-Hour Lunch Breaks</p>
                        </div>

                        <div class="d-flex mb-2 align-items-center">
                            <div> <img src="images/dental.png" alt=""/></div>
                            <p>Dental Insurance</p>
                        </div>

                        <div class="d-flex mb-2 align-items-center">
                            <div> <img src="images/cofee.png" alt=""/></div>
                            <p>Break Room Coffee Bar</p>
                        </div>

                        <div class="d-flex mb-2 align-items-center">
                            <div> <img src="images/sick.png" alt=""/></div>
                            <p>Unlimited Sick Leave</p>
                        </div>

                        <div class="d-flex mb-2 align-items-center">
                            <div> <img src="images/lunch-plate.png" alt=""/></div>
                            <p>1-Hour Lunch Breaks</p>
                        </div>

                        <div class="d-flex mb-2 align-items-center">
                            <div> <img src="images/life-insurance.png" alt=""/></div>
                            <p>Life Insurance</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
  </>);


}

CompanyDetails.propTypes = {};

CompanyDetails.defaultProps = {};

export default CompanyDetails;