import React,{history,useEffect, useState, useMemo } from "react";
import Recent_Jobs from "../Jobs/Recent_Jobs";
import services from "../../services/services";
import Hiring from "../Jobs/Hiring";
import { useNavigate, useParams, useHistory,Route,Link,Switch } from "react-router-dom";
const EducationTrainingDetails = (props) => {

    const [leisureContent, setLeisureContent] = useState([]);
   
    const [slugVar, setSlugVar] = useState('');
    const urlObj = useParams();
    //const slugv = props.slug;     
    const getPageInfo = (slugTxt) => {
        services.getCMSPageContentBySlug(slugTxt).then((res) => {
            console.log("########################");
            console.log(JSON.stringify(res.data[0]));
            console.log("########################");
            if(res){
                setLeisureContent(res.data[0].content.rendered);
               //setLeisureContent(res.data[0].content.rendered);
              // setLeisureContent(res.data.content[0].content.rendered);
            }
       
        });
       
     }

    useEffect((slugv,match) => {  
       // alert()
       // alert(match); 
      // let done = useParams();    
       //alert(JSON.stringify(done)); 
      //  alert(JSON.stringify(props));
       var slugTxt = urlObj.slug;
       setSlugVar(slugTxt);

        getPageInfo(slugTxt);
  
    }, []);
  return(
  <>
 <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        {/* <!-- info typography --> */}
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav style={{"--bs-breadcrumb-divider": '>'}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item headtitle "><a href="/educationTraining"> Education & Training</a><span style={{color: "#6c757d"}}>&#62;</span></li>
                                <li class="breadcrumb-item active headtitle ">{slugVar}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="py-5">
            <div class="container">
                <div class="row">
                    {/* <!-- right side --> */}
                    <div class="col-lg-8 mb-4">

                        <div class="p-4 white-bg">
                            
                            
                        <div dangerouslySetInnerHTML={{ __html: leisureContent  }} />
                            
                            {/* <div class="grid-item p-3 mb-4">
                                <div class="grid-head-employer">
                                    <div class="grid-img-employer mr-2">
                                        <img src="images/career-logo.JPG" alt=""/>
                                    </div>
                                    <div class="grid-title-wrap">
                                        <h4 class="margin-bottom-0">iTone Aide</h4>
                                        <address>
                                            Ohio Hi Point Career Center <br/>
                                            2280 St Rt 540 <br/>
                                            Bellefontaine, OH, 43311 <br/>
                                           
                                        </address>
                                        <a href="#" class="red-text text-underline"> www.abcd.com </a>
                                        <p> 8765479779</p>
                                    </div>
                                </div>

                            </div>

                            <div class="grid-item p-3 mb-4">
                                <div class="grid-head-employer">
                                    <div class="grid-img-employer mr-2">
                                        <img src="images/Ohio_Hi-Point1.png" alt=""/>
                                    </div>
                                    <div class="grid-title-wrap">
                                        <h4 class="margin-bottom-0">Ohio Hi-Point Career Center</h4>
                                        <address>
                                            Rick Smith, Superintendent <br/>
                                            2280 S.R. 540, Bellefontaine <br/>
                                        </address>
                                        <a href="#" class="red-text text-underline"> www.ohiohipoint.com </a>
                                        <p>(937) 599-3010</p>
                                    </div>
                                </div>
                            </div> */}
                           


                      

                          

                         



                        </div>

                    </div>

                    {/* <!-- left side --> */}
                    <div class="col-lg-4">
                    <Recent_Jobs/>
                        {/* <div class="white-bg mb-4">
                            <img src="/images/ad-1.png" class="w-100" alt=""/>
                        </div>
                        <div class="white-bg mb-4">
                            <img src="/images/ad-2.png" class="w-100" alt=""/>
                        </div> */}
                        {/* <div class="p-4 white-bg mb-4">
                            <h2 class="title-stay-connect">Stay connected. Get the app!</h2>
                            <p>Download the Hometown Opportunity app and stay constanly connected with companies and job oppportunities in your area. Hometown Opportunity is available on the Apple App Store or on Google Play.</p>
                            <img src="images/HTO-app-face.png" class="w-100" alt=""/>

                            <div class="d-flex justify-content-center  mt-3">
                                <img src="images/apple-app-store.png" alt="" style={{width: "130px"}}/>
                                <img src="images/google-play.png" alt="" class="ms-2" style={{maxWidth: "130px"}}/>
                            </div>
                        </div> */}


                    </div>

                </div>
            </div>
        </section>
        <Hiring/>
        {/* <section class="white-bg py-5">
            <div class="container">
                <div class="row mb-4">
                    <div class="col-12">
                        <div class="d-flex justify-content-between border-bottom  mb-4 pb-4 flex-lg-row flex-column">
                            <h4 class="headtitle">Top companies hiring in your area</h4>
                            <a href="#" class="text-uppercase">See All</a>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="card p-3">
                            <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                            <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                            <div class="text-center stock-wrap">
                                <h3>Midmark Corporation</h3>
                                <span>Versailles, Ohio</span>
                                <a href="#">15 OPEN JOBS</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="card p-3">
                            <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                            <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                            <div class="text-center stock-wrap">
                                <h3>Midmark Corporation</h3>
                                <span>Versailles, Ohio</span>
                                <a href="#">15 OPEN JOBS</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="card p-3">
                            <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                            <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                            <div class="text-center stock-wrap">
                                <h3>Midmark Corporation</h3>
                                <span>Versailles, Ohio</span>
                                <a href="#">15 OPEN JOBS</a>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </section> */}
    </main>
  </>
)};

EducationTrainingDetails.propTypes = {};

EducationTrainingDetails.defaultProps = {};

export default EducationTrainingDetails;
