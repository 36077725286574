import React, { history, useEffect, useState, useMemo } from "react";
import { useNavigate, useParams, useLocation, useHistory } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import moment from "moment";
import UsNumberValidate from '../../utility/usNumberValidate';
import Services from "../../services/services";
//import PaginationComponent from "../../utility/Pagination/Pagination";
import Pagination from "../../utility/Pagination/Pagination";
const CareerExploreJobs = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const catInput = queryParams.get('cat');

    const companyIdChkLogin = sessionStorage.getItem("logCompanyId");
    const studentIdChkLogin = sessionStorage.getItem("logStudentId");
    const jobSeekerIdChkLogin = sessionStorage.getItem("logProfileId");
    const userIdChkLogin = sessionStorage.getItem("logUserId");
    const chKRoleAr=sessionStorage.getItem("roleAr");

    const navigate = useNavigate();
    const [categories, setCategory] = useState([]);
    const [profileList, setProfileList] = useState([]);
    const [jobList, setJobList] = useState([]);

    const [jobTypes, setJobType] = useState([]);
    const [itemName, setFieldName] = useState('');
    const ITEMS_PER_PAGE = 10; // Per page show the data pagination
    const Pagesize = 5;
    const siteID = process.env.React_App_SITE_ID;//2;

    const [totalItems, setTotalItems] = useState(0);
    const [educationData, setEducations] = useState([]);
    
    const [totalpages, settotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [locationCounty, setLocationCounty] = useState([]);
    const joinJsxArray2 = (arr, joinWith) => {
        if (!arr || arr.length < 2) { return arr; }

        const out = [arr[0]];
        for (let i = 1; i < arr.length; i += 1) {
            out.push(joinWith, arr[i]);
        }
        return out;
    };

    const joinJsxArray = (arr, joinWith) => {
        let chk = Array.isArray(arr);
        if (chk) {
            let text = arr.join(", ");
            return text;
        } else {
            return "";
        }

    };
    useEffect(() => {

        if (userIdChkLogin == null || userIdChkLogin == "null") {
            //navigate('/login');
            navigate('/Home');
        }

        /*
        if( studentIdChkLogin == null || studentIdChkLogin == "null"){            
            navigate('/Home');
        }
        
        if(jobSeekerIdChkLogin == null || jobSeekerIdChkLogin == "null"){     
            navigate('/Home');
        }
        */

        Services.getLocationCounty().then((res) => {
            console.log("setJobType data=" + JSON.stringify(res.data));
            setLocationCounty(res.data.content);
          });


        getIndustryData();
        getJobType();
        //getLocationCounty();

        setTimeout(() => {
            filterContent(ITEMS_PER_PAGE, '1');
            filterContent_mobile(ITEMS_PER_PAGE, '1');
        }, 1000)

        
        getEdicationInfo(); 
       

        $(document).on("click", ".searchContent", function () {
            filterContent(ITEMS_PER_PAGE, '1');
        });

        $(document).on("change", ".searchKeyInput", function () {
            filterContent(ITEMS_PER_PAGE, '1');
        });

        $(document).on("click", ".searchContent_mobile", function () {
            filterContent_mobile(ITEMS_PER_PAGE, '1');
        });

        $(document).on("change", ".searchKeyInput_mobile", function () {
            filterContent_mobile(ITEMS_PER_PAGE, '1');
        });

        $(".paginationContent").hide();
        setTimeout(() => {
            setIndustryCheck(catInput);
        }, 1000);

    }, []);

    const setIndustryCheck = (catInput) => {
        //
        if (catInput) {
            var companyCatIn = catInput.split(",");
            for (var i = 0; i < companyCatIn.length; i++) {
                //$("input[value='" + val + "']").prop('checked', true);
                console.log("###" + companyCatIn[i]);
                $('input[name="category"][value="' + companyCatIn[i] + '"]').prop("checked", true);
                //$('input[name="category"][value="' + companyCatIn[i] + '"]').trigger("click");
            }
        }

    }

    /*const getCountyNameById = (countyId) => {
        console.log(locationCounty);
        return locationCounty[countyId];
    } */

    function getCountyNameById(countyId){
        if(countyId !='' && countyId != null){
          var temp_seeking = '';
          
              for (var i = 0; i < locationCounty.length; i ++) {
                if (locationCounty[i]['jobLocationCountyId'] == countyId) {
                  temp_seeking = locationCounty[i].name;
                  
                }
            }
          
          return temp_seeking;
        }else{
          return "";
        }
      }

    const getLocationCounty = () => {
        Services.getLocationCounty().then((res) => {
            console.log("getLocationCounty data=" + JSON.stringify(res.data));
            var lc = res.data;
            var myObject = {}
            for (var i = 0; i < lc.length; i++) {
                var dstrVal = lc[i]['name'];
                myObject[lc[i]['jobLocationCountyId']] = dstrVal;
                //lcObject.push(myObject);
            }
            setLocationCounty(myObject);
        });
    }
    const handlePageChange2 = (offset, triggerevent) => {
        // alert(offset);
        filterContent(ITEMS_PER_PAGE, offset);
    };

    const handlePageChange2_mobile = (offset, triggerevent) => {
        // alert(offset);
        filterContent_mobile(ITEMS_PER_PAGE, offset);
    };

    

    const filterContent_mobile = (ITEMS_PER_PAGE, pageNumber) => {
        var categoriesArray = [];

        $.each($("input[name='category_m']:checked"), function () {
            categoriesArray.push($(this).val());
        });

        var jobTypeArray = [];
        $.each($("input[name='jobType_mobile']:checked"), function () {
            jobTypeArray.push($(this).val());
        });


        const requestObject = {
            "siteId": process.env.React_App_SITE_ID,
            "categoriesOfExpertise": categoriesArray,
            "type": jobTypeArray,
            "titleSummary": $(".searchKeyInput_mobile").val(),
            "pageNumber": pageNumber,
            // "pageSize":"3",
            "pageSize": ITEMS_PER_PAGE,
            "sortBy": "updatedOn"
        }
        /*
      const requestObject_new = {
        "jobCategory": categoriesArray,
        "jobLocation": locationArray,
        "jobEducation": educationArray,
        "jobType": jobtypeArray,
        "pageSize": ITEMS_PER_PAGE,
        "pageNumber": pageNumber,
        "sortBy": "updatedOn",
        "sortDir": "asc",
        "siteId":siteID

    }'
    */

        var accessTokenIn = sessionStorage.getItem("accToken");
        console.log(requestObject);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': "Bearer "+accessTokenIn 
                //'Authorization': accessTokenIn 
            },
            body: JSON.stringify(requestObject)
        };

        Services.findFilterJob(requestOptions).then((res) => {
            console.log(JSON.stringify(res));
            if (res) {
                $(".paginationContent").show();
                setTotalItems(res.totalElements);
                settotalPages(Math.ceil(res.totalElements / ITEMS_PER_PAGE));
                setCurrentPage(pageNumber);

                var contAr = res.content;
                //setProfileList(res.content);
                if (res.content) {
                    setJobList(res.content);

                    if (contAr.length > 0) {
                        $(".paginationContent").show();
                    } else {
                        $(".paginationContent").hide();
                    }
                }
            }


        });



    }

    const filterContent = (ITEMS_PER_PAGE, pageNumber) => {
        var categoriesArray = [];

        $.each($("input[name='category']:checked"), function () {
            categoriesArray.push($(this).val());
        });

        var jobTypeArray = [];
        $.each($("input[name='jobType']:checked"), function () {
            jobTypeArray.push($(this).val());
        });

        


        var levelofEduArr = [];
        $.each($("input[name='educationIdArr']:checked"), function () {
            levelofEduArr.push($(this).val());
        });

        const requestObject = {
            "siteId": process.env.React_App_SITE_ID,
            "categoriesOfExpertise": categoriesArray,
            "type": jobTypeArray,
            "levelOfEducation":levelofEduArr,   
            "titleSummary": $(".searchKeyInput").val(),
            "pageNumber": pageNumber,
            // "pageSize":"3",
            "pageSize": ITEMS_PER_PAGE,
            "sortBy": "updatedOn"
        }
        /*
      const requestObject_new = {
        "jobCategory": categoriesArray,
        "jobLocation": locationArray,
        "jobEducation": educationArray,
        "jobType": jobtypeArray,
        "pageSize": ITEMS_PER_PAGE,
        "pageNumber": pageNumber,
        "sortBy": "updatedOn",
        "sortDir": "asc",
        "siteId":siteID

    }'
    */

        var accessTokenIn = sessionStorage.getItem("accToken");
        console.log(requestObject);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': "Bearer "+accessTokenIn 
                //'Authorization': accessTokenIn 
            },
            body: JSON.stringify(requestObject)
        };

        Services.findFilterJob(requestOptions).then((res) => {
            console.log(JSON.stringify(res));
            if (res) {
                $(".paginationContent").show();
                setTotalItems(res.totalElements);
                settotalPages(Math.ceil(res.totalElements / ITEMS_PER_PAGE));
                setCurrentPage(pageNumber);

                var contAr = res.content;
                //setProfileList(res.content);
                if (res.content) {
                    setJobList(res.content);

                    if (contAr.length > 0) {
                        $(".paginationContent").show();
                    } else {
                        $(".paginationContent").hide();
                    }
                }
            }


        });



    }

    const getIndustryData = () => {
        Services.getIndustryData().then((res) => {
            console.log("Industry data=" + JSON.stringify(res.data));
            setCategory(res.data.content);
        });
    }

    const getJobType = () => {
        Services.getJobType().then((res) => {
            console.log("setJobType data=" + JSON.stringify(res.data));
            setJobType(res.data.content);
        });
    }


    const getEdicationInfo = () => {
        Services.getEducation().then((res) => {
            //console.log("getEducation data=" + JSON.stringify(res.data));
            setEducations(res.data.content);
        });
    }

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all", mode: "onBlur"
    });

    const submitValue = () => {

    }

    return (
        <>
            <main class="empr-directory-main">
                <div class="main-bgpatten"></div>
                {/* <!-- info typography --> */}
                <section class="headtitle-wrap">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <nav style={{ "--bs-breadcrumb-divider": "'>';" }} aria-label="breadcrumb">
                                    <ol class="breadcrumb">




                                        {(() => {

                                            if (jobSeekerIdChkLogin != null && jobSeekerIdChkLogin != "null") {
                                                return (<><li class="breadcrumb-item headtitle"> <a href="/profileDashboard">Job Seeker Dashboard</a> </li> </>);
                                            }

                                            if (studentIdChkLogin != null && studentIdChkLogin != "null") {
                                                return (<><li class="breadcrumb-item headtitle"> <a href="/studentDashboard">Student Dashboard</a> </li> </>);
                                            }

                                            if( chKRoleAr == "schooladmin" || chKRoleAr == "schoolsuper"){  
                                                return (<><li class="breadcrumb-item headtitle"> <a href="/schoolAdminDashboard">School Admin</a> </li> </>);
                                            }

                                        })()}


                                        <li class="breadcrumb-item headtitle "><a href="#">Careers</a></li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- info typography --> */}
                <section class="py-5 cd-desktop">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 ">
                                <div class="white-bg p-5">
                                    <div class="row">
                                        {/* <!-- left side --> */}
                                        <div class="col-lg-4 mb-4">
                                            <div class="card p-3 mb-3 ">
                                                <div class="bg-heading">
                                                    <h3 class="mb-0">Filter Jobs</h3>
                                                </div>
                                                <div class="py-3 text-start">
                                                    <b class="text-uppercase">Key words</b>

                                                    <input class="form-control me-2 searchKeyInput" type="search" aria-label="Search" placeholder="" />
                                                    {/* <button class="border-0 p-0 dir-search" type="submit"><img src="images/search-icon.JPG" alt="" /></button> */}

                                                </div>
                                                <div class="mb-4">
                                                    <b class="text-uppercase mb-3 w-100">Categories of Interest</b>
                                                    <div class="category-check-itm mt-2">
                                                        <ul>
                                                            {
                                                                categories.map((data, index) => (


                                                                    <li><input class="form-check-input searchContent" type="checkbox" name="category" value={data.catId} id="category" />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                                ))}
                                                            {/* <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Accounting </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Advanced Manufacturing</label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> CNC  </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Advertising and PR Services  </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Agriculture and Food Processing  </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Architectural and Design Services  </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Art Athletic Training/Physical/ </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Art Athletic Training/Physical/ </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Occupational Therapy  </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Auto/Diesel </label></li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="mb-4">
                                                    <b class="text-uppercase mb-3 w-100">Type</b>
                                                    <div class="category-check-itm mt-2">
                                                        <ul>

                                                            {
                                                                jobTypes.map((data, index) => (


                                                                    <li><input class="form-check-input searchContent" type="checkbox" name="jobType" value={data.jobTypeId} id="category" />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                                ))}
                                                            {/* <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Community Service </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1">  Full Time</label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1">  Internship/Coop</label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1">  Job Shadow</label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1">  Part Time</label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1">  Summer/Seasonal</label></li> */}
                                                        </ul>
                                                    </div>
                                                </div>



                                                <div class="mb-4">
                                                    <b class="text-uppercase mb-3 w-100">Education</b>
                                                    <div class="category-check-itm mt-2">
                                                        <ul>

                                                            {
                                                                educationData.map((data, index) => (
                                                                    <li><input class="form-check-input searchContent" type="checkbox" name="educationIdArr" value={data.educationId} id="educationId" />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                                 ))}
                                                           
                                                        </ul>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        {/* <!-- right side --> */}
                                        <div class="col-lg-8">

                                            <div class="two-grid-wrap">
                                                {
                                                    jobList.map((data, index) => (
                                                        <>
                                                            <div class="grid-item p-3">
                                                                <div class="grid-head-career">
                                                                    <div class="grid-img-company">
                                                                        <img src={data.logo_url} class="w-100" alt="" />
                                                                    </div>
                                                                    <div class="grid-title-wrap">
                                                                        <h4 class="margin-bottom-0">{data.title}</h4>
                                                                        <small class="d-flex">
                                                                        <span class="mr-2" style={{minWidth:"25px"}}>By:</span>
                                                                            <span><a href={`/companyDetails?companyId=` + data.companyId}>{data.name}</a></span>
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                                <div class="grid-body mb-4">
                                                                    <div class="grid-body-item">
                                                                        <div class="grid-body-inn">
                                                                            <span>Posted:</span>
                                                                            <span>{moment(data.createdDate).format("MMMM D, YYYY")}</span>
                                                                        </div>
                                                                        <div class="grid-body-inn">
                                                                            <span>Website:</span>
                                                                            <span><a target="_blank" href={data.website} class="red-text text-underline">View Website</a></span>
                                                                        </div>
                                                                        <div class="grid-body-inn">
                                                                            <span>Type:</span>
                                                                            <span>{data.strJobType}</span>
                                                                        </div>
                                                                        <div class="grid-body-inn clamp">
                                                                            <span>Industry:</span>
                                                                            {/* <span>Non-Profit, Healthcare/Medical</span> */}

                                                                            {joinJsxArray(data.jobCategoryName, ', ')}




                                                                        </div>
                                                                        <div class="grid-body-inn">
                                                                            <span>County:</span>
                                                                            <span>{getCountyNameById(data.countyId)}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="grid-footer">
                                                                    <a href={`/jobsummary?jobId=` + data.jobId} class="reg-button">View</a>
                                                                </div>
                                                            </div>

                                                        </>
                                                    ))
                                                }
                                            </div>
                                            <div class="pagination-wrap text-center mt-5 paginationContent">
                                                <Pagination
                                                    total={totalItems}
                                                    itemsPerPage={ITEMS_PER_PAGE}
                                                    currentPage={currentPage}
                                                    onPageChange={handlePageChange2} />
                                                {/* <Pagination items={this.state.exampleItems} onChangePage={this.onChangePage} /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="py-3 cd-mobile">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 ">
                                <div class="white-bg p-5">
                                    <div class="row">
                                        {/* <!-- right side --> */}
                                        <div class="col-lg-8 mb-4">
                                            <div class="border-top border-bottom py-2 d-flex justify-content-end mb-4">
                                                <span class="float-end ">
                                                    24 Profiles - Page 1 of 2
                                                </span>
                                            </div>
                                            <div class="two-grid-wrap">
                                                {
                                                    jobList.map((data, index) => (
                                                        <>
                                                            <div class="grid-item p-3">
                                                                <div class="grid-head-career">
                                                                    <div class="grid-img-company">
                                                                        <img src={data.logo_url} class="w-100" alt="" />
                                                                    </div>
                                                                    <div class="grid-title-wrap">
                                                                        <h4 class="margin-bottom-0">{data.title}</h4>
                                                                        <small class="d-flex">
                                                                            <span class="mr-2" style={{minWidth:"25px"}}>By:</span>
                                                                            <span><a href={`/companyDetails?companyId=` + data.companyId}>{data.name}</a></span>
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                                <div class="grid-body mb-4">
                                                                    <div class="grid-body-item">
                                                                        <div class="grid-body-inn">
                                                                            <span>Posted:</span>
                                                                            <span>
                                                                                {moment(data.createdDate).format("MMMM D, YYYY")}
                                                                            </span>
                                                                        </div>
                                                                        <div class="grid-body-inn">
                                                                            <span>Website:</span>
                                                                            <span><a href={data.website} class="red-text text-underline">View Website</a></span>
                                                                        </div>
                                                                        <div class="grid-body-inn">
                                                                            <span>Type:</span>
                                                                            <span>{data.strJobType}</span>
                                                                        </div>
                                                                        <div class="grid-body-inn">
                                                                            <span>Industry:</span>
                                                                            <span> {joinJsxArray(data.jobCategoryName, ', ')}</span>
                                                                        </div>
                                                                        <div class="grid-body-inn">
                                                                            <span>County:</span>
                                                                            <span>{getCountyNameById(data.countyId)}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="grid-footer">
                                                                    <a href={`/jobsummary?jobId=` + data.jobId} class="reg-button">View</a>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))
                                                }


                                            </div>
                                            <div class="pagination-wrap text-center mt-5 paginationContent">
                                                <Pagination
                                                    total={totalItems}
                                                    itemsPerPage={ITEMS_PER_PAGE}
                                                    currentPage={currentPage}
                                                    onPageChange={handlePageChange2_mobile} />
                                                {/* <Pagination items={this.state.exampleItems} onChangePage={this.onChangePage} /> */}
                                            </div>
                                        </div>
                                        {/* <!-- left side --> */}
                                        <div class="col-lg-4 mb-4">
                                            <div class="card p-3 mb-3 ">
                                                <div class="bg-heading">
                                                    <h3 class="mb-0">Filter Profiles</h3>
                                                </div>
                                                <div class="py-3 text-start">
                                                    <b class="text-uppercase">Key words</b>


                                                    <input class="form-control me-2 searchKeyInput_mobile" type="search" aria-label="Search" placeholder="" />
                                                    {/* <button class="border-0 p-0 dir-search" type="submit"><img src="images/search-icon.JPG" alt="" /></button> */}

                                                </div>
                                                <div class="mb-4">
                                                    <b class="text-uppercase mb-3 w-100">Categories of Interest</b>
                                                    <div class="category-check-itm mt-2">
                                                        <ul>
                                                            {
                                                                categories.map((data, index) => (
                                                                    <li><input class="form-check-input searchContent_mobile" type="checkbox" name="category_m" value={data.catId} id="category" />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>
                                                                ))}
                                                            {/* <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1"> Accounting </label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1"> Advanced Manufacturing</label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1"> CNC  </label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1"> Advertising and PR Services  </label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1"> Agriculture and Food Processing  </label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1"> Architectural and Design Services  </label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1"> Art Athletic Training/Physical/ </label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1"> Art Athletic Training/Physical/ </label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1"> Occupational Therapy  </label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1"> Auto/Diesel </label></li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="mb-4">
                                                    <b class="text-uppercase mb-3 w-100">Type</b>
                                                    <div class="category-check-itm mt-2">
                                                        <ul>
                                                            {
                                                                jobTypes.map((data, index) => (
                                                                    <li><input class="form-check-input searchContent_mobile" type="checkbox" name="jobType_mobile" value={data.jobTypeId} id="category" />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>
                                                                ))}
                                                            {/* <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1"> Community Service </label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1">  Full Time</label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1">  Internship/Coop</label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1">  Job Shadow</label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1">  Part Time</label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1">  Summer/Seasonal</label></li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
};

CareerExploreJobs.propTypes = {};

CareerExploreJobs.defaultProps = {};

export default CareerExploreJobs;