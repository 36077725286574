import React,{history,useEffect, useState, useMemo } from "react";
import Quick_links from "../utility/Quick_links";
import Hiring from "./Jobs/Hiring";
import $, { parseJSON } from 'jquery';
import Services from "../services/services";
import { useNavigate,useParams } from "react-router-dom";
const EmailNotification = () => {
    const navigate = useNavigate();
    const [categories, setCategory] = useState([]);
    const companyId=sessionStorage.getItem("logCompanyId");
    const emailAddress=sessionStorage.getItem("logEmail");

    useEffect(() => {
      if(companyId == null || companyId == "null"){            
        //navigate('/login');
        navigate('/Home');
      }

      getIndustryData();
      getSubscriptionTagsList();
      $("#successMessageSub").hide();
      

      $( "#subscribeBtn" ).click(function() {
        subscribeAction();
      });
  
    }, []);
  
    
    const getSubscriptionTagsList = () => {  
        

        const requestObject= {
                                "listId": "af04cd2b2b",
                                //"emailAddress": "testemail1employer@nexasi.com"
                                "emailAddress": "testemail1employer@nexasi.com"
                                
                             };
       console.log(requestObject);
       var accessTokenIn=sessionStorage.getItem("accToken");
      const requestOptions = {
        method: 'POST',
        headers: { 
                  'Content-Type': 'application/json',
                  //'Content-Type': 'application/javascript',
                  'Authorization': "Bearer "+accessTokenIn, 
                  //'Authorization': accessTokenIn, 
                  'data-type': 'jsonp',
                 
                },
        body: JSON.stringify(requestObject)
      };
      
      Services.getProfileMemberSubscribeTags(requestOptions).then((res) => 
      {
        var categoriesArray = [];
        //console.log("-->"+JSON.stringify(res));
        if(res){
            var tagsAr = res.tags;
            for(var i=0; i<tagsAr.length; i++){
                console.log(tagsAr[i].name);
                categoriesArray.push(tagsAr[i].name);
                var catName = tagsAr[i].name;
                $('input[name="category"][value="' + catName + '"]').prop("checked", true);
            }
        }
        //navigate('/thankyou');
      
      });


      }


    const getIndustryData = () => {  
      Services.getIndustryData().then((res) => {  
        console.log("Industry data=" + JSON.stringify(res.data));  
        setCategory(res.data.content);  
      });  
    }

    const subscribeAction=()=>
      {
      
       
        var catofExpertiseArrayUnsub = [];

        /*
        for(var i=0;i<categories.length;i++){
          var tn = {};
          tn.name = "Profile - "+categories[i].name;
          
          tn.status = "inactive";  
          catofExpertiseArrayUnsub.push(tn);
        }
        */

        $.each($("input[name='category']"), function(){
          var tn = {};
          tn.name = $(this).val();
          tn.status = "inactive";  
          //catofExpertiseArray.push($(this).val());
          catofExpertiseArrayUnsub.push(tn);
        });  


        console.log(JSON.stringify(categories));
        console.log(JSON.stringify(catofExpertiseArrayUnsub));
        console.log("#########86$$");
        const requestObjectUnsub={ 
          
            "email_address": "testemail1employer@nexasi.com",
          
          "tags":catofExpertiseArrayUnsub    
        
        }
     
    var accessTokenIn=sessionStorage.getItem("accToken"); 
    const requestOptionsUnsub = {
      method: 'POST',
      headers: { 
                'Content-Type': 'application/json',               
                'data-type': 'jsonp',
                'Authorization': "Bearer "+accessTokenIn                
              },
      body: JSON.stringify(requestObjectUnsub)
    };
    
    Services.profileMemberSubscribeTags(requestOptionsUnsub).then((res) => 
    {   
        //navigate('/thankyou');    
        ///#################################################


        var catofExpertiseArray = [];
        $.each($("input[name='category']:checked"), function(){
          var tn = {};
          tn.name = $(this).val();
          tn.status = "active";  
          //catofExpertiseArray.push($(this).val());
          catofExpertiseArray.push(tn);
        });  
        
         //phone_val = "1234567899";
          const requestObject={        
          
            
            "email_address": "testemail1employer@nexasi.com",
            
            "tags":catofExpertiseArray    
          
          }
       console.log(requestObject);
       var mailchimpAPIkey = "ff090a200b0502d7853365f13897bc77";
       var accessTokenIn=sessionStorage.getItem("accToken");
      const requestOptions = {
        method: 'POST',
        headers: { 
                  'Content-Type': 'application/json',
                  //'Content-Type': 'application/javascript',
                  'data-type': 'jsonp',
                  'Authorization': "Bearer "+accessTokenIn 
                  //'Authorization': accessTokenIn 
                  //'Authorization': "apikey "+mailchimpAPIkey 
                },
        body: JSON.stringify(requestObject)
      };
      
      Services.profileMemberSubscribeTags(requestOptions).then((res) => 
      {
      
        $("#successMessageSub").fadeTo(2000, 500).slideUp(500, function () {
          $("#successMessageSub").slideUp(500);
        });
        //navigate('/thankyou');
      
      });





        ///###################################################
    });



       



    
      }

  return(
  <>
 <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        {/* <!-- info typography -->  */}
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                            <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item headtitle "><a href="/companyJobs">Company Dashboard</a> </li>
                                <li class="breadcrumb-item headtitle "><a href="#">Subscribe to Resumes and Profiles</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-5">
                            <div class="row">
                                {/* <!-- left side --> */}
                               <Quick_links/>

                                {/* <!-- right side --> */}
                                <div class="col-lg-8 mb-4">

                                    <div class="p-3 border mb-3">
                                        This page shows your current subscriptions. If you would like to unsubscribe to any category, please use the unsubscribe link in the email and it will be updated on this page. Unsubsriptions get updated within 24-48 hours.
                                    </div>

                                    <b>Emails are sent to: {emailAddress}</b>

                                    <div class="col-lg-12 my-3">
                                        <div class="d-flex"><label for="firstName" class="form-label mr-2 mb-3">PROFILE INDUSTRIES <span class="red-text">*</span></label></div>

                                        <div >
                                            <div class="category-check-itm">
                                                <ul class="category-check">
                                                {
                                categories.map((data, index) => (


                                  <li><input class="form-check-input" type="checkbox" name="category" value={"Profile-"+data.name} id="defaultCheck1" />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                  ))}
                                                    {/* <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Accounting </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Advanced Manufacturing</label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> CNC  </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Advertising and PR Services  </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Agriculture and Food Processing  </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Architectural and Design Services  </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Art Athletic Training/Physical/ </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Art Athletic Training/Physical/ </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Occupational Therapy  </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Auto/Diesel </label></li> */}
                                                </ul>
                                            </div>
                                            {/* <div class="category-check-itm">
                                                <ul>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Accounting </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Advanced Manufacturing</label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> CNC  </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Advertising and PR Services  </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Agriculture and Food Processing  </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Architectural and Design Services  </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Art Athletic Training/Physical/ </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Art Athletic Training/Physical/ </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Occupational Therapy  </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Auto/Diesel </label></li>
                                                </ul>
                                            </div> */}

                                        </div>

                                        <div class="d-flex justify-content-between align-items-end choose mt-3">
                                            <div><a id="subscribeBtn" class="reg-button">Subscribe</a></div>
                                        </div>
                                            <br></br>
                                        <div id="successMessageSub" class="alert alert-success" role="alert">
                                           Subscription details updated</div>

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </section>
       {/* <Hiring/> */}
    </main>
  </>
)};

EmailNotification.propTypes = {};

EmailNotification.defaultProps = {};

export default EmailNotification;
