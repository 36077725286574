import React,{history,useEffect, useState, useMemo } from "react";
import { useNavigate,useParams,useLocation,useHistory } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import { useKeycloak } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';
import services from "../../services/services";

import { ReactKeycloakProvider } from '@react-keycloak/web';
import jwt from 'jwt-decode' // import dependency
const Login = () => 
{
   const navigate = useNavigate();
   //const { keycloak } = useKeycloak();

   const location = useLocation();
   const queryParams = new URLSearchParams(location.search);
   const logStatus = queryParams.get('status');

   const keycloak = new Keycloak({
     
      //url:"http://keycloak-dotstow-dev-alb-1135353619.us-east-2.elb.amazonaws.com/auth",
      //realm: "SNV3",
      //clientId: "snv3_ui"

      url:process.env.React_App_KEYCLOAK_URL,
      realm: process.env.React_App_REALM,
      clientId: process.env.React_App_KEYCLOAK_CLIENT_ID
    
    })

   const [itemName, setFieldName] = useState('');
   const {
     register,
     formState: { errors },
     handleSubmit
   } = useForm({
     criteriaMode: "all",mode: "onBlur" 
   });

   function checkArray(needle, haystack) {
      var length = haystack.length;
      for(var i = 0; i < length; i++) {
          if(haystack[i] == needle) return true;
      }
      return false;
  }

   function setToken(token) {
     // sessionStorage.setItem("accToken",token);
      var logObj = jwt(keycloak.token); 
     var logObjStr = JSON.stringify(logObj);
      //sessionStorage.setItem("logObj",logObjStr); 
      var lobObjVal = eval(logObj);
      
      var roleAr = lobObjVal.resource_access.snv3_app.roles;
      var logEmail = lobObjVal.email;
      //sessionStorage.setItem("roleAr",roleAr); 
      //sessionStorage.setItem("logEmail",lobObjVal.email); 
      

      const requestObject=
      {         
          "siteId": process.env.React_App_SITE_ID,
          "email": logEmail   
      }

    
      var accessTokenIn=sessionStorage.getItem("accToken");
      const requestOptions = {
                                 method: 'POST',
                                 headers: { 'Content-Type': 'application/json',
                                          //'Authorization': "Bearer "+accessTokenIn 
                                          //'Authorization': accessTokenIn 
                                       },
                                 body: JSON.stringify(requestObject)
                              };
      services.getUserInfoByEmail(requestOptions).then((res) => 
      {
         //alert(JSON.stringify(res));
         //console.log(res);
         if(res.data){
            var temp = res.data;
            if(temp.length > 0){

               sessionStorage.setItem("accToken",token);
               var logObjStr = JSON.stringify(logObj);
               sessionStorage.setItem("logObj",logObjStr); 

               sessionStorage.setItem("roleAr",roleAr); 
               sessionStorage.setItem("logEmail",lobObjVal.email); 

               sessionStorage.setItem("logCompanyId",res.data[0].company_Id); 
               sessionStorage.setItem("logStudentId",res.data[0].student_Id); 
               sessionStorage.setItem("logProfileId",res.data[0].profile_Id); 
               sessionStorage.setItem("logUserId",res.data[0].user_Id);


               if(checkArray("student",roleAr)){
                  //navigate('/studentDashboard');
                  window.location.href=('/studentDashboard');
               }else if(checkArray("company",roleAr) || checkArray("companyuser",roleAr)){
                  //navigate('/companyJobs');
                  window.location.href=('/companyJobs');
               }else if(checkArray("jobseeker",roleAr)){
                  //navigate('/profileDashboard');
                  window.location.href=('/profileDashboard');
               }

            }else{
               keycloak.logout();
            }
             
         }
         

        


      });

     
      
     // navigate('/companyUsers');
    }

   const handleOnEvent = async (event, error) =>
  {
   
   if (event === 'onReady') 
   {
      if (keycloak.authenticated) {
         //let response = await moviesApi.getUserExtrasMe(keycloak.token)
         if(keycloak.token!="")
         {
            setToken(keycloak.token);
          /*
           sessionStorage.setItem("accToken",keycloak.token);
           var logObj = jwt(keycloak.token); 
           logObj = JSON.stringify(logObj);
           sessionStorage.setItem("logObj",logObj); 
           navigate('/companyUsers');
          */
        
         }
       
       }
   }
   if (event === 'onAuthSuccess') 
    {
    
      if (keycloak.authenticated) {
        //let response = await moviesApi.getUserExtrasMe(keycloak.token)
        if(keycloak.token!="")
        {
         setToken(keycloak.token);
         /*
          sessionStorage.setItem("accToken",keycloak.token);
          var logObj = jwt(keycloak.token); 
          logObj = JSON.stringify(logObj);
          sessionStorage.setItem("logObj",logObj);
          navigate('/companyUsers');
          */
       
          //$('#loginCall').trigger('click');
        }
      
      }else{
        //window.location.href="/login";
      }
    }
  }
   const submitValue=()=>
   {
     //alert("test");
     //keycloak.login();
    // alert("keaylock-->"+keycloak.authenticated);
     if (keycloak.authenticated) {
        //alert("39");
      //let response = await moviesApi.getUserExtrasMe(keycloak.token)
      if(keycloak.token!="")
      {
        //alert("40");
        setToken(keycloak.token);
        /*
        sessionStorage.setItem("accToken",keycloak.token);
        navigate('/companyUsers');
         */
        //$('#loginCall').trigger('click');
      }
      // if (response.status === 404) {
      //   const userExtra = { avatar: keycloak.tokenParsed.preferred_username }
      //   response = await moviesApi.saveUserExtrasMe(keycloak.token, userExtra)
      //   console.log('UserExtra created for ' + keycloak.tokenParsed.preferred_username)
      // }
      // keycloak['avatar'] = response.data.avatar
    }else{
      keycloak.login();
      //sessionStorage.setItem("accToken",keycloak.token);
      //navigate('/companyUsers');
      //window.location.href="/login";
    }



   }


   const checkLogin = () => {
     // alert("keaylock-->"+keycloak.token);
      //alert("68");
      if (keycloak.authenticated) {
         //let response = await moviesApi.getUserExtrasMe(keycloak.token)
        // alert("71");
         if(keycloak.token!="")
         {
            //alert("73")
            setToken(keycloak.token);
            /*
           sessionStorage.setItem("accToken",keycloak.token);
           var logObj = jwt(keycloak.token); 
          logObj = JSON.stringify(logObj);
          sessionStorage.setItem("logObj",logObj);
          */

           //alert("test");
           //navigate('/companyUsers');
        
           
         }
        
       }

      /*
      var actoken = sessionStorage.getItem("accToken");
      console.log(actoken);
      if (keycloak.authenticated) {
        
         if(keycloak.token!="")
         {
            navigate('/companyUsers');
         }
      }
      */
    }


   useEffect(() => {        
      navigate('/Home');
      //checkLogin(); 
      /*
      var userIdChk=sessionStorage.getItem("logUserId");
      if(logStatus == "0" && userIdChk!=""){
         //alert(logStatus)
         sessionStorage.clear();
         keycloak.logout();
         //navigate('/login');
         //return;

      }
      */

  }, []);
  
  return(
  <>
    {/* <ReactKeycloakProvider
      authClient={keycloak}
      onEvent={(event, error) => handleOnEvent(event, error)}
     
      
    > */}
<main  class="empr-directory-main">
         {/* <!-- <div class="main-bgpatten"></div> --> */}
      
         {/* <!-- info typography --> */}
         <section class="login-wrap py-4 ">
            <div class="container">
               <div class="row">
                  <div class="col-lg-5 col-md-10 col-sm-12 m-auto">
                  <form onSubmit={handleSubmit(submitValue)}>
                     <div class="white-bg px-4 pt-2 pb-2">

                        <div class="row mt-4 resume-wrap">
                           {/* <div class="col-lg-12 mb-3">
                              <label for="firstName" class="form-label">Emai Address </label>
                              <input type="text" class="form-control" id="firstName" onChange={e => setFieldName(e.target.value)} {...register("Email", {
                                    required: "Email is required.",
                                   
                                    pattern: {
                                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                      message: "invalid email address"
                                    }
                                    })}/>
                                 <ErrorMessage
                                    errors={errors}
                                    name="Email"
                                    class="invalid-feedback"
                                    render={({ messages }) => {
                                    console.log("messages", messages);
                                    return messages
                                        ? Object.entries(messages).map(([type, message]) => (
                                            <p key={type}  class="error">{message}</p>
                                        ))
                                        : null;
                                    }}
                                /> 
                           </div> */}
                         
                           {/* <div class="col-lg-12 mb-4">
                              <label for="firstName" class="form-label">Password<span class="red-text">*</span></label>
                              <input type="password" class="form-control" id="firstName"  onChange={e => setFieldName(e.target.value)} {...register("Password", {
                                    required: "Password is required.",
                                    pattern: {
                                      value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                      message: "Password contain uppercase,lowercase and special character"
                                    },
                                    minLength: { value: 8, message: 'Too short' }
                                    })}/>
                                 <ErrorMessage
                                    errors={errors}
                                    name="Password"
                                    class="invalid-feedback"
                                    render={({ messages }) => {
                                    console.log("messages", messages);
                                    return messages
                                        ? Object.entries(messages).map(([type, message]) => (
                                            <p key={type}  class="error">{message}</p>
                                        ))
                                        : null;
                                    }}
                                /> 
                           </div> */}
                           {/* <div class="col-lg-12 mb-1">
                              <div  class="login-button-pg"><button type="submit" >Login</button></div>
                              
                           </div> */}
                           <div class="col-lg-12 mb-1">
                              <button type="submit"  class="login-button-pg">Login</button>
                           </div>
                           <a href="/forgotPwd"><span class="font-weight-light">Forgot Password</span></a>

                           <hr class="my-3"/>

                           <div class="crs">
                                 <div class="text-center">
                                    <h5>Company </h5>
                                    <a href="/companyRegister">Register</a>
                                 </div>
                                 <div class="text-center">
                                    <h5>Resume </h5>
                                    <a href="/postResume">Post</a>
                                 </div>
                                 <div class="text-center">
                                    <h5>Student </h5>
                                    <a href="/studentRegistration">Register</a>
                                 </div>
                           </div>

                        </div>
                     </div>
                   </form>
                  </div>
               </div>
            </div>
         </section>



      </main>
      {/* </ReactKeycloakProvider> */}
  </>
)};

Login.propTypes = {};

Login.defaultProps = {};

export default Login;
