import React,{history,useEffect, useState, useMemo } from "react";
import Hiring from "../Jobs/Hiring";
import Recent_Jobs from "../Jobs/Recent_Jobs";
import Pagination3 from "../../utility/Pagination/Pagination";
import Services from "../../services/services";
import $, { parseJSON } from 'jquery';
const VideoLibrary = () => {
   const companyIdChkLogin=sessionStorage.getItem("logCompanyId");
    const studentIdChkLogin=sessionStorage.getItem("logStudentId");
    const jobSeekerIdChkLogin=sessionStorage.getItem("logProfileId");
    const userIdChkLogin=sessionStorage.getItem("logUserId");
    const chKRoleAr=sessionStorage.getItem("roleAr");

    const ITEMS_PER_PAGE = 10; // Per page show the data pagination
    const [totalItems, setTotalItems] = useState(0);
    const [totalpages, settotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [videoList, setVideoList] = useState([]);


    const getVideoList = (ITEMS_PER_PAGE,pageNumber) => {
      //alert("14");
    const requestObject={
        "siteId":process.env.React_App_SITE_ID,
        "pageNumber":pageNumber,
        "pageSize":ITEMS_PER_PAGE
     }

     var accessTokenIn=sessionStorage.getItem("accToken");
     const requestOptions = {
       method: 'POST',
       headers: { 'Content-Type': 'application/json',
                 //'Authorization': "Bearer "+accessTokenIn
                 //'Authorization': accessTokenIn
               },
       body: JSON.stringify(requestObject)
     };

     Services.getVideosBySite(requestOptions).then((res) =>
     {
        console.log("############36");
       console.log(JSON.stringify(res))
       if(res.content){
         console.log(JSON.stringify(res));
            $(".paginationContent").show();
            setTotalItems(res.totalElements);
            settotalPages(Math.ceil(res.totalElements / ITEMS_PER_PAGE));
            setCurrentPage(pageNumber);

            var contAr = res.content;
            //setProfileList(res.content);
            if(res.content){
               setVideoList(res.content);
            }


       }
      //navigate('/thankyou');

     });


  }

  const handlePageChange3 = (offset,triggerevent) => {
   getVideoList(ITEMS_PER_PAGE,offset)
 };

  useEffect(() => {

   //getTopCompanyHiringList();
   //filterContent(ITEMS_PER_PAGE,offset);
   //filterEvents(ITEMS_PER_PAGE,'1');
   getVideoList(ITEMS_PER_PAGE,'1');

}, []);

  return(
  <>
    <main  class="empr-directory-main">
         <div class="main-bgpatten"></div>
         {/* <!-- info typography --> */}
         <section class="headtitle-wrap">
            <div class="container">
               <div class="row">
                  <div class="col-lg-12">
                <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb">
                        <ol class="breadcrumb">

                        {(() => {

                                if( jobSeekerIdChkLogin != null && jobSeekerIdChkLogin != "null"){
                                    return (<><li class="breadcrumb-item headtitle"> <a href="/profileDashboard">Job Seeker Dashboard</a> </li> </>);
                                }

                                if( chKRoleAr == "schooladmin"  || chKRoleAr == "schoolsuper" ){
                                 return (<><li class="breadcrumb-item headtitle"> <a href="/schoolAdminDashboard">School Admin</a> </li> </>);
                                 }

                               

                                if( studentIdChkLogin != null && studentIdChkLogin != "null"){
                                    return (<><li class="breadcrumb-item headtitle"> <a href="/studentDashboard">Student Dashboard</a> </li> </>);
                                }

                           })()}

                           <li class="breadcrumb-item headtitle "><a href="#">Video Library</a></li>
                        </ol>
                     </nav>
                  </div>
               </div>
            </div>
         </section>
         {/* <!-- info typography --> */}
         <section class="info-typography py-5">
            <div class="container">
               <div class="row">
                  <div class="col-lg-8">
                     <div class="py-5 px-5 white-bg" style={{paddingBottom:"20px !important"}}>
                        <div class="row">



                        {
                           videoList.map((data, index) => (


                        <div class="video-section2 mb-4">
                        {/* <div class="video-item"> <img src="images/screen-shot.png" class="w-100" alt=""/></div> */}
                        <div class="video-item">
                        <iframe width="100%" height="100%" src={data.videoUrl} />
                        </div>
                           <div class="info-vid">
                                 <div class="d-flex align-items-center">
                                 <h4>{data.name}</h4>
                                 {data.jobShadowConcert == 'Y' ? <a href="#" style={{width:"36px"}} class="tool" data-bs-toggle="tooltip" data-bs-placement="top" data-tip="This company provides job shadow opportunities">
                                    <div class="mx-0"> <img src="images/badge.png" alt="" class="w-100"/></div></a> : ''}
                                 </div>
                                 <span> {data.address1 != '' ? data.address1 : ''}
                                 {data.address1 != '' && data.address2 != '' ? ', ' : ''}
                                 {data.address2 != '' ? data.address2 : ''}<br></br>
                                 {data.city != '' ? data.city : ''}
                                 {data.city != '' && data.state != '' ? ', ' : ''}
                                 {data.state != '' ? data.state : ''}
                                 {data.state != '' && data.zip != '' ? ', ' : ''}
                                 {data.zip != '' ? data.zip : ''}
                                 </span>
                                 <div class="stock-wrap justify-content-between align-items-center">
                                    {/* <a href={`/jobsByCompany?companyId=`+data.companyId}>{data.jobCount} OPEN JOBS</a> */}
                                    <a href={`/jobsByCompany?companyId=`+data.companyId}> Open Jobs</a>
                                 </div>
                                 
                              </div>
                              {/* <div class="vid-close d-flex align-items-center">
                                 <a href="#"> <img src="images/close.jpg" alt=""/></a>
                              </div> */}
                              


                              
                        </div>
                        ))}



<div class="pagination-wrap text-center mt-3 paginationContent">
                           <Pagination3
                     total={totalItems}
                     itemsPerPage={ITEMS_PER_PAGE}
                     currentPage={currentPage}
                     onPageChange={handlePageChange3}/>

            </div>
                           {/* <div class="col-lg-12 mb-5">
                              <div class="video-section2">
                                 <div class="video-item"> <img src="images/screen-shot.png" class="w-100" alt=""/></div>
                                 <div class="info-vid">
                                    <h4>AGRANA FRUIT US, INC</h4>
                                    <span> Botkins, Ohio</span>
                                    <div class="stock-wrap justify-content-between align-items-center">
                                       <a href="#">15 OPEN JOBS</a>
                                    </div>
                                 </div>
                              </div>
                           </div> */}




                           {/* <div class="col-lg-12 mb-5">
                              <div class="video-section2 ">
                                 <div class="video-item"> <img src="images/screen-shot.png" class="w-100" alt=""/></div>
                                 <div class="info-vid">
                                    <h4>AGRANA FRUIT US, INC</h4>
                                    <span> Botkins, Ohio</span>
                                    <div class="stock-wrap justify-content-between align-items-center">
                                       <a href="#">15 OPEN JOBS</a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-lg-12 mb-5">
                              <div class="video-section2 ">
                                 <div class="video-item"> <img src="images/screen-shot.png" class="w-100" alt=""/></div>
                                 <div class="info-vid">
                                    <h4>AGRANA FRUIT US, INC</h4>
                                    <span> Botkins, Ohio</span>
                                    <div class="stock-wrap justify-content-between align-items-center">
                                       <a href="#">15 OPEN JOBS</a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-lg-12 mb-5">
                              <div class="video-section2 ">
                                 <div class="video-item"> <img src="images/screen-shot.png" class="w-100" alt=""/></div>
                                 <div class="info-vid">
                                    <h4>AGRANA FRUIT US, INC</h4>
                                    <span> Botkins, Ohio</span>
                                    <div class="stock-wrap justify-content-between align-items-center">
                                       <a href="#">15 OPEN JOBS</a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-lg-12 mb-5">
                              <div class="video-section2">
                                 <div class="video-item"> <img src="images/screen-shot.png" class="w-100" alt=""/></div>
                                 <div class="info-vid">
                                    <h4>AGRANA FRUIT US, INC</h4>
                                    <span> Botkins, Ohio</span>
                                    <div class="stock-wrap justify-content-between align-items-center">
                                       <a href="#">15 OPEN JOBS</a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-lg-12 mb-5">
                              <div class="video-section2">
                                 <div class="video-item"> <img src="images/screen-shot.png" class="w-100" alt=""/></div>
                                 <div class="info-vid">
                                    <h4>AGRANA FRUIT US, INC</h4>
                                    <span> Botkins, Ohio</span>
                                    <div class="stock-wrap justify-content-between align-items-center">
                                       <a href="#">15 OPEN JOBS</a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-lg-12 mb-3">
                              <div class="video-section2">
                                 <div class="video-item"> <img src="images/screen-shot.png" class="w-100" alt=""/></div>
                                 <div class="info-vid">
                                    <h4>AGRANA FRUIT US, INC</h4>
                                    <span> Botkins, Ohio</span>
                                    <div class="stock-wrap justify-content-between align-items-center">
                                       <a href="#">15 OPEN JOBS</a>
                                    </div>
                                 </div>
                              </div>
                           </div> */}



                        </div>
                        {/* <div class="pagination-wrap text-center mt-3">
                           <nav aria-label="...">
                              <ul class="pagination m-auto d-inline-flex">
                                 <li class="page-item disabled">
                                    <span class="page-link"><img src="images/arrow-left.JPG" alt=""/></span>
                                 </li>
                                 <li class="page-item"><a class="page-link" href="#">1</a></li>
                                 <li class="page-item active" aria-current="page">
                                    <span class="page-link">2</span>
                                 </li>
                                 <li class="page-item"><a class="page-link" href="#">3</a></li>
                                 <li class="page-item">
                                    <a class="page-link" href="#"><img src="images/arrow-right.JPG" alt=""/></a>
                                 </li>
                              </ul>
                           </nav>
                        </div> */}
                     </div>
                  </div>
                  <div class="col-lg-4">
                     <Recent_Jobs/>
                     {/* <div class="white-bg mb-4">
                        <img src="images/ad-1.png" class="w-100" alt=""/>
                     </div>
                     <div class="white-bg mb-4">
                        <img src="images/ad-2.png" class="w-100" alt=""/>
                     </div> */}
                     {/* <div class="p-4 white-bg mb-4">
                        <h2>Stay connected. Get the app!</h2>
                        <p>Download the Hometown Opportunity app and stay constanly connected with companies and job oppportunities in your area. Hometown Opportunity is available on the Apple App Store or on Google Play.</p>
                        <img src="images/HTO-app-face.png" class="w-100" alt=""/>
                        <div class="d-flex justify-content-center  mt-3">
                           <img src="images/apple-app-store.png" alt="" style={{width: "130px"}}/>
                           <img src="images/google-play.png" alt="" class="ms-2" style={{maxWidth: "130px"}}/>
                        </div>
                     </div> */}
                  </div>
               </div>
            </div>
         </section>
      <Hiring/>
      </main>
  </>
)};

VideoLibrary.propTypes = {};

VideoLibrary.defaultProps = {};

export default VideoLibrary;
