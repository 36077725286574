import React,{history,useEffect, useState, useMemo } from "react";
import Quick_links from "../../utility/Quick_links";
import Hiring from "../Jobs/Hiring";
import services from "../../services/services";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import { useNavigate, useParams } from "react-router-dom";
const ProfileBanner = () => {  // const { companyId } = useParams(); 
    //const companyId = 24;
    const companyId=sessionStorage.getItem("logCompanyId");
 
    const navigate = useNavigate();
 
    const [banner, setBanner] = useState([]);
    const [bannerBase64, setBannerBase64] = useState([]);
    const [imageName, setImageName] = useState([]);
    const [imageExtension, setImageExtension] = useState([]);

  function getImage(){
        var files = document.getElementById('banner').files;

       
        // console.log("@@@@@24@@@ =="+files[0]);
        // console.log("@@@@@24@@@ =="+files[0].type);
        // console.log("@@@@@24@@@ =="+JSON.stringify(files[0]));

    //    alert(files.substr(files.lastIndexOf('.') + 1));
    //    alert(files.split('.'));
    //    alert(/[^.]+$/.exec(filename));
    //    alert(files.split('.').pop());
    //    string.toString().split('/');
        // alert(files);
        if (files.length > 0) {


            var fname = files[0].name;
            // var fextn = fname.substr(0, fname.lastIndexOf('.'));
            var fextn = fname.split(".").pop();
            var fileActName = fname.split(".")[0];
            setImageName(fileActName);
            setImageExtension(fextn);
            /*
            if(files[0].type=="image/png"){
                setImageExtension("png");
            }else if(files[0].type=="image/jpeg"){
                setImageExtension("jpeg");
            }else if(files[0].type=="image/gif"){
                setImageExtension("gif");
            }else if(files[0].type=="image/jpg"){
                setImageExtension("jpg");
            }else{
                setImageExtension(files[0].type); 
            }
            */
            // console.log("@@@@@24@@@ =="+JSON.stringify(files[0]));
          getBase64(files[0]);
        }
      }

    function getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          console.log(reader.result);

          var base64result = reader.result.split(',')[1];
          //setBannerBase64(reader.result)
          setBannerBase64(base64result);
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
     }
 
    useEffect(() => {
        if(companyId == null || companyId == "null"){            
            //navigate('/login');
            navigate('/Home');
          }
       getCompanyBanner();
       $("#imgError").hide();
 
    }, []);
 
    const getCompanyBanner = () => {
       services.getCompanyProfile(companyId).then((res) => {
        if(res.data.bannerUrl==null){
            //setBanner("images/Photo/Video box.png");
            setBanner("images/no-banner.jpg");
           }else{
            
            setBanner(res.data.bannerUrl);
           }
       
      
       });
    }
 
    const {
       register,
       formState: { errors },
       handleSubmit
    } = useForm({
       criteriaMode: "all", mode: "onBlur"
    });
 
    const submitValue = () => {
 
    //    const requestObject =
    //    {
    //       "companyId": "24",
    //       "siteId": 2,
    //       // "logoUrl": "https:/hometownopportunity.com"
    //       "bannerUrl": bannerBase64
    //    }



    if(imageExtension != "png" && imageExtension != "jpeg" && imageExtension != "jpg" && imageExtension != "gif"  ){
        //document.getElementById('logo').reset();
        $("#imgError").show();
        return false;
     }else{
        $("#imgError").hide();
     }
       const requestObject ={
        "imageName": imageName,
        "companyId": companyId,
        // "siteId": 2,
        "imageExtension": imageExtension,
        "imageByteString": bannerBase64
      }
 
       console.log(requestObject);
       var accessTokenIn=sessionStorage.getItem("accToken");
       const requestOptions = {
          method: 'POST',
          headers: {
             'Content-Type': 'application/json',
              'Authorization': "Bearer "+accessTokenIn 
              //'Authorization': accessTokenIn 
          },
          body: JSON.stringify(requestObject)
       };
 
       services.editCompanyBanner(requestOptions).then((res) => {
        //   navigate('/thankyou');
          $("#addUserForm").trigger("reset");
          getCompanyBanner();
       });
 
    }
 



  return(
  <>
      <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        {/* <!-- info typography --> */}
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item headtitle "><a href="/companyJobs">Company Dashboard</a> </li>
                                <li class="breadcrumb-item headtitle "><a href="#">Company Banner</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-5">
                            <div class="row">
                                {/* <!-- left side --> */}
                                <Quick_links/>

                                {/* <!-- right side --> */}
                                <div class="col-lg-8 mb-4">
                                <form id="addUserForm"   onSubmit={handleSubmit(submitValue)}>
                                    <div class="row resume-wrap">
                                        <div class="col-lg-12 mb-4">
                                            <label for="firstName" class="form-label">Current Banner </label>
                                            <div class="mb-4">
                                                <img src={banner} class="w-100 mb-2 m-auto" alt=""/>
                                                {/* <img src="images/Photo/Video box.png" class="w-100 mb-2 m-auto" alt=""/> */}
                                            </div>

                                            <div class="d-flex align-items-center choose">
                                                <div>
                                                    <label for="firstName" class="form-label">Change Banner </label>
                                                    <div class="d-flex align-items-center">
                                                        <input class="form-control form-control-lg" id="banner" type="file" onChange={()=>getImage()}/>
                                                       
                                                    </div>
                                                    <small> 1 MB Max, File type: png or jpg</small>
                                                    <div id="imgError"  class="error">Invalid file format</div>
                                                </div>
                                                <div><button type="submit" class="reg-button">Save</button></div>
                                            </div>

                                        </div>
                                    </div>
                                    </form>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </section>
            {/* <Hiring/> */}
    </main>
  </>
)};

ProfileBanner.propTypes = {};

ProfileBanner.defaultProps = {};

export default ProfileBanner;
