import React,{history,useEffect, useState, useMemo } from "react";
import Quick_links from "../../utility/Quick_links";
import Sidebar from "../../utility/sidebar";
import Hiring from "../Jobs/Hiring";
import services from "../../services/services";
import { useNavigate, useParams, useLocation, useHistory } from "react-router-dom";

const WatchVideos = () => {
    const [itemName, setFieldName] = useState('');

   const siteID = process.env.React_App_SITE_ID;
 
   const navigate = useNavigate();

   const [videoUrl, setVideo] = useState([]);
   const [videoList, setVideoList] = useState([]);



   const companyIdChkLogin=sessionStorage.getItem("logCompanyId");
    const studentIdChkLogin=sessionStorage.getItem("logStudentId");
    const jobSeekerIdChkLogin=sessionStorage.getItem("logProfileId");
    const userIdChkLogin=sessionStorage.getItem("logUserId");

    useEffect(() => {

        getCompanyVideos();
  
     }, []);

     const getCompanyVideos = () => {
        services.getVideoLinkById(siteID).then((res) => {
  
            if(res){
                setVideoList(res.data);
            }
            
        
        });
     }

  return(
  <>
    <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        {/* <!-- info typography --> */}
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav style={{"--bs-breadcrumb-divider": '>'}} aria-label="breadcrumb">
                            <ol class="breadcrumb">

                            {(() => {
                                
                                if( jobSeekerIdChkLogin != null && jobSeekerIdChkLogin != "null"){            
                                    return (<><li class="breadcrumb-item headtitle"> <a href="/profileDashboard">Job Seeker Dashboard</a> </li> </>);
                                }

                                if( studentIdChkLogin != null && studentIdChkLogin != "null"){            
                                    return (<><li class="breadcrumb-item headtitle"> <a href="/studentDashboard">Student Dashboard</a> </li> </>);
                                }

                                })()} 

                                <li class="breadcrumb-item headtitle ">Watch Videos</li>
                                {/* <li class="breadcrumb-item headtitle "><a href="#">Company Banner</a></li> */}
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-5">
                            <div class="row">
                                {/* <!-- left side --> */}
                                {(() => {
                                if( studentIdChkLogin != null || studentIdChkLogin == "null"){ 
                                    return (<>           
                                    <Sidebar/>
                                    </>)
                                }else  if( companyIdChkLogin != null || companyIdChkLogin == "null"){ 
                                    return (<>           
                                    <Quick_links/>
                                    </>)
                                }

                                

                            })()}
                             

                                {/* <!-- right side --> */}
                                <div class="col-lg-8 mb-4">

                                    <div class="video-watch-wrap">

                                    {
                                    videoList.map((data, index) => (
                                        <div class="mb-4">
                                            {/* <div class="video-item mb-3"> <img src="images/screen-shot.png" class="w-100" alt=""/></div> */}
                                            <div class="video-item mb-3">
                                                <iframe width="100%" height="420" src={data} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                            {/* <h5 class="mb-0">Video name</h5>
                                            <small>Video mini Dispristion</small> */}
                                        </div>

                                    ))
                                    }

                                        {/* <div class="mb-4">
                                            <div class="video-item mb-3"> <img src="images/screen-shot.png" class="w-100" alt=""/></div>
                                            <h5 class="mb-0">Video name</h5>
                                            <small>Video mini Dispristion</small>
                                        </div>

                                        <div class="mb-4">
                                            <div class="video-item mb-3"> <img src="images/screen-shot.png" class="w-100" alt=""/></div>
                                            <h5 class="mb-0">Video name</h5>
                                            <small>Video mini Dispristion</small>
                                        </div>

                                        <div class="mb-4">
                                            <div class="video-item mb-3"> <img src="images/screen-shot.png" class="w-100" alt=""/></div>
                                            <h5 class="mb-0">Video name</h5>
                                            <small>Video mini Dispristion</small>
                                        </div>

                                        <div class="mb-4">
                                            <div class="video-item mb-3"> <img src="images/screen-shot.png" class="w-100" alt=""/></div>
                                            <h5 class="mb-0">Video name</h5>
                                            <small>Video mini Dispristion</small>
                                        </div> */}
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </section>
        <Hiring/>
    </main>
  </>
)};

WatchVideos.propTypes = {};

WatchVideos.defaultProps = {};

export default WatchVideos;
