
const router = (function () {
  // reference to the singleton
  var instance;

  function init() {
    // private
    const routes = {
      home: {
        path: '/home'
      },
      studentDashboard: {
        path: '/studentDashboard'
      },
      profileDashboard: {
        path: '/profileDashboard'
      },
      studentRegistration: {
        path: '/studentRegistration'
      },
      employeeManufacturing: {
        path: '/employeeManufacturing'
      },
      employerDirectory: {
        path: '/employerDirectory'
      },
      availableJobs: {
        path: '/availableJobs'
      },
      availableJobs2: {
        path: '/availableJobs2'
      },
      locallifeCommunities: {
        path: '/locallifeCommunities'
      },
      locallifeSchool: {
        path: '/locallifeSchool'
      }, 
      postJob: {
        path: '/postJob'
      }, 
      companyDashboard: {
        path: '/companyDashboard'
      },
      companyLogo: {
        path: '/companyLogo'
      },
      companyRegister: {
        path: '/companyRegister'
      },
      postResume: {
        path: '/postResume'
      },
      profileInfo: {
        path: '/profileInfo'
      },
      videoUpload: {
        path: '/videoUpload'
      },
      VideoLibrary: {
        path: '/VideoLibrary'
      },
      aboutus: {
        path: '/aboutus'
      },
      blog: {
        path: '/blog'
      },
      contactus: {
        path: '/contactus'
      },
      companydetails: {
        path: '/companyDetails/:companyId'
      },
      companyListByIndustry: {
        path: '/companyListByIndustry/:catId'
      },
      jobsByCompany: {
        path: '/jobsByCompany'
      },
      jobSummary: {
        path: '/jobSummary/:jobId'
      },
      login: {
        path: '/login'
      },
      login: {
        path: '/logout'
      },
      faq: {
        path: '/faq'
      },
      industries: {
        path: '/industries'
      },
      privacyPolicy: {
        path: '/privacyPolicy'
      },
      events: {
        path: '/events'
      },
      eventDetails: {
        path: '/eventDetails'
      },
      notificationDetails: {
        path: '/notificationDetails'
      },
      forgotPwd: {
        path: '/forgotPwd'
      },
      ourlibrary: {
        path: '/ourlibrary'
      },
      helplink: {
        path: '/helplink'
      },
      relevantArticles: {
        path: '/relevantarticles'
      },
      subscribeToCareer: {
        path: '/subscribeToCareer'
      },
      companyJobs: {
        path: '/companyJobs'
      },
      personalProfiles: {
        path: '/personalProfiles'
      },

      studentList: {
        path: '/studentList'
      },
      studentListBySchool: {
        path: '/studentListBySchool'
      },

      studentReportBySchool: {
        path: '/studentReportBySchool'
      },

      personalProfileView: {
        path: '/personalProfileView'
      },  
      
      studentProfileView: {
        path: '/studentProfileView'
      },

      careerView: {
        path: '/careerView'
      },

      careerExploreJobs: {
        path: '/careerExploreJobs'
      },

      emailNotification: {
        path: '/emailNotification'
      },

      editAdminstrationLogin: {
        path: '/editAdminstrationLogin'
      },

      wageData: {
        path: '/wageData'
      },

      wageSurvey: {
        path: '/wageSurvey'
      },
      wageSurveyData: {
        path: '/wageSurveyData'
      },

      CompanyUsers: {
        path: '/CompanyUsers'
      },

      PerksList: {
        path: '/PerksList'
      },
      
      CompanyJobApplications: {
        path: '/CompanyJobApplications'
      },

      jobApplicantProfileView: {
        path: '/jobApplicantProfileView'
      },

      helpfullLinks: {
        path: '/helpfullLinks'
      },

      DownloadApp: {
        path: '/DownloadApp'
      },

      PublicProfileView: {
        path: '/PublicProfileView'
      },

      ProfileBanner: {
        path: '/ProfileBanner'
      },
      manageProfile: {
        path: '/manageProfile'
      },
      movingToTheArea: {
        path: '/movingToTheArea'
      },
      marketSupport: {
        path: '/marketSupport'
      },

      resumeDashboard: {
        path: '/resumeDashboard'
      },
      watchVideos: {
        path: '/watchVideos'
      },
      updateProfile: {
        path: '/updateProfile'
      },
      applyJobProfile: {
        path: '/applyJobProfile'
      },
      viewResume: {
        path: '/viewResume'
      },
      localCompanyMap: {
        path: '/localCompanyMap'
      },
      updateStudent: {
        path: '/updateStudent'
      },
      thankyou: {
        path: '/thankyou'
      },
      educationTraining: {
        path: '/educationTraining'
      },
      schoolList: {
        path: '/schoolList'
      },
      'educationTrainingDetails/:slug': {
        path: '/educationTrainingDetails/:slug'
      },
      schoolDetails: {
        path: '/schoolDetails'
      },
      /*'schoolDetails/:slug': {
        path: '/schoolDetails/:schoolId'
      },*/
      blogDetails: {
        path: '/blogDetails'
      },
     /* 'blogDetails/:slug': {
        path: '/blogDetails/:blogId'
      }, */
      statsFacts: {
        path: '/statsFacts'
      },

      convertToProfile: {
        path: '/convertToProfile'
      },

      leisure: {
        path: '/leisure'
      },
      
      sampleCareerPaths: {
        path: '/sampleCareerPaths'
      },
      companyEditJob: {
        path: '/companyEditJob/:jobId'
      },
      

      
    };

    function getRouteInfo(routeName) {
      //alert(routeName);
      return routes[routeName];
    }

    function getRoutesInfo(routeNames) {
      const routesInfo = {};

      routeNames.forEach(route => {
        routesInfo[route] = routes[route];
      });


      return routesInfo;
    }

    function getLinksInfo(routeNames) {
      const links = {};

      routeNames.forEach(route => {
        links[route] = routes[route];
        links[route].pathName = links[route].path;
      });

      return links;
    }



    return {
      getRouteInfo,
      getRoutesInfo,
      getLinksInfo
    };
  }

  return {
    // get instance if one exists
    // or create one it it doesn't

    getInstance: function () {
      if (!instance) {
        instance = init();
      }

      return instance;
    },
  };
})();

export default router;
