import React from "react";
import './../css/bootstrap.min.css';
import '../assets/css/custome.css';
import '../assets/css/media.css';
import '../assets/css/animate.min.css';
import { BrowserRouter } from "react-router-dom";
import MainContent from "./mainContent/MainContent";
import Appbar from "./header/header";
import Footer from "./footer/index";

function Layout() {

  return (
   
   
      <BrowserRouter forceRefresh={true}>
        {/* <Appbar/> */}
          <MainContent />
        {/* <Footer/> */}
      </BrowserRouter>
      
     
  );
}

export default Layout;