import React,{history,useEffect, useState, useMemo } from "react";
import { useNavigate,useParams,useLocation,useHistory } from "react-router-dom";
import Hiring from "../Jobs/Hiring";
import Services from "../../services/services";
import $, { parseJSON } from 'jquery';
import moment from "moment";
import Quick_links from "../../utility/Quick_links";
// import Pagination from "../pagination/pagination";
import Pagination from "../../utility/Pagination/Pagination";
import ExpiredJobs from "./expired_Jobs";
import PendingJobs from "./pending_Jobs";
import DateObject from "react-date-object";
import FilledJobs from "./filled_Jobs";


const CompanyJobs = () => {

    const navigate = useNavigate();
    const [availableJobsList, setAvailableJobsList] = useState([]);
    const [expiredJobsList, setExpiredJobsList] = useState([]);
    const [path, setPath] = useState([]);

    const [searchText, setsearchText] = useState();
    const [totalItems, setTotalItems] = useState(0);
    const [totalpages, settotalPages] = useState();
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [users, setUsers] = useState([]);  
    const [currentPage, setCurrentPage] = useState(1);
    const ITEMS_PER_PAGE = 15; // Per page show the data pagination
    const Pagesize=10;

    const [status, setStatus] = useState();
    const [title, setTitle] = useState();
    const [sortBy, setSortBy] = useState();
    const [sortDir, setSortDir] = useState();

    var companyId=sessionStorage.getItem("logCompanyId");
    //var companyId = "24";
    const siteId = process.env.React_App_SITE_ID;

 

      const handlePageChange2 = (offset,triggerevent) => {
        // alert(offset);
        getCurrent(companyId,'active','',ITEMS_PER_PAGE,offset,'postedDate','desc');
      };

    useEffect(() => 
    {
        
        if(companyId == null || companyId == "null"){            
            //navigate('/login');
            navigate('/Home');
          }
        getCurrent(companyId,'published','',ITEMS_PER_PAGE,'1','postedDate','desc');

        const pathName=window.location.pathname;
        setPath(pathName);

    },[]);
   
     function getExpireyDate(startdate){
        if(startdate == ""){
            return "";
        }
        var daten = new DateObject(startdate);
        var daten1 = daten.add(45, "d").format("D-MMM-YY");
        return daten1;       
     }

    function getCurrent(companyId,status,title,ITEMS_PER_PAGE,pageNumber,sortBy,sortDir){
    //    alert(pageNumber);
        var title = $("#jobTitleCurrent").val();
        var sortByInput = sortBy;//"postedDate";
        var sorDirInput = sortDir;//"desc";
        status = 'published';
        
        Services.getCompanyJobExpiredCurrent(companyId,status,title,ITEMS_PER_PAGE,pageNumber,sortByInput,sorDirInput,siteId).then((res) => 
        {
            setTotalItems(res.data.totalElements);
         
            settotalPages(Math.ceil(res.data.totalElements / ITEMS_PER_PAGE));
            // settotalPages(Math.ceil(res.data.totalElements / ITEMS_PER_PAGE));
            // setCurrentPage(res.data.number);
            setCurrentPage(pageNumber);

            setAvailableJobsList(res.data.content);
        //    var dVal = eval(res);
        //    console.log(dVal);
        //    console.log(typeof(dVal));
        //   // console.log(dVal.length);
        //    console.log("142-->"+JSON.stringify(dVal));
        //    //console.log(res.data.length);
        //    if(typeof(dVal) == "object"&& dVal.data.length>0){
    
        //     console.log("64-------->"+JSON.stringify(dVal.data));
        //     setAvailableJobsList(dVal.data);
             
        //    }else{
        //     setAvailableJobsList([]);
               
        //    }  
    
            //navigate('/thankyou');
        });
    }

 
  return(
  <>
     <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        {/* <!-- info typography --> */}
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item headtitle"> <a href="/companyJobs">Company Dashboard</a> </li>
                                <li class="breadcrumb-item headtitle "><a href="#">Company Jobs</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-5">
                            <div class="row">
                                <Quick_links/>
                                {/* <!-- left side --> */}
                                {/* <div class="col-lg-4 mb-4">
                                    <div class="card p-3 mb-3 ">
                                        <div class="bg-heading">
                                            <h3 class="mb-0">Company Jobs</h3>
                                        </div>
                                        <div class="qlinks">
                                            <ul>
                                                <li><a href="/companyJobs">Company Jobs</a></li>
                                                <li><a href="/postJob">Post a job </a></li>
                                            </ul>

                                        </div>
                                    </div>

                                    <div class="card p-3 mb-3 ">
                                        <div class="bg-heading">
                                            <h3 class="mb-0">Company Options</h3>
                                        </div>
                                        <div class="qlinks">
                                            <ul>
                                            <li><a href="/publicProfileView" class={"/publicProfileView"!=path?'':"active"}>View Public Profile </a></li>
                                                <li><a href="/profileInfo" class={"/profileInfo"!=path?'':"active"}>Profile Info </a></li>
                                                <li><a href="/companyLogo"  class={"/companyLogo"!=path?'':"active"}>Company Logo</a></li>
                                                <li><a href="/profileBanner" class={"/profileBanner"!=path?'':"active"}>Profile Banner</a></li>
                                                <li><a href="/videoUpload" class={"/videoUpload"!=path?'':"active"}>Upload Video</a></li>
                                                <li><a href="/editAdminstrationLogin" class={"/editAdminstrationLogin"!=path?'':"active"}>Edit Administrator Login </a></li>
                                                <li><a href="/companyUsers" class={"/companyUsers"!=path?'':"active"}>Company Users</a></li>
                                                <li><a href="/emailNotification" class={"/emailNotification"!=path?'':"active"}>Email Notifications</a></li>
                                                <li><a href="/emailNotification">Business Page Template</a></li>
                                            </ul>

                                        </div>
                                    </div>
                                </div> */}

                                {/* <!-- right side --> */}
                                <div class="col-lg-8">

                                    <div class="row resume-wrap">
                                        <div class="col-lg-12 mb-4">
                                            <div class="col-lg-12">

                                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                                    <li class="nav-item" role="presentation">
                                                        <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="false">Current</button>
                                                    </li>
                                                   
                                                    <li class="nav-item" role="presentation">
                                                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#pending" type="button" role="tab" aria-controls="pending" aria-selected="false">Pending</button>
                                                    </li>

                                                    <li class="nav-item" role="presentation">
                                                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#filled" type="button" role="tab" aria-controls="pending" aria-selected="false">Filled</button>
                                                    </li>
                                                    
                                                    <li class="nav-item" role="presentation">
                                                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Expired</button>
                                                    </li>

                                                   

                                                </ul>

                                                <div class="tab-content" id="myTabContent">
                                                    <div class="tab-pane fade active show" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                        <div class="my-4"></div>
                                                        <div class="mb-4">
                                                            <div class="job-item-wrap position-relative">
                                                                {/* <!-- search --> */}
                                                                {/* <form class=" search-bar mb-4 w-100"> */}
                                                                    <input class="form-control mb-3" type="search" id="jobTitleCurrent" aria-label="Search" placeholder="Search by Job Title" onKeyPress={()=>getCurrent(companyId,'active','',ITEMS_PER_PAGE,1,'','')}/>
                                                                    <button class="border-0 p-0 dir-search" type="button"><img src="images/search-icon.JPG" alt="" onClick={()=>getCurrent(companyId,'active','',ITEMS_PER_PAGE,1,'','')}/></button>
                                                                {/* </form> */}

                                                                {/* <!-- table --> */}
                                                                <div class="current-job-tbl">
                                                                    <table class="table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">Posted</th>
                                                                                <th scope="col">Title</th>
                                                                                <th scope="col" >Expire</th>
                                                                                <th scope="col">Status</th>
                                                                                <th scope="col">Actions</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {

                                                                            availableJobsList.map((data, index) => (

                                                                            <tr>

                                                                            <td style={{width:"120px"}}>{moment(data.createdDate).format("D-MMM-YY")}</td>

                                                                            <td>{data.title}</td>

                                                                            <td style={{width:"125px"}}>{getExpireyDate(data.createdDate)}</td>

                                                                            <td>Published</td>

                                                                            <td><a href={`/companyEditJob?jobId=${data.jobId}`} class="apply-button text-uppercase text-center">Edit</a></td>

                                                                            </tr>

                                                                            ))}


                                                                        </tbody>
                                                                    </table>
                                                                    <Pagination
                
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={handlePageChange2}
                
            />
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                                        <div class="my-4"></div>
                                                        <div>
                                                           <ExpiredJobs/>
                                                        </div>
                                                    </div>

                                                    <div class="tab-pane fade" id="filled" role="tabpanel" aria-labelledby="profile-tab">
                                                        <div class="my-4"></div>
                                                        <div>
                                                           <FilledJobs/>
                                                        </div>
                                                    </div>

                                                    <div class="tab-pane fade" id="pending" role="tabpanel" aria-labelledby="pending-tab">
                                                        <div class="my-4"></div>
                                                        <div>
                                                             <PendingJobs></PendingJobs>                   
                                                        </div>
                                                    </div>
                                                    {/* <div class="pagination-wrap text-center mt-5">
                                    <Pagination items={exampleItems} onChangePage={OnChangePage} />
                                    </div>                             */}
                                  
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </section>
      {/* <Hiring/> */}
    </main>
  </>
)};

CompanyJobs.propTypes = {};

CompanyJobs.defaultProps = {};

export default CompanyJobs;
