import React,{useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import { useHistory,useNavigate  } from "react-router-dom";
import Services from "../../services/services";
const StudentRegistration3 = () => {

    const [jobTypes, setJobType] = useState([]);

    const [fileName, setFileName] = useState('');
    const [fileExtension, setFileExtension] = useState('');
    const [fileBase64, setFileBase64] = useState('');
    //const navigate = useNavigate();

    useEffect(() => {
      
        getJobType();
  
    }, []);
  
    const getJobType = () => {
        Services.getJobType().then((res) => {
          console.log("setJobType data=" + JSON.stringify(res.data));
          setJobType(res.data);
        });
      }


    const {
        register: register4,
        formState: { errors: errors4 },
        handleSubmit: handleSubmit4,
      } = useForm({
        criteriaMode: "all",mode: "onBlur" 
      });
    //   const history = useHistory();
      const navigate = useNavigate();
      const submitValueStep4=()=>
      {
        
        
        
        
        var categoriesArray = [];

            $.each($("input[name='seeking_m']:checked"), function(){
                categoriesArray.push($(this).val());
            });
        var catofExpertiseArray = [];
        $.each($("input[name='category_m']:checked"), function(){
            catofExpertiseArray.push($(this).val());
        });  
        var phone_val = $("#yourphone2").val();

        var passwordConf = $("#passwordConf").val();
        var passwordCheck = $("#password").val();
        if(passwordConf != passwordCheck){
            $("#matchPwd").show();
            $("#passwordConf").focus();
            return false;
        }else{
            $("#matchPwd").hide();
        }
        //phone_val = "1234567899";
            const requestObject={
            "siteId": process.env.React_App_SITE_ID,
            "countyId": 2,
            "classYear": $("#class_type").val(),
            "firstName": $('#firstName').val(),
            "lastName": $('#lastName').val(),
            "email": $('#email').val(),
            "password":$('#password').val(),

            //"phone":$('#yourphone2').val(),
            "phone":phone_val,
            "isStudent": true,////////////////////////////
            "reasonForSelectCareers":$('#careers').val(),
            "course": $('#courses_m').val(),
            "experiance":$('#skills_m').val(),
            "helpPrepare": "yes",
            "comments": $('#comments_m').val(),

            "status": "1",

            "schoolId": $("#school_district").val(),
            
            //"password": $('#password').val(),
            "categoriesOfExpertise":catofExpertiseArray,     
            //
            
            // ///////////////////////////////////
            // "interested": $('#interested').val(),
            "seekingEmploymentTypes": categoriesArray,
            // "seeking": $('#skills').val(),
            //"events": $('#events').val(),
            "authorization":$('#authorization_m').val(),
            "studentTalentConnectionForumIn": [1,2],
            "studentInterestedIn":[3,4]
            //"pdfByString":fileBase64,
            //"fileName":fileName,
            //"fileExtension":fileExtension
            }
            if(fileName !="" && fileExtension != "" ){
            requestObject.pdfByString = fileBase64;
            requestObject.fileName = fileName;
            requestObject.fileExtension = fileExtension;
            }
        console.log(requestObject);
        var accessTokenIn=sessionStorage.getItem("accToken");
        const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
                    //'Authorization': "Bearer "+accessTokenIn 
                    //'Authorization': accessTokenIn 
                },
        body: JSON.stringify(requestObject)
        };
        console.log(JSON.stringify(requestObject));
        Services.studentRegistration(requestOptions).then((res) => 
        {
        console.log(JSON.stringify(res));
        // alert(res.error);
        //  if(res.error){
        //    console.log(res.error);
        //  }else{
        //   console.log(res.success);
        //  }
        // navigate('/thankyou');

        });
        
        
        
        
        
        
        
        
        
        //navigate('/home');
        // history.push("/studentRegistration");
      }
      
  return(
  <>
     <section class="company-detail-wrap " id="step3">
            <div>
                <div class="col-12 ">
                <form onSubmit={handleSubmit4(submitValueStep4)}>
                    <div class="white-bg px-4">
                        <div class="row stu-reg-st3 pt-3">

                            <div class="col-lg-12 mb-5">
                                <div><label for="firstName" class="form-label mr-2" >TELL US WHY YOU SELECTED THESE CAREERS?<span class="red-text">*</span></label> <small>Max 500 Characters</small></div>
                                <textarea class="form-control textarea" placeholder="Leave a comment here" id="careers_m" spellcheck="false" {...register4('career', { required: true })}></textarea>
                                {errors4.career && errors4.career.type === "required" && <div class="error">Careers is required</div>}
                            </div>
                            <div class="col-lg-12 mb-5">
                                <div><label for="firstName" class="form-label mr-2 mb-0">WHAT COURSES HAVE YOU TAKEN THAT ARE RELATED TO THIS CAREER? <span class="red-text">*</span></label> <small>Max 500 Characters</small></div>
                                <textarea class="form-control textarea" placeholder="Leave a comment here" id="courses_related_m" spellcheck="false" {...register4('courses', { required: true })}></textarea>
                                {errors4.courses && errors4.courses.type === "required" && <div class="error">Courses is required</div>}
                            </div>
                            <div class="col-lg-12 mb-5">
                                <div><label for="firstName" class="form-label mr-2 mb-0" > LIST SOME SKILLS/EXPERIENCES THAT MAKE THIS A GOOD CHOICE FOR YOU.<span class="red-text">*</span></label> <small>Max 500 Characters</small></div>
                                {/* <span class="red-text">Required for Juniors and Seniors</span> */}
                                <textarea class="form-control textarea" placeholder="Leave a comment here" id="skills_m" spellcheck="false" {...register4('skills', { required: true })}></textarea>
                                {errors4.skills && errors4.skills.type === "required" && <div class="error">Skills is required</div>}
                            </div>
                            <div class="col-lg-12 mb-5">
                                <div><label for="firstName" class="form-label mr-2" >WHAT ADDITIONAL COURSES MIGHT HELP YOU PREPARE FOR THIS CAREER? <span class="red-text">*</span></label> <small>Max 500 Characters</small></div>
                                <textarea class="form-control textarea" placeholder="Leave a comment here" id="courses_m" spellcheck="false" {...register4('additional_course', { required: true })}></textarea>
                                {errors4.additional_course && errors4.additional_course.type === "required" && <div class="error">Additional course is required</div>}
                            </div>
                            <div class="col-lg-12 mb-5">
                                <div><label for="firstName" class="form-label mr-2" >ANY ADDITIONAL THOUGHTS/COMMENTS THAT MIGHT BE HELPFUL? <span class="red-text">*</span></label> <small>Max 500 Characters</small></div>
                                <textarea class="form-control textarea" placeholder="Leave a comment here" id="comments_m" spellcheck="false" {...register4('thoughts', { required: true })}></textarea>
                                {errors4.thoughts && errors4.thoughts.type === "required" && <div class="error">Additional thoughts is required</div>}
                            </div>
                            <div class="col-lg-12 mb-5">
                                <div><label for="firstName" class="form-label mr-2" >I’M CURRENTLY SEEKING EMPLOYMENT FOR: <span class="red-text">*</span></label> <small>Max 500 Characters</small></div>
                                <div class="category-check-itm">
                                    <ul>
                                    {
                                jobTypes.map((data, index) => (
                                    <li><input name="seeking_m" class="form-check-input" type="checkbox"  value={data.jobTypeId} id="defaultCheck1" {...register4('employment', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>
                                  ))}
                                        {/* <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register4('employment', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Full Time </label></li>
                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register4('employment', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Part Time</label></li>
                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register4('employment', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Summer/Season work </label></li>
                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register4('employment', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Internship/Co-opportunity  </label></li> */}
                                    </ul>
                                </div>
                                {errors4.employment && errors4.employment.type === "required" && <div class="error">Employment is required</div>}
                            </div>
                            <div class="col-lg-12">
                                <div class="justify-content-between align-items-end">
                                    <div>
                                        <label for="firstName" class="form-label">MY PARENT/GUARDIAN AUTHORIZES MY INFORMATION TO BE SHARED</label>
                                        <select class="form-select" aria-label="Default select example" style={{maxWidth: "420px"}} id="authorization_m">
                                    <option selected="">Select</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                 </select>
                                    </div>

                                </div>
                            </div>

                            <div class="mt-4 mb-4">
                                <button type="submit" class="reg-button">Register</button>
                            </div>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
        </section>
  </>
)};

StudentRegistration3.propTypes = {};

StudentRegistration3.defaultProps = {};

export default StudentRegistration3;