import React,{history,useEffect, useState, useMemo } from "react";
import Quick_links from "../utility/Quick_links";
import Hiring from "./Jobs/Hiring";
import services from "../services/services";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import { useNavigate, useParams } from "react-router-dom";
import SidebarStudent from "../utility/sidebar";
import SidebarProfile from "../utility/sidebarProfile";
import SidebarSchoolAdmin from "../utility/sidebarSchoolAdmin";
const EditAdminstrationLogin = () => {
    const navigate = useNavigate();
    const [profileDetails, setProfileDetails] = useState([]);
    //const userId=138;
    const userId=sessionStorage.getItem("logUserId");
    const companyId=sessionStorage.getItem("logCompanyId");

    const companyIdChkLogin=sessionStorage.getItem("logCompanyId");
    const studentIdChkLogin=sessionStorage.getItem("logStudentId");
    const jobSeekerIdChkLogin=sessionStorage.getItem("logProfileId");
    const userIdChkLogin=sessionStorage.getItem("logUserId");
    const chKRoleAr=sessionStorage.getItem("roleAr");



    const [imageName, setImageName] = useState('');
    const [imageExtension, setImageExtension] = useState('');
    const [banner, setBanner] = useState('');
    const [emaildefault, setemaildefault] = useState('');
    const [bannerBase64, setBannerBase64] = useState('');
    const [profilePicS3URL, setProfilePicS3URL] = useState('');
    //var emaildefault = '';

    const {
        register,reset,
        formState: { errors },
        handleSubmit
      } = useForm({
        criteriaMode: "all",mode: "onBlur" 
      });

      function getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          console.log(reader.result);

          var base64result = reader.result.split(',')[1];
          //setBannerBase64(reader.result)
          setBannerBase64(base64result);
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
     }

      function getImage(){
        var files = document.getElementById('banner').files;     
        
        if (files.length > 0) {
            var fname = files[0].name;
            var fextn = fname.split(".").pop();
            var fileActName = fname.split(".")[0];
            setImageName(fileActName);
            setImageExtension(fextn);           
          getBase64(files[0]);
        }
      }  
    useEffect(() => {
        $("#matchPwd").hide();
        $('#phone').usPhoneFormat();
        $( "#passwordTxtConf" ).blur(function() {
            var passwordConf = $("#passwordTxtConf").val();
            var passwordCheck = $("#passwordTxt").val();
            if(passwordConf != passwordCheck){
            $("#matchPwd").show();          
            }else{
            $("#matchPwd").hide();
            }
        });
        /*
        if(companyId == null || companyId == "null"){            
            //navigate('/login');
            navigate('/Home');
          }
        */
          if(userId == null || userId == "null"){            
            //navigate('/login');
            navigate('/Home');
          }
        getUserProfile();
        
        $("#successMessage").hide();
        $("#imgError").hide();
 
    }, []);



    const getUserProfile = () => {
        services.getUserInfo(userId).then((res) => {
 
         setProfileDetails(res.data);
         $("#firstNameTxt").val(res.data.firstName);
         $("#lastNameTxt").val(res.data.lastName);
         $("#emailTxt").val(res.data.email);
         $("#phone").val(res.data.phone);
         //emaildefault = res.data.email;
         setemaildefault(res.data.email)
         setProfilePicS3URL(res.data.profilePicS3URL)
         // alert(profileDetails.name);
        });
     }

      const submitValue=()=>
      {
       //////////////////
        var pwd =  $("#passwordTxt").val();
       const requestObject =
       {
          "companyId": companyId,
          "siteId": process.env.React_App_SITE_ID,
          //"videoUrl": $("#videoUrl").val(),

         
          
            "userId": userId,
            "firstName": $("#firstNameTxt").val(),
            "lastName":  $("#lastNameTxt").val(),
            //"email":  $("#emailTxt").val(),
            "email": emaildefault,
           // "password":  $("#passwordTxt").val(),
            "phone" : $("#phone").val(),
                  
 
       }
       if(pwd != ""){

        var passwordConf = $("#passwordTxtConf").val();
        var passwordCheck = $("#passwordTxt").val();
        if(passwordConf != passwordCheck){
         $("#matchPwd").show();
         $("#passwordTxtConf").focus();
         return false;
        }else{
         $("#matchPwd").hide();
        }

        requestObject['password'] = pwd;
       }
       if(imageName !=""){
        
        requestObject.fileName = imageName;
        requestObject.fileExtension = imageExtension;
        requestObject.pdfByString = bannerBase64;
        
       }
 
       console.log(requestObject);
       var accessTokenIn=sessionStorage.getItem("accToken");
       const requestOptions = {
          method: 'POST',
          headers: {
             'Content-Type': 'application/json',
              'Authorization': "Bearer "+accessTokenIn 
              //'Authorization': accessTokenIn 
          },
          body: JSON.stringify(requestObject)
       };
       //editCompanyUser
       //alert("120");
       services.updateUserProfile(requestOptions).then((res) => {
          // navigate('/thankyou');
           // $("#editCompanyUserForm").trigger("reset");
           // getUserProfile();
           $("#successMessage").fadeTo(2000, 1000).slideUp(1000, function () {
            $("#successMessage").slideUp(1000);
          });
          if( companyIdChkLogin != null && companyIdChkLogin != "null"){   
            //setTimeout(function() {navigate('/companyJobs');}, 2000);
          }

          if( jobSeekerIdChkLogin != null && jobSeekerIdChkLogin != "null"){  
            setTimeout(function() {navigate('/profileDashboard');}, 2000);
          }

          if( studentIdChkLogin != null && studentIdChkLogin != "null"){     
            setTimeout(function() {navigate('/studentDashboard');}, 2000); 
          }
       });



       //////////////
      }

  return(
  <>
<main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        {/* <!-- info typography --> */}
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb">
                            <ol class="breadcrumb">

                            {(() => {
                                
                                if( companyIdChkLogin != null && companyIdChkLogin != "null"){            
                                    return (<> <li class="breadcrumb-item headtitle "><a href="/companyJobs">Company Dashboard</a> </li>
                                    <li class="breadcrumb-item headtitle "><a href="#">User Profile</a></li></>);
                                }

                                if( jobSeekerIdChkLogin != null && jobSeekerIdChkLogin != "null"){            
                                    return (<><li class="breadcrumb-item headtitle"> <a href="/profileDashboard">Job Seeker Dashboard</a> </li>
                                    <li class="breadcrumb-item headtitle "><a href="#">User Profile</a></li></>);
                                }

                                if( studentIdChkLogin != null && studentIdChkLogin != "null"){            
                                    return (<> <li class="breadcrumb-item headtitle "><a href="/studentDashboard">Student Dashboard</a> </li>
                                    <li class="breadcrumb-item headtitle "><a href="#">User Profile</a></li></>);
                                }

                                if( chKRoleAr == "schooladmin" || chKRoleAr == "schoolsuper"){                    
                                    return (<> <li class="breadcrumb-item headtitle "><a href="/schoolAdminDashboard">School Admin</a> </li>
                                    <li class="breadcrumb-item headtitle "><a href="#">User Profile</a></li></>);
                                }

                                })()}    


                               
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-5">
                            <div class="row">
                                {/* <!-- left side --> */}

                                {(() => {
                                
                                if( companyIdChkLogin != null && companyIdChkLogin != "null"){            
                                    return (<><Quick_links/></>);
                                }

                                if( jobSeekerIdChkLogin != null && jobSeekerIdChkLogin != "null"){            
                                    return (<><SidebarProfile/></>);
                                }

                                if( studentIdChkLogin != null && studentIdChkLogin != "null"){            
                                    return (<> <SidebarStudent/></>);
                                }

                                if( chKRoleAr == "schooladmin" || chKRoleAr == "schoolsuper"){            
                                    return (<> <SidebarSchoolAdmin/></>);
                                }

                                })()}    
                               

                                {/* <!-- right side --> */}
                                <div class="col-lg-8 mb-4">
                                <form onSubmit={handleSubmit(submitValue)} id="editCompanyUserForm">
                                    <div className="col-12 mb-4">
                                    <div class=" mb-4">
                                        <div class="user-picture">
                                            {/* <img src="images/student1.png" alt=""/> */}
                                            <img src={profilePicS3URL?profilePicS3URL:"images/user-default.jpg"} alt=""/>
                                            
                                        </div>
                                    </div>
                                    </div>


                                    <div class="d-flex justify-content-between align-items-end choose mb-4">
                                        <div>
                                            <label for="firstName" class="form-label">Change Profile Pic </label>
                                            <div class="d-flex align-items-center">
                                                <input class="form-control form-control-lg" id="banner" type="file" onChange={()=>getImage()}/>
                                            </div>
                                            <small> 1 MB Max, File type: png or jpg</small>
                                            <div id="imgError"  class="error">Invalid file format</div>
                                        </div>
                                        
                                    </div>


                                    <div class="row resume-wrap">
                                        <div class="col-lg-6 mb-4">
                                            <label for="firstName" class="form-label">First Name<span class="red-text">*</span></label>
                                            <input type="text" class="form-control" name="firstName" defaultValue="First name" id="firstNameTxt"   {...register('firstName', { required: true })}/>
                                            {errors.firstName && errors.firstName.type === "required" && <div class="error">First name is required</div>}
                                        </div>

                                        <div class="col-lg-6 mb-4">
                                            <label for="firstName" class="form-label">Last Name<span class="red-text">*</span></label>
                                            <input type="text" class="form-control" id="lastNameTxt" defaultValue="Last name"  {...register('lastName', { required: true })}/>
                                            {errors.lastName && errors.lastName.type === "required" && <div class="error">Last name is required</div>}
                                        </div>

                                        <div class="col-lg-6 mb-4">
                                            <label for="firstName" class="form-label">Email<span class="red-text">*</span></label>
                                            <input type="text" readOnly class="form-control" id="emailTxt"  defaultValue="test@gmail.com"  {...register('email', { required: true, pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/ })}/>
                                            {errors.email && errors.email.type === "required" && <div class="error">Email is required</div>}
                                            {errors.email && errors.email.type === "pattern" && <div class="error">Invalid email address.</div> }
                                        </div>

                                        <div class="col-lg-6 mb-4">
                                        <label for="phone" class="form-label">Phone<span class="red-text">*</span></label>
                                            <input type="text" class="form-control" id="phone" defaultValue="1234567890" placeholder="" {...register('phone', { required: true })}/>
                                            {errors.phone && errors.phone.type === "required" && <div class="error">Phone number is required</div>}
                                        </div>

                                         <div class="col-lg-6 mb-4">
                                            <label for="firstName" class="form-label">Password<span class="red-text"></span></label>
                                            <p style={{fontSize: "12px"}}>Minimum of 8 characters long, and a combination of lowercase & uppercase letters, numbers, and special characters.</p>
                                            <input type="password" class="form-control" id="passwordTxt"  defaultValue="" {...register('password', { required: false,pattern:/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/ })}/>
                                            {/* {errors.password && errors.password.type === "required" && <div class="error">Password is required</div>} */}
                                            {/* { errors.password.type === "pattern" && <div class="error">Password contain uppercase,lowercase and special character</div>} */}
                                            {errors.password && errors.password.type === "pattern" && <div class="error">Password contain uppercase,lowercase and special character</div>}
                                             <small>(only fill this out if it needs to be changed)</small>
                                        </div> 

                                        <div class="col-lg-6 mb-4">
                                        <label for="firstName" class="form-label">Confirm Password<span class="red-text"></span></label>
                                        <p style={{fontSize: "12px"}}>Minimum of 8 characters long, and a combination of lowercase & uppercase letters, numbers, and special characters.</p>
                                            <input type="password" class="form-control" id="passwordTxtConf"  defaultValue="" {...register('passwordTxtConf', { required: false,pattern:/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/ })}/>
                                            {errors.passwordTxtConf && errors.passwordTxtConf.type === "pattern" && <div class="error">Password contain uppercase,lowercase and special character</div>}
                                            <p id="matchPwd" class="error">Passwords must match</p>
                                        </div>
                                        


                                        

                                        <div class="col-lg-12 mb-4">

                                            <div class="d-flex justify-content-between align-items-end choose">

                                                <div><button type="submit" class="reg-button">Update</button></div>
                                            </div>

                                        </div>

                                        <div id="successMessage" class="alert">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <div class="d-flex align-items-center">
                                                    <img src="/images/success-alert.png" alt=""/>
                                                    <strong class="mx-2">Success!</strong> Details updated successfully.
                                                </div>
                                                <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                                            </div>
                                        </div>


                                        
                                    </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </section>
        {/* <Hiring/> */}
    </main>
  </>
)};

EditAdminstrationLogin.propTypes = {};

EditAdminstrationLogin.defaultProps = {};

export default EditAdminstrationLogin;
