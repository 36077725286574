import React, { history, useEffect, useState, useMemo } from "react";
import Services from "../../services/services";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import { useNavigate } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import Modal from 'react-modal';
const PostJob = () => {
  const navigate = useNavigate();
  const [categories, setCategory] = useState([]);
  const [jobTypes, setJobType] = useState([]);
  const [jobSummary, setJobSummary] = useState('');
  const [jobExperience, setJobExperience] = useState('');
  const [jobSalary, setJobSalary] = useState('');

  const [educations, setEducation] = useState([]);
  const [locationCounty, setLocationCounty] = useState([]);
  const [countListAr, setCountListAr] = useState([]);
  
  const companyId = sessionStorage.getItem("logCompanyId");

  //get animated components wrapper
  const animatedComponents = makeAnimated();

  //set default query terms
  const [query, setQuery] = useState("");
  let jobTitleGlobal = '';

  // fetch filteres search results for dropdown
  const loadOptions = () => {
    return fetch(`https://wagesurvey.smallnation.site/api/jobSugList?title=${query}`)
      .then((res) => res.json());
  };

  const handleChange = (selectedOption) => {
    //alert(JSON.stringify(selectedOption));
    var title = selectedOption.title;
    jobTitleGlobal = selectedOption.title;
    setJobCode(title);
    setSocCodeTitle(title);
    setSocCode(selectedOption.socCode);
    setTimeout(function () { getWageInfoCall(); }, 2000);
  }

  const [countyDisplayName, setCountyDisplayName] = useState('');
  const [collab, setCollabs] = useState('');
  const [socCode, setSocCode] = useState('');
  const [socCodeTitle, setSocCodeTitle] = useState('');
  const [jobCodeTitle, setJobCode] = useState('');
  const [wageList, setWageListObj] = useState('');
  const [jobCodeTitleInput, setJobCodeTitleInput] = useState('');


  const [dolWageLevel1, setDolWageLevel1] = useState('');
  const [dolWageLevel2, setDolWageLevel2] = useState('');
  const [dolWageLevel3, setDolWageLevel3] = useState('');
  const [dolWageLevel4, setDolWageLevel4] = useState('');

  const [dolWageHourlyLevel1, setDolWageHourlyLevel1] = useState('');
  const [dolWageHourlyLevel2, setDolWageHourlyLevel2] = useState('');
  const [dolWageHourlyLevel3, setDolWageHourlyLevel3] = useState('');
  const [dolWageHourlyLevel4, setDolWageHourlyLevel4] = useState('');

  const [surveyWageLevel1, setsurveyWageLevel1] = useState('');
  const [surveyWageLevel2, setsurveyWageLevel2] = useState('');
  const [surveyWageLevel3, setsurveyWageLevel3] = useState('');
  const [surveyWageLevel4, setsurveyWageLevel4] = useState('');

  const [surveyWageHourlyLevel1, setsurveyWageHourlyLevel1] = useState('');
  const [surveyWageHourlyLevel2, setsurveyWageHourlyLevel2] = useState('');
  const [surveyWageHourlyLevel3, setsurveyWageHourlyLevel3] = useState('');
  const [surveyWageHourlyLevel4, setsurveyWageHourlyLevel4] = useState('');


  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);


  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    $(".load-wrap").hide();
    if (companyId == null || companyId == "null") {
      //navigate('/login');
      navigate('/Home');
    }

    

    $("#jobSummaryValidMsg").hide();
    $("#jobExperienceValidMsg").hide();
    $("#jobSalaryValidMsg").hide();
    $("#successMessage").hide();
    getIndustryData();
    getJobType();
    getEducation();
    
    getLocationCounty();
    /*
    $( "#countyId" ).change(function() {
      getWageInfoCall();
    });
    */


    $("#title").change(function () {
      var st = $("#title").val();
      setJobCodeTitleInput(st);
    });
    


  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    criteriaMode: "all", mode: "onBlur"
  });

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      width: '100%',
      maxWidth: '600px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',

    },
  };

  function validateCheck() {
    const checked = $('input[name="category"]:checked').length;

    if (checked >= 3) {
      $('input[name="category"]').not(':checked').prop('disabled', true);
    } else {
      $('input[name="category"]').prop('disabled', false);
    }
  }


  const getWageInfoCall = () => {
    //var state = $("#stateId").val();
    //var county = $("#countyId").val(); 
    var job_code = $("#jobCodeTitle").html();
    var soc_code = $("#socCodeVal").html();

    var state = 'OH';
    //var county = 'Crawford County';
    var countyId = $("#countyId").val();
    //alert(countyId);
   // alert(JSON.stringify(countListAr));
    //alert( countListAr[countyId]);
    if(countyId == ""){
      var county = 'Crawford County';
    }else{
      var county = countListAr[countyId]+' County';
    }
   
    
    county = county == ""?'Crawford County':county;
    //alert(county);
    setCountyDisplayName(county)

    //alert(socCodeTitle);      
    // alert(job_code);
    const requestObject = {
      "stateCode": state,
      "county": county,
      //"job_code": jobCode, 
      "job_code": job_code,
      "soccode": soc_code,
      //"soccode": socCode,      

    }
    // console.log(JSON.stringify(objInputAvailableJobs));
    var accessTokenIn = sessionStorage.getItem("accToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //'Authorization': "Bearer "+accessTokenIn 
        //'Authorization': accessTokenIn 
      },
      body: JSON.stringify(requestObject)
    };

    Services.getSurveyWageList(requestOptions).then((res) => {
      console.log(JSON.stringify(res))
      if (res) {
        setWageListObj(res);
        setDolWageLevel1('');
        setDolWageLevel2('');
        setDolWageLevel3('');
        setDolWageLevel4('');

        setDolWageHourlyLevel1('');
        setDolWageHourlyLevel2('');
        setDolWageHourlyLevel3('');
        setDolWageHourlyLevel4('');

        setsurveyWageLevel1('');
        setsurveyWageLevel2('');
        setsurveyWageLevel3('');
        setsurveyWageLevel4('');

        setsurveyWageHourlyLevel1('');
        setsurveyWageHourlyLevel2('');
        setsurveyWageHourlyLevel3('');
        setsurveyWageHourlyLevel4('');
        try {
          if (typeof (res.dolWage[0].level1) != "undefined" && res.dolWage[0].level1 != null) {
            // alert(res.dolWage[0].level1);
            setDolWageLevel1(res.dolWage[0].level1);
            setDolWageLevel2(res.dolWage[0].level2);
            setDolWageLevel3(res.dolWage[0].level3);
            setDolWageLevel4(res.dolWage[0].level4);
          }
        } catch (ex) {

        }


        try {
          if (typeof (res.dolWageHourly[0].level1) != "undefined" && res.dolWageHourly[0].level1 != null) {

            setDolWageHourlyLevel1(res.dolWageHourly[0].level1);
            setDolWageHourlyLevel2(res.dolWageHourly[0].level2);
            setDolWageHourlyLevel3(res.dolWageHourly[0].level3);
            setDolWageHourlyLevel4(res.dolWageHourly[0].level4);
          }
        } catch (ex) {

        }



        try {
          if (typeof (res.surveyWage[0].level1) != "undefined" && res.surveyWage[0].level1 != null) {
            setsurveyWageLevel1(res.surveyWage[0].level1);
            setsurveyWageLevel2(res.surveyWage[0].level2);
            setsurveyWageLevel3(res.surveyWage[0].level3);
            setsurveyWageLevel4(res.surveyWage[0].level4);
          }
        } catch (ex) {

        }



        try {
          if (typeof (res.surveyWageHourly[0].level1) != "undefined" && res.surveyWageHourly[0].level1 != null) {
            setsurveyWageHourlyLevel1(res.surveyWageHourly[0].level1);
            setsurveyWageHourlyLevel2(res.surveyWageHourly[0].level2);
            setsurveyWageHourlyLevel3(res.surveyWageHourly[0].level3);
            setsurveyWageHourlyLevel4(res.surveyWageHourly[0].level4);
          }
        } catch (ex) {

        }


      }

    });



  }


  const submitValue = () => {

    var categoriesArray = [];
    $.each($("input[name='category']:checked"), function () {
      categoriesArray.push($(this).val());
    });
    var validateEditorTxt = 1;
    if (jobSummary == "") {
      $("#jobSummaryValidMsg").show();
      // $("#jobSummaryValidMsg").focus();
      $(window).scrollTop($('#jobSummaryValidMsg').position().top);
      validateEditorTxt = 0;

    }

    if (jobExperience == "") {
      $("#jobExperienceValidMsg").show();
      //$("#jobExperienceValidMsg").focus();
      $(window).scrollTop($('#jobExperienceValidMsg').position().top);
      validateEditorTxt = 0;

    }

    if (jobSalary == "") {
      $("#jobSalaryValidMsg").show();
      //$("#jobSalaryValidMsg").focus();
      $(window).scrollTop($('#jobSalaryValidMsg').position().top);
      validateEditorTxt = 0;

    }
    if (validateEditorTxt == 0) {
      return false;
    }

    console.log(JSON.stringify(categoriesArray));

    const requestObject =
    {
      "siteId": process.env.React_App_SITE_ID,
      "companyId": companyId,
      "countyId": $("#countyId").val(),
      "jobTypeId": $("#jobTypeId").val(),
      "educationId": $("#educationId").val(),
      //"posted" : $("#cname").val(),
      "title": $("#title").val(),
      //"summary" : $("#summary").val(),
      "summary": jobSummary,
      //"experiance" : $("#experiance").val(),
      "experiance": jobExperience,
      "career": $("#career").val(),
      "apply": $("#apply").val(),
      // "retail" : $("#cname").val(),
      "reference": $("#reference").val(),
      //"salary" : $("#salary").val(),
      "salary": jobSalary,
      // "status" : $("#cname").val(),
      //"updatedOn" : $("#cname").val(),
      //"jobCategory":categoriesArray,   
      "jobCategoryRelId": categoriesArray,

    }
    console.log(requestObject);
    var accessTokenIn = sessionStorage.getItem("accToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + accessTokenIn
        //'Authorization': accessTokenIn 
      },
      body: JSON.stringify(requestObject)
    };
    $(".load-wrap").show();
    Services.postJob(requestOptions).then((res) => {
      

      $("#successMessage").fadeTo(2000, 500).slideUp(500, function () {
        $(".load-wrap").hide();
        $("#successMessage").slideUp(500);
      });
      // navigate('/companyJobs');
      setTimeout(function () { navigate('/companyJobs'); }, 2000);

    });


  }

  const getIndustryData = () => {
    Services.getIndustryData().then((res) => {
      console.log("Industry data=" + JSON.stringify(res.data));
      setCategory(res.data.content);
    });
  }

  const getJobType = () => {
    Services.getJobType().then((res) => {
      console.log("setJobType data=" + JSON.stringify(res.data));
      setJobType(res.data.content);
    });
  }

  const getEducation = () => {
    Services.getEducation().then((res) => {
      console.log("getEducation data=" + JSON.stringify(res.data));
      setEducation(res.data.content);
    });
  }
  
  const changeCountyTrigger = () => {
    //alert('400');
    getWageInfoCall();
  }
  const getLocationCounty = () => {
    Services.getLocationCounty().then((res) => {
      console.log("getLocationCounty data=" + JSON.stringify(res.data));
      var countyArr = res.data.content;
      var countListArTemp = {};
      //alert(JSON.stringify(countyArr))
      for(var j=0; j<countyArr.length; j++){
        var atval = countyArr[j]['jobLocationCountyId'];
        countListArTemp[atval]= countyArr[j].name;
        //alert(JSON.stringify(countListArTemp))
      }
      //alert(JSON.stringify(countLdirectoryistArTemp))
      setCountListAr(countListArTemp);
      setLocationCounty(res.data.content);
      /*
      $( "#countyId" ).change(function() {
         setCountListAr(countListArTemp);
        getWageInfoCall();
      });
      */
    });
  }

  return (
    <>
      <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">

                <nav style={{ "--bs-breadcrumb-divider": "'>';" }} aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item headtitle"> <a href="/companyJobs">Company Dashboard</a> </li>
                    <li class="breadcrumb-item headtitle "><a href="#">Post a Job </a></li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- info typography --> */}
        <section class="company-detail-wrap py-5 ">
          <form onSubmit={handleSubmit(submitValue)}>
            <div class="container">
              <div class="row">
                <div class="col-12 ">
                  <div class="white-bg px-5 pt-4 pb-4">

                    {/* <div class="row">
                          <div class="col-lg-12 m-auto">
                                <h1 class="text-center ">
                                   Post Job
                                </h1>
                                <div class="col-3 m-auto">
                                    <hr/>
                                </div>
                          </div>
                         
                      </div> */}

                    <div class="row mt-4 resume-wrap">
                      <div class="col-lg-12 mb-4">
                        <label for="firstName" class="form-label">Job Title <span class="red-text">*</span></label>
                        <input type="text" class="form-control" id="title" name="title" placeholder="" {...register('title', { required: true })} />
                        {errors.title && errors.title.type === "required" && <div class="error">Title is required</div>}
                      </div>



                      <div class="col-lg-12 mb-4">
                        <label for="firstName" class="form-label">Standardized job code</label>
                        {/* <input type="text"  class="form-control" id="stjobcode"  defaultValue=""  {...register('stjobcode', { required: true, pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/ })}/> */}


                        <AsyncSelect
                          cacheOptions
                          components={animatedComponents}
                          getOptionLabel={(e) => e.title}
                          getOptionValue={(e) => e.socCode}
                          loadOptions={loadOptions}
                          onInputChange={(value) => setQuery(value)}
                          onChange={handleChange}
                        // onChange={(value) => setSocCode(value)}
                        />
                      </div>

                      <div class="col-lg-12 mb-4">
                        <label for="phone" class="form-label">Soc Code:</label>
                        <span id="socCodeVal">{socCode}</span>
                      </div>

                      <div class="col-lg-6 mb-4">
                        <label for="firstName" class="form-label">Job Type </label>
                        <select class="form-select" aria-label="Default select example" name="jobTypeId" id="jobTypeId" >
                          <option value='' selected>Select</option>
                          {/* <option value="1">One</option>
                               <option value="2">Two</option>
                               <option value="3">Three</option> */}
                          {
                            jobTypes.map((data, index) => (
                              <option value={data.jobTypeId}>{data.name}</option>
                            ))}
                        </select>

                      </div>

                      <div class="col-lg-6 mb-4">
                        <label for="firstName" class="form-label"> Education <span class="red-text">*</span></label>
                        <select class="form-select" aria-label="Default select example" name="educationId" id="educationId" {...register('educationId', { required: true })}>
                          <option value='' selected>Select</option>
                          {/* <option value="1">One</option>
                               <option value="2">Two</option>
                               <option value="3">Three</option> */}
                          {
                            educations.map((data, index) => (
                              <option value={data.educationId}>{data.name}</option>
                            ))}
                        </select>
                        {errors.educationId && errors.educationId.type === "required" && <div class="error">Education is required</div>}
                      </div>

                      <div class="col-lg-6 mb-4">
                        <label for="firstName" class="form-label"> Job Is Located In Which County? </label>
                        <select class="form-select" aria-label="Default select example" onChange={changeCountyTrigger} name="countyId" id="countyId">
                           <option value='' selected>Select</option> 
                          {/* <option value="1">One</option>
                               <option value="2">Two</option>
                               <option value="3">Three</option> */}
                          {
                            locationCounty.map((data, index) => (
                              <option value={data.jobLocationCountyId} >{data.name}</option>
                            ))}
                        </select>
                      </div>

                      <div class="col-lg-6 mb-4">
                        <label for="firstName" class="form-label">Career Level<span class="red-text">*</span></label>
                        <select class="form-select" aria-label="Default select example" name="career" id="career" {...register('career', { required: true })}>
                          <option selected value=''>Select</option>
                          <option value="Entry-Level">Entry-Level</option>
                          <option value="Mid-Level">Mid-Level</option>
                          <option value="Management/Supervisor">Management/Supervisor</option>
                          <option value="Executive Level">Executive Level</option>
                        </select>
                        {errors.career && errors.career.type === "required" && <div class="error">Career level is required</div>}
                      </div>

                      <div class="col-lg-6 mb-4">
                        <label for="firstName" class="form-label">How to Apply</label>
                        <input type="text" class="form-control" id="apply" name="apply" placeholder="" />                         
                      </div>


                      {/* <div class="col-lg-6 mb-4">
                        <label for="firstName" class="form-label">How to Apply<span class="red-text">*</span></label>
                        <input type="text" class="form-control" id="apply" name="apply" placeholder="https://www.example.com" {...register("Apply", {
                                    required: "How to apply is required.",
                                   
                                    pattern: {
                                      value: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i,
                                      message: "invalid Website URL"
                                    }
                                    })} />
                         <ErrorMessage
                                    errors={errors}
                                    name="Apply"
                                    class="invalid-feedback"
                                    render={({ messages }) => {
                                    console.log("messages", messages);
                                    return messages
                                        ? Object.entries(messages).map(([type, message]) => (
                                            <p key={type}  class="error">{message}</p>
                                        ))
                                        : null;
                                    }}
                                /> 
                      </div> */}

                      <div class="col-lg-6 mb-4">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">Reference Code </label></div>
                        <input type="text" class="form-control" name="reference" id="reference" placeholder=""  />
                        
                      </div>


                      {/* <div class="col-lg-12 mb-4">
                            <div class="d-flex"><label for="firstName" class="form-label mr-2">Summary <span class="red-text">*</span></label> <small>Max 2000 Characters</small></div>
                            <textarea class="form-control textarea" placeholder="Leave a comment here" id="summary" name="summary" spellcheck="false" {...register('summary', { required: true,maxLength:2000 })}></textarea>
                            {errors.summary && errors.summary.type === "required" && <div class="error">Summary is required</div>}
                            {errors.summary && errors.summary.type === "maxLength" && <div class="error">Should not allow more than 2000 character</div>}       
                          </div> */}

                      <div class="col-lg-12 mb-4">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">Summary <span class="red-text">*</span></label></div>
                        <CKEditor
                          editor={ClassicEditor}
                          id="summary"
                          onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                            console.log('Editor is ready to use!', editor);
                            //editor.setData(profileDescription);
                            //editor.setData(profileDescription);

                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setJobSummary(data);
                            console.log({ event, editor, data });
                          }}
                          onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                          }}
                          onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                          }}
                        />
                        <div id="jobSummaryValidMsg" class="error">Summary is required.</div>
                      </div>


                      <div class="col-lg-12 mb-4">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">Experience/Certification Required <span class="red-text">*</span></label></div>

                        <CKEditor
                          editor={ClassicEditor}
                          id="experiance"
                          onReady={editor => {
                            console.log('Editor is ready to use!', editor);
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setJobExperience(data);
                            console.log({ event, editor, data });
                          }}
                          onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                          }}
                          onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                          }}
                        />
                        <p id="jobExperienceValidMsg" class="error">Experience/Certification is required.</p>


                        {/* <textarea class="form-control textarea" placeholder="Leave a comment here" id="experiance" name="experiance" spellcheck="false" {...register('experiance', { required: true, maxLength:2000 })}></textarea>
                           {errors.experiance && errors.experiance.type === "required" && <div class="error">Experience/Certification is required</div>}
                           {errors.experiance && errors.experiance.type === "maxLength" && <div class="error">Should not allow more than 2000 character</div>}        */}
                      </div>

                      <div class="col-lg-12 mb-4">
                        <div>
                          <button onClick={openModal} class="reg-button mb-5">WAGE DATA</button>
                          <Modal
                            isOpen={modalIsOpen}
                            onAfterOpen={afterOpenModal}
                            onRequestClose={closeModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                          >
                            <h2 ref={(_subtitle) => (subtitle = _subtitle)}>WAGE DATA</h2>
                            <button onClick={closeModal} class="wage-close">close</button>


                            <table class="table wage-table">
                              <tr>
                                <td>
                                  <b>Location: </b> OH, {countyDisplayName}
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <b>Job Title: </b> {jobCodeTitleInput}
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <b>Standardized Job Title: </b> <span id="jobCodeTitle">{jobCodeTitle}</span>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <b>SOC CODE: </b> <span >{socCode}</span>
                                </td>
                              </tr>


                            </table>


                            <table class="table wage-data-tb" >
                              <thead class="thead-light">
                                <tr >
                                  <th scope="col">SOURCE</th>
                                  <th scope="col">Type</th>
                                  <th scope="col">LEVEL-1</th>
                                  <th scope="col">LEVEL-2</th>
                                  <th scope="col">LEVEL-3</th>
                                  <th scope="col">LEVEL-4</th>


                                </tr>
                              </thead>
                              <tbody>
                                <tr>

                                  <th scope="row" rowspan="2">DOL Wage Data</th>
                                  <td id="">Yearly</td>
                                  <td id="dol_level1">{dolWageLevel1}</td>
                                  <td id="dol_level2">{dolWageLevel2}</td>
                                  <td id="dol_level3">{dolWageLevel3}</td>
                                  <td id="dol_level4">{dolWageLevel4}</td>

                                </tr>

                                <tr>
                                  <td id="">Hourly</td>
                                  <td id="dol_level1_hourly">{dolWageHourlyLevel1}</td>
                                  <td id="dol_level2_hourly">{dolWageHourlyLevel2}</td>
                                  <td id="dol_level3_hourly">{dolWageHourlyLevel3}</td>
                                  <td id="dol_level4_hourly">{dolWageHourlyLevel4}</td>

                                </tr>

                                <tr>
                                  <th scope="row" rowspan="2">Local Survey Data</th>

                                  <td id="">Yearly</td>
                                  <td id="com_level1">{surveyWageLevel1}</td>
                                  <td id="com_level2">{surveyWageLevel2}</td>
                                  <td id="com_level3">{surveyWageLevel3}</td>
                                  <td id="com_level4">{surveyWageLevel4}</td>

                                </tr>


                                <tr>
                                  <td id="">Hourly</td>
                                  <td id="com_level1_hourly">{surveyWageHourlyLevel1}</td>
                                  <td id="com_level2_hourly">{surveyWageHourlyLevel2}</td>
                                  <td id="com_level3_hourly">{surveyWageHourlyLevel3}</td>
                                  <td id="com_level4_hourly">{surveyWageHourlyLevel4}</td>

                                </tr>

                              </tbody>
                            </table>






                          </Modal>
                        </div>



                        <div class="d-flex"><label for="firstName" class="form-label mr-2">Salary/Benefits <span class="red-text">*</span></label></div>
                        <CKEditor
                          editor={ClassicEditor}
                          id="salary"
                          onReady={editor => {
                            console.log('Editor is ready to use!', editor);
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setJobSalary(data);
                            console.log({ event, editor, data });
                          }}
                          onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                          }}
                          onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                          }}
                        />
                        <p id="jobSalaryValidMsg" class="error">Salary/Benefits is required.</p>

                        {/* <textarea class="form-control textarea" placeholder="Leave a comment here" id="salary" name="salary" spellcheck="false" {...register('salary', { required: true,maxLength:2000 })}></textarea>
                           {errors.salary && errors.salary.type === "required" && <div class="error">Salary/Benefits is required</div>}
                           {errors.salary && errors.salary.type === "maxLength" && <div class="error">Should not allow more than 2000 character</div>}        */}
                      </div>




                      <div class="col-lg-12 mb-4">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2 mb-3">Job Industry <span class="red-text" >*</span></label> <small>Max 3 Categories</small></div>

                        <div >
                          <div class="category-check-itm">
                            <ul class="category-check">
                              {
                                categories.map((data, index) => (


                                  <li><input class="form-check-input" type="checkbox" name="category" value={data.catId} id="category" {...register('category', { required: true, maxLength: 2000 })} onClick={() => validateCheck()} />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                ))}
                              {/* <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Accounting </label></li> */}
                              {/* <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Advanced Manufacturing</label></li>
                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> CNC  </label></li>
                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Advertising and PR Services  </label></li>
                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Agriculture and Food Processing  </label></li>
                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Architectural and Design Services  </label></li>
                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Art Athletic Training/Physical/ </label></li>
                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Art Athletic Training/Physical/ </label></li>
                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Occupational Therapy  </label></li>
                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Auto/Diesel </label></li> */}
                            </ul>
                          </div>
                         

                        </div>
                        {errors.category && errors.category.type === "required" && <div class="error">Job industry check atleast one</div>}
                        {errors.category && errors.category.type === "maxLength" && <div class="error">Should not allow more than 2000 character</div>}
                      </div>






                      <div class="col-lg-12 mb-4">

                        <div class="d-flex justify-content-between align-items-end choose">

                          <div><button type="submit" class="reg-button">post Job</button></div>
                        </div>

                      </div>

                      <div id="successMessage" class="alert alert-success" role="alert">
                        Job Posted Successfully</div>

                    </div>



                  </div>




                </div>

              </div>
            </div>
          </form>
        </section>

        <section class="load-wrap">
          <div class="loadingIcon">
            <img src="images/loading.gif"></img>
             </div>
        </section>

      </main>
    </>
  )
};

PostJob.propTypes = {};

PostJob.defaultProps = {};

export default PostJob;
