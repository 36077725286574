//import React, { useEffect, useState } from 'react';
import React,{history,useEffect, useState, useMemo } from "react";
import { useNavigate,useParams,useLocation,useHistory } from "react-router-dom";
import $, { parseJSON } from 'jquery';
import { useKeycloak } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';
import services from "../../services/services";
import { ReactKeycloakProvider } from '@react-keycloak/web';
//import { useNavigate } from 'react-router-dom';
import jwt from 'jwt-decode' // import dependency
const Header = () => {
const navigate = useNavigate();    
const companyIdChkLogin=sessionStorage.getItem("logCompanyId");
const studentIdChkLogin=sessionStorage.getItem("logStudentId");
const jobSeekerIdChkLogin=sessionStorage.getItem("logProfileId");
const userIdChkLogin=sessionStorage.getItem("logUserId");

const location = useLocation();
const queryParams = new URLSearchParams(location.search);
const loginRedirectVal = queryParams.get('loginRedirect');
const loginResetVal = queryParams.get('loginReset');

const keycloak = new Keycloak({    
    url:process.env.React_App_KEYCLOAK_URL,
    realm: process.env.React_App_REALM,
    clientId: process.env.React_App_KEYCLOAK_CLIENT_ID  
  })
  
  const logout=()=>
  {
    sessionStorage.clear();  
    sessionStorage.setItem("hideFlashPage","1"); 

      keycloak.logout();
      keycloak.authenticated = false;
      //window.location.replace("/");
      setTimeout(function() {window.location.replace("/");}, 1000);
      //window.location.replace("/Home");
      //window.location.href = "/thankyou";
  }

  const handleLogout=()=>
  {
    sessionStorage.clear();  

      keycloak.logout();
      keycloak.authenticated = false;
      window.location.replace("/Home");
      //window.location.replace("/Home?loginRedirect=1");
      //handleLogin();
      //window.location.replace("/");
      //setTimeout(function() {window.location.replace("/thankyou");}, 2000);
      //window.location.replace("/Home");
      //window.location.href = "/thankyou";
  }
  function checkArray(needle, haystack) {
        var length = haystack.length;
        for(var i = 0; i < length; i++) {
            if(haystack[i] == needle) return true;
        }
        return false;
    }
  
  
    const handleLogin=()=>
    {
        if (keycloak.authenticated) {
            if(keycloak.token!="")
            {
                setToken(keycloak.token);
            }
        }else{
           
            keycloak.login();
        }
    }

  function setToken(token) {
    // sessionStorage.setItem("accToken",token);
     var logObj = jwt(keycloak.token); 
    var logObjStr = JSON.stringify(logObj);
     //sessionStorage.setItem("logObj",logObjStr); 
     var lobObjVal = eval(logObj);
     if(lobObjVal.resource_access.snv3_app.roles){
        var roleAr = lobObjVal.resource_access.snv3_app.roles;
     }else{
         var roleAr = [];
     }
    
     var logEmail = lobObjVal.email;
     //sessionStorage.setItem("roleAr",roleAr); 
     //sessionStorage.setItem("logEmail",lobObjVal.email); 
     
     const companyIdchck =sessionStorage.getItem("logCompanyId");   
     const studentIdchck =sessionStorage.getItem("logStudentId");   
     const profileIdchck =sessionStorage.getItem("logProfileId");   
     const userIdchck =sessionStorage.getItem("logUserId");   


     if(userIdchck == null || userIdchck == "null"){   
           //
           const requestObject=
            {         
                "siteId": 2,
                "email": logEmail   
            }

        
            var accessTokenIn=sessionStorage.getItem("accToken");
            const requestOptions = {
                                        method: 'POST',
                                        headers: { 'Content-Type': 'application/json',
                                                //'Authorization': "Bearer "+accessTokenIn 
                                                //'Authorization': accessTokenIn 
                                            },
                                        body: JSON.stringify(requestObject)
                                    };
            services.getUserInfoByEmail(requestOptions).then((res) => 
            {
                //alert(JSON.stringify(res));
               //alert(roleAr);
                //alert(JSON.stringify(roleAr))
                //console.log(res);
                if(res.data){
                    var temp = res.data;
                    if(temp.length > 0){

                        if( (res.data[0].status=="pending" || res.data[0].status=="Pending") && checkArray("company",roleAr) )
                        {
                            navigate('/thankyou?r=9');
                            sessionStorage.clear();  
                            keycloak.logout();
                            keycloak.authenticated = false;
                            return; 
                        }
                        /*
                        if( res.data[0].status=="pending" && checkArray("jobseeker",roleAr) )
                        {
                            navigate('/thankyou?r=9');
                            sessionStorage.clear();  
                            keycloak.logout();
                            keycloak.authenticated = false;
                            return; 
                        }
                        */
                        if( checkArray("siteadmin",roleAr)  ||  roleAr == "siteadmin" )
                        {
                            //alert("145");
                            keycloak.logout();
                            keycloak.authenticated = false;
                            window.location.href = process.env.React_App_SITE_ADMIN_URL  ;
                            return;
                        }
        
                        sessionStorage.setItem("accToken",token);
                        var logObjStr = JSON.stringify(logObj);
                        sessionStorage.setItem("logObj",logObjStr); 
        
                        sessionStorage.setItem("roleAr",roleAr); 
                        sessionStorage.setItem("logEmail",lobObjVal.email); 
                        sessionStorage.setItem("hideFlashPage","1"); 
        
                        sessionStorage.setItem("logCompanyId",res.data[0].company_Id); 
                        sessionStorage.setItem("logStudentId",res.data[0].student_Id); 
                        sessionStorage.setItem("logProfileId",res.data[0].profile_Id); 
                        sessionStorage.setItem("logUserId",res.data[0].user_Id);
                        sessionStorage.setItem("displayName",res.data[0].first_Name+" "+res.data[0].last_Name);
        
                        //return;
                        if(checkArray("student",roleAr)){
                            navigate('/studentDashboard');
                            //window.location.href=('/studentDashboard');
                        }else if(checkArray("company",roleAr) || checkArray("companyuser",roleAr) || checkArray("companyadmin",roleAr) ){
                           
                            navigate('/companyJobs');                        
                            //window.location.replace("/companyJobs");
                        }else if(checkArray("jobseeker",roleAr)){
                            navigate('/profileDashboard');
                            //window.location.href=('/profileDashboard');
                        }else if(checkArray("schoolsuper",roleAr) || roleAr == "schoolsuper" ){
                            //alert("super")
                            navigate('/studentList');
                            //window.location.href=('/profileDashboard');
                        }else if( checkArray("schooladmin",roleAr) || roleAr == "schooladmin"){
                            navigate('/studentListBySchool');
                            //window.location.href=('/profileDashboard');
                        }
        
                    }else{
                        keycloak.logout();
                    }
                    
                }       
        
            });
                                    
           
           //
     }
     
     

    
     
    // navigate('/companyUsers');
   }

   const handleOnEvent2 = async (event, error) =>
  {
    console.log("############ event-->"+event);
   
  }
  const handleOnEvent = async (event, error) =>
  {
   // alert("145-->"+keycloak.authenticated);
    //alert("146-->"+event);
   
   if (event === 'onReady') 
   {
      if (keycloak.authenticated) {
         //let response = await moviesApi.getUserExtrasMe(keycloak.token)
         if(keycloak.token!="")
         {
            setToken(keycloak.token);      
        
         }       
       }
   }
   if (event === 'onAuthSuccess') 
    {
    
      if (keycloak.authenticated) {
        //let response = await moviesApi.getUserExtrasMe(keycloak.token)
        if(keycloak.token!="")
        {
         setToken(keycloak.token);       
        }
      
      }else{
        //window.location.href="/login";
      }
    }
  }

    useEffect(() => {
        if(loginResetVal == "1"){
            handleLogout();
        }

        if(window.location.pathname == "/studentDashboard"){
            $(".login-desktop").hide();
        }
        if(loginRedirectVal == "1"){
            handleLogin();
        }

        

      }, []);

    

    return (
<>

<ReactKeycloakProvider authClient={keycloak} onEvent={(event, error) => handleOnEvent(event, error)}>

            <header class="main-header desktop-menu">
                <nav class="top-nav-bk w-100">
                    <div class="container d-flex flex-wrap">
                        <ul class="nav me-auto align-items-center">
                            <li class="nav-item"><a href="/aboutus" class="nav-link link-light px-2 active" aria-current="page">About Us</a>
                            </li>
                            <li class="nav-item"><a href="/contactus" class="nav-link link-light px-2">Contact Us</a>
                            </li>
                        </ul>
                        <ul class="nav align-items-center">
                        {/* <li class="nav-item"><a href="/login?status=0" class="nav-link link-light px-2">Logout</a>
                            </li> */}
                            <li class="nav-item"><a href="/faq" class="nav-link link-light px-2">FAQ</a>
                            </li>

                            


                           

                             {(() => {
                                if (window.location.pathname == "/studentDashboard") {
                                    return (

                            <li class="nav-item dropdown user-log-dropd">
                                <a class="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Student
                                </a>
                                <ul class="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
                                    {/* <li><a class="dropdown-item" href="/editAdminstrationLogin">Manage Profile</a></li> */}
                                    <li><a onClick={() => logout() } class="dropdown-item" >Log Out</a></li>
                                </ul>
                            </li>
                                )
                            }
                        })()}  

                            {(() => {
                                if (window.location.pathname != "/companyDashboard") {
                                    
                                   
                                
                                        if( userIdChkLogin != null && userIdChkLogin != "null"){            
                                            return (<><li  class="nav-item login-desktop"><a onClick={() => logout() }  class="nav-link link-light px-2 logoutBtnCls">Log Out</a></li></>);
                                        }else{
                                            return (<li class="nav-item login-desktop"><a onClick={handleLogin} class="nav-link link-light px-2">Login</a></li>)
                                        }
                                    
                                   
                                    
                                   
                                } else {
                                    return (
                                        <>
                                            <li class="nav-item login-desktop"><a href="#" class="nav-link link-light px-2">Post a Job</a> </li>
                                            <li class="nav-item dropdown user-log-dropd">
                                                <a class="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Jhon
                                                </a>
                                                <ul class="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
                                                    <li><a class="dropdown-item" href="/companyDashboard">Dashboard</a></li>
                                                    <li><a class="dropdown-item" href="/login">Log Out</a></li>
                                                </ul>
                                            </li></>
                                    )
                                }
                            })()}

                        </ul>
                    </div>
                </nav>
                <div class="main-menu">
                    <nav class="navbar navbar-expand-lg navbar-light d-none d-lg-block">
                        <div class="container">
                            <a class="navbar-brand" href="/home">
                                <img src="images/logo-white.svg" alt="" />
                            </a>
                            <div class="collapse navbar-collapse " id="navbarNav">
                                <ul class="navbar-nav ms-auto">
                                {(() => {
                  if (window.location.pathname == "/companyDashboard" || window.location.pathname == "/studentDashboard") {
                  return (
                  <li class="nav-item"> <a class="nav-link active" aria-current="page" href="/availableJobs">
                      Available Jobs</a>
                  </li>
                  )
                  } else {
                  return (
                  <li class="nav-item"> <a class="nav-link active" aria-current="page" href="/availableJobs">Available Jobs</a>
                  </li>
                  )
                  }
                  })()}
                                    
                <li class="nav-item"> <a class="nav-link active" aria-current="page" href="/industries">Industries</a></li>
                                    
                                    <li class="nav-item dropdown ">
                                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Employer
                                        </a>
                                        <ul class="dropdown-menu animate__bounceIn" aria-labelledby="navbarDropdown">
                                            
                                            


                                     {(() => {
                                
                                        if( companyIdChkLogin != null && companyIdChkLogin != "null"){            
                                            return (<><li><a class="dropdown-item" href="/companyJobs">Employer Dashboard</a></li>
                                            <li><a class="dropdown-item" href="/postJob">Post a Job</a></li></>);
                                        }else{
                                            return (<><li><a class="dropdown-item" href="/companyRegister">Employer Registration</a></li></>);
                                        }

                                    })()}    

                                            <li><a class="dropdown-item" href="/employerDirectory">Directory</a></li>
                                            {/*<li><a class="dropdown-item" href="/industries">Industries</a></li>   */} 

                                            {/* {(() => {
                                                if (window.location.pathname == "/companyDashboard" || window.location.pathname == "/studentDashboard") {
                                                    return (<li><a class="dropdown-item" href="/industries">Key Industries</a></li>)
                                                }else{
                                                    <li><a class="dropdown-item" href="/employeeManufacturing">Manfacturing</a></li>
                                                }
                                            })()} */}
                                        </ul>
                                    </li>


                                    <li class="nav-item dropdown ">
                                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Job Seeker
                                        </a>
                                        <ul class="dropdown-menu animate__bounceIn" aria-labelledby="navbarDropdown">
                                            {(() => {

                                                if( jobSeekerIdChkLogin != null && jobSeekerIdChkLogin != "null"){            
                                                    return (<><li><a class="dropdown-item" href="/profileDashboard">Job Seeker Dashboard</a></li></>);
                                                }else{
                                                    return (<><li><a class="dropdown-item" href="/postResume">Job Seeker Registration</a></li></>);
                                                }

                                            })()}        
                                        </ul>
                                        </li>


                                        <li class="nav-item dropdown ">
                                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Students
                                                </a>
                                                <ul class="dropdown-menu animate__bounceIn" aria-labelledby="navbarDropdown">
                                                

                                    {(() => {
                                        
                                        if( studentIdChkLogin != null && studentIdChkLogin != "null"){            
                                            return (<><li><a class="dropdown-item" href="/studentDashboard">Student Dashboard</a></li></>);
                                        }else{
                                            return (<><li><a class="dropdown-item" href="/studentRegistration">Student Registration</a></li></>);
                                        }
                                    
                                    })()}
                                                    
                                                    <li><a class="dropdown-item" href="/educationTraining">Education & Training</a></li>
                                                    <li><a class="dropdown-item" href="/ourlibrary"> Libraries</a></li>
                                                    <li><a class="dropdown-item" target="_blank" href=" http://education.ohio.gov/Topics/Career-Tech/Career-Connections/Career-Pathways">Sample Career Paths</a></li>
                                                   
                                                    {/* <li><a class="dropdown-item" href="/RelevantArticles">Relevant Articles</a></li>
                                                    <li><a class="dropdown-item" href="/helplink">Helpful Links</a></li> */}
                                                    {/* <li><a class="dropdown-item" href="https://www.logancountyohio.com/ed-3e.html" target="_blank">Logan County 3e's</a></li> */}
                                                

                                                </ul>
                                            </li>


                                    {/* {(() => {
                                if (window.location.pathname == "/companyDashboard" || window.location.pathname == "/studentDashboard") {
                                    
                                    return( <li class="nav-item"> <a class="nav-link" href="/careerExploreJobs">Job Postings</a>
                                     </li>)
                                }else{
                                    //return (<li class="nav-item"> <a class="nav-link" href="/profileDashboard">Job Seeker</a></li>)
                                }
                            })()} */}

                          
                                    
                                   
                                    <li class="nav-item dropdown ">
                                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Local Life
                                        </a>
                                        <ul class="dropdown-menu animate__bounceIn" aria-labelledby="navbarDropdown">
                                            <li><a class="dropdown-item" href="/locallifeCommunities">Communities</a></li>
                                            <li><a class="dropdown-item" href="/schoolList">Our Schools</a></li>
                                            {/* <li><a class="dropdown-item" href="/leisure">Leisure</a></li>
                                            <li><a class="dropdown-item" href="/statsFacts">Stats & Facts</a></li> */}
                                    {/*    {(() => { if (window.location.pathname == "/companyDashboard" || window.location.pathname == "/studentDashboard") {
                                    return (<><li><a class="dropdown-item" href="https://experiencelogancounty.com/" target="_blank"> Leisure: Logan County</a></li>
                                    <li><a class="dropdown-item" href="https://www.visitunioncountyohio.org/" target="_blank"> Leisure: Union County</a></li>
                                    <li><a class="dropdown-item" href="/statsFacts"> Stats & Facts</a></li></>)
                                }
                            })()} */}
                                          
                                        </ul>
                                    </li>
                                    {/* </li> */}

                                   
                                    {/* </li> */}
                                    <li class="nav-item dropdown ">
                                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Resources
                                        </a>
                                        <ul class="dropdown-menu animate__bounceIn" aria-labelledby="navbarDropdown">
                                        {(() => { if (window.location.pathname != "/studentDashboard") {
                                    return (<>
                                            {/* <li><a class="dropdown-item" href="/events">Events calendar</a></li> */}
                                            {/* <li><a class="dropdown-item" href="/blog">Blog</a></li> */}
                                            <li><a class="dropdown-item" href="/videoLibrary">video Library</a></li>
                                            <li><a class="dropdown-item" href="/movingToTheArea">Moving to the Area</a></li>
                                            <li><a class="dropdown-item" href="/marketSupport">Marketing Support</a></li>
                                            {/* <li><a class="dropdown-item" href="/DownloadApp">Download Our App</a></li> */}
                                           </>)
                                }
                            })()}
                                           

                                            {(() => { if (window.location.pathname == "/companyDashboard" || window.location.pathname == "/studentDashboard") {
                                    return (<>
                                            {/* <li><a class="dropdown-item" href="/events">Events calendar</a></li> */}
                                            {/* <li><a class="dropdown-item" href="/blog">Blog</a></li> */}
                                            <li><a class="dropdown-item" href="/videoLibrary">video Library</a></li>
                                            <li><a class="dropdown-item" href="/movingToTheArea">Moving to the Area</a></li>
                                           <li><a class="dropdown-item" href="/marketSupport">Marketing Support</a></li>
                                           </>)
                                }
                            })()}
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
            <header class="main-header mobile-menu">
                <div class="navbar navbar-expand-lg ">
                    <div class="container">
                        <a class="navbar-brand" href="/home"><img src="images/logo-white.svg" alt="" /></a>
                        <div class="icons ml-auto">
                            <ul>
                                <div class="menu-icon"></div>
                                <div class="menu-icon"></div>
                                <div class="menu-icon"></div>
                            </ul>
                        </div>
                        {/* <div class="account-ic">
                            <a href="#"> <img src="images/account.png" alt="account" /></a>
                        </div> */}
                        <div id="mySidenav" class="sidenav d-lg-none">
                            <a class="navbar-brand" href="#"><img src="images/logo-white.svg" alt="" /></a>
                            <div class="menu ml-auto" id="menu">
                                <ul class="list-unstyled mob-nav ps-0">
                                    <li class="mb-1"> <a href="/availableJobs">Available Jobs</a> </li>

                                    <li class="mb-1"> <a href="/industries">Industries</a> </li>

                                    <li class="mb-1 btn-toggle">
                                        <a href="/employerDirectory" class=" collapsed" data-bs-toggle="collapse" data-bs-target="#dashboard-collapse" aria-expanded="false">
                                            Employer
                                        </a>
                                        <div class="collapse" id="dashboard-collapse">
                                            <ul class="btn-toggle-nav list-unstyled ">
                                               
                                                {(() => {
                                
                                                        if( companyIdChkLogin != null && companyIdChkLogin != "null"){            
                                                            return (<><li class="mob-sub"><a  href="/companyJobs">Employer Dashboard</a></li>
                                                            <li class="mob-sub"><a  href="/postJob">Post a Job</a></li></>);
                                                        }else{
                                                            return (<><li class="mob-sub"><a  href="/companyRegister">Employer Registration</a></li></>);
                                                        }

                                                    })()} 

                                                <li class="mob-sub"><a href="/employerDirectory"> Directory</a></li>
                                                {/*<li class="mob-sub"><a href="/industries"> Industries</a></li>*/}
                                            </ul>
                                        </div>
                                    </li>

                                    <li class="mb-1 btn-toggle">
                                        <a href="#" class=" collapsed" data-bs-toggle="collapse" data-bs-target="#js-collapse" aria-expanded="false">
                                            Job Seeker
                                        </a>
                                        <div class="collapse" id="js-collapse">
                                            <ul class="btn-toggle-nav list-unstyled ">
                                                
                                                {(() => {

                                                        if( jobSeekerIdChkLogin != null && jobSeekerIdChkLogin != "null"){            
                                                            return (<><li class="mob-sub"><a  href="/profileDashboard">Job Seeker Dashboard</a></li>
                                                            <li class="mob-sub"><a  href="/postJob">Post a Job</a></li></>);
                                                        }else{
                                                            return (<><li class="mob-sub"><a  href="/postResume">Job Seeker Registration</a></li></>);
                                                        }

                                                    })()} 
                                            </ul>
                                        </div>
                                    </li>
                                    
                                    <li class="mb-1 btn-toggle">
                                        <a href="#" class=" collapsed" data-bs-toggle="collapse" data-bs-target="#std-collapse" aria-expanded="false">
                                            Students
                                        </a>
                                        <div class="collapse" id="std-collapse">
                                            <ul class="btn-toggle-nav list-unstyled ">
                                                
                                                {(() => {

                                                        if( studentIdChkLogin != null && studentIdChkLogin != "null"){            
                                                            return (<><li class="mob-sub"><a  href="/studentDashboard">Student Dashboard</a></li></>);
                                                        }else{
                                                            return (<><li class="mob-sub"><a  href="/studentRegistration">Student Registration</a></li></>);
                                                        }

                                                    })()} 
                                                    
                                                    <li class="mob-sub"><a  href="/educationTraining">Education & Training</a></li>
                                                    <li class="mob-sub"><a  href="/ourlibrary">Libraries</a></li>
                                                    <li class="mob-sub"><a  href="http://education.ohio.gov/Topics/Career-Tech/Career-Connections/Career-Pathways" target="_blank">Sample Career Paths</a></li>
                                                    
                                                    
                                                    {/* <li class="mob-sub"><a  href="/helplink">Helpful Links</a></li> */}
                                                    {/* <li class="mob-sub"><a  href="https://www.logancountyohio.com/ed-3e.html" target="_blank">Logan County 3e's</a></li> */}
                                            </ul>
                                        </div>
                                    </li>
                                   
                                    
                                    
                                  
                                    <li class="mb-1 btn-toggle">
                                        <a href="#" class=" collapsed" data-bs-toggle="collapse" data-bs-target="#locallife" aria-expanded="false">
                                            Local Life
                                        </a>
                                        <div class="collapse" id="locallife">
                                            <ul class="btn-toggle-nav list-unstyled ">
                                                <li class="mob-sub"><a href="/locallifeCommunities" class="link-dark rounded">Communities</a></li>
                                                <li class="mob-sub"><a href="/schoolList" class="link-dark rounded">Our Schools</a></li>

                                                {/* <li class="mob-sub"><a href="/leisure" class="link-dark rounded">Leisure</a></li>
                                                <li class="mob-sub"><a href="/statsFacts" class="link-dark rounded">Stats & Facts</a></li> */}

                                               
                                            </ul>
                                        </div>
                                    </li>
                                    
                                    <li class="mb-1 btn-toggle">
                                        <a href="#" class=" collapsed" data-bs-toggle="collapse" data-bs-target="#resources" aria-expanded="false">
                                            Resources
                                        </a>
                                        <div class="collapse" id="resources">
                                            <ul class="btn-toggle-nav list-unstyled">
                                                {/* <li class="mob-sub"><a href="/blog" class="link-dark rounded">Blog</a></li> */}
                                                <li class="mob-sub"><a href="/videoLibrary" class="link-dark rounded">Video Library</a></li>
                                                {/* <li class="mob-sub"><a href="/events" class="link-dark rounded">Events calendar</a></li>*/}
                                                <li class="mob-sub"><a href="/movingToTheArea" class="link-dark rounded">Moving to the Area</a></li>
                                                <li class="mob-sub"><a href="/marketSupport" class="link-dark rounded">Marketing Support</a></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                                <ul class="second-nav">
                                    <li><a href="/aboutus">About Us</a></li>
                                    <li><a href="/contactus">Contact Us</a></li>
                                    <li><a href="/faqs">Faq</a></li>
                                    {/* <li><a onClick={handleLogin} class="mobile-login">Login</a></li> */}
                                    {(() => {
                                        if( userIdChkLogin != null && userIdChkLogin != "null"){            
                                            return (<><li><a onClick={logout} class="mobile-login">Log Out</a></li></>);
                                        }else{
                                            return (<li><li><a onClick={handleLogin} class="mobile-login">Login</a></li></li>)
                                        }                    

                                    })()}   
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            </ReactKeycloakProvider>
        </>

    );
};

export default Header;