import React,{history,useEffect, useState, useMemo } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { set, useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import Services from '../../services/services';
import UsNumberValidate from '../../utility/usNumberValidate';
import services from "../../services/services";
import { useNavigate,useParams } from "react-router-dom";
import SidebarProfile from "../../utility/sidebarProfile";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const UpdateProfle = () => {
   
    const [jobTypes, setJobTypes] = useState([]);
    const [categoriesSelected, setCategoriesSelected] = useState([]);
    const [jobType, setJobType] = useState([]);
    const [collegeInfo, setCollegeInfo] = useState([]);

    const [jobSeekerResumePath, setJobSeekerPath] = useState('');
    const [educations, setEducation] = useState([]);
    const [schoolList, setSchoolList] = useState([]);
    
    // const{jobSeekerId}=
    const [jobSeekerDetails, setJobSeekerDetails] = useState([]);
    //const jobSeekerId = 51;
    const jobSeekerId=sessionStorage.getItem("logProfileId");
    const [fileName, setFileName] = useState([]);
    const [fileExtension, setFileExtension] = useState([]);
    const [fileBase64, setFileBase64] = useState([]);

    const [profilePersonalProfile, setProfilePersonalProfile] = useState("");
    const [profileEducationExperiance, setProfileEducationExperiance] = useState("");
    const [profileCertificationTraining, setProfileCertificationTraining] = useState("");
    const [profileLookingFor, setProfileLookingFor] = useState("");
    const [profileEmail, setProfileEmail] = useState('');

    const [pdfDownload, setPdfDownload] = useState([]);
    const navigate = useNavigate();
    const defemail = '';

    const getEducation = () => {
      services.getEducation().then((res) => {
        console.log("getEducation data=" + JSON.stringify(res.data));
        setEducation(res.data.content);
      });
    }

    const getCollegeInfo = () => {
      services.getEnrolledCollege().then((res) => {
        //console.log("getEducation data=" + JSON.stringify(res.data));
        setCollegeInfo(res.data);
      });
    }

    function handleChangeContinueEduSelect(event){
      var continueEducationSelect = $("#continueEducationSelect").val();
      if( continueEducationSelect == "Y"){
        $("#continueEduDiv").show();
      }else  if( continueEducationSelect == "N"){
        $("#continueEduDiv").hide();
        $('#collegeId').val('');
        $('#pursuingProgram').val('');
      }   

    }
    function  handleChangeContinueEdu(event){
      /*
      var sch = event.target.value;
      alert(sch)
      var selectedEdu = $('input[name="continueEducation"]:checked').val();
      alert(selectedEdu);
      if( selectedEdu == "Y"){
        $("#continueEduDiv").show();
      }else  if( selectedEdu == "N"){
        $("#continueEduDiv").hide();
      }
      */
    }

    function handleChangeSchool(event) {
      //alert("teste")
      //alert(event.target.value);
      var sch = event.target.value;
      //console.log(event.target.value);
      //var sch = $("#schoolId'").val();    
      if(sch == "0"){
        $("#schoolIdOtherDiv").show();
      }else{
        $("#schoolIdOtherDiv").hide();
      }
    }
    const getSchoolList = () => {
      //alert("14");
    const requestObject={
        "siteId":process.env.React_App_SITE_ID,
        "pageNumber":"1",
        "pageSize":150, 
        "orderBy":"name"          
     }
   
     var accessTokenIn=sessionStorage.getItem("accToken");
     const requestOptions = {
       method: 'POST',
       headers: { 'Content-Type': 'application/json',
                 //'Authorization': "Bearer "+accessTokenIn 
                 //'Authorization': accessTokenIn 
               },
       body: JSON.stringify(requestObject)
     };
  
     services.getSchoolList(requestOptions).then((res) => 
     {
        console.log("############36");
       console.log(JSON.stringify(res))
        if(res.content){
            console.log(JSON.stringify(res.data));
            
   
               var contAr = res.content;
               //setProfileList(res.content);
               if(res.content){
                setSchoolList(res.content);
               }                
               
  
          }  
      //navigate('/thankyou');
  
     });
   
   
  }

  $('#educationId').change(function(){
    if($('#educationId').val() == 3){
      $('#schoolId').prop('disabled', false);
    }
    else{
      $('#schoolId').prop('disabled', true);
      $("#schoolId").val('');
    }
  })

    function getImage(){
        var files = document.getElementById('resume').files;  
        if (files.length > 0) {
           
          var fname = files[0].name;
          // var fextn = fname.substr(0, fname.lastIndexOf('.'));
          var fextn = fname.split(".").pop();
          // alert(fextn);
          var fileActName = fname.split(".")[0];
          // alert(fileActName);
          setFileName(fileActName);
          setFileExtension(fextn);
          getBase64(files[0]);
    
          // if(fextn != "pdf"){
          if(fextn != "doc" && fextn != "docx" && fextn != "pdf"  ){
            
            $("#fileError").show();
                return false;
            }else{
                $("#fileError").hide();
                return true;
            }
    
        }
      }
    
      function getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          // console.log(reader.result);
          var base64result = reader.result.split(',')[1];
          setFileBase64(base64result)
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
     }

     const submitValue=()=>
     {
       
       
      var validateEditorTxt = 1;
      if(profilePersonalProfile == ""){
        $("#profilePersonalProfileValidMsg").show();  
       $(window).scrollTop($('#profilePersonalProfileValidMsg').position().top);
        validateEditorTxt = 0;     
      }else{
        $("#profilePersonalProfileValidMsg").hide();  
      }
      
      /*
      if(profileEducationExperiance == ""){
       $("#profileEducationExperianceValidMsg").show();  
      $(window).scrollTop($('#profileEducationExperianceValidMsg').position().top);
       validateEditorTxt = 0;     
     }else{
       $("#profileEducationExperianceValidMsg").hide();  
     }
     */
   
     if(profileCertificationTraining == ""){
       $("#profileCertificationTrainingValidMsg").show();  
      $(window).scrollTop($('#profileCertificationTrainingValidMsg').position().top);
       validateEditorTxt = 0;     
     }else{
       $("#profileCertificationTrainingValidMsg").hide();  
     }

     if(profileLookingFor == ""){
      $("#profileLookingForValidMsg").show();  
     $(window).scrollTop($('#profileLookingForValidMsg').position().top);
      validateEditorTxt = 0;     
    }else{
      $("#profileLookingForValidMsg").hide();  
    }


     if(validateEditorTxt == 0){
       return false;
      }

      validateCategory();
   
       var categoriesArray = [];
   
       $.each($("input[name='category']:checked"), function(){
         categoriesArray.push($(this).val());
     });

     //var selectedEduction = $('input[name="continueEducation"]:checked').val();
     var selectedEduction =$("#continueEducationSelect").val();
     var eduId = $("#educationId").val();

       const requestObject={
        "profileId": jobSeekerId,
        "firstName":$("#firstName").val(),
        "lastName": $("#lastName").val(),
        "email": profileEmail,
        "phone": $("#phone").val(),
        "password": $("#password").val(),
        //"personalProfile": $("#personalProfile").val(),
        //"educationExperiance": $("#education").val(),
        //"certificationTraining": $("#certificationTraining").val(),

        "personalProfile": profilePersonalProfile,
        "educationExperiance": profileEducationExperiance,
        "certificationTraining": profileCertificationTraining,
        "educationExperiance":"NA",

        "jobType": $("#jobType").val(),
        "enrolledCollege": $('#collegeId').val(),
        //"lookingFor": $("#lookingFor").val(),
        "lookingFor": profileLookingFor,  
        "resumePath": "resumepath",   
          //"pdfByString":fileBase64,
          //"fileName":fileName,
          //"fileExtension":fileExtension,
        "siteId": process.env.React_App_SITE_ID,
       "status": "published",
        "categoriesOfExpertise": categoriesArray,
        "levelEducation": eduId,
        "listSchool": $("#schoolId").val(),
        "continueEducation":selectedEduction,
        "pursuingProgram":$("#pursuingProgram").val()
            }

       if(fileExtension != ""){
        //
        if(fileExtension != "doc" && fileExtension != "docx" && fileExtension != "pdf" && fileExtension != "txt"  ){
          //document.getElementById('logo').reset();
          $("#fileError").show();
          return false;
       }else{
          $("#fileError").hide();

          requestObject.fileName = fileName;
          requestObject.fileExtension = fileExtension;
          requestObject.pdfByString = fileBase64;


       }
        //
      }


       console.log("Resume update request object==="+JSON.stringify(requestObject));
      
       var accessTokenIn=sessionStorage.getItem("accToken");
       const requestOptions = {
         method: 'POST',
         headers: { 'Content-Type': 'application/json',
                   'Authorization': "Bearer "+accessTokenIn 
                   //'Authorization': accessTokenIn 
                 },
         body: JSON.stringify(requestObject)
       };
   
       services.editProfile(requestOptions).then((res) => 
       {
         
        $("#addUserForm").trigger("reset");
        // navigate('/thankyou');
        //getProfile(jobSeekerId);
        $("#successMessage").fadeTo(2000, 1000).slideUp(1000, function () {
          $("#successMessage").slideUp(1000);
        });

        setTimeout(function() {navigate('/profileDashboard');}, 2000);
    
       });
   
     }

     const getProfile = (jobSeekerId) => {
        services.getProfile(jobSeekerId).then((res) => {
          console.log(res);
            setJobSeekerDetails(res);
            $("#firstName").val(res.data.firstName);
            $("#lastName").val(res.data.lastName);
            $("#email").val(res.data.email);
            $("#phone").val(res.data.phone);
            setProfileEmail(res.data.email)
           // defemail = res.data.email;
            //$("#personalProfile").val(res.data.personalProfile);
            //$("#education").val(res.data.educationExperiance);
            //$("#certificationTraining").val(res.data.certificationTraining);      
            if(res.data.personalProfile){
              setProfilePersonalProfile(res.data.personalProfile);
            }

            if(res.data.educationExperiance){
              setProfileEducationExperiance(res.data.educationExperiance);
            }
           
            if(res.data.certificationTraining){
              setProfileCertificationTraining(res.data.certificationTraining);
            }
            //setProfileEducationExperiance(res.data.educationExperiance);
            //setProfileCertificationTraining(res.data.certificationTraining);
            if(res.data.lookingFor){
              setProfileLookingFor(res.data.lookingFor);
            }
            
            //$("#lookingFor").val(res.data.lookingFor);
            $("#jobType").val(res.data.jobType);
            $("#resume").val(res.data.pdfByString);
            
            
            
            
            $("#pursuingProgram").val(res.data.pursuingProgram);
            setPdfDownload(res.data.resumePath);
            setJobSeekerPath(res.data.resumePath);
           
            setJobType(res.data.jobType);
            console.log("jobseeker@@@@@@@@"+JSON.stringify(res.data));

            setCategoriesSelected(res.data.categoriesOfExpertise);
            var companyCatIn = res.data.categoriesOfExpertise;
           // alert(companyCatIn);

            //$('#jobType').prop('selectedIndex', res.data.jobType);  
            $('#jobType').val( res.data.jobType);  
            //alert("res.data.jobType"+res.data.jobType);
            //alert(res.data.listSchool);
            $("#schoolId").val(res.data.listSchool);
            
      
            //alert(res.data.pursuingProgram);
            setTimeout(() => {
              for (var i = 0; i < companyCatIn.length; i++) {
                //$("input[value='" + val + "']").prop('checked', true);
                console.log("###" + companyCatIn[i]);
                $('input[name="category"][value="' + companyCatIn[i] + '"]').prop("checked", true);
                //$('input[name="category"][value="' + companyCatIn[i] + '"]').trigger("click");
              }
              validateCheck();
              $("#schoolId").val(res.data.listSchool);
              console.log('_______________');
              console.log(res.data.continueEducation);
              $("#continueEducationSelect").val(res.data.continueEducation);

              if( res.data.continueEducation == "Y"){
                $("#continueEduDiv").show();
              }else  if( res.data.continueEducation == "N"){
                $("#continueEduDiv").hide();
              }   
              $("#pursuingProgram").val(res.data.pursuingProgram);
              $("#collegeId").val(res.data.enrolledCollege);
              $("#educationId").val(res.data.levelEducation);
              /*
              validateCheck();
      
              var isCheck = true;
      
              var checkedValues = $("input[name='category']").val();
              // var checkedValues = [];
              //     $.each($("input[name='category']:checked"), function () {
              //       checkedValues.push($(this).val());
              //     });
              // alert(checkedValues);
              if (!checkedValues) {
                isCheck = false;
                $("#categoryError").html("Category field is required");
              } else {
                // alert(checkedValues);
                isCheck = true;
                $("#categoryError").html("");
              }
      
              return isCheck;
      
              setTimeout(() => {
                console.log("141");
                $('input[name="category"]').trigger("change");
                $('input[name="state"]').trigger("change");
              }, 3000);
              */
      
            }, 2000);
      

        });
     }

    const [categories, setCategory] = useState([]);

 
  

    const {
        register,
        formState: { errors },
        handleSubmit
      } = useForm({
        criteriaMode: "all", mode: "onBlur"
      });
    

    useEffect(() => {
      if(jobSeekerId == null || jobSeekerId == "null"){            
        //navigate('/login');
        navigate('/Home');
      }
      /*
      $( "#inlineRadio1, #inlineRadio2" ).click(function() {
        var selectedEdu = $('input[name="continueEducation"]:checked').val();
     // alert(selectedEdu);
      if( selectedEdu == "Y"){
        $("#continueEduDiv").show();
      }else  if( selectedEdu == "N"){
        $("#continueEduDiv").hide();
      }
      });
      */

      $("#profilePersonalProfileValidMsg").hide();
      $("#profileEducationExperianceValidMsg").hide();
      $("#profileCertificationTrainingValidMsg").hide();
      $("#profileLookingForValidMsg").hide();  
      $("#continueEduDiv").hide();

      $("#schoolIdOtherDiv").hide();

      $("#successMessage").hide();
        $('#phone').usPhoneFormat();
      getIndustryData();

      getEducation();
      getSchoolList();
      getCollegeInfo();
      getProfile(jobSeekerId);
      $("#fileError").hide();

      Services.getJobType().then((res) => {
        console.log("setJobType data=" + JSON.stringify(res.data));
        var tempjobtypes = res.data.content;
      //console.log(tempjobtypes);
      tempjobtypes = tempjobtypes.filter(function(elem) {  
        return elem.name !== 'Job Shadow'; 
      });
        setJobTypes(tempjobtypes);
      });

    }, []);
  
    const getIndustryData = () => {
  
      Services.getIndustryData().then((res) => {
  
        console.log("Industry data=" + JSON.stringify(res.data));
  
        setCategory(res.data.content);
  
      });
  
    }


    function validateCategory() {
      // alert("@@category");
      var isCheck = true;
  
      var checkedValues = $("input[name='category']:checked");
      // var checkedValues = [];
      //     $.each($("input[name='category']:checked"), function () {
      //       checkedValues.push($(this).val());
      //     });
      //  alert(checkedValues);
      // alert(checkedValues.length);
      var lenghtCheck=checkedValues.length;
      if (lenghtCheck==0) {
        isCheck = false;
        $("#categoryError").html("Category field is required");
      } else {
        // alert(checkedValues);
        isCheck = true;
        $("#categoryError").html("");
      }
  
      return isCheck;
    }

   const validateCheck = () =>{
        
        const checked=$('input[name="category"]:checked').length;
    
        if (checked >= 3) {
          $('input[name="category"]').not(':checked').prop('disabled', true);
        } else {
          $('input[name="category"]').prop('disabled', false);
        }
      }

  
  return(
  <>
<main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        {/* <!-- info typography --> */}
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                      
                        <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb"> 
                            <ol class="breadcrumb">
                            <li class="breadcrumb-item headtitle"> <a href="/profileDashboard">Job Seeker Dashboard</a> </li>
                                <li class="breadcrumb-item headtitle ">Update Profile</li>

                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-5">
                            <div class="row">
                                {/* <!-- left side --> */}
                                <SidebarProfile/>
                                {/* <div class="col-lg-4 mb-4">
                                    <div class="card p-3 ">
                                        <div class="bg-heading">
                                            <h3 class="mb-0">Profile Management </h3>
                                        </div>

                                       
                                        
                                         <div class="qlinks">
                                            <ul>
                                                <li><a href="/updateProfile">Edit Your Profile </a></li>
                                                <li><a href="/profileInfo">View Attached Resume</a></li>
                                            </ul>
                                            <div class="p-5 white-bg">
                                                <div class="text-center">
                                                    <img src="images/Group 75.png" alt=""/>
                                                </div>
                                                <div class="text-cente">
                                                    <h3 class="pb-3 border-bottom text-center">Upcoming Events</h3>
                                                    <div class="pb-4 mt-4 border-bottom">
                                                        <h4>CAREER FAIR</h4>
                                                        <span>September 21, 2021</span>
                                                        <p>Wright State University will be hosiint it’s 8th Annual Career Fair on October 21st.</p>
                                                    </div>

                                                    <div class="pb-4 mt-4 border-bottom">
                                                        <h4>CAREER FAIR</h4>
                                                        <span>September 21, 2021</span>
                                                        <p>Wright State University will be hosiint it’s 8th Annual Career Fair on October 21st.</p>
                                                    </div>

                                                    <div class=" mt-4 ">
                                                        <h4>CAREER FAIR</h4>
                                                        <span>September 21, 2021</span>
                                                        <p>Wright State University will be hosiint it’s 8th Annual Career Fair on October 21st.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> *
                                    </div>

                                </div> */}

                                {/* <!-- right side --> */}
                                <div class="col-lg-8 mb-4">
                                <form id="addUserForm" onSubmit={handleSubmit(submitValue)}>
                                    <div class="row resume-wrap mb-4">
                                        <div class="col-lg-6 mb-4">
                                            <label for="firstName" class="form-label">First Name<span class="red-text">*</span></label>
                                            <input type="text" class="form-control" id="firstName" defaultValue="firstName" placeholder="" {...register('firstName', { required: true })}/>
                                            {errors.firstName && errors.firstName.type === "required" && <div class="error">First name is required</div>}
                                        </div>

                                        <div class="col-lg-6 mb-4">
                                            <label for="lastName" class="form-label">Last Name<span class="red-text">*</span></label>
                                            <input type="text" class="form-control" id="lastName" defaultValue="lastName" placeholder="" {...register('lastName', { required: true })}/>
                                            {errors.lastName && errors.lastName.type === "required" && <div class="error">Last name is required</div>}
                                        </div>

                                        <div class="col-lg-6 mb-4">
                                            <label for="email" class="form-label">Email<span class="red-text">*</span></label>
                                            <input type="text" class="form-control" id="email" defaultValue="test@mailnator.com" readOnly placeholder=""  {...register('email', { required: true, pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/ })}/>
                                            {errors.email && errors.email.type === "required" && <div class="error">Email is required</div>}
                                            {errors.email && errors.email.type === "pattern" && <div class="error">Invalid email address.</div> }
                                        </div>

                                        <div class="col-lg-6 mb-4">
                                            <label for="phone" class="form-label">Phone<span class="red-text">*</span></label>
                                            <input type="text" class="form-control" id="phone" defaultValue="1234567890" placeholder="" {...register('phone', { required: true })}/>
                                            {errors.phone && errors.phone.type === "required" && <div class="error">Phone number is required</div>}
                                        </div>

                                        <div class="col-lg-12 mb-5">
                                            <div><label for="personalProfile" class="form-label">Personal Profile<span class="red-text">*</span></label></div>
                                            
                                            <CKEditor
                                              editor={ ClassicEditor }
                                              id="profilePersonalProfile"  
                                              data={profilePersonalProfile}                          
                                              onReady={ editor => {              
                                                  console.log( 'Editor is ready to use!', editor );                                
                                              } }
                                              onChange={ ( event, editor ) => {
                                                  const data = editor.getData();
                                                  setProfilePersonalProfile(data);
                                                  console.log( { event, editor, data } );
                                              } }
                                              onBlur={ ( event, editor ) => {
                                                  console.log( 'Blur.', editor );
                                              } }
                                              onFocus={ ( event, editor ) => {
                                                  console.log( 'Focus.', editor );
                                              } }
                                          />
                                          <div id="profilePersonalProfileValidMsg" class="error">Personal Profile is required.</div>
                                          
                                            {/* <textarea class="form-control textarea" id="personalProfile" defaultValue="personalProfile" placeholder="" {...register('personalProfile', { required: true,maxLength:500 })}></textarea>
                                            {errors.personalProfile && errors.personalProfile.type === "required" && <div class="error">Personal profile is required</div>}
                                            {errors.personalProfile && errors.personalProfile.type === "maxLength" && <div class="error">Should not allow more than 500 character</div>} */}
                                        </div>









                                        <div class="col-lg-12 mb-4">
                           
                               <div className="row">
                               <div class="col-md-6 mb-4">
                               <div class="d-flex">
                            <label for="education" class="form-label mr-2">Level of education Completed<span class="red-text">*</span></label> 
                            </div>
                                <select class="form-select" aria-label="Default select example" id="educationId"  >
                                {/* <option selected value="">Select</option> */}
                                {
                                educations.map((data, index) => (
                                  <option value={data.educationId}>{data.name}</option>
                                  ))}
                              </select>
                                </div>

                                <div class="col-md-6 mb-4">
                               <div class="d-flex">
                            <label for="schoolId" class="form-label mr-2">Graduated From </label>
                            </div>
                                <select class="form-select" aria-label="Default select example" id="schoolId" onChange={handleChangeSchool} disabled>
                                <option selected value="">Select</option>
                                {
                                schoolList.map((data, index) => (
                                  <option value={data.schoolId}>{data.name}</option>
                                  ))}
                                   <option value="0" >Name of The Institution</option>
                              </select>
                                </div>

                                <div class="col-md-6 " id="schoolIdOtherDiv">
                                  <div class="d-flex ">
                                    <label for="schoolIdOther" class="form-label mr-2">Other</label>
                                  </div>
                                  <input type="text" class="form-control " id="schoolIdOther" aria-describedby="emailHelp" placeholder="Other"/>
                                  
                                </div>

                               </div>
                          </div>


                          <div class="col-lg-12 mb-4">

<div class="d-flex">
  <label for="education" class="form-label mr-2">Are you currently continuing education? <span class="red-text">*</span></label>
  </div>

  <select class="form-select" id="continueEducationSelect" name="continueEducationSelect" onChange={handleChangeContinueEduSelect}>
              <option value="Y">Yes</option>
              <option value="N">No</option>
             
            </select>

  {/* <div class="form-check form-check-inline">
    <input class="form-check-input" type="radio" name="continueEducation" id="inlineRadio1"  onChange={handleChangeContinueEdu} value="Y" style={{borderRadius: "30px" }}/>
    <label class="form-check-label" for="inlineRadio1">YES</label>
  </div>
  <div class="form-check form-check-inline">
    <input class="form-check-input" type="radio" name="continueEducation" id="inlineRadio2" checked onChange={handleChangeContinueEdu} value="N"/>
    <label class="form-check-label" for="inlineRadio2">NO</label>
  </div> */}

      <div className="row" id="continueEduDiv">
      <div className="col-md-6 mt-4">
      <div class="d-flex"><label for="lookingFor" class="form-label mr-2">Name of the Institution </label></div>
            <select class="form-select" id="collegeId">
              <option value="">Select College</option>
              {
                collegeInfo.map((data, index) => (
                  <option value={data.enrolledCollegeId}>{data.name}</option>
                  ))}
            </select>
            </div>

            <div className="col-md-6 mt-4">
<div class="d-flex"><label for="pursuingProgram" class="form-label mr-2">Pursuing Program<span class="red-text">*</span></label></div>
<input type="text" class="form-control " id="pursuingProgram" aria-describedby="emailHelp" placeholder="pursuing"/>
</div>
      </div>

  </div>

                                        {/* <div class="col-lg-12 mb-5">
                                            <div class="d-flex"><label for="education" class="form-label">Education/Experience<span class="red-text">*</span></label><small>Max 500 Characters</small></div>
                                            
                                            <CKEditor
                                              editor={ ClassicEditor }
                                              id="profileEducationExperiance"  
                                              data={profileEducationExperiance}                          
                                              onReady={ editor => {              
                                                  console.log( 'Editor is ready to use!', editor );                                
                                              } }
                                              onChange={ ( event, editor ) => {
                                                  const data = editor.getData();
                                                  setProfileEducationExperiance(data);
                                                  console.log( { event, editor, data } );
                                              } }
                                              onBlur={ ( event, editor ) => {
                                                  console.log( 'Blur.', editor );
                                              } }
                                              onFocus={ ( event, editor ) => {
                                                  console.log( 'Focus.', editor );
                                              } }
                                          />
                                          <div id="profileEducationExperianceValidMsg" class="error">Education/Experience is required.</div>
                                            
                                           
                                        </div> */}

                                        <div class="col-lg-12 mb-5">
                                            <div class="d-flex"><label for="certificationTraining" class="form-label">Certifications/training<span class="red-text">*</span></label>
                                            </div>
                                            
                                            <CKEditor
                                              editor={ ClassicEditor }
                                              id="profileCertificationTraining" 
                                              data={profileCertificationTraining}                           
                                              onReady={ editor => {              
                                                  console.log( 'Editor is ready to use!', editor );                                
                                              } }
                                              onChange={ ( event, editor ) => {
                                                  const data = editor.getData();
                                                  setProfileCertificationTraining(data);
                                                  console.log( { event, editor, data } );
                                              } }
                                              onBlur={ ( event, editor ) => {
                                                  console.log( 'Blur.', editor );
                                              } }
                                              onFocus={ ( event, editor ) => {
                                                  console.log( 'Focus.', editor );
                                              } }
                                          />
                                          <div id="profileCertificationTrainingValidMsg" class="error">Certifications/training is required.</div>

                                          
                                            {/* <textarea class="form-control textarea" id="certificationTraining" defaultValue="certificationTraining" placeholder="" {...register('certificationTraining', { required: true,maxLength:500 })}></textarea>
                                            {errors.certificationTraining && errors.certificationTraining.type === "required" && <div class="error">Certification/training is required</div>}
                                            {errors.certificationTraining && errors.certificationTraining.type === "maxLength" && <div class="error">Should not allow more than 500 character</div>} */}
                                        </div>

                                        <div class="col-lg-12 mb-4">
                                            <div class="d-flex"><label for="firstName" class="form-label mr-2 mb-3">Categories of Expertise<span class="red-text">*</span></label></div>
                                            <div >
                                                <div class="category-check-itm">
                                                    <ul class="category-check">
                                                    {
                                categories.map((data, index) => (


                                  <li><input class="form-check-input" type="checkbox" name="category" value={data.catId} id="defaultCheck1"  onClick={()=>validateCheck()}/>  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                  ))}

                                                      
                                                    </ul>
                                                </div>
                                                
                                               
                                            </div>
                                            {errors.category && errors.category.type === "required" && <div class="error">Category is required</div>}
                                        </div>



                                      

                                        <div class="col-lg-12 mb-5">
                                            <div class="d-flex"><label for="lookingFor" class="form-label mr-2">What You're Looking For<span class="red-text">*</span></label></div>
                                           
                                           
                                            <CKEditor
                                              editor={ ClassicEditor }
                                              id="profileLookingFor"  
                                              data={profileLookingFor}                          
                                              onReady={ editor => {              
                                                  console.log( 'Editor is ready to use!', editor );                                
                                              } }
                                              onChange={ ( event, editor ) => {
                                                  const data = editor.getData();
                                                  setProfileLookingFor(data);
                                                  console.log( { event, editor, data } );
                                              } }
                                              onBlur={ ( event, editor ) => {
                                                  console.log( 'Blur.', editor );
                                              } }
                                              onFocus={ ( event, editor ) => {
                                                  console.log( 'Focus.', editor );
                                              } }
                                          />
                                          <div id="profileLookingForValidMsg" class="error">What You're Looking For is required.</div>

                                            {/* <textarea class="form-control textarea" placeholder="Leave a comment here" id="lookingFor" defaultValue="lookingFor" spellcheck="false" {...register('lookingFor', { required: true,maxLength:500 })}></textarea>
                                            {errors.lookingFor && errors.lookingFor.type === "required" && <div class="error">This field is required</div>}
                                            {errors.lookingFor && errors.lookingFor.type === "maxLength" && <div class="error">Should not allow more than 500 character</div>} */}
                                            
                                        </div>

                                        <div class="col-lg-6 mb-5">

  <div class="d-flex justify-content-between align-items-end">
      <div>
          <label for="firstName" class="form-label">Job Type</label>

          <select class="form-select form-control" aria-label="Default select example" style={{maxWidth: "420px"}} id="jobType">
          {/* <option  value=''>Select</option> */}
          {
jobTypes.map((data, index) => (
<option value={data.jobTypeId} >{data.name}</option>
))}
          {/* <option value="freeeducation" selected={jobType=="freeeducation"?"selected":''}>freeeducation</option> */}
          {/* <option value="1">One</option> */}
          
        </select>
      </div>
      {/* {errors.category && errors.category.type === "required" && <div class="error">Profile ype is required</div>} */}
  </div>

</div>

                                        <div class="col-lg-12 mb-4">

                                            <div class="d-flex justify-content-between align-items-end choose">
                                                <div>
                                                    <label for="resume" class="form-label">Upload a Resume</label>
                                                    <div class="d-flex align-items-center">
                                                        <input class="form-control form-control-lg choose-file mr-2" id="resume" type="file" onChange={()=>getImage()} />
                                                        <small> 1MB Max, File Type: PDF, DOC</small>
                                                        {/* <a href={window.location.path+'/'+pdfDownload} download="apple"><button type="button">Download</button></a> */}
                                                    </div>
                                                      <div id="fileError"  class="error">Invalid file format</div><br/>
                                                      <a href={jobSeekerResumePath} download="resume" target="_blank"><button type="button">Download Current Resume</button></a> 
                                                      {/* {...register('resume', { required: true })} */}
                                                    {errors.resume && errors.resume.type === "required" && <div class="error">Upload a PDF Resume is required</div>}
                                                    <span id="FileSizeError" class="error"></span>
                                                </div>
                                                {/* <div><a href="#" class="reg-button">Update</a></div> */}
                                                <div><button type="submit" class="reg-button">Update</button></div>
                                            </div><br/><br/>
                                            <div id="successMessage" class="alert">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <img src="/images/success-alert.png" alt=""/>
                                        <strong class="mx-2">Success!</strong> Profile has been updated successfully.
                                    </div>
                                    <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                                </div>
                            </div>
                                        </div>


                                    </div>
                                    </form>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </section>

    </main>
  </>
)};

UpdateProfle.propTypes = {};

UpdateProfle.defaultProps = {};

export default UpdateProfle;
