import React,{history,useEffect, useState, useMemo } from "react";
import { useNavigate,useParams,useLocation,useHistory } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import moment from "moment";
import Services from "../../services/services";
import $, { parseJSON } from 'jquery';
import UsNumberValidate from '../../utility/usNumberValidate';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Pagination3 from "../../utility/Pagination/Pagination";

const JobsByCompany = () => {
  const [itemName, setFieldName] = useState('');
  const navigate = useNavigate();
  const [stateData, setStateData] = useState([]);
  //const [categories, setCategory] = useState([]);
  //const [locationCounty, setLocationCounty] = useState([]);
  const [companyProfile, setCompanyProfile] = useState([]);
  const [companyProfilePerkList, setCompanyProfilePerkList] = useState([]);
  const [availableJobsList, setAvailableJobsList] = useState([]);

  const [totalItems, setTotalItems] = useState(0);
  const [totalpages, settotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const companyId = queryParams.get('companyId');
    const siteId  = process.env.React_App_SITE_ID;
    const ITEMS_PER_PAGE = 15; // Per page show the data pagination

    const companyIdChkLogin=sessionStorage.getItem("logCompanyId");
    const studentIdChkLogin=sessionStorage.getItem("logStudentId");
    const jobSeekerIdChkLogin=sessionStorage.getItem("logProfileId");
    const userIdChkLogin=sessionStorage.getItem("logUserId");

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    criteriaMode: "all",mode: "onBlur" 
  });

  const getCompanyProfile = () => {
    Services.getCompanyProfile(companyId).then((res) => {
        if(res.data){
            setCompanyProfile(res.data);
            
            if(res.data.perksList){
               //alert(JSON.stringify(res.data.perksList))
               setCompanyProfilePerkList(res.data.perksList);
            }
            
        }
      
    });
 }

 const handlePageChange3 = (offset,triggerevent) => {
    getCurrent(ITEMS_PER_PAGE,offset)
  };


  function getCurrent(items_per_page,pagenum){
        
    //var companyId = "24";
    //alert(companyId);
    var status = "published";
    var title = "";
    //Services.getCompanyJobExpiredCurrent(companyId,status,title,ITEMS_PER_PAGE,pageNumber,sortBy,sortDir).then((res) => 
   //Services.getCompanyJobExpiredCurrent(companyId,status,title).then((res) => 
    //Services.getCompanyJobExpiredCurrent(companyId,status,title,30,1,"","").then((res) => 
    //var items_per_page = 15;
    //var pagenum = 1;
    var sortBy = "";
    var sortDir=    "";
    
    Services.getCompanyJobExpiredCurrentSpPaging(companyId,siteId,items_per_page,pagenum).then((res) => 
    {
        if(res.data.content){
            setAvailableJobsList(res.data.content); 
            setTotalItems(res.data.totalElements);         
            settotalPages(Math.ceil(res.data.totalElements / ITEMS_PER_PAGE));            
            setCurrentPage(pagenum);
        }
          
       
       /*
        var dVal = eval(res);
       console.log(dVal);
       console.log(typeof(dVal));
      // console.log(dVal.length);
       console.log("142-->"+JSON.stringify(dVal));
       //console.log(res.data.length);
       if(typeof(dVal) == "object"&& dVal.data.length>0){

        console.log("64-------->"+JSON.stringify(dVal.data));
        setAvailableJobsList(dVal.data);
         
       }else{
        setAvailableJobsList([]);
           
       }  
       */

        //navigate('/thankyou');
    });
}
 useEffect(() => 
 {
  
    getCurrent(ITEMS_PER_PAGE,'1');
    getCompanyProfile();
     
 },[]);
  return(
    <>
    <main  class="empr-directory-main cd-desktop">
       <div class="main-bgpatten"></div>      
             
     

      <section class="headtitle-wrap">
                <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        
                        <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                            
                            {(() => {
                                
                                if( jobSeekerIdChkLogin != null && jobSeekerIdChkLogin != "null"){            
                                    return (<><li class="breadcrumb-item headtitle"> <a href="/profileDashboard">Job Seeker Dashboard</a> </li> </>);
                                }

                                if( studentIdChkLogin != null && studentIdChkLogin != "null"){            
                                    return (<><li class="breadcrumb-item headtitle"> <a href="/studentDashboard">Student Dashboard</a> </li> </>);
                                }

                           })()} 


                            <li class="breadcrumb-item headtitle "><a href="#">Company Details </a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
                </div>
            </section>
            <br/> <br/>                   
       {/* <!-- Top companies hiring in your area --> */}
       <section class="pb-5" >
           <div class="container">
               <div >
                   <div class="col-12">

                   <div class="white-bg px-5 pt-4 ">
                            <div class="row ">
                                <div class="col-lg-12 px-0 ">

                                    <div class="joblist-header">
                                        <div> <img src={companyProfile.logoUrl!=""?companyProfile.logoUrl:'images/no-logo.jpg'} class="joblist-cmp-img" alt="" /></div>

                                        <div>
                                            <h3 class="mb-0">{companyProfile.name}</h3>
                                            <div>
                                                {/* <span>Versailles, Ohio</span> */}
                                                <span> {companyProfile.address1 != '' ? companyProfile.address1 : ''}
                                                {companyProfile.address1 != '' && companyProfile.state != '' ? ', ' : ''}
                                                {companyProfile.state != '' ? companyProfile.state : ''}</span>
                                            </div>
                                            <div class="quickinfo-social justify-content-start mt-2 ">
                                                <a href="#"><img src="images/FACEBOOK 2.png" alt="" /></a>
                                                <a href="#"><img src="images/INSTA 2.png" alt="" /></a>
                                                <a href="#"><img src="images/TWITTER 2.png" alt="" /></a>
                                                <a href="#"><img src="images/LINKEDIN 2.png" alt="" /></a>
                                                <a href="#"><img src="images/YOUTUBE 2.png" alt="" /></a>
                                            </div>
                                        </div>

                                    </div>
                                    </div>
                                    </div>
                                    </div>   
                                    




                       <div class="white-bg px-5 pt-4 py-4"> 
                       {
                            availableJobsList.map((data, index) => (


                           <div class="oppertunity-card2">
                              <div class="logo-org justify-content-start">
                                  <img src={companyProfile.name?companyProfile.logoUrl:""} alt=""/>
                              </div>
                              <div class="right-info">
                                  <div class="d-flex justify-content-between flex-lg-row flex-column">
                                      <h4>
                                      <a href={`/jobsummary?jobId=${data.jobId}`} class="company-name">{data.title?data.title:""} </a>
                                      </h4>
                                      <div class="date">
                                          <b class="d-flex text-uppercase">
                                      {/* <span>August 19</span>,
                                      <span class="ms-1"> 2021</span> */}
                                      <span>{moment(data.createdDate).format("MMMM D, YYYY")}</span>
                                  </b>
                                      </div>
                                  </div>
                                  <div class="d-flex mb-3">
                                      <span><a href={`/companyDetails?companyId=${companyProfile.companyId}`} class="company-name">{companyProfile.name?companyProfile.name:""} </a></span>
                                      <span class="mx-3">|</span>
                                      <span> {companyProfile.address1 != '' ? companyProfile.address1 : ''}
                                      {companyProfile.address1 != '' && companyProfile.state != '' ? ', ' : ''}
                                      {companyProfile.state != '' ? companyProfile.state : ''}</span>
                                  </div>
                                  {/* <p class="mt-2">
                                      
                                      {`${data.summary.substring(0, 150)}...`}
                                      </p> */}

                                      <p class="mt-2" dangerouslySetInnerHTML={{ __html: data.summary.substring(0, 150)  }} />

                              </div>
                              <div class="job-seeker">
                                  <b class="text-uppercase mb-2">Perks</b>
                                  <div class="job-seek-icon mb-3">

                                     {
                                    companyProfilePerkList.map((datap, index) => (
                                        <div> <img src={datap.perksImageUrl} alt="" /></div>
                                    ))}
                                      {/* <div> <img src="images/health.png" alt=""/></div>
                                      <div> <img src="images/lunch-plate.png" alt=""/></div>
                                      <div> <img src="images/dental.png" alt=""/></div>
                                      <div> <img src="images/cofee.png" alt=""/></div>
                                      <div> <img src="images/cofee.png" alt=""/></div>
                                      <div><a href="#" class="js-more" data-bs-toggle="modal" data-bs-target="#exampleModal">more &#62;</a></div> */}

                                  </div>
                                  <div class="text-center">
                                      <a href={`/jobsummary?jobId=${data.jobId}`} class="reg-button">View JOB</a>
                                  </div>
                              </div>
                          </div>            
                       ))}


                        <div class="pagination-wrap text-center mt-3 paginationContent">
                           <Pagination3               
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={handlePageChange3}/>
                                            
                        </div>                  

                       </div>

                   </div>
               </div>
           </div>
       </section>
      
    </main>

    <main class="empr-directory-main cd-mobile">
    <div class="main-bgpatten"></div>                                  
    <section class="headtitle-wrap">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
              
                <nav style={{ "--bs-breadcrumb-divider": "'>';" }} aria-label="breadcrumb">  
                  <ol class="breadcrumb">
                    {/* <li class="breadcrumb-item headtitle"> <a href="/studentDashboard">Student Dashboard</a> </li>     */}
                    
                    {(() => {
                            if( jobSeekerIdChkLogin != null && jobSeekerIdChkLogin != "null"){            
                                return (<><li class="breadcrumb-item headtitle"> <a href="/profileDashboard">Job Seeker Dashboard</a> </li> </>);
                            }

                            if( studentIdChkLogin != null && studentIdChkLogin != "null"){            
                                return (<><li class="breadcrumb-item headtitle"> <a href="/studentDashboard">Student Dashboard</a> </li> </>);
                            }

                        })()} 
                    
                    <li class="breadcrumb-item headtitle ">
                        {/* <a href="#">Company Details</a> */}
                        <a href={`/companyDetails?companyId=${companyProfile.companyId}`} class="company-name">Company Details </a>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
    
          <section class="pb-5" id="step5">


         

                                    
           <div class="container">
           <div class="white-bg px-5 pt-4 mt-4 ">
                            <div class="row ">
                                <div class="col-lg-12 px-0 ">

                                    <div class="joblist-header">
                                        <div> <img src={companyProfile.logoUrl!=""?companyProfile.logoUrl:'images/no-logo.jpg'} class="joblist-cmp-img" alt="" /></div>

                                        <div>
                                            <h3 class="mb-0">{companyProfile.name}</h3>
                                            <div>
                                                {/* <span>Versailles, Ohio</span> */}
                                                <span> {companyProfile.address1 != '' ? companyProfile.address1 : ''}
                                                {companyProfile.address1 != '' && companyProfile.state != '' ? ', ' : ''}
                                                {companyProfile.state != '' ? companyProfile.state : ''}</span>
                                            </div>
                                            {/* <div class="quickinfo-social justify-content-start mt-2 ">
                                                <a href="#"><img src="images/FACEBOOK 2.png" alt="" /></a>
                                                <a href="#"><img src="images/INSTA 2.png" alt="" /></a>
                                                <a href="#"><img src="images/TWITTER 2.png" alt="" /></a>
                                                <a href="#"><img src="images/LINKEDIN 2.png" alt="" /></a>
                                                <a href="#"><img src="images/YOUTUBE 2.png" alt="" /></a>
                                            </div> */}
                                        </div>

                                    </div>
                                    </div>
                                    </div>
                                    </div> 
               <div >
                   <div class="col-12">
                       <div class="white-bg px-5 pt-4 pb-4">            


                       {
                              availableJobsList.map((data, index) => (


                           <div class="oppertunity-card2">
                              <div class="logo-org justify-content-start">
                                  <img src={companyProfile.logoUrl!=""?companyProfile.logoUrl:'images/no-logo.jpg'} alt=""/>
                              </div>
                              <div class="right-info">
                                  <div class="d-flex justify-content-between flex-lg-row flex-column">
                                      <h4>
                                          {/* {data.title?data.title:""} */}
                                      <a href={`/jobsummary?jobId=${data.jobId}`} class="company-name">{data.title?data.title:""} </a>
                                      </h4>
                                      <div class="date">
                                          <b class="d-flex text-uppercase">
                                       {/* <span>August 19</span>,
                                      <span class="ms-1"> 2021</span>  */}
                                    <span>{moment(data.createdDate).format("MMMM D, YYYY")}</span>                                      
                                  </b>
                                      </div>
                                  </div>
                                  <div class="d-flex mb-3">
                                      <span><a href={`/companyDetails?companyId=${data.companyId}`}>{companyProfile.name} </a></span>
                                      <span class="mx-3">|</span>
                                      <span> 
                                    {companyProfile.address1 != '' ? companyProfile.address1 : ''}
                                    {companyProfile.address1 != '' && companyProfile.state != '' ? ', ' : ''}
                                    {companyProfile.state != '' ? companyProfile.state : ''}
                                      </span>
                                  </div>
                                  {/* <p class="mt-2">{`${data.summary.substring(0, 150)}...`}</p> */}

                                  <p class="mt-2" dangerouslySetInnerHTML={{ __html: data.summary.substring(0, 150)  }} />

                              </div>
                              <div class="job-seeker">
                                 
                                  <div class="text-center">
                                      <a href={`/jobsummary?jobId=${data.jobId}`} class="reg-button">View JOB</a>
                                  </div>
                              </div>
                          </div>            
                       ))}



                       </div>

                   </div>
               </div>
           </div>
       </section>
  </main>

  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">JOB PERKS</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body py-5">
                  <div class="job-seek-modal">
                      <div> <img src="images/health.png" alt=""/></div>
                      <div> <img src="images/lunch-plate.png" alt=""/></div>
                      <div> <img src="images/dental.png" alt=""/></div>
                      <div> <img src="images/cofee.png" alt=""/></div>
                      <div> <img src="images/sick.png" alt=""/></div>
                      <div> <img src="images/health.png" alt=""/></div>
                      <div> <img src="images/lunch-plate.png" alt=""/></div>
                      <div> <img src="images/dental.png" alt=""/></div>

                  </div>
              </div>

          </div>
      </div>
  </div>
</>
)};

JobsByCompany.propTypes = {};

JobsByCompany.defaultProps = {};

export default JobsByCompany;
