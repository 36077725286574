import React,{history,useEffect, useState, useMemo } from "react";
import Hiring from "../Jobs/Hiring";
import services from "../../services/services";
const LocallifeCommunities = () => {
   
   const [importantLocalLinkContent, setImportantLocalLinkContent] = useState('');
   const getPageInfo = (reqopt) => {        
      services.getCMSPageContent('222').then((res) => {            
          console.log(JSON.stringify(res));
          if(res.data){
            setImportantLocalLinkContent(res.data.content.rendered);
          }       
      });       
   }
   useEffect(() => {        
      getPageInfo("locallinks");

  }, []);
  return(
  <>
    <main  class="empr-directory-main">
         <div class="main-bgpatten"></div>
         <section class="headtitle-wrap">
            <div class="container">
               <div class="row">
                  <div class="col-lg-12">
                     <nav style={{"--bs-breadcrumb-divider": '>'}} aria-label="breadcrumb">
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item headtitle "><a href="#">Local life <span style={{color: "#6c757d"}}>&#62;</span></a></li>
                          <li class="breadcrumb-item active headtitle" aria-current="page">Our Communities</li>
                        </ol>
                      </nav>
                  </div>
               </div>
            </div>
         </section>
         {/* <!-- info typography --> */}
         <section class="company-detail-wrap py-5 ">
            <div class="container">
               <div class="row">
                  <div class="col-12 ">


                     <div class="white-bg px-5 pt-4 pb-4">
                        <div class="row">
                           <div class="col-lg-12 m-auto">
                              <h1 class="text-center ">
                                 What’s the  <span class=" red-text">local life </span> like in our area?
                              </h1>
                              <div class="col-3 m-auto">
                                 <hr/>
                              </div>
                           </div>
                           <div class="col-lg-8 m-auto text-center">
                              <p>
                                 Mercer, Auglaize, Darke and Shelby counties have so many great characteristics many people desire in a place to call home. Not only does the area provide a diverse industry base, great school districts and highly rated healthcare systems as a foundation of the region but the friendly, honest, hardworking people that live here makes this area a very attractive place to live and raise a family!
                              </p>
                           </div>
                        </div>
                        <div class="row mb-4">
                           <div class="col-12 m-auto locallife-carousel">
                              <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                                 <div class="carousel-indicators">
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                 </div>
                                 <div class="carousel-inner">
                                    <div class="carousel-item active">
                                       <img src="images/Bitmap.png" class="d-block w-100" alt="..."/>
                                    </div>
                                    <div class="carousel-item">
                                       <img src="images/Bitmap.png" class="d-block w-100" alt="..."/>
                                    </div>
                                    <div class="carousel-item">
                                       <img src="images/Bitmap.png" class="d-block w-100" alt="..."/>
                                    </div>
                                 </div>
                                 <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                 <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                 <span class="visually-hidden">Previous</span>
                                 </button>
                                 <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                 <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                 <span class="visually-hidden">Next</span>
                                 </button>
                              </div>
                           </div>
                        </div>


                        <div dangerouslySetInnerHTML={{ __html: importantLocalLinkContent }} />
                        {/* <div class="row mb-4">
                           <div class="col-12 mb-4">
                              <div class="d-flex justify-content-between border-bottom pb-4">
                                 <h4 class="headtitle">Important Local Links</h4>
                              </div>
                           </div>
                           <div class="col-lg-3 col-md-6 mb-3">
                              <h5>qqqqqqqqq </h5>
                              <ul class="general-link">
                                 <li><a href="#">  City of Wapakoneta </a></li>
                                 <li><a href="#">  Why Wapakoneta </a></li>
                                 <li><a href="#">  Village of New Bremen </a></li>
                                 <li><a href="#"> Village of Minster </a></li>
                                 <li><a href="#"> City of St. Marys</a></li>
                                 <li><a href="#"> Village of New Knoxville </a></li>
                                 <li><a href="#"> Village of Cridersville </a></li>
                                 <li><a href="#"> Village of Waynesfield</a></li>
                              </ul>
                           </div>
                           <div class="col-lg-3 col-md-6 mb-3">
                              <h5>AUGLAIZE COUNTY: </h5>
                              <ul class="general-link">
                                 <li><a href="#">  City of Wapakoneta </a></li>
                                 <li><a href="#">  Why Wapakoneta </a></li>
                                 <li><a href="#">  Village of New Bremen </a></li>
                                 <li><a href="#"> Village of Minster </a></li>
                                 <li><a href="#"> City of St. Marys</a></li>
                                 <li><a href="#"> Village of New Knoxville </a></li>
                                 <li><a href="#"> Village of Cridersville </a></li>
                                 <li><a href="#"> Village of Waynesfield</a></li>
                              </ul>
                           </div>
                           <div class="col-lg-3 col-md-6 mb-3">
                              <h5>AUGLAIZE COUNTY: </h5>
                              <ul class="general-link">
                                 <li><a href="#">  City of Wapakoneta </a></li>
                                 <li><a href="#">  Why Wapakoneta </a></li>
                                 <li><a href="#">  Village of New Bremen </a></li>
                                 <li><a href="#"> Village of Minster </a></li>
                                 <li><a href="#"> City of St. Marys</a></li>
                                 <li><a href="#"> Village of New Knoxville </a></li>
                                 <li><a href="#"> Village of Cridersville </a></li>
                                 <li><a href="#"> Village of Waynesfield</a></li>
                              </ul>
                           </div>
                           <div class="col-lg-3 col-md-6 mb-3">
                              <h5>AUGLAIZE COUNTY: </h5>
                              <ul class="general-link">
                                 <li><a href="#">  City of Wapakoneta </a></li>
                                 <li><a href="#">  Why Wapakoneta </a></li>
                                 <li><a href="#">  Village of New Bremen </a></li>
                                 <li><a href="#"> Village of Minster </a></li>
                                 <li><a href="#"> City of St. Marys</a></li>
                                 <li><a href="#"> Village of New Knoxville </a></li>
                                 <li><a href="#"> Village of Cridersville </a></li>
                                 <li><a href="#"> Village of Waynesfield</a></li>
                              </ul>
                           </div>
                        </div> */}






                        <div class="row mb-4">
                           <div class="col-lg-4 text-center my-3">
                              <img src="images/ICON 2.png" class="mb-4" alt=""/> <br/>
                              <a href="/schoolList" class="reg-button ">our school</a>
                           </div>
                           <div class="col-lg-4 text-center my-3">
                              <img src="images/ICON.png" class="mb-4" alt=""/><br/>
                              <a href="/leisure" class="reg-button ">LEISURE</a>
                           </div>
                           <div class="col-lg-4 text-center my-3">
                              <img src="images/ICON 3.png" class="mb-4" alt=""/> <br/>
                              <a href="/statsFacts" class="reg-button ">STATS & FACTS</a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         
         
         {/* <!-- Top companies hiring in your area --> */}
         <section class="white-bg py-5">
            <div class="container">
            <Hiring/>
               {/* <div class="row mb-4">
                  <div class="col-12">
                     <div class="d-flex justify-content-between border-bottom  mb-4 pb-4 flex-lg-row flex-column">
                        <h4 class="headtitle">Top companies hiring in your area</h4>
                        <a href="#" class="text-uppercase black-link">See All</a>
                     </div>
                  </div>
                  
               </div>
               <div class="row">
                  <div class="col-lg-4">
                     <div class="card p-3">
                        <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                        <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                        <div class="text-center stock-wrap">
                           <h3>Midmark Corporation</h3>
                        <span>Versailles, Ohio</span>
                        <a href="#">15 OPEN JOBS</a>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-4">
                     <div class="card p-3">
                        <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                        <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                        <div class="text-center stock-wrap">
                           <h3>Midmark Corporation</h3>
                        <span>Versailles, Ohio</span>
                        <a href="#">15 OPEN JOBS</a>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-4">
                     <div class="card p-3">
                        <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                        <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                        <div class="text-center stock-wrap">
                           <h3>Midmark Corporation</h3>
                        <span>Versailles, Ohio</span>
                        <a href="#">15 OPEN JOBS</a>
                        </div>
                     </div>
                   
                  </div>

                
               </div> */}
            </div>
         </section>
      </main>
  </>
)};

LocallifeCommunities.propTypes = {};

LocallifeCommunities.defaultProps = {};

export default LocallifeCommunities;
