import React,{history,useEffect, useState, useMemo } from "react";
import Quick_links from "../../utility/Quick_links";
import Hiring from "./Hiring";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import services from "../../services/services";
import { useNavigate, useParams } from "react-router-dom";
//import Pagination3 from "../../utility/Pagination/Pagination";
import Pagination3 from "../../utility/Pagination/Pagination";
import Services from "../../services/services";
import moment from "moment";


const Recent_Jobs = () => {
   const [itemName, setFieldName] = useState('');

   //const companyId = 24;
   const companyId=sessionStorage.getItem("logCompanyId");
   const siteId = process.env.React_App_SITE_ID;
 
   const navigate = useNavigate();
   const ITEMS_PER_PAGE = 10; // Per page show the data pagination
   const [totalItems, setTotalItems] = useState(0);
   const [totalpages, settotalPages] = useState();
   const [currentPage, setCurrentPage] = useState(1);

   const [videoUrl, setVideo] = useState([]);
   const [jobList, setJobList] = useState([]);
   const [eventList, setEventList] = useState([]);
   const [recentJobList, setRecentJobList] = useState([]);

   useEffect(() => {

      //getTopCompanyHiringList();
      //filterContent(ITEMS_PER_PAGE,offset);
      filterEvents(ITEMS_PER_PAGE,'1');
      $(".paginationContent").hide();

   }, []);

  

  
   const handlePageChange3 = (offset,triggerevent) => {
      filterEvents(ITEMS_PER_PAGE,offset)
    };

    const filterEvents = (ITEMS_PER_PAGE,pageNumber) => {     
     
          
          var siteIdIn = process.env.React_App_SITE_ID;//"2";
         
          Services.getRecentJob(siteIdIn,ITEMS_PER_PAGE,pageNumber).then((res) => 
          {

            console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@events widg 113");
            console.log(JSON.stringify(res));
              if(res.data){
               console.log(JSON.stringify(res.data));
                 // $(".paginationContent").show();
                  setTotalItems(res.data[0].totalElement);         
                  settotalPages(Math.ceil(res.data[0].totalElement / ITEMS_PER_PAGE));                    
                  setCurrentPage(pageNumber);
      
                  var contAr = res.data.content;
                  //setProfileList(res.content);
                  if(res.data){
                     setRecentJobList(res.data);
                  }                
                  

             }              

          });
  }  

   const {
      register,
      formState: { errors },
      handleSubmit
   } = useForm({
      criteriaMode: "all", mode: "onBlur"
   });

   const submitValue = () => {

     

   }

  return(
 
    <>
        <div class="p-4 white-bg mb-4">
                   <div class="bg-heading">
                      <h3 class="mb-0">Recent Jobs</h3>
                   </div>
                   <ul class="mt-4 recent-job">
                   {
                     recentJobList.map((data, index) => (

                      <li>
                         <a href={"/jobSummary?jobId="+data.jobId}>
                         <span>{data.title}</span>
                          <span>{data.companyName?data.companyName:"-"}</span> 
                         </a>
                      </li>
                        ))} 
         
                        
                     
                   </ul>
                   <a class="red-text" href="/availableJobs">View All</a>

                   {/* <div class="pagination-wrap text-center mt-3 paginationContent">
                                       <Pagination3               
                                 total={totalItems}
                                 itemsPerPage={ITEMS_PER_PAGE}
                                 currentPage={currentPage}
                                 onPageChange={handlePageChange3}/>
                                             
                        </div> */}
                </div>
        </>
  
)};

Recent_Jobs.propTypes = {};

Recent_Jobs.defaultProps = {};

export default Recent_Jobs;