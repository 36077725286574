import React,{history,useEffect, useState, useMemo } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import Recent_Jobs from "../Jobs/Recent_Jobs";
import { useNavigate,useParams } from "react-router-dom";
import services from "../../services/services";
import Hiring from "../Jobs/Hiring";
const Aboutus=()=>
{
   const { id } = useParams(); 
   const [aboutUsContent, setAboutUsContent] = useState([]);


   const getPageInfo = (reqopt) => {        
      services.getCMSPageContent('83').then((res) => {            
          console.log(JSON.stringify(res));
          if(res.data){
            setAboutUsContent(res.data.content.rendered);
          }       
      });       
   }

   // alert(id);
      useEffect(() => 
      {
         getPageInfo();
      },[]);  

   return(<>
         <main  class="empr-directory-main">
         <div class="main-bgpatten"></div>
         <section class="headtitle-wrap">
            <div class="container">
               <div class="row">
                  <div class="col-lg-12">
                  <nav style={{"--bs-breadcrumb-divider": '>'}} aria-label="breadcrumb">
                        <ol class="breadcrumb">
                           <li class="breadcrumb-item headtitle "><a href="#">About Us</a></li>
                        </ol>
                     </nav>
                  </div>
               </div>
            </div>
         </section>
      
         <section class="company-detail-wrap py-5 ">
            <div class="container">
               <div class="row">
                  <div class="col-lg-8">
                     <div class="white-bg px-5 pt-5 pb-4">
                        <div class="row mb-3">
                           {/* <div class="col-lg-7 mb-4">
                              <p>
                                 The goal of My Local Career is to ensure that everyone in our community is aware of the jobs and careers available. Our local companies are in need of our local talent, who all too often do not realize the great opportunity right here in our own backyard.

                                 
                              </p><br/>
                              <p>Our mission is to connect job seekers of all ages with local businesses, aimed at steering high school, college students and adults toward the appropriate education and/or training that can lead to a career that is available right here, right now.</p>
                           </div>

                           <div class="col-lg-5 mb-4">
                           <img  src="images/about21.jpg" />
                           </div> */}

                        <div dangerouslySetInnerHTML={{ __html: aboutUsContent }} />

                        </div>
                     </div>
                  </div>
                  <div class="col-lg-4">
                        <Recent_Jobs/>
                        {/* <div class="white-bg mb-4">
                            <img src="images/ad-1.png" class="w-100" alt=""/>
                        </div>
                        <div class="white-bg mb-4">
                            <img src="images/ad-2.png" class="w-100" alt=""/>
                        </div> */}
                       {/* <div class="p-4 white-bg mb-4">
                            <h2 class="title-stay-connect">Stay connected. Get the app!</h2>
                            <p>Download the Hometown Opportunity app and stay constanly connected with companies and job oppportunities in your area. Hometown Opportunity is available on the Apple App Store or on Google Play.</p>
                            <img src="images/HTO-app-face.png" class="w-100" alt=""/>

                            <div class="d-flex justify-content-center  mt-3">
                                <img src="images/apple-app-store.png" alt="" style={{width: "130px"}}/>
                                <img src="images/google-play.png" alt="" class="ms-2" style={{maxWidth: "130px"}}/>
                            </div>
                        </div>*/}
                     {/* <div class="p-4 white-bg mb-4">
                        <div class="bg-heading">
                           <h3 class="mb-0">Recent Posted</h3>
                        </div>
                        <ul class="mt-4 recent-job">
                           <li>
                              <a href="#">
                              <span>Quality Checker - $14.50/Hr</span>
                              <span>By: Spherion Staffing</span>
                              </a>
                           </li>
                           <li>
                              <a href="#">
                              <span>Quality Operations Clerk - $17.95/Hr</span>
                              <span>By: Spherion Staffing</span>
                              </a>
                           </li>
                           <li>
                              <a href="#">
                              <span>Mechanical Design Engineer</span>
                              <span>By: PowerBuilt Material Handling Solutions</span>
                              </a>
                           </li>
                           <li>
                              <a href="#">
                              <span>Production</span>
                              <span>Pete DeLuke &amp; Associates, Inc.</span>
                              </a>
                           </li>
                           <li>
                              <a href="#">
                              <span>Quality Operations Clerk - $17.95/Hr</span>
                              <span>By: Spherion Staffing</span>
                              </a>
                           </li>
                           <li>
                              <a href="#">
                              <span>Mechanical Design Engineer</span>
                              <span>By: PowerBuilt Material Handling Solutions</span>
                              </a>
                           </li>
                        </ul>
                     </div> */}
                  </div>
               </div>
            </div>
         </section>
         <Hiring/>
      </main>
   </>);
 
}

Aboutus.propTypes = {};

Aboutus.defaultProps = {};

export default Aboutus;