//import React,{history,useEffect, useState, useMemo } from "react";
import React,{history,useEffect, useState, useMemo } from "react";
import { useNavigate,useParams,useLocation,useHistory } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { set, useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import Services from '../../services/services';
import UsNumberValidate from '../../utility/usNumberValidate';
import services from "../../services/services";
import moment from "moment";
//import { useNavigate,useParams } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

const JobApplicantProfileView = () => {

    const [jobTypes, setJobTypes] = useState([]);
    const [categoriesSelected, setCategoriesSelected] = useState([]);
    const [jobType, setJobType] = useState([]);
    // const{jobSeekerId}=
    const [jobSeekerDetails, setJobSeekerDetails] = useState([]);
    //const jobSeekerId = 11;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const jobSeekerId = queryParams.get('applicantId');
    const [fileName, setFileName] = useState([]);
    const [fileExtension, setFileExtension] = useState([]);
    const [fileBase64, setFileBase64] = useState([]);

    const [pdfDownload, setPdfDownload] = useState([]);
    const navigate = useNavigate();

    function strCatArr(arr){
      if(Array.isArray(arr)){
        return arr.join(arr,", ");
      }else{
        return "";
      }
      

    }
    function getImage(){
        var files = document.getElementById('resume').files;  
        if (files.length > 0) {
           
          var fname = files[0].name;
          // var fextn = fname.substr(0, fname.lastIndexOf('.'));
          var fextn = fname.split(".").pop();
          // alert(fextn);
          var fileActName = fname.split(".")[0];
          // alert(fileActName);
          setFileName(fileActName);
          setFileExtension(fextn);
          getBase64(files[0]);
    
          // if(fextn != "pdf"){
          if(fextn != "doc" && fextn != "docx" && fextn != "pdf"  ){
            
            $("#fileError").show();
                return false;
            }else{
                $("#fileError").hide();
                return true;
            }
    
        }
      }
    
      function getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          // console.log(reader.result);
          var base64result = reader.result.split(',')[1];
          setFileBase64(base64result)
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
     }

     const getProfile = (jobSeekerId) => {

      const requestObject=
      {         
          "applicationJobId": jobSeekerId          
      }

    
      var accessTokenIn=sessionStorage.getItem("accToken");
     const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json',
                'Authorization': "Bearer "+accessTokenIn 
                //'Authorization': accessTokenIn 
              },
      body: JSON.stringify(requestObject)
  };
  
  console.log(requestOptions);


        services.viewJobApplication(requestOptions).then((res) => {
            setJobSeekerDetails(res);
            $("#firstName").val(res.firstName);
            $("#lastName").val(res.lastName);
            $("#email").val(res.email);
            $("#phone").val(res.phone);
            $("#personalProfile").val(res.personalProfile);
            $("#education").val(res.educationExperiance);
            $("#lookingFor").val(res.lookingFor);
            $("#jobType").val(res.jobType);
            $("#resume").val(res.pdfByString);
            setPdfDownload(res.resumePath);
            setJobType(res.jobType);
            console.log("jobseeker@@@@@@@@"+JSON.stringify(res));

           // setCategoriesSelected(res.data.categoriesOfExpertise);
            //var companyCatIn = res.data.categoriesOfExpertise;

            $('#jobType').prop('selectedIndex', res.jobType);  
      
      
            
      

        });
     }

    const [categories, setCategory] = useState([]);

 
  

    const {
        register,
        formState: { errors },
        handleSubmit
      } = useForm({
        criteriaMode: "all", mode: "onBlur"
      });
    

    useEffect(() => {
      $("#successMessage").hide();
        $('#phone').usPhoneFormat();
      getIndustryData();
      getProfile(jobSeekerId);
      $("#fileError").hide();

      Services.getJobType().then((res) => {
        console.log("setJobType data=" + JSON.stringify(res.data));
        setJobTypes(res.data.content);
      });

    }, []);
  
    const getIndustryData = () => {
  
      Services.getIndustryData().then((res) => {
  
        console.log("Industry data=" + JSON.stringify(res.data));
  
        setCategory(res.data.content);
  
      });
  
    }


    function validateCategory() {
      // alert("@@category");
      var isCheck = true;
  
      var checkedValues = $("input[name='category']:checked");
      // var checkedValues = [];
      //     $.each($("input[name='category']:checked"), function () {
      //       checkedValues.push($(this).val());
      //     });
      //  alert(checkedValues);
      // alert(checkedValues.length);
      var lenghtCheck=checkedValues.length;
      if (lenghtCheck==0) {
        isCheck = false;
        $("#categoryError").html("Category field is required");
      } else {
        // alert(checkedValues);
        isCheck = true;
        $("#categoryError").html("");
      }
  
      return isCheck;
    }

   const validateCheck = () =>{
        const checked=$('input[name="category"]:checked').length;
    
        if (checked >= 3) {
          $('input[name="category"]').not(':checked').prop('disabled', true);
        } else {
          $('input[name="category"]').prop('disabled', false);
        }
      }

  return(
  <>
    <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                              <li class="breadcrumb-item headtitle"> <a href="/companyJobs">Company Dashboard</a> </li>
                                <li class="breadcrumb-item headtitle "><a href="#">Job Applicant</a></li>

                                {/* <li class="breadcrumb-item headtitle "><a href="#">Job Application:{jobSeekerDetails.jobTitle} </a></li> */}
                                {/* <li class="breadcrumb-item headtitle "><a href="#">{jobSeekerDetails.firstName}</a></li> */}
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>

        {/* <!-- info typography --> */}
        <section class="company-detail-wrap py-5 ">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-4">
                            <div class="grid-item p-3 border-0">
                                <div class="grid-head">
                                    <div class="grid-img">
                                        <img src={jobSeekerDetails.profilePicS3URL?jobSeekerDetails.profilePicS3URL:"images/user-default.jpg"} class="w-100" alt=""/>
                                    </div>
                                    <div class="grid-title-wrap">
                                        <h4 class="margin-bottom-0">{jobSeekerDetails.firstName} {jobSeekerDetails.lastName}</h4>
                                        <small class="d-flex">
                                                <span class="mr-1"><b>Seeking:</b></span>
                                                 <span> {jobSeekerDetails.jobTypeName}</span> 
                                            </small>
                                    </div>
                                </div>

                                <div class="grid-body mb-4">
                                    <div class="grid-body-item">
                                        
                                    <div class="grid-body-inn">
                                        <span>Job Title:</span>
                                        <span>{jobSeekerDetails.jobTitle}</span>
                                    </div>

                                        <div class="grid-body-inn">
                                            <span>Updated:</span>
                                            <span>{moment(jobSeekerDetails.createdDate).format("D-MMM-YY")}</span>
                                        </div>
                                        <div class="grid-body-inn">
                                            <span>Email:</span>
                                            <span>{jobSeekerDetails.email}</span>
                                        </div>
                                        {/* <div class="grid-body-inn">
                                            <span>Phone:</span>
                                            <span>{jobSeekerDetails.phone}</span>
                                        </div> */}
                                       
                                    </div>
                                </div>
                                <hr/>
                                <div class="view-item mb-4">
                                    <h5>Personal Profile:</h5>
                                    {/* <p>{jobSeekerDetails.profileSummary}</p> */}
                                    <p dangerouslySetInnerHTML={{ __html: jobSeekerDetails.profileSummary  }} />
                                </div>

                                <div class="view-item mb-4">
                                    <h5>Education:</h5>
                                    {/* <p>{jobSeekerDetails.educationExperiance}</p> */}
                                    <p dangerouslySetInnerHTML={{ __html: jobSeekerDetails.educationExperiance  }} />
                                </div>

                                <div class="view-item mb-4">
                                    <h5>Certifications and Training:</h5>
                                    {/* <p>{jobSeekerDetails.certificationsTrainingInfo}</p> */}
                                    <p dangerouslySetInnerHTML={{ __html: jobSeekerDetails.certificationsTrainingInfo  }} />
                                </div>

                                <div class="view-item mb-4">
                                    <h5>I Am Looking For:</h5>
                                    {/* <p>{jobSeekerDetails.lookingFor}</p> */}
                                    <p dangerouslySetInnerHTML={{ __html: jobSeekerDetails.lookingFor  }} />
                                </div>

                                <div class="view-item mb-4">
                                    <h5>Resume:</h5>
                                    <p><a target="_blank" href={jobSeekerDetails.resumePath} download="resume"><button type="button">Download</button></a></p>
                                </div>

                                
                            </div>
                        </div>

                    </div>
                </div>
                </div>
        </section>



    </main>
  </>
)};

JobApplicantProfileView.propTypes = {};

JobApplicantProfileView.defaultProps = {};

export default JobApplicantProfileView;
