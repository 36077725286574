import logo from './logo.svg';
import './App.css';
import './css/bootstrap.min.css';
import './assets/css/custome.css';
import './assets/css/media.css';
import './assets/css/animate.min.css';
import React,{history,useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import ReactGA from 'react-ga';
import Layout from './layouts/layout';
ReactGA.initialize('UA-249285647-1');

function App() {
  //const TRACKING_ID = "UA-233788033-1"; // OUR_TRACKING_ID
  //ReactGA.initialize(TRACKING_ID);

  useEffect(() => 
  {
    ReactGA.pageview(window.location.pathname + window.location.search);
      
  },[]);  
  return (
   
    <Layout /> 
 
  );
}

export default App;
