import React, { history, useEffect, useState, useMemo } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import moment from "moment";
import Services from "../../services/services";
import $, { parseJSON } from 'jquery';
import UsNumberValidate from '../../utility/usNumberValidate';
import Pagination from "../../utility/Pagination/Pagination";
import { components } from "react-select";
import { useNavigate, useLocation } from 'react-router-dom';
import { default as ReactSelect } from "react-select";
import Modal from 'react-modal';

const Option = (props) => {
    return (
      <div>
          {
              //console.log("results=="+JSON.stringify(props))
          }
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
const AvailableJobs = () => {
    const [itemName, setFieldName] = useState('');
    const navigate = useNavigate();
    const [stateData, setStateData] = useState([]);
   // const siteID = 2;
    const siteID = process.env.React_App_SITE_ID;
    const ITEMS_PER_PAGE = 20; // Per page show the data pagination
    //const [categories, setCategory] = useState([]);
    //const [locationCounty, setLocationCounty] = useState([]);
      
    const [availableJobsList, setAvailableJobsList] = useState([]);
    const [availableJobsList_m, setAvailableJobsList_m] = useState([]);
    const [availableJobsList_mobile, setAvailableJobsList_mobile] = useState([]);
    const [locationCounty, setLocationCounty] = useState([]);


    const [educations, setEducations] = useState([]);
    const [jobTypes, setJobTypes] = useState([]);



    const [pageOfItems, setPageOfItems] = useState([]);
    const [exampleItems, setExampleItems] = useState([]);

    const [totalItems, setTotalItems] = useState(0);
    const [totalpages, settotalPages] = useState();
 
  
    const [currentPage, setCurrentPage] = useState(1);

    const [totalItems_m, setTotalItems_m] = useState(0);
    const [totalpages_m, settotalPages_m] = useState();
    const [currentPage_m, setCurrentPage_m] = useState(1);
    
    ///mobile job filter variables/////////////////
    const [optionSelected, setoptionSelected] = useState(null);
    const [optionEduSelected, setoptionEduSelected] = useState(null);
    const [optionCatSelected, setoptionCatSelected] = useState(null);
    const [muleducation, setMulEducationTypes] = useState([]);
    
    const [categories, setCategory] = useState([]);
    const [mulcategories, setMulCategory] = useState([]);
    const [mullocationCounty, setMulLocationCounty] = useState([]);
    const [mullocationCountyselected, setMulLocationCountyOptionSelected] = useState([]);
    const [muljobTypes, setMulJobTypes] = useState([]);

 
    const [industryRequestArray, setIndustryRequestArray] = useState([]);

    const [locationRequestArray, setLocationRequestArray] = useState([]);

    const [jobtypeRequestArray, setJobTypeRequestArray] = useState([]);

    const [eduRequestArray, setEduTypeRequestArray] = useState([]);


////Web New Design Variables 
    const [industryRequestArray_d, setIndustryRequestArrayd] = useState([]);
    const [optionCatSelected_d, setoptionCatSelectedd] = useState(null);
    const [mulcategories_d, setMulCategory_d] = useState([]);

    const [mullocationCountyselected_d, setMulLocationCountyOptionSelectedd] = useState([]);
    const [locationRequestArray_d, setLocationRequestArrayd] = useState([]);

    const [jobtypeRequestArray_d, setJobTypeRequestArrayd] = useState([]);
    const [optionSelected_d, setoptionSelectedd] = useState(null);

    const [eduRequestArray_d, setEduTypeRequestArrayd] = useState([]);
    const [optionEduSelected_d, setoptionEduSelectedd] = useState(null);

    const [perks_popup, setPerks_popup] = useState([]);

    var industry_webvar = [];
    var location_webvar = [];
    var jobtype_webvar = [];
    var education_webvar = [];

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const pagenum = queryParams.get('page');
    
    let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
    

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all", mode: "onBlur"
    });

    const handleChange = (selected) => {
        var dummArray=[];
        for(var i=0;i<selected.length;i++)
        {
            dummArray.push(selected[i].value.toString());
        }
        setoptionSelected(selected);
        setJobTypeRequestArray(dummArray);
      };

      const handleChange_d = (selected) => {
        var dummArray=[];
        for(var i=0;i<selected.length;i++)
        {
            dummArray.push(selected[i].value.toString());
            jobtype_webvar.push(selected[i].value.toString());
        }
        setoptionSelectedd(selected);
        //jobtype_webvar = dummArray;
        $('#jobtype_selected').val(JSON.stringify(dummArray));
        setJobTypeRequestArrayd(dummArray);
        
      };

      const handleChangeForEdu = (selected) => {
        var dummArray=[];
        for(var i=0;i<selected.length;i++)
        {
            dummArray.push(selected[i].value.toString());
        }
        setoptionEduSelected(selected);
        setEduTypeRequestArray(dummArray);
      };

      const handleChangeForEdu_d = (selected) => {
        var dummArray=[];
        for(var i=0;i<selected.length;i++)
        {
            dummArray.push(selected[i].value.toString());
            education_webvar.push(selected[i].value.toString());
        }
        setoptionEduSelectedd(selected);
        //education_webvar = dummArray;
        $('#education_selected').val(JSON.stringify(dummArray));
        setEduTypeRequestArrayd(dummArray);
        
      };

      const handleChangeForCate = (selected) => {
      //alert();
        var dummArray=[];
        for(var i=0;i<selected.length;i++)
        {
            dummArray.push(selected[i].value.toString());
        }
        setIndustryRequestArray(dummArray);
        setoptionCatSelected(selected);
      };

      const handleChangeForCate_d = (selected) => {
        //alert();
          var dummArray=[];
          for(var i=0;i<selected.length;i++)
          {
              dummArray.push(selected[i].value.toString());
              industry_webvar.push(selected[i].value.toString());
          }
          console.log(industry_webvar);
          setIndustryRequestArrayd(dummArray);
          //industry_webvar = dummArray;
          $('#industry_selected').val(JSON.stringify(dummArray));
          setoptionCatSelectedd(selected);
        };

      

      const handleChangeForLocation = (selected) => {
        var dummArray=[];
        for(var i=0;i<selected.length;i++)
        {
            dummArray.push(selected[i].value.toString());
        }
        setMulLocationCountyOptionSelected(selected);
        setLocationRequestArray(dummArray);
        
      };

      const handleChangeForLocation_d = (selected) => {
        var dummArray=[];
        for(var i=0;i<selected.length;i++)
        {
            dummArray.push(selected[i].value.toString());
            location_webvar.push(selected[i].value.toString());
        }
        setMulLocationCountyOptionSelectedd(selected);
        //location_webvar = dummArray;
        $('#location_selected').val(JSON.stringify(dummArray));
        setLocationRequestArrayd(dummArray);
       
        
      };
    const submitStep1 = () => {       
        $("#step1").hide();
        $("#step2").show();
        $("#step3").hide();
        $("#step4").hide();
        $("#step5").hide();
        return false;
    }

    const submitStep2 = () => {
        $("#step1").hide();
        $("#step2").hide();
        $("#step3").show();
        $("#step4").hide();
        $("#step5").hide();
    }

    const submitStep3 = () => {
        $("#step1").hide();
        $("#step2").hide();
        $("#step3").hide();
        $("#step4").show();
        $("#step5").hide();
    }

    const submitStep4 = () => {
        $("#step1").hide();
        $("#step2").hide();
        $("#step3").hide();
        $("#step4").hide();
        $("#step5").show();
        searchInit_m(ITEMS_PER_PAGE,'1');
    }

    
    

    const filterFuncInit = () => {
       //$("#availableJobsList_mobileResult").hide();
        //$(".company-detail-wrap").hide();
      };


    const handlePageChange2_m = (offset,triggerevent) => {
        var pageoff=offset;
       mobileFilterCall(ITEMS_PER_PAGE,offset);
      };
    const handlePageChange2 = (offset,triggerevent) => {
        console.log(offset);
        var pageoff=offset;
        searchInit(ITEMS_PER_PAGE,offset);
      };

      function searchInit_m(ITEMS_PER_PAGE,pageNumber) {
        ///////////
        console.log("test--75");
        var industryArray = [];

        $.each($("input[name='industry_m']:checked"), function () {
            industryArray.push($(this).val());
        });

        var locationArray = [];

        $.each($("input[name='location_m']:checked"), function () {
            locationArray.push($(this).val());
        });

        var jobtypeArray = [];

        $.each($("input[name='jobtype_m']:checked"), function () {
            jobtypeArray.push($(this).val());
        });


        var educationArray = [];

        $.each($("input[name='education_m']:checked"), function () {
            educationArray.push($(this).val());
        });
        const requestObject = {
            "jobCategory": industryArray,
            "jobLocation": locationArray,
            "jobEducation": educationArray,
            "jobType": jobtypeArray,
            "pageSize": ITEMS_PER_PAGE,
            "pageNumber": pageNumber,
            "sortBy": "jobId",
            "sortDir": "desc",
            "siteId":siteID

        }
        // console.log(JSON.stringify(objInputAvailableJobs));
        var accessTokenIn=sessionStorage.getItem("accToken");
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': "Bearer "+accessTokenIn 
                //'Authorization': accessTokenIn 
            },
            body: JSON.stringify(requestObject)
        };

        Services.getAvailableJobs(requestOptions).then((res) => {
            //  alert(JSON.stringify(res));
            if(res.content){
                setAvailableJobsList_m(res.content);

              //  console.log("company jobs list@@@@@@@" + JSON.stringify(res.content));

                setTotalItems_m(res.totalElements);         
                settotalPages_m(Math.ceil(res.totalElements / ITEMS_PER_PAGE));                    
                setCurrentPage_m(pageNumber);
            }
            

            
        });

        ////////
    }
    const submitValue = () => {
        searchInit(ITEMS_PER_PAGE,'1');
    }

    function searchInit(ITEMS_PER_PAGE,pageNumber) {
        //alert(pageNumber);
        ///////////
        console.log("test--75");
        console.log(locationRequestArray_d);
        /*var industryArray = [];

        $.each($("input[name='industry']:checked"), function () {
            industryArray.push($(this).val());
        });

        var locationArray = [];

        $.each($("input[name='location']:checked"), function () {
            locationArray.push($(this).val());
        });

        var jobtypeArray = [];

        $.each($("input[name='jobtype']:checked"), function () {
            jobtypeArray.push($(this).val());
        });


        var educationArray = [];

        $.each($("input[name='education']:checked"), function () {
            educationArray.push($(this).val());
        }); */

        var searchkey = $('#search_text').val();
        var industries_filter = [];
        var selected_ind = $('#industry_selected').val();
        if(selected_ind != ''){
            var industries_filter = JSON.parse(selected_ind);
        }

        var locations_filter = [];
        var selected_loc = $('#location_selected').val();
        if(selected_loc != ''){
            var locations_filter = JSON.parse(selected_loc);
        }

        var educations_filter = [];
        var selected_edu = $('#education_selected').val();
        if(selected_edu != ''){
            var educations_filter = JSON.parse(selected_edu);
        }
        
        var jobtypes_filter = [];
        var selected_types = $('#jobtype_selected').val();
        if(selected_types != ''){
            var jobtypes_filter = JSON.parse(selected_types);
        }
        
        const requestObject = {
            /*
            "jobCategory": industryArray,
            "jobLocation": locationArray,
            "jobEducation": educationArray,
            "jobType": jobtypeArray,
            "pageSize": ITEMS_PER_PAGE,
            "pageNumber": pageNumber,
            "sortBy": "jobId",
            "sortDir": "desc",
            "siteId":siteID
*/                
            "searchTxt": searchkey,
            "jobCategory": industries_filter,
            "jobLocation": locations_filter,
            "jobEducation": educations_filter,
            "jobType": jobtypes_filter,
            "pageSize": ITEMS_PER_PAGE,
            "pageNumber": pageNumber,
            "sortBy": "jobId",
            "sortDir": "desc",
            "siteId":siteID

        }
        // console.log(JSON.stringify(objInputAvailableJobs));
        var accessTokenIn=sessionStorage.getItem("accToken");
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': "Bearer "+accessTokenIn 
                //'Authorization': accessTokenIn 
            },
            body: JSON.stringify(requestObject)
        };

        Services.getAvailableJobs(requestOptions).then((res) => {
          
            if(res.content){
                setAvailableJobsList(res.content);



                // console.log("company jobs list@@@@@@@" + JSON.stringify(res.content));

                setTotalItems(res.totalElements);         
                settotalPages(Math.ceil(res.totalElements / ITEMS_PER_PAGE));                    
                setCurrentPage(pageNumber);
            }
            

            //    var dVal = eval(res);
            //    console.log(dVal);
            //    console.log(typeof(dVal));
            //   // console.log(dVal.length);
            //    console.log("142-->"+JSON.stringify(dVal));
            //    //console.log(res.data.length);
            //    if(typeof(dVal) == "object"&& dVal.length>0){

            //     console.log(JSON.stringify(res));
            //     setAvailableJobsList(res);

            //    }else{
            //     setAvailableJobsList([]);

            //    }




            //navigate('/thankyou');
        });

        ////////
    }
    /// mobile filter services////////////////////////
    const ClearAll=()=>
    {
        setIndustryRequestArray([]);
        setIndustryRequestArrayd([]);
        setLocationRequestArray([]);
        setLocationRequestArrayd([]);
        setJobTypeRequestArray([]);
        setJobTypeRequestArrayd([]);
        setEduTypeRequestArray([]);
        setEduTypeRequestArrayd([]);
        setoptionSelected("");
        setoptionSelectedd("");
        setoptionCatSelected("");
        setoptionCatSelectedd("");
        setMulLocationCountyOptionSelected("");
        setMulLocationCountyOptionSelectedd("");
        setoptionEduSelected("");
        setoptionEduSelectedd("");
        education_webvar = [];
        location_webvar = [];
        jobtype_webvar = [];
        industry_webvar = [];
        $('#industry_selected').val("");
        $('#location_selected').val("");
        $('#education_selected').val("");
        $('#jobtype_selected').val("");
        $('#search_text').val("");
        //mobileFilterCall(ITEMS_PER_PAGE,"1");

    }
    const mobileFilterCall=(ITEMS_PER_PAGE,pageNumber)=>
    {
        
        const requestObject = {
            "searchTxt": "",
            "jobCategory": industryRequestArray,
            "jobLocation": locationRequestArray,
            "jobEducation": eduRequestArray,
            "jobType": jobtypeRequestArray,
            "pageSize": ITEMS_PER_PAGE,
            "pageNumber": pageNumber,
            "sortBy": "jobId",
            "sortDir": "desc",
            "siteId":siteID

        }
        
        var accessTokenIn=sessionStorage.getItem("accToken");
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': "Bearer "+accessTokenIn 
                //'Authorization': accessTokenIn 
            },
            body: JSON.stringify(requestObject)
        };

        Services.getAvailableJobs(requestOptions).then((res) => {
            //$("#availableJobsList_mobileResult").show();
            //$(".company-detail-wrap").show();
            if(res.content){
                setAvailableJobsList_mobile(res.content);

                // console.log("company jobs list@@@@@@@" + JSON.stringify(res.content));

                setTotalItems_m(res.totalElements);         
                settotalPages_m(Math.ceil(res.totalElements / ITEMS_PER_PAGE));                    
                setCurrentPage_m(pageNumber);
               document.getElementById("closeMobilePopup").click();                  
               // setCurrentPage(pageNumber);
            }
            

            //    var dVal = eval(res);
            //    console.log(dVal);
            //    console.log(typeof(dVal));
            //   // console.log(dVal.length);
            //    console.log("142-->"+JSON.stringify(dVal));
            //    //console.log(res.data.length);
            //    if(typeof(dVal) == "object"&& dVal.length>0){

            //     console.log(JSON.stringify(res));
            //     setAvailableJobsList(res);

            //    }else{
            //     setAvailableJobsList([]);

            //    }




            //navigate('/thankyou');
        });

    }

    function openModal() {
        //alert(rid);
        
        setIsOpen(true);
      }
      function afterOpenModal() {
        
        // references are now sync'd and can be accessed.
        //subtitle.style.color = '#f00';
      }
      function closeModal() {
        setIsOpen(false);
      }
      const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          width: '100%',
          maxWidth: '600px',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
    
        },
      };

    useEffect(() => {
      
        $('#yourphone2').usPhoneFormat();

        $("#step1").show();
        $("#step2").hide();
        $("#step3").hide();
        $("#step4").hide();
        $("#step5").hide();

        Services.getLocationCounty().then((res) => {
            console.log("##################433");
            console.log(JSON.stringify(res));
            setLocationCounty(res.data.content);
           // alert(res.data.content.length)
            var dummArray=[];
            for(var i=0;i<res.data.content.length;i++)
            {
                dummArray.push({"label":res.data.content[i].name,"value":res.data.content[i].jobLocationCountyId})
            }
            setMulLocationCounty(dummArray)
        });


        Services.getJobType().then((res) => {
          console.log("setJobType data=" + JSON.stringify(res.data));
            setJobTypes(res.data.content);
            var dummArray=[];
            for(var i=0;i<res.data.content.length;i++)
            {
                dummArray.push({"label":res.data.content[i].name,"value":res.data.content[i].jobTypeId})
            }
            setMulJobTypes(dummArray)
        });


        Services.getEducation().then((res) => {
            //console.log("getEducation data=" + JSON.stringify(res.data));
            setEducations(res.data.content);
            var dummArray=[];
            for(var i=0;i<res.data.content.length;i++)
            {
                dummArray.push({"label":res.data.content[i].name,"value":res.data.content[i].educationId})
            }
            setMulEducationTypes(dummArray)
          
        });

        //   Services.getLocationCounty().then((res) => {
        //     console.log("getLocationCounty data=" + JSON.stringify(res.data));
        //     setLocationCounty(res.data);
        //   });

        Services.getIndustryData().then((res) => {
            console.log("Industry data=" + JSON.stringify(res.data));
            //   this.state.industryData(res.data);
            setCategory(res.data.content);
            var dummArray=[];
            for(var i=0;i<res.data.content.length;i++)
            {
                dummArray.push({"label":res.data.content[i].name,"value":res.data.content[i].catId})
            }
            setMulCategory(dummArray);
            setMulCategory_d(dummArray)
        });

/*
        $(document).on('click', ".searchCls", function () {

            searchInit(ITEMS_PER_PAGE,'1');

        }); */
        if(pagenum != null){
            
        searchInit(ITEMS_PER_PAGE,pagenum);

        mobileFilterCall(ITEMS_PER_PAGE,pagenum);
        }
        else {
            
        searchInit(ITEMS_PER_PAGE,'1');

        mobileFilterCall(ITEMS_PER_PAGE,'1');
        }
    }, []);
    return (
        <>
            <main class="empr-directory-main cd-desktop">
                <div class="main-bgpatten"></div>
                <section class="headtitle-wrap">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <nav style={{ "--bs-breadcrumb-divider": '>' }} aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item headtitle "><a href="#">Available Jobs</a></li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>


                <section class="py-5 cd-desktop">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-5">
                        <div class="row mb-3">
                            <div class="col-lg-12 m-auto">
                                <h1 class="text-center ">

                                    There are   <span class=" red-text">so many opportunities </span>for local careers!
                                </h1>
                                <div class="col-12 m-auto">
                                    <hr />
                                </div>
                            </div>
                            <div class="col-lg-12 m-auto text-left">
                                <p>
                                    To find a great new job with one of the many amazing local employers in your area, simply select your industry, location and experience level below, as well as whether you’d like part-time, full-time, seasonal work or a full time career. Your matches will automatically appear in the box below.
                                </p>
                            </div>
                        </div>

                                <div class="row">
                                <div class="col-lg-4 mb-4">
                                    <div class="card p-3 mb-3 ">
                                        <div class="bg-heading">
                                            <h3 class="mb-0">Filter Jobs</h3>
                                        </div>
                                        <div class="py-3 text-start">
                                            <b class="text-uppercase">Keywords</b>
                                            <form onSubmit={handleSubmit(submitValue)} class=" search-bar my-2 w-100">
                                                <input class="form-control me-2" type="search" aria-label="Search" id="search_text" name="search_text" placeholder="Search by Job / Company" />
                                                <button class="border-0 p-0 dir-search" type="submit" onClick={()=>{searchInit(ITEMS_PER_PAGE,"1")}}><img src="images/search-icon.JPG" alt=""/></button>
                                            </form>
                                        </div>
                                       

                                        <div class="mb-4">
                                             <label for="firstName" class="form-label">industry</label>
                                                    {/* <select class="form-select" aria-label="Default select example">
                                                 <option selected>Select industry</option>
                                                 <option value="1">Ohio</option>
                                                 <option value="2">California</option>
                                                 <option value="3">Masyville</option>
                                              </select> */}
                                                      <ReactSelect
                                                class="form-select"
                                                options={mulcategories_d}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                components={{
                                                    Option
                                                }}            
                                                onChange={handleChangeForCate_d}
                                                allowSelectAll={false}
                                                value={optionCatSelected_d}
                                              />
                                        </div>

                                        <div class="col-12 mb-4">
                                                    <label for="firstName" class="form-label">Location</label>
                                                    {/* <select class="form-select" aria-label="Default select example">
                                                 <option selected>Select industry</option>
                                                 <option value="1">Ohio</option>
                                                 <option value="2">California</option>
                                                 <option value="3">Masyville</option>
                                              </select> */}
                                                      <ReactSelect
                                                class="form-select"
                                                options={mullocationCounty}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                components={{
                                                    Option
                                                }}            
                                                onChange={handleChangeForLocation_d}
                                                allowSelectAll={false}
                                                value={mullocationCountyselected_d}
                                              />
                                                </div>
                                                <div class="col-12 mb-4">
                                                    <label for="firstName" class="form-label">Job Type</label>
                                                    {/* <select class="form-select" aria-label="Default select example">
                                                 <option selected>Select Job Type</option>
                                                 <option value="1">Ohio</option>
                                                 <option value="2">California</option>
                                                 <option value="3">Masyville</option>
                                              </select> */}
                                               <ReactSelect
                                                class="form-select"
                                                options={muljobTypes}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                components={{
                                                    Option
                                                }}            
                                                onChange={handleChange_d}
                                                allowSelectAll={true}
                                                value={optionSelected_d}
                                              />
                                                </div>

                                                <div class="col-12 mb-4">
                                                    <label for="firstName" class="form-label">Education</label>
                                                    <ReactSelect
                                                class="form-select"
                                                options={muleducation}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                components={{
                                                    Option
                                                }}            
                                                onChange={handleChangeForEdu_d}
                                                allowSelectAll={true}
                                                value={optionEduSelected_d}
                                              />
                                                    {/* <select class="form-select" aria-label="Default select example">
                                                 <option selected>Select Education</option>
                                                 <option value="1">Ohio</option>
                                                 <option value="2">California</option>
                                                 <option value="3">Masyville</option>
                                              </select> */}
                                                </div>

                                           {/*
                                                <div class="d-flex justify-content-center pb-5">
                                                <button type="button" class="btn btn-secondary text-uppercase mx-2"  onClick={()=>{ClearAll()}}>Reset</button>
                                                <button type="button" class="btn btn-primary text-uppercase" onClick={()=>{mobileFilterCall(ITEMS_PER_PAGE,"1")}}>Filter</button>
                                            </div> */}
                                            <input type="hidden" id="industry_selected" />
                                            <input type="hidden" id="education_selected" />
                                            <input type="hidden" id="jobtype_selected" />
                                            <input type="hidden" id="location_selected" />

                                            <div class="d-flex">
                                            <button type="button" class="btn btn-secondary text-uppercase  mb-2"  onClick={()=>{ClearAll()}}>Reset</button>
                                             <button type="button" class="btn btn-primary text-uppercase mx-2 mb-2" onClick={()=>{searchInit(ITEMS_PER_PAGE,"1")}}>Filter</button>
                                            </div>

                                          
                                    </div>
                                </div>
                                <div class="col-lg-8">
                                    <div class="border-top border-bottom py-2 d-flex justify-content-between align-items-center mb-4">
                                        <span class="float-end ">
                                        {totalItems} Careers - Page {currentPage} of {totalpages}
                                 </span>
                                 <nav aria-label="..." >
                                    <ul class="pagination m-auto d-inline-flex">
                                <Pagination               
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={handlePageChange2}/>
                                </ul>
                                </nav>
                                    </div>



                                    {
                                        availableJobsList.map((data, index) => (

                                            <div class="avl-job p-3 border mb-3">
                                            <div class="avl-logo">
                                                {(data.logoUrl == '' || data.logoUrl == null) ? 
                                                    <img src="https://snv3-dev-fs.s3.us-east-2.amazonaws.com/HometownMigration/uploads/default-logo.png" alt="" /> :
                                                     <img src={data.logoUrl} alt="" />
                                                   
                                                }
                                            </div>
                                            <div class="pt-4">
                                                <h4><a href={`/jobsummary?jobId=${data.jobId}&page=`+currentPage} class="company-name">{data.title ? data.title : ""} </a></h4>
                                                <div class="d-flex justify-content-between align-self-center avl-ft-14">
                                                    <div class="d-flex align-self-center"><span class="text-underline font-light avl-lmt"><a href={`/companyDetails?companyId=${data.companyId}&page=`+currentPage}>{data.companyName ? data.companyName : ""} </a></span><span class="mx-3">|</span> {data.city != '' ? data.city : '' }{data.city != '' && data.state != '' ? ', ' : '' }{data.state != '' ? data.state : ''}</div>
                                                    <div class="w-25 p-1"><small><b>{moment(data.createdDate).format("MMMM D, YYYY")}</b></small></div>
                                                </div>
                                                <div>
                                            
                                                    <p class="mt-3" dangerouslySetInnerHTML={{ __html: data.summary.substring(0, 150)+"...  <a style='font-weight:normal;text-decoration:underline;' href='/jobsummary?jobId="+data.jobId+"&page="+currentPage+"'>View more</a>" }} />

                                                </div>
                                                <hr></hr>
                                                <div class="d-flex justify-content-between align-items-center py-2">
                                                    <div class="perks-avl d-flex align-items-center">
                                                        <span class="mr-2"><small><b>PERKS</b></small></span>
                                                        <div class="perks-avl-icon">

                                                        {
                                                        data.perksList.map((datap, index) => (
                                                            <div> <img src={datap.perksImageUrl} alt="" /></div>
                                                        ))}

                                                        

                                                        </div>

                                                        
                        
                                                    </div>
                                                    <div>
                                                        <a href={`/jobsummary?jobId=${data.jobId}&page=`+currentPage} class="reg-button">View JOB</a>
                                                    </div>
                                                </div>
                                            </div>
    
                                        </div>



                                        ))}

                                   
{/*
                                    <div class="avl-job p-3 border mb-4">
                                        <div class="avl-logo">
                                            <img src="images/midmark-logo.JPG" alt="" />
                                        </div>
                                        <div class="pt-4">
                                            <h4><a href="#">Summer Production Help</a></h4>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div><span class="text-underline font-light"><a href="#">Celina City Schools</a></span> <span class="px-2">|</span> <span>OH</span></div>
                                                <div><small><b>MAY 28, 2022</b></small></div>
                                            </div>
                                            <div>
                                                <p class="mt-3">
                                                    CELINA TENT, INC is hiring Summer Production Help in our Celina, OH facility during the summer months on our 1st shift. We’re ... View more
                                                </p>
                                            </div>
                                            <hr></hr>
                                            <div class="d-flex justify-content-between align-items-center py-2">
                                                <div class="perks-avl d-flex">
                                                    <span class="mr-2"><small><b>PERKS</b></small></span>
                                                    <div class="d-flex">
                                                        <div> <img src="images/health.png" alt="" /></div>
                                                        <div> <img src="images/lunch-plate.png" alt="" /></div>
                                                        <div> <img src="images/dental.png" alt="" /></div>
                                                        <div> <img src="images/cofee.png" alt="" /></div>
                                                        <div> <img src="images/cofee.png" alt="" /></div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <a href="#" class="reg-button">View JOB</a>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="avl-job p-3 border mb-4">
                                        <div class="avl-logo">
                                            <img src="images/midmark-logo.JPG" alt="" />
                                        </div>
                                        <div class="pt-4">
                                            <h4><a href="#">Summer Production Help</a></h4>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div><span class="text-underline font-light"><a href="#">Celina City Schools</a></span> <span class="px-2">|</span> <span>OH</span></div>
                                                <div><small><b>MAY 28, 2022</b></small></div>
                                            </div>
                                            <div>
                                                <p class="mt-3">
                                                    CELINA TENT, INC is hiring Summer Production Help in our Celina, OH facility during the summer months on our 1st shift. We’re ... View more
                                                </p>
                                            </div>
                                            <hr></hr>
                                            <div class="d-flex justify-content-between align-items-center py-2">
                                                <div class="perks-avl d-flex">
                                                    <span class="mr-2"><small><b>PERKS</b></small></span>
                                                    <div class="d-flex">
                                                        <div> <img src="images/health.png" alt="" /></div>
                                                        <div> <img src="images/lunch-plate.png" alt="" /></div>
                                                        <div> <img src="images/dental.png" alt="" /></div>
                                                        <div> <img src="images/cofee.png" alt="" /></div>
                                                        <div> <img src="images/cofee.png" alt="" /></div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <a href="#" class="reg-button">View JOB</a>
                                                </div>
                                            </div>
                                        </div>

                                    </div> */}


                                    <div class="pagination-wrap text-center mt-5">
                                        <nav aria-label="...">

                                      


                                            <ul class="pagination m-auto d-inline-flex">
                                            <Pagination               
                                        total={totalItems}
                                        itemsPerPage={ITEMS_PER_PAGE}
                                        currentPage={currentPage}
                                        onPageChange={handlePageChange2}/>
                                            </ul>
                                        </nav>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>



                {/* <!-- info typography --> */}
                {/*
                <section class="company-detail-wrap py-5 ">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 ">
                                <div class="white-bg px-4 pt-4 pb-4">
                                    <div class="row mb-3">
                                        <div class="col-lg-12 m-auto">
                                            <h1 class="text-center ">

                                                There are   <span class=" red-text">so many opportunities </span>for local careers!
                                            </h1>
                                            <div class="col-12 m-auto">
                                                <hr />
                                            </div>
                                        </div>
                                        <div class="col-lg-12 m-auto text-left">
                                            <p>
                                                To find a great new job with one of the many amazing local employers in your area, simply select your industry, location and experience level below, as well as whether you’d like part-time, full-time, seasonal work or a full time career. Your matches will automatically appear in the box below.
                                            </p>
                                        </div>
                                    </div>

                                    {/* <!-- Your Industry --> 
                                    <div class="col-lg-12 mb-4">
                                        <div class="d-flex"><label for="firstName" class=" headtitle">Your Industry </label></div>
                                        <div class="my-2">
                                            <hr />
                                        </div>
                                        <div>
                                            <div class="category-check-itm">
                                                <ul class="category-check">
                                                    {
                                                        categories.map((data, index) => (


                                                            <li><input class="form-check-input searchCls" type="checkbox" name="industry" value={data.catId} id="category" />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                        ))}

                                                </ul>
                                            </div>


                                        </div>

                                    </div>

                                    <div class="row">
                                        <div class="col-lg-4">
                                            <div class="d-flex"><label for="firstName" class=" headtitle">Your Location </label></div>
                                            <div class="my-2">
                                                <hr />
                                            </div>
                                            <div class="category-check-itm">
                                                <ul>
                                                    {
                                                        locationCounty.map((data, index) => (


                                                            <li><input class="form-check-input searchCls" type="checkbox" name="location" value={data.jobLocationCountyId} id="category" />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                        ))}
                                                    {/* <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Accounting </label></li>
                                  <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Advanced Manufacturing</label></li>
                                  <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                  <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> CNC  </label></li> *
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="d-flex"><label for="firstName" class=" headtitle">Job Type </label></div>
                                            <div class="my-2">
                                                <hr />
                                            </div>
                                            <div class="category-check-itm">
                                                <ul>
                                                    {
                                                        jobTypes.map((data, index) => (


                                                            <li><input class="form-check-input searchCls" type="checkbox" name="jobtype" value={data.jobTypeId} id="category" />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                        ))}
                                                    {/* <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Accounting </label></li>
                                  <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Advanced Manufacturing</label></li>
                                  <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                  <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> CNC  </label></li> *
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="d-flex"><label for="firstName" class=" headtitle">Education </label></div>
                                            <div class="my-2">
                                                <hr />
                                            </div>
                                            <div class="category-check-itm">
                                                <ul>
                                                    {
                                                        educations.map((data, index) => (


                                                            <li><input class="form-check-input searchCls" type="checkbox" name="education" value={data.educationId} id="category" />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                        ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>





                                </div>



                            </div>

                        </div>
                    </div>
                </section> */}

                {/* <!-- Top companies hiring in your area --> */}
               {/* <section class="pb-5" >
                    <div class="container">
                        <div >
                            <div class="col-12">
                                <div class="white-bg px-4 pt-4 pb-4">


                                    {
                                        availableJobsList.map((data, index) => (


                                            <div class="oppertunity-card2">
                                                <div class="logo-org justify-content-start">
                                                {data.logoUrl != '' ? 
                                                        <img src={data.logoUrl} alt="" /> : 
                                                        <img src="../images/no-logo.jpg" alt="" />
                                                     }
                                                </div>
                                                <div class="right-info">
                                                    <div class="d-flex justify-content-between flex-lg-row flex-column">
                                                        <h4>
                                                            <a href={`/jobsummary?jobId=${data.jobId}`} class="company-name">{data.title ? data.title : ""} </a>
                                                        </h4>
                                                        <div class="date">
                                                            <b class="d-flex text-uppercase">
                                                               * <span>August 19</span>,
                                      <span class="ms-1"> 2021</span> *
                                                                <span>{moment(data.createdDate).format("MMMM D, YYYY")}</span>
                                                            </b>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex mb-3">
                                                        <span><a href={`/companyDetails?companyId=${data.companyId}`} class="company-name">{data.companyName ? data.companyName : ""} </a></span>
                                                        <span class="mx-3">|</span>
                                                        <span> {data.city != '' ? data.city : '' }
                                                        {data.city != '' && data.state != '' ? ', ' : '' }
                                                        {data.state != '' ? data.state : ''}</span>
                                                    </div>

                                                    <p dangerouslySetInnerHTML={{ __html: data.summary.substring(0, 150)+"...  <a style='font-weight:normal;text-decoration:underline;' href='/jobsummary?jobId="+data.jobId+"'>View more</a>" }} />
                                                    * <p class="mt-2">
                                                        {`${data.summary.substring(0, 150)}...`}
                                                        </p> *

                                                </div>
                                                <div class="job-seeker">
                                                    <b class="text-uppercase mb-2">Perks</b>
                                                    <div class="job-seek-icon mb-3">

                                                    {
                                                    data.perksList.map((datap, index) => (
                                                        <div> <img src={datap.perksImageUrl} alt="" /></div>
                                                    ))}
                                                        * <div> <img src="images/health.png" alt="" /></div>
                                                        <div> <img src="images/lunch-plate.png" alt="" /></div>
                                                        <div> <img src="images/dental.png" alt="" /></div>
                                                        <div> <img src="images/cofee.png" alt="" /></div>
                                                        <div> <img src="images/cofee.png" alt="" /></div>
                                                        <div><a href="#" class="js-more" data-bs-toggle="modal" data-bs-target="#exampleModal">more &#62;</a></div> *

                                                    </div>
                                                    <div class="text-center">
                                                        <a href={`/jobsummary?jobId=${data.jobId}`} class="reg-button">View JOB</a>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}


* <div class="pagination-wrap text-center mt-3">
                                    <nav aria-label="...">
                                        <ul class="pagination m-auto d-inline-flex">
                                            <li class="page-item disabled">
                                                <span class="page-link"><img src="images/arrow-left.JPG" alt=""/></span>
                                            </li>
                                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                                            <li class="page-item active" aria-current="page">
                                                <span class="page-link">2</span>
                                            </li>
                                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                                            <li class="page-item"><a class="page-link" href="#">...</a></li>
                                            <li class="page-item">
                                                <a class="page-link" href="#"><img src="/images/arrow-right.JPG" alt=""/></a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>    *


<div class="paginationContent">
                                       <Pagination               
                                        total={totalItems}
                                        itemsPerPage={ITEMS_PER_PAGE}
                                        currentPage={currentPage}
                                        onPageChange={handlePageChange2}/>

                                       </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section> */}

            </main>

            <main class="empr-directory-main cd-mobile">
                <section class="headtitle-wrap">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item headtitle "><a href="#">Available Jobs</a></li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- info typography --> */}
                <section class="company-detail-wrap">
                    <div>
                        <div class="row">
                            <div class="col-12 ">
                                <div class="white-bg px-4 pt-4 pb-4">
                                    <div class="row mb-3">
                                        <div class="col-lg-12 m-auto">
                                            <h1 class="text-center ">
                                                There are <span class=" red-text">so many opportunities </span>for local careers!
                                            </h1>
                                        </div>
                                        {/* <div class="col-lg-12 m-auto text-center">
                                            <p>
                                                To find a great new job with one of the many amazing local employers in your area, simply select your industry, location and experience level below, as well as whether you’d like part-time, full-time, seasonal work or a full time career.
                                                <br /><br /> Your matches will automatically appear in the <b>“top job opportunities”</b> box below.
                                            </p>
                                        </div> */}
                                    </div> 
                                    <div class="row">
                             
                                <div class="col-12">
                                    <div class="white-bg px-4">
                                        <a href="#" class="reg_button btn btn-primary text-uppercase  d-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                            <span><img src="../images/filter-com.svg" width="42" alt="" /></span>
                                            <h3 class="mb-0 mx-2" onClick={filterFuncInit} id="filterInit">Filter Preferences</h3>
                                        </a>
                                    </div>
                                    <div id="availableJobsList_mobileResult" class="white-bg px-4 pt-4 pb-4">


                                        {
                                            availableJobsList_mobile.map((data, index) => (


                                                <div class="oppertunity-card2">
                                                    <div class="logo-org justify-content-start">
                                                        <img src={(data.logoUrl == "" || data.logoUrl == null) ?  "images/no-logo-sn.JPG" : data.logoUrl} alt="" />
                                                    </div>
                                                    <div class="right-info">
                                                        <div class="d-flex justify-content-between flex-lg-row flex-column">
                                                            <h4>
                                                                {/* {data.title?data.title:""} */}
                                                                <a href={`/jobsummary?jobId=${data.jobId}&page=`+currentPage} class="company-name">{data.title ? data.title : ""} </a>
                                                            </h4>
                                                            <div class="date">
                                                                <b class="d-flex text-uppercase">
                                                                    {/* <span>August 19</span>,
                                        <span class="ms-1"> 2021</span>  */}
                                                                    <span>{moment(data.createdDate).format("MMMM D, YYYY")}</span>
                                                                </b>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex mb-3">
                                                            <span><a href={`/companyDetails?companyId=${data.companyId}&page=`+currentPage}>{data.companyName ? data.companyName : ""} </a></span>
                                                            <span class="mx-3">|</span>
                                                            <span>  {data.city + ', '} {data.state}</span>
                                                        </div>
                                                        {/* <p class="mt-2">{`${data.summary.substring(0, 150)}...`}</p> */}
                                                        <p dangerouslySetInnerHTML={{ __html: data.summary.substring(0, 150)+"...  <a style='font-weight:normal;text-decoration:underline;' href='/jobsummary?jobId="+data.jobId+"&page="+currentPage+"'>View more</a>" }} />

                                                    </div>
                                                    <div class="job-seeker">
                                                        {/* <b class="text-uppercase mb-2">Perks</b>
                                                        <div class="job-seek-icon mb-3">
                                                    
                                                        <div class="d-flex align-items-center"> <img src="images/health.png" alt="" /></div>
                                                        <div class="d-flex align-items-center"> <img src="images/lunch-plate.png" alt="" /></div>
                                                        <div class="d-flex align-items-center"> <img src="images/dental.png" alt="" /></div>
                                                        <div class="d-flex align-items-center"> <img src="images/cofee.png" alt="" /></div>
                                                        <div class="d-flex align-items-center"> <img src="images/cofee.png" alt="" /></div>
                                                            <div><a href="#" class="js-more" data-bs-toggle="modal" data-bs-target="#exampleModal">more &#62;</a></div> 

                                                        </div> */}
                                                        <div class="text-center">
                                                            <a href={`/jobsummary?jobId=${data.jobId}&page=`+currentPage} class="reg-button">View JOB</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}


                                        <div class="paginationContent">
                                        <Pagination               
                                            total={totalItems_m}
                                            itemsPerPage={ITEMS_PER_PAGE}
                                            currentPage={currentPage_m}
                                            onPageChange={handlePageChange2_m}/>

                                        </div>


                                        </div>

                                </div>
                            
                              
                               

                            </div>
                                    {/* Category List */}
                                    {/* <div class="row" id="step1">
                                        <div class="text-center">
                                            <h1 class="mb-4">
                                                <span class=" red-text pb-2 border-bottom ">Step 1:</span>
                                            </h1>
                                            <h2 class="headtitle mt-3">Choose Your Industry</h2>
                                        </div>
                                        <div class="col-12 mt-3 p-3">
                                            <div class="mobile-form-avl p-3">
                                                <div class="category-check-itm">
                                                    <ul>
                                                        {
                                                            categories.map((data, index) => (


                                                                <li><input class="form-check-input searchCls" type="checkbox" name="industry_m" value={data.catId} id="industry" />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                            ))}

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-4 mb-4">
                                            <a  class="reg-button" onClick={() => submitStep1()}>Next &#62;&#62;</a>
                                        </div>
                                    </div> */}

                                    {/* Location List */}
                                    {/* <div class="row" id="step2">
                                        <div class="text-center">
                                            <h1 class="mb-4">
                                                <span class=" red-text pb-2 border-bottom ">Step 2:</span>
                                            </h1>
                                            <h2 class="headtitle mt-3">Choose Your Location</h2>
                                        </div>
                                        <div class="col-12 mt-3 p-3">
                                            <div class="mobile-form-avl p-3">
                                                <div class="category-check-itm">
                                                    <ul>
                                                        {
                                                            locationCounty.map((data, index) => (


                                                                <li><input class="form-check-input searchCls" type="checkbox" name="location_m" value={data.jobLocationCountyId} id="location" />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                            ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-4 mb-4">
                                            <a  class="reg-button" onClick={() => submitStep2()}>Next &#62;&#62;</a>
                                        </div>
                                    </div> */}

                                    {/* Job types List */}
                                    {/* <div class="row" id="step3">
                                        <div class="text-center">
                                            <h1 class="mb-4">
                                                <span class=" red-text pb-2 border-bottom ">Step 3:</span>
                                            </h1>
                                            <h2 class="headtitle mt-3">Choose Your Job Types</h2>
                                        </div>
                                        <div class="col-12 mt-3 p-3">
                                            <div class="mobile-form-avl p-3">
                                                <div class="category-check-itm">
                                                    <ul>
                                                        {
                                                            jobTypes.map((data, index) => (


                                                                <li><input class="form-check-input searchCls" type="checkbox" name="jobtype_m" value={data.catId} id="jobtype" />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                            ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-4 mb-4">
                                            <a  class="reg-button" onClick={() => submitStep3()}>Next &#62;&#62;</a>
                                        </div>
                                    </div> */}

                                    {/* Education List */}
                                    {/* <div class="row" id="step4">
                                        <div class="text-center">
                                            <h1 class="mb-4">
                                                <span class=" red-text pb-2 border-bottom ">Step 4:</span>
                                            </h1>
                                            <h2 class="headtitle mt-3">Choose Your Education</h2>
                                        </div>
                                        <div class="col-12 mt-3 p-3">
                                            <div class="mobile-form-avl p-3">
                                                <div class="category-check-itm">
                                                    <ul>
                                                        {
                                                            educations.map((data, index) => (


                                                                <li><input class="form-check-input searchCls" type="checkbox" name="education_m" value={data.educationId} id="education" />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                            ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-4 mb-4">
                                            <a  class="reg-button" onClick={() => submitStep4()}>Next &#62;&#62;</a>
                                        </div>
                                    </div> */}




                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="pb-5" id="step5">
                    <div class="container">
                        <div >
                            <div class="col-12">
                                <div class="white-bg px-4 pt-4 pb-4">


                                    {
                                        availableJobsList_m.map((data, index) => (


                                            <div class="oppertunity-card2">
                                                <div class="logo-org justify-content-start">
                                                    <img src={(data.logoUrl == '' || data.logoUrl == null) ? "images/no-logo-sn.JPG" : data.logoUrl } alt="" />
                                                </div>
                                                <div class="right-info">
                                                    <div class="d-flex justify-content-between flex-lg-row flex-column">
                                                        <h4>
                                                            {/* {data.title?data.title:""} */}
                                                            <a href={`/jobsummary?jobId=${data.jobId}&page=`+currentPage} class="company-name">{data.title ? data.title : ""} </a>
                                                        </h4>
                                                        <div class="date">
                                                            <b class="d-flex text-uppercase">
                                                                {/* <span>August 19</span>,
                                      <span class="ms-1"> 2021</span>  */}
                                                                <span>{moment(data.createdDate).format("MMMM D, YYYY")}</span>
                                                            </b>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex mb-3">
                                                        <span><a href={`/companyDetails?companyId=${data.companyId}&page=`+currentPage}>{data.companyName ? data.companyName : ""} </a></span>
                                                        <span class="mx-3">|</span>
                                                        <span>  {data.city + ', '} {data.state}</span>
                                                    </div>
                                                    {/* <p class="mt-2">{`${data.summary.substring(0, 150)}...`}</p> */}
                                                    <p dangerouslySetInnerHTML={{ __html: data.summary.substring(0, 150) }} />

                                                </div>
                                                <div class="job-seeker">
                                                    {/* <b class="text-uppercase mb-2">Perks</b>
                                                    <div class="job-seek-icon mb-3">
                                                 
                                                     <div class="d-flex align-items-center"> <img src="images/health.png" alt="" /></div>
                                                       <div class="d-flex align-items-center"> <img src="images/lunch-plate.png" alt="" /></div>
                                                       <div class="d-flex align-items-center"> <img src="images/dental.png" alt="" /></div>
                                                       <div class="d-flex align-items-center"> <img src="images/cofee.png" alt="" /></div>
                                                       <div class="d-flex align-items-center"> <img src="images/cofee.png" alt="" /></div>
                                                        <div><a href="#" class="js-more" data-bs-toggle="modal" data-bs-target="#exampleModal">more &#62;</a></div> 

                                                    </div> */}
                                                    <div class="text-center">
                                                        <a href={`/jobsummary?jobId=${data.jobId}&page=`+currentPage} class="reg-button">View JOB</a>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}


                                    <div class="paginationContent">
                                       <Pagination               
                                        total={totalItems_m}
                                        itemsPerPage={ITEMS_PER_PAGE}
                                        currentPage={currentPage_m}
                                        onPageChange={handlePageChange2_m}/>

                                       </div>


                                </div>

                               

                            </div>
                        </div>
                    </div>
                </section>
            </main>

            {/* <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">JOB PERKS</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body py-5">
                            <div class="job-seek-modal">
                                <div> <img src="images/health.png" alt="" /></div>
                                <div> <img src="images/lunch-plate.png" alt="" /></div>
                                <div> <img src="images/dental.png" alt="" /></div>
                                <div> <img src="images/cofee.png" alt="" /></div>
                                <div> <img src="images/sick.png" alt="" /></div>
                                <div> <img src="images/health.png" alt="" /></div>
                                <div> <img src="images/lunch-plate.png" alt="" /></div>
                                <div> <img src="images/dental.png" alt="" /></div>

                            </div>
                        </div>

                    </div>
                </div>
            </div> */}




                            <div class="filter-modal modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header justify-content-start">
                                                <button type="button" class="btn-close" style={{"position":"absolute","top":"10px","right":"10px"}} data-bs-dismiss="modal" id="closeMobilePopup" aria-label="Close"></button>
                                               
                                            </div>
                                            <div class="modal-body">
                                           
                                                {/* <div class="col-12 mb-4">
                                                    <label for="firstName" class="form-label">Location</label>
                                                    <select class="form-select" aria-label="Default select example">
                                                 <option selected>Select Location</option>
                                                 <option value="1">Ohio</option>
                                                 <option value="2">California</option>
                                                 <option value="3">Masyville</option>
                                              </select>
                                                </div> */}
                                                <div class="col-12 mb-4">
                                                    <label for="firstName" class="form-label">industry</label>
                                                    {/* <select class="form-select" aria-label="Default select example">
                                                 <option selected>Select industry</option>
                                                 <option value="1">Ohio</option>
                                                 <option value="2">California</option>
                                                 <option value="3">Masyville</option>
                                              </select> */}
                                                      <ReactSelect
                                                class="form-select"
                                                options={mulcategories}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                components={{
                                                    Option
                                                }}            
                                                onChange={handleChangeForCate}
                                                allowSelectAll={false}
                                                value={optionCatSelected}
                                              />
                                                </div>
                                               
                                                <div class="col-12 mb-4">
                                                    <label for="firstName" class="form-label">Location</label>
                                                    {/* <select class="form-select" aria-label="Default select example">
                                                 <option selected>Select industry</option>
                                                 <option value="1">Ohio</option>
                                                 <option value="2">California</option>
                                                 <option value="3">Masyville</option>
                                              </select> */}
                                                      <ReactSelect
                                                class="form-select"
                                                options={mullocationCounty}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                components={{
                                                    Option
                                                }}            
                                                onChange={handleChangeForLocation}
                                                allowSelectAll={false}
                                                value={mullocationCountyselected}
                                              />
                                                </div>
                                                <div class="col-12 mb-4">
                                                    <label for="firstName" class="form-label">Job Type</label>
                                                    {/* <select class="form-select" aria-label="Default select example">
                                                 <option selected>Select Job Type</option>
                                                 <option value="1">Ohio</option>
                                                 <option value="2">California</option>
                                                 <option value="3">Masyville</option>
                                              </select> */}
                                               <ReactSelect
                                                class="form-select"
                                                options={muljobTypes}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                components={{
                                                    Option
                                                }}            
                                                onChange={handleChange}
                                                allowSelectAll={true}
                                                value={optionSelected}
                                              />
                                                </div>

                                                <div class="col-12 mb-4 d-none">
                                                    <label for="firstName" class="form-label">Education</label>
                                                    <ReactSelect
                                                class="form-select"
                                                options={muleducation}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                components={{
                                                    Option
                                                }}            
                                                onChange={handleChangeForEdu}
                                                allowSelectAll={true}
                                                value={optionEduSelected}
                                              />
                                                    {/* <select class="form-select" aria-label="Default select example">
                                                 <option selected>Select Education</option>
                                                 <option value="1">Ohio</option>
                                                 <option value="2">California</option>
                                                 <option value="3">Masyville</option>
                                              </select> */}
                                                </div>

                                                
                                            </div>
                                            <div class="d-flex justify-content-center pb-5">
                                                <button type="button" class="btn btn-secondary text-uppercase mx-2"  onClick={()=>{ClearAll()}}>Reset</button>
                                                <button type="button" class="btn btn-primary text-uppercase" onClick={()=>{mobileFilterCall(ITEMS_PER_PAGE,"1")}}>Filter</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
        </>
    )
};

AvailableJobs.propTypes = {};

AvailableJobs.defaultProps = {};

export default AvailableJobs;
