import React,{history,useEffect, useState, useMemo } from "react";
import { useNavigate,useParams,useLocation,useHistory } from "react-router-dom";
import Services from "../services/services";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import moment from "moment";

const EventDetails = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const eventId = queryParams.get('eventId');
    const [eventDetails, setEventDetails] = useState([]);


    const getEventDetails = () => {
        Services.getEventDetailsByIdPub(eventId).then((res) => {    
          console.log("Industry data=" + JSON.stringify(res.data));    
          if(res){
            setEventDetails(res.data);
          }
          
    
        });
    
      }

    useEffect(() => {
        getEventDetails();
    }, []);
  return(
  <>
   <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav style={{"--bs-breadcrumb-divider": '>'}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item headtitle "><a href="/events">Events <span style={{color: "#6c757d"}}>&#62;</span></a></li>
                                <li class="breadcrumb-item headtitle "><a href="#">View Details</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>

        {/* <!-- info typography --> */}
        <section class="company-detail-wrap py-5 ">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-4">
                            <div class="grid-item p-3 border-0">
                                <div class="d-flex align-items-center">

                                    <div class="grid-title-wrap">
                                        <h2 class="margin-bottom-0">{eventDetails.eventTitle} </h2>

                                    </div>
                                </div>
                                <hr/>



                                <div class="grid-body mb-4">
                                    <div class="grid-body-item">

                                        <div class="grid-body-inn">
                                            <span>When:</span>
                                            <span>{moment(eventDetails.eventDate).format("D-MMM-YY")}</span>
                                        </div>
                                        <div class="grid-body-inn">
                                            <span>Where:</span>
                                            <span> 
                                                {eventDetails.eventAddress}
                                                {eventDetails.eventAddress!=''? ',' : ''}
                                                {eventDetails.eventCity}
                                                {eventDetails.eventCity!=''? ',' : ''}
                                                {eventDetails.eventZip}
                                            </span>
                                        </div>


                                    </div>
                                </div>
                                <div class="quickinfo-social justify-content-start ">
                                    <a href="#"><img src="images/FACEBOOK 2.png" alt=""/></a>
                                    <a href="#"><img src="images/INSTA 2.png" alt=""/></a>
                                    <a href="#"><img src="images/TWITTER 2.png" alt=""/></a>
                                    <a href="#"><img src="images/LINKEDIN 2.png" alt=""/></a>
                                    <a href="#"><img src="images/YOUTUBE 2.png" alt=""/></a>
                                </div>
                                <hr/>
                                <div class="view-item mb-4">
                                    <h5>Information</h5>
                                    {/* <p>
                                    {eventDetails.eventDescription}
                                    </p> */}

                                    <p dangerouslySetInnerHTML={{ __html: eventDetails.eventDescription  }} />    
                                    {/* <div class="my-4">
                                        <img src="images/Bitmap.png" class="w-100" alt=""/>
                                    </div> */}
                                    <hr/>
                                </div>

                                <a target="_blank" href={eventDetails.eventExternalLink} class="reg-button">Learn more</a>


                            </div>
                        </div>

                    </div>
                </div>
                </div>
        </section>



    </main> 
  </>
)};

EventDetails.propTypes = {};

EventDetails.defaultProps = {};

export default EventDetails;
